export default {
  auth: {
    login: "api/auth/login/",
    register: "/api/auth/register/",
    profile:"api/auth/profile/",
    approveUsers:"api/auth/new_user/",
    approveLists:'api/auth/approved_list/',
    rejectLists:"api/auth/rejected_list/"
  },
  project: {
    baseUrl: "api/project/",
    file: "api/userfile/",
    adduser:'users/'
  },
  calculation: {
    listOfCalculations: "api/category/",
  },
  member: {
    listOfMembers: "api/user/",
  },

  canvas: {
    baseURl: "api/column/",
    addRow:'api/add_row/',
    updateRow:'api/columnrow/',
    removeRow: "/col/delete_main_row/",
    rebarRow:'api/column-rebar/',
    deleteRebarRow:'api/column-rebar-row/',
    endRow:'api/column-endcondition',
    loadRow:'api/column-load',
    load_design_add_row:'api/column-load-row/',
    load_design_remove_row: 'col/delete_load_row/',
    addLoadDesignRow:'api/column-load-row/',
    
  },
  settings:{
    projectDetails:'api/projectsetting/',
    projectMaterial:'api/project-materials/',
    projectUnlock:'delete_calcs',
    projectMaterialBulkUpdate:'api/project-materials/bulk_update/'

  }
};
