import React from "react";
import { Flex } from "@chakra-ui/react";

// Custom components
import logo from "../../../images/logo_12.png";
import { HSeparator } from "components/misc/separator/Separator";
import tw from "twin.macro";
const LogoLink = tw.a`flex items-center font-black border-b-0 text-3xl! ml-0!`;

export function SidebarBrand() {
  return (
    <Flex align="center" direction="column">
      <LogoLink href="/admin">
        <img src={logo} width={70} height={70} style={{ marginBlock: '37px' }} />
        {/* TEDAPS */}
      </LogoLink>
      <HSeparator mb="20px" />
    </Flex>
  );
}

export default SidebarBrand;
