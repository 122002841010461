import React from "react";
import {
  Chart as ChartJS,
  LinearScale,
  PointElement,
  Tooltip,
  Legend,
} from "chart.js";
import { Bubble } from "react-chartjs-2";
import { colors } from "components/misc/colors";
import { Box } from "@chakra-ui/react";
ChartJS.register(LinearScale, PointElement, Tooltip, Legend);

function Circle({ data, width, height }) {
  const options = {
    maintainAspectRatio: false,
    responsive:true,
    chartArea: {
      backgroundColor: "lightgray",
    },
    scales: {
      y: {
        beginAtZero: true,
        max: height,
        grid: {
          display: false,
        },
      },
      x: {
        beginAtZero: true,
        max: width,
        grid: {
          display: false,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: (context) =>
            context.formattedValue + ` (no:${context?.raw?.no})` || "",
        },
      },
    },
  };
  const chartData = {
    datasets: [
      {
        data: data,
        backgroundColor: colors.buttonBG,
        radius: 12,
      },
    ],
  };

  const plugins = [
    {
      beforeDraw: function (chart) {
        if (
          chart.config.options.chartArea &&
          chart.config.options.chartArea.backgroundColor
        ) {
          var ctx = chart.ctx;
          var chartArea = chart.chartArea;

          ctx.save();
          ctx.fillStyle = chart.config.options.chartArea.backgroundColor;
          ctx.fillRect(
            chartArea.left,
            chartArea.top,
            chartArea.right - chartArea.left,
            chartArea.bottom - chartArea.top
          );
          ctx.restore();
        }
      },
    },
  ];

  const scalew =
    width <= 500
      ? "55%"
      : width > 500 && width < 1000
      ? `${width / 10}%`
      : "100%";
  const canvasScale = (size) => {
    return size >= 1000 && size <= 3000
      ? size >= 1000 && size <= 1500
        ? size / 4
        : size >= 1500 && size <= 2000
        ? size / 8
        : size / 10
      : 350;
  };
  return (
    // <Box
    //   width={width === height ? '60%' : scalew}
    //   // w={width}
    //   // height={height}
    //   margin={'auto'}
    // >
      

    // </Box>
    <Bubble
      data={chartData}
      options={options}
      // style={{width:`${canvasScale(width)}px`,height:`${canvasScale(height)}px`}}
      plugins={plugins}
    />
    
  );
}

export default Circle;
