import axiosInstance from "api/config";
import { apiCallBegin, apiCallFailed, apiCallSuccess } from "store/api";

const request =
  ({ dispatch, getState }) =>
  (next) =>
  async (action) => {
    if (action.type !== apiCallBegin?.type) return next(action);

    const {
      url,
      formData,
      deleteData,
      jsonData,
      method = "get",
      onSuccess,
      onError,
      onStart,
    } = action.payload;

    if (onStart) dispatch({ type: onStart });

    next(action);

    try {
      let form = false;
      if (formData) {
        form = new FormData();
        Object.keys(formData).map((field) => {
          form.append(field, formData[field]);
        });
      }

      //adding JWT token
      const token =
        localStorage.getItem("access_token") || getState().auth.user.token;
      axiosInstance.defaults.headers = token
        ? { Authorization: `Bearer ${token}` }
        : {};

      const { data, config, status } = await axiosInstance[method](
        url,
        form || jsonData || { data: { pks: deleteData } }
      );

      const conf = { method: config.method, url: config.url, status };
      //login and all other api request have different response, so that why two condition for 200 status code
      if (status >= 200 && status < 300) {
        const dataType = Array.isArray(data) ? "array" : "object";
        let previous = dataType === "array" ? [jsonData] : { ...jsonData };

        if (deleteData) {
          previous = { ids: deleteData };
        }

        dispatch(apiCallSuccess({ ...data, config: conf, status }));
        if (onSuccess) dispatch({ type: onSuccess, payload: data, previous });
      } else {
        dispatch(apiCallFailed({ ...data, config: conf }));
        if (onError) dispatch({ type: onError, payload: data });
      }
    } catch (error) {
      const { message, status, code, config } = error;
      const conf = { method: config.method, url: config.url, status };
      dispatch(
        apiCallFailed({
          message,
          status: status || error.response.status,
          code,
          config: conf,
        })
      );
      if (onError)
        dispatch({
          type: onError,
          payload: { message, status: status || error.response.status, code },
        });
    }
  };
export default request;
