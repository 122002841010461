import React, { useEffect, useRef } from "react";
import * as THREE from "three";
import { Text } from 'troika-three-text';
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import {
    AddManualMesh,
    AddRebar,
    ExportJson,
    GetDis,
    GetRGBCol,
    GetShapeMesh,
} from "./data/common";
import { Button } from "@chakra-ui/react";

const CanvasComponent = ({ pageKey, wSize, mapData, containerId }) => {
    // assuming this value is constant
    // assuming default size

    const sceneRef = useRef();
    const rendererRef = useRef();
    const cameraRef = useRef();
    const controlsRef = useRef();

    useEffect(() => {
        initScene();
        loadModel();
        animate();

        return () => {
            rendererRef.current.dispose();
        };
    }, []);

    const loadModel = () => {
        const verArr = [],
            faceArr = [],
            lineArr = [],
            { pointData, surfaceData, lineData, maxSize, labels } = mapData;
        let dis = -Infinity;

        // Load point data
        pointData.forEach((ver) => {
            const id = ver[0],
                posInfo = ver[1],
                r = ver[2],
                color = GetRGBCol(ver[3]),
                opa = ver[4];
            const pos = { x: posInfo[0], y: posInfo[2], z: posInfo[1] };
            verArr.push({ id, pos, r, color, opa });
            const pointGeo = new THREE.SphereGeometry(r, 12, 12);
            const pointMat = new THREE.MeshStandardMaterial({
                color,
                transparent: true,
                opacity: opa,
            });
            const pointMesh = new THREE.Mesh(pointGeo, pointMat);
            pointMesh.position.set(pos.x, pos.y, pos.z);
            sceneRef.current.add(pointMesh);
        });

        // Load surface data
        surfaceData.forEach((face) => {
            const id = face[0],
                posIdArr = face[1],
                thickInfo = face[2],
                edgeColor = GetRGBCol(face[3]),
                edgeRadius = face[4] / 2,
                edgeOpa = face[5],
                faceColor = GetRGBCol(face[6]),
                faceOpa = face[7];
            const posArr = [];
            posIdArr.forEach((posId, posIdx) => {
                const posItem = verArr.find((ver) => ver.id === posId);
                const nextIdx = posIdx === posIdArr.length - 1 ? 0 : posIdx + 1;
                const nextPosItem = verArr.find((ver) => ver.id === posIdArr[nextIdx]);
                posArr.push(posItem.pos);
                AddRebar(
                    sceneRef.current,
                    [posItem.pos, nextPosItem.pos],
                    edgeRadius,
                    edgeRadius,
                    edgeColor,
                    edgeOpa
                );
                const verDis = GetDis({ x: 0, y: 0, z: 0 }, posItem.pos);
                if (verDis > dis) dis = verDis;
            });
            const { shapeMesh, manualAng, lastNode } = GetShapeMesh(
                posArr,
                faceColor,
                faceOpa,
                thickInfo
            );
            AddManualMesh(sceneRef.current, shapeMesh, manualAng, lastNode);
        });

        // Load line data
        lineData.forEach((line) => {
            const id = line[0],
                posIdArr = line[1],
                thickInfo = line[2],
                lineCol = GetRGBCol(line[3]),
                lineOpa = line[4];
            const posArr = [];
            posIdArr.forEach((posId) => {
                const posItem = verArr.find((ver) => ver.id === posId);
                posArr.push(posItem.pos);
            });
            const lineThick = { w: 1, d: 1 };
            let type = "box";
            if (thickInfo[2] !== "NA") {
                lineThick.w = thickInfo[2];
                lineThick.d = thickInfo[2];
                type = "cylinder";
            } else if (thickInfo[0] !== "NA" && thickInfo[1] !== "NA") {
                lineThick.w = thickInfo[0];
                lineThick.d = thickInfo[1];
            }
            AddRebar(
                sceneRef.current,
                posArr,
                lineThick.w,
                lineThick.d,
                lineCol,
                lineOpa,
                type
            );
        });

        // const labels = [
        //     {
        //         "text": "25",
        //         "position": [-120, 420, 620],
        //         "angle": [0, 90, 0]
        //     },
        //     {
        //         "text": "800",
        //         "position": [-150, -700, 0],
        //         "angle": [90, 90, 0]
        //     },
        //     {
        //         "text": "300",
        //         "position": [0, -700, 400],
        //         "angle": [-90, 0, -180]
        //     },
        // ]

        labels && labels?.forEach((label) => {
            const textMesh = new Text();
            textMesh.text = label.text;
            textMesh.fontSize = 40;
            textMesh.color = '#000';
            // textMesh.anchorX = 'center';
            // textMesh.anchorY = 'middle';
            textMesh?.position.set(label.position[0], label.position[2], label.position[1]);
            textMesh?.rotation?.set(
                THREE.MathUtils.degToRad(label.angle[0]),
                THREE.MathUtils.degToRad(label.angle[1]),
                THREE.MathUtils.degToRad(label.angle[2])
            );

            textMesh.sync();
            sceneRef.current.add(textMesh);
        });

        maxSize && cameraRef.current.position.set(maxSize[0], maxSize[1], maxSize[2]);
        cameraRef.current.lookAt(0, 0, 0);
    };

    const initScene = () => {
        rendererRef.current = new THREE.WebGLRenderer({ antialias: true });
        rendererRef.current.setSize(wSize.width, wSize.height);
        const container = document.getElementById(containerId);
        container.appendChild(rendererRef.current.domElement);

        rendererRef.current.setClearColor(0xffffff, 1);

        sceneRef.current = new THREE.Scene();
        cameraRef.current = new THREE.PerspectiveCamera(
            60,
            wSize.width / wSize.height,
            1,
            100000
        );

        sceneRef.current.add(cameraRef.current);

        controlsRef.current = new OrbitControls(
            cameraRef.current,
            rendererRef.current.domElement
        );
        controlsRef.current.enablePan = false;

        const ambientLight = new THREE.AmbientLight(0xffffff, 0.3);
        sceneRef.current.add(ambientLight);

        const helper = new THREE.GridHelper(5000, 20, 0xd3d3d3, 0xd3d3d3);
        sceneRef.current.add(helper);

        const axesHelper = new THREE.AxesHelper(5000);
        var colors = axesHelper.geometry.attributes.color;
        colors.setXYZ(0, 1, 0, 0);
        colors.setXYZ(1, 1, 0, 0);
        colors.setXYZ(2, 0, 1, 0);
        colors.setXYZ(3, 0, 1, 0);
        colors.setXYZ(4, 0, 0, 1);
        colors.setXYZ(5, 0, 0, 1);
        sceneRef.current.add(axesHelper);

        const createDottedLine = (start, end, color) => {
            const material = new THREE.LineDashedMaterial({
                color,
                dashSize: 50,
                gapSize: 50,
            });

            const points = [start, end];
            const geometry = new THREE.BufferGeometry().setFromPoints(points);
            const line = new THREE.Line(geometry, material);
            line.computeLineDistances();
            return line;
        };

        const negXAxis = createDottedLine(
            new THREE.Vector3(-5000, 0, 0),
            new THREE.Vector3(0, 0, 0),
            0xff0000 //red
        );
        const negYAxis = createDottedLine(
            new THREE.Vector3(0, -5000, 0),
            new THREE.Vector3(0, 0, 0),
            0x00ff00 //green
        );
        const negZAxis = createDottedLine(
            new THREE.Vector3(0, 0, -5000),
            new THREE.Vector3(0, 0, 0),
            0x0000ff //blue
        );

        sceneRef.current.add(negXAxis);
        sceneRef.current.add(negYAxis);
        sceneRef.current.add(negZAxis);

        const shadowLight = new THREE.DirectionalLight(0xffffff, 0.7);
        sceneRef.current.add(shadowLight);

        const axisLabels = [
            { text: 'X-axis', position: [1250, 0, 0] },
            { text: 'Z-axis', position: [0, 1250, 0] },
            { text: 'Y-axis', position: [0, 0, 1250] }
        ];
        axisLabels.forEach(label => {
            const textMesh = new Text();
            textMesh.text = label.text;
            textMesh.fontSize = 20;
            textMesh.color = '#000';
            textMesh.anchorX = 'center';
            textMesh.anchorY = 'middle';
            textMesh.position.set(...label.position);
            textMesh.sync();

            sceneRef.current.add(textMesh);
        });

        // Add other lights or elements if needed
    };


    // const exportJson = () => {
    //   ExportJson({
    //     pointData: mapData.pointData,
    //     surfaceData: mapData.surfaceData,
    //     lineData: mapData.lineData,
    //   });
    // };

    const animate = () => {
        rendering();
        requestAnimationFrame(animate);
        sceneRef.current.children.forEach(child => {
            if (child instanceof Text) {
                child.lookAt(cameraRef.current.position);
            }
        });

        controlsRef.current.update();
        rendererRef.current.render(sceneRef.current, cameraRef.current);
    };

    const rendering = () => {
        if (!cameraRef.current || !rendererRef.current) return;
        rendererRef.current.render(sceneRef.current, cameraRef.current);
    };

    return (
        <div
            className={`back-board canvas ${pageKey === "canvas" ? "active" : ""}`}
        >
            <div id={containerId}></div>
            <div
                className="setting"
                style={{
                    position: "absolute",
                    bottom: "0",
                    cursor: "pointer",
                }}
            >
                {/* <Button
          className="export-json button"
          onClick={(e) => {
            e.stopPropagation();
            exportJson();
          }}
        >
          Export Json
        </Button> */}
            </div>
        </div>
    );
};

export default CanvasComponent;
