import { Box, Button, IconButton } from "@chakra-ui/react";
import React from "react";
import MainComponent from "../three/Main";
import { loadGraph } from "store/slices/entities/loadGraph";
import { useSelector } from "react-redux";
import { getLoadGraph } from "store/selector";
import { CloseIcon } from "@chakra-ui/icons";
import { useEffect } from "react";

function Graph({ link, onClose }) {
    const { data_points, loading } = useSelector(getLoadGraph);

    useEffect(() => {
        loadGraph(link);
    }, []);

    return (
        <Box
            w="1100px"
            h="728px"
            backgroundColor="gray.100"
            display="flex"
            justifyContent="center"
            alignItems="center"
            position="relative"
        >
            {!loading ? (
                <MainComponent
                    wSize={{ width: 1024, height: 728 }}
                    mapData={data_points}
                    containerId={"graph"}
                />
            ) : (
                <h1>Loading Graph...</h1>
            )}
            {/* <Button position="absolute" top="0" right="0" onClick={onClose}>
        <CloseIcon>
      </Button> */}
        </Box>
    );
}

export default Graph;
