import tw from "twin.macro";
import { Button as BaseBtn } from "@chakra-ui/react";
export const PrimaryButton = tw.button`px-8 py-3 font-bold rounded-lg bg-other-buttonBG  text-gray-100  hocus:text-gray-200 focus:shadow-outline focus:outline-none transition duration-300`;

export const Button = (props) => (
  <BaseBtn
    {...props}
    size={"sm"}
    
    fontSize={"16px"}
    colorScheme={props.colorScheme || "teal"}
    variant={props.varient || 'outline'}
  />
);
