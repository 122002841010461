import PropTypes from "prop-types";
import React, { useContext } from "react";
import {
    Avatar,
    Flex,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Text,
    useColorModeValue,
} from "@chakra-ui/react";
import { useHistory } from "react-router-dom";

import { SidebarResponsive } from "components/sidebar/Sidebar";
import routes from "router/routes";
import { adminRoutes } from "router/routes";
import store from "store/store";
import { logOutUser } from "store/slices/auth/user";
import { colors } from "components/misc/colors";
import { useSelector } from "react-redux";
import { getAuth } from "store/selector";

export default function HeaderLinks(props) {
    const { secondary } = props;
    const { profile } = useSelector(getAuth);
    const history = useHistory();
    const currentUser = history?.location.pathname.split("/")[1];
    const handleLogout = () => {
        store.dispatch(logOutUser());
    };

    let menuBg = useColorModeValue("white", "navy.800");
    const textColor = useColorModeValue("secondaryGray.900", "white");
    const borderColor = useColorModeValue("#E6ECFA", "rgba(135, 140, 189, 0.3)");
    const shadow = useColorModeValue(
        "14px 17px 40px 4px rgba(112, 144, 176, 0.18)",
        "14px 17px 40px 4px rgba(112, 144, 176, 0.06)"
    );
    return (
        <Flex
            w={{ sm: "auto", md: "auto" }}
            alignItems="center"
            flexDirection="row"
            bg={menuBg}
            flexWrap={secondary ? { base: "wrap", md: "nowrap" } : "unset"}
            p="10px"
            borderRadius="30px"
            boxShadow={shadow}
        >
            <SidebarResponsive
                routes={currentUser === "admin" ? adminRoutes : routes}
            />
            <Menu>
                <MenuButton p="0px">
                    <Avatar
                        _hover={{ cursor: "pointer" }}
                        color="white"
                        name={profile?.username}
                        bg={"rgba(51,117,184,1)"}
                        size="sm"
                        w="25px"
                        h="25px"
                    />
                </MenuButton>
                <MenuList
                    boxShadow={shadow}
                    p="0px"
                    mt="10px"
                    borderRadius="20px"
                    bg={menuBg}
                    border="none"
                >
                    <Flex w="100%" mb="0px">
                        <Text
                            ps="20px"
                            pt="16px"
                            pb="10px"
                            w="100%"
                            textTransform={"capitalize"}
                            borderBottom="1px solid"
                            borderColor={borderColor}
                            fontSize="sm"
                            fontWeight="700"
                            color={textColor}
                        >
                            👋&nbsp; Hey, {profile?.username}
                        </Text>
                    </Flex>
                    <Flex flexDirection="column" p="10px">
                        {/* <MenuItem
              _hover={{ bg: "none" }}
              _focus={{ bg: "none" }}
              borderRadius="8px"
              px="14px"
            >
              <Text fontSize="sm">Profile Settings</Text>
            </MenuItem> */}
                        <MenuItem
                            _hover={{ bg: "none" }}
                            _focus={{ bg: "none" }}
                            color="red.400"
                            borderRadius="8px"
                            px="14px"
                        >
                            <Text onClick={handleLogout} fontSize="sm">
                                Logout
                            </Text>
                        </MenuItem>
                    </Flex>
                </MenuList>
            </Menu>
        </Flex>
    );
}

HeaderLinks.propTypes = {
    variant: PropTypes.string,
    fixed: PropTypes.bool,
    secondary: PropTypes.bool,
    onOpen: PropTypes.func,
};
