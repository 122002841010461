import {
    Flex,
    Table,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    useColorModeValue,
    Icon as BaseIcon,
    Input,
    useDisclosure,
} from "@chakra-ui/react";
import React, { useMemo, useState } from "react";
import {
    useGlobalFilter,
    usePagination,
    useSortBy,
    useTable,
} from "react-table";
import { MdDelete, MdEdit } from "react-icons/md";
import { TiTick } from "react-icons/ti";
import { ImCross } from "react-icons/im";

// Custom components
import Card from "components/misc/card/Card";
import { dateFormat } from "components/helpers/convertDate";
import store from "store/store";
import { deleteFile, editProjectFile } from "store/slices/entities/projects";
import { getProjects } from "store/selector";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import Modal from "components/common/Modal";

const Icon = ({ w = 5, h = 5, color, icon, onClick, cell }) => (
    <BaseIcon
        w={w}
        h={h}
        mr={2}
        color={color}
        cursor={"pointer"}
        as={icon}
        onClick={() => onClick(cell)}
    />
);

export default function ManageFiles({ projectID }) {
    const columnsData = [
        {
            Header: "File Name",
            accessor: "name",
        },
        {
            Header: "Created Date",
            accessor: "created_on",
        },
        {
            Header: "Project Type",
            accessor: "calc_type",
        },
        {
            Header: "Created By",
            accessor: "created_by",
        },
        {
            Header: "",
            accessor: "edit",
        },
    ];

    const { list: projects } = useSelector(getProjects);

    const project = projects?.filter((pro) => pro.id === projectID);
    const files = project[0]?.userfile_set;

    const tableData = files?.map((file) => ({
        id: file.id,
        name: file.name,
        created_on: dateFormat(file.created_on),
        calc_type: file.calc_type,
        created_by: file.created_by,
        edit: false,
    }));
    const initial = { name: "", id: "" };
    const [columnData, setColumnsData] = useState(columnsData);
    const [tableDatas, setTableDatas] = useState(tableData);
    const [fileEdit, setFileEdit] = useState(initial);
    const [modalInfo, setModalInfo] = useState();

    const columns = useMemo(() => columnData, [columnData]);
    const data = useMemo(() => tableDatas, [tableDatas]);

    const tableInstance = useTable(
        {
            columns,
            data,
        },
        useGlobalFilter,
        useSortBy,
        usePagination
    );

    const { headerGroups, page, prepareRow, initialState } = tableInstance;

    const { isOpen, onClose, onOpen } = useDisclosure();
    const textColor = useColorModeValue("secondaryGray.900", "white");
    const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
    const deleteIcon = useColorModeValue("red.400");
    const editIcon = useColorModeValue("gray.400");

    const enableEdit = (props) => {
        const {
            row: {
                original: { id, name },
            },
        } = props;
        const previousEdit = tableDatas?.map((data) =>
            data.edit ? { ...data, edit: false } : data
        );
        const newData = previousEdit?.map((data) =>
            data.id === id ? { ...data, edit: !data.edit } : data
        );
        setTableDatas(newData);
        setFileEdit({ id, name });
    };

    const disableEdit = (props) => {
        const {
            row: {
                original: { id },
            },
        } = props;
        const newData = tableDatas?.map((data) =>
            data.id === id ? { ...data, edit: false } : data
        );
        setTableDatas(newData);
    };

    const fileEditSubmit = ({ row: { original } }) => {
        const { id, name } = fileEdit;
        const newData = tableDatas?.map((data) =>
            data.id === id ? { ...data, name, edit: false } : data
        );
        setTableDatas(newData);
        store.dispatch(editProjectFile(id, name));
    };

    const fileDelete = ({ row: { original } }) => {
        store.dispatch(deleteFile(original.id, projectID));
        onClose(true);
    };

    useEffect(() => {
        setTableDatas(tableData);
    }, [files]);

    return (
        <>
            <Card
                direction="column"
                w="100%"
                pl="10px"
                overflowX={{ sm: "scroll", lg: "hidden" }}
            >
                <Table variant="simple" color="gray.500" mb="24px">
                    <Thead>
                        {headerGroups.map((headerGroup, index) => (
                            <Tr key={index}>
                                {headerGroup.headers.map((column, index) => (
                                    <Th pe="10px" key={index} borderColor={borderColor}>
                                        <Flex
                                            justify="space-between"
                                            align="center"
                                            fontSize={{ sm: "10px", lg: "12px" }}
                                            color="gray.400"
                                        >
                                            {column.render("Header")}
                                        </Flex>
                                    </Th>
                                ))}
                            </Tr>
                        ))}
                    </Thead>
                    <Tbody>
                        {page.map((row, index) => {
                            prepareRow(row);
                            return (
                                <Tr key={index}>
                                    {row.cells.map((cell, index) => {
                                        const {
                                            row: { original },
                                        } = cell;

                                        return (
                                            <Td
                                                key={index}
                                                fontSize={{ sm: "14px" }}
                                                minW={{
                                                    sm: "150px",
                                                    md: "200px",
                                                    lg: "auto",
                                                }}
                                                maxW={{
                                                    sm: "200px",
                                                    md: "230px",
                                                    lg: "130px",
                                                }}
                                                borderColor="transparent"
                                            >
                                                {cell.column.Header != "" ? (
                                                    <>
                                                        {original.edit &&
                                                            cell.column.Header == "File Name" ? (
                                                            <Input
                                                                onChange={(event) =>
                                                                    setFileEdit({
                                                                        name: event.target.value,
                                                                        id: original.id,
                                                                    })
                                                                }
                                                                borderColor={"purple.500"}
                                                                w={"80px"}
                                                                value={fileEdit.name}
                                                            />
                                                        ) : (
                                                            <Text
                                                                color={textColor}
                                                                fontSize="sm"
                                                                fontWeight="700"
                                                            >
                                                                {cell.value}
                                                            </Text>
                                                        )}
                                                    </>
                                                ) : (
                                                    <>
                                                        {original.edit ? (
                                                            <Flex
                                                                justifyContent="center"
                                                                flexDirection={"row"}
                                                                alignItems={"center"}
                                                            >
                                                                <Icon
                                                                    w={6}
                                                                    h={6}
                                                                    color={editIcon}
                                                                    icon={TiTick}
                                                                    onClick={fileEditSubmit}
                                                                    cell={cell}
                                                                />
                                                                <Icon
                                                                    w={4}
                                                                    h={4}
                                                                    color={editIcon}
                                                                    icon={ImCross}
                                                                    onClick={disableEdit}
                                                                    cell={cell}
                                                                />
                                                            </Flex>
                                                        ) : (
                                                            <Flex
                                                                justifyContent="center"
                                                                alignItems={"center"}
                                                            >
                                                                <Icon
                                                                    color={editIcon}
                                                                    icon={MdEdit}
                                                                    onClick={enableEdit}
                                                                    cell={cell}
                                                                />
                                                                <Icon
                                                                    color={deleteIcon}
                                                                    icon={MdDelete}
                                                                    onClick={() => {
                                                                        setModalInfo(cell);
                                                                        onOpen(true);
                                                                    }}
                                                                    cell={cell}
                                                                />
                                                            </Flex>
                                                        )}
                                                    </>
                                                )}
                                            </Td>
                                        );
                                    })}
                                </Tr>
                            );
                        })}
                    </Tbody>
                </Table>
            </Card>
            <Modal
                isOpen={isOpen}
                modalHead="Confirm"
                children={<Text>Are you sure you want to delete this file ?</Text>}
                onClose={onClose}
                onProcced={() => fileDelete(modalInfo)}
                confirmText={"Delete"}
            />
        </>
    );
}
