import { createSelector } from "reselect";

//selectors
export const getAuth = (state) => state.auth.user;
export const getLoading = (state) => state.auth.user.loading;
export const getCanvasDetails = (state) => state.entities.projectCanvas;
export const getRebars = createSelector(
  (state) => state.entities.rebars,
  (rebars) => rebars
);

export const getLoadDesign = createSelector(
  (state) => state.entities.loadDesign,
  (loads) => loads
);

export const getLoadGraph = createSelector(
  (state) => state.entities.loadGraph,
  (graph) => graph
);
export const getLoadGeometry = createSelector(
  (state) => state.entities.loadGeometry,
  (geometry) => geometry
);

export const getEndCondition = createSelector(
  (state) => state.entities.endCondition,
  (end) => end
);

export const getProjects = createSelector(
  (state) => state.entities.projects,
  (projects) => projects
);
export const getCalcus = createSelector(
  (state) => state.entities.calculations,
  (calus) => calus
);

export const getMembers = createSelector(
  (state) => state.entities.members.list,
  (members) =>
    members.map((member) => ({
      id: member.id,
      label: member.username,
      value: member.username,
    }))
);

export const getSettings = createSelector(
  (state) => state.entities.settings,
  (settings) => settings
);
