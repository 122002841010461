import { apiCallBegin } from "store/api";
import store from "store/store";

const { createSlice } = require("@reduxjs/toolkit");

const slice = createSlice({
  name: "loadGraph",
  initialState: {
    data_points: {
      pointData: [],
      surfaceData: [],
      lineData: [],
    },
    loading: false,
  },
  reducers: {
    graphRequested: (graph) => {
      graph.loading = true;
    },
    graphRequestFailed: (graph) => {
      graph.data_points = {
        pointData: [],
        surfaceData: [],
        lineData: [],
      };
      graph.loading = false;
    },

    graphReceived: (graph, action) => {
      if (action.payload && action.payload.error) {
        graph.data_points = {
          pointData: [],
          surfaceData: [],
          lineData: [],
        };
      } else {
        graph.data_points = action.payload;
      }
      graph.loading = false;
    },
  },
});

const { graphRequestFailed, graphReceived, graphRequested } = slice.actions;
export default slice.reducer;

export const loadGraph = (url) => {
  return store.dispatch(
    apiCallBegin({
      url,
      onSuccess: graphReceived.type,
      onStart: graphRequested.type,
      onError: graphRequestFailed.type,
    })
  );
};
