import { Avatar, Box, Flex, HStack, Icon, Menu, MenuButton, MenuItem, MenuList, Modal, Portal, Text, useColorModeValue, useDisclosure } from "@chakra-ui/react";
import { MdHome } from "react-icons/md";
import { AiFillContacts, AiOutlineFundProjectionScreen } from "react-icons/ai";
import { FaGraduationCap } from "react-icons/fa";
import { NavLink, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { getCanvasDetails } from "store/selector"
import { getAuth } from "store/selector";
import store from "store/store";
import { logOutUser } from "store/slices/auth/user";
import logo from "../../../images/logo_12.png";


import { changeProject } from "store/slices/entities/projectCanvas";
import { useEffect } from "react";

const Sidebar = ({ routes, feedbackModal }) => {
    const { selectedProject } = useSelector(getCanvasDetails);
    const { profile } = useSelector(getAuth);


    let location = useLocation();
    let activeColor = useColorModeValue("gray.700", "white");
    let inactiveColor = useColorModeValue(
        "secondaryGray.600",
        "secondaryGray.600"
    );
    let activeIcon = useColorModeValue("#217BF4", "white");
    let textColor = useColorModeValue("secondaryGray.500", "white");
    let brandColor = useColorModeValue("#217BF4", "brand.400");

    const activeRoute = (routeName) => {
        return location.pathname.includes(routeName);
    };

    const handleLogout = () => {
        store.dispatch(logOutUser());
    };

    const renderUserLinks = (selectedProject) => (
        <Box>
            <HStack>
                <Flex w={"full"} display="flex" flexDirection="column" alignItems='center' justifyContent='center'>
                    <Flex>
                        <img src={logo} width={48} height={48} style={{ marginBlock: '37px' }} alt="logo" />
                    </Flex>
                    <NavLink to="/user/dashboard" onClick={() => { changeProject("") }} style={{ cursor: "pointer" }}>
                        <Icon as={MdHome} width="25px" height="25px" color={(!selectedProject && (activeRoute("/user/calculation") || activeRoute("/user/dashboard"))) ? activeIcon : textColor} mb="26px" />
                    </NavLink>
                    <NavLink to="/user/calculation">
                        <Icon as={AiOutlineFundProjectionScreen} width="25px" height="25px" color={(activeRoute("/user/calculation") || activeRoute("/user/dashboard")) && selectedProject ? activeIcon : textColor} mb="26px" />
                    </NavLink>
                    <NavLink to="/user/e-learning" onClick={feedbackModal.onOpen}>
                        <Icon as={FaGraduationCap} width="25px" height="25px" color={activeRoute("/user/e-learning") ? activeIcon : textColor} mb="26px" />
                    </NavLink>
                    <NavLink to="/user/contact" style={{ cursor: "pointer" }}>
                        <Icon as={AiFillContacts} width="25px" height="25px" color={activeRoute("/user/contact") ? activeIcon : textColor} />
                    </NavLink>
                    <Flex bottom={"30"} position={"absolute"}>
                        <Box>
                            <Menu>
                                <MenuButton>
                                    <Avatar
                                        _hover={{ cursor: "pointer" }}
                                        color="white"
                                        bg={"rgba(51,117,184,1)"}
                                        size="sm"
                                        w="25px"
                                        h="25px"
                                        name={profile?.username}
                                    />
                                </MenuButton>
                                <MenuList p="0px"
                                    mt="10px">
                                    <Flex w="100%" mb="0px">
                                        <Text
                                            ps="20px"
                                            pt="16px"
                                            pb="10px"
                                            w="100%"
                                            textTransform={"capitalize"}
                                            borderBottom="1px solid #ddd"
                                            fontSize="sm"
                                            fontWeight="700"
                                        >
                                            👋&nbsp; Hey, {profile?.username}
                                        </Text>
                                    </Flex>
                                    <Flex flexDirection="column" p="10px">
                                        <MenuItem
                                            _hover={{ bg: "none" }}
                                            _focus={{ bg: "none" }}
                                            color="red.400"
                                            borderRadius="8px"
                                            px="14px"
                                        >
                                            <Text fontSize="sm" onClick={handleLogout}>Logout</Text>
                                        </MenuItem>
                                    </Flex>
                                </MenuList>
                            </Menu>
                        </Box>
                    </Flex>
                </Flex>
            </HStack>
        </Box>
    );

    const renderAdminLinks = (routes) => (
        routes.filter(route => route.layout === '/admin').map((route, index) => (
            <NavLink to={route.layout + route.path} key={index}>
                {route.icon ? (
                    <Box>
                        <HStack
                            spacing={activeRoute(route.path.toLowerCase()) ? "22px" : "26px"}
                            py='5px'
                            ps='10px'>
                            <Flex w='100%' alignItems='center' justifyContent='center'>
                                <Box
                                    color={activeRoute(route.path.toLowerCase()) ? activeIcon : textColor}
                                    me='18px'>
                                    {route.icon}
                                </Box>
                            </Flex>
                            <Box
                                h='36px'
                                w='4px'
                                bg={activeRoute(route.path.toLowerCase()) ? brandColor : "transparent"}
                                borderRadius='5px'
                            />
                        </HStack>
                    </Box>
                ) : (
                    <Box>
                        <HStack
                            spacing={
                                activeRoute(route.path.toLowerCase()) ? "22px" : "26px"
                            }
                            py='5px'
                            ps='10px'>
                            <Text
                                me='auto'
                                color={
                                    activeRoute(route.path.toLowerCase())
                                        ? activeColor
                                        : inactiveColor
                                }
                                fontWeight={
                                    activeRoute(route.path.toLowerCase()) ? "bold" : "normal"
                                }>
                            </Text>
                            <Box h='36px' w='4px' bg='brand.400' borderRadius='5px' />
                        </HStack>
                    </Box>
                )}
            </NavLink>
        ))
    );


    return (
        <>
            <Box w={"3.6%"} h={"full"} background={"white"} position={"fixed"} zIndex={999}>
                {routes.some(route => route.layout === '/user') && renderUserLinks(selectedProject)}
                {renderAdminLinks(routes)}
            </Box>
            <Portal>
            </Portal>
        </>
    )
}

export default Sidebar;