import { Box, Grid, GridItem } from "@chakra-ui/react";
import React from "react";
import SettingsTable from "../SettingsTable";

function SteelDesign() {
  return (
    <Grid gap={4} templateColumns="repeat(3, 1fr)" p={3}>
      <GridItem rowSpan={3} colSpan={1}>
        <SettingsTable
          tableName={"Steel Strength Reduction"}
          style={{ width: "400px" }}
          columnPadding="0.5rem 0.5rem"
        />
      </GridItem>
      <GridItem  colSpan={1}>
        <SettingsTable
          tableName={"Steel Design"}
          style={{ width: "400px" }}
          columnPadding="0.5rem 0.5rem"
        />
      </GridItem>
      <GridItem colSpan={1}>
        <SettingsTable
          tableName={"Weld Settings"}
          style={{ width: "400px" }}
          columnPadding="0.5rem 0.5rem"
        />
      </GridItem>
      <GridItem  colSpan={1}>
        <SettingsTable
          tableName={"Bolt Settings"}
          style={{ width: "400px" }}
          columnPadding="0.5rem 0.5rem"
        />
      </GridItem>
      
    </Grid>
  );
}

export default SteelDesign;
