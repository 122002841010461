import React from "react";
import InputField from "./InputField";
import SelectField from "./SelectField";

const FieldType = {
  input: InputField,
  select: SelectField,
};

function GenerateField(props) {
  const {type } = props;
  const FieldComponent = FieldType[type]
  return <FieldComponent {...props}/>;
}

export default GenerateField;
