import {
    Flex,
    Table,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    useColorModeValue,
    Icon,
    useDisclosure,
    FormControl,
    FormLabel,
    Select,
} from "@chakra-ui/react";
import React, { useMemo, useState } from "react";
import {
    useGlobalFilter,
    usePagination,
    useSortBy,
    useTable,
} from "react-table";
import { MdDelete } from "react-icons/md";

// Custom components
import Card from "components/misc/card/Card";
import { Button } from "components/misc/Buttons";
import { colors } from "components/misc/colors";
import { AiOutlinePlus } from "react-icons/ai";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { getProjects } from "store/selector";
import {
    addNewUser,
    deleteUser,
    getNonAllocatedUsers,
    loadProjects,
} from "store/slices/entities/projects";
import store from "store/store";
import Modal from "components/common/Modal";

const AddForm = ({ changeUser, users, nonUsers }) => (
    <FormControl mt={4}>
        <FormLabel>User</FormLabel>
        <Select
            placeholder="Choose"
            onChange={(e) => changeUser(e.target.value)}
            value={users}
        >
            {nonUsers?.map((user) => (
                <option key={user.id} value={user.id}>
                    {user.username}
                </option>
            ))}
        </Select>
    </FormControl>
);

export default function ManageUsers({ users, projectID, onCloseSidebar }) {
    const { list: projects, nonAllocatedUsers } = useSelector(getProjects);
    const [selectedUser, setSelectedUser] = useState();
    const [activeModal, setActiveModal] = useState();

    const columnsData = [
        {
            Header: "User",
            accessor: "username",
        },
        {
            Header: "",
            accessor: "action",
        },
    ];

    const projectUsers = projects.filter(project => project.id == projectID)[0];

    const tableData = projectUsers.users?.map((user) => ({
        id: user.id,
        username: user.username,
        action: "",
    }));

    const [columnData, setColumnsData] = useState(columnsData);
    const [tableDatas, setTableDatas] = useState(tableData);

    const columns = useMemo(() => columnData, [columnData]);
    const data = useMemo(() => tableDatas, [tableDatas]);
    const { isOpen, onClose, onOpen } = useDisclosure();

    const tableInstance = useTable(
        {
            columns,
            data,
        },
        useGlobalFilter,
        useSortBy,
        usePagination
    );

    const { headerGroups, page, prepareRow } = tableInstance;

    const textColor = useColorModeValue("secondaryGray.900", "white");
    const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
    const deleteIcon = useColorModeValue("red.400");

    const onAddUser = async () => {
        store.dispatch(addNewUser(projectID, selectedUser));
        store.dispatch(loadProjects());
        setSelectedUser();
    };

    const onDeleteUser = async (user) => {
        store.dispatch(deleteUser(projectID, user?.row?.original.id));
        store.dispatch(loadProjects());
    };


    useEffect(() => {
        nonAllocatedUsers?.length === 0 &&
            store.dispatch(getNonAllocatedUsers(projectID));
    }, []);

    return (
        <>
            <Card
                direction="column"
                w="100%"
                px="0px"
                overflowX={{ sm: "scroll", lg: "hidden" }}
            >
                <Button
                    size={{ md: "sm", lg: "lg" }}
                    colorScheme={colors.add}
                    textColor={colors.add}
                    borderColor={colors.buttonBG}
                    alignSelf={"end"}
                    onClick={() => { setActiveModal('adduser'); onOpen(true) }}
                    leftIcon={<AiOutlinePlus />}
                >
                    Add
                </Button>
                <Table variant="simple" color="gray.500" mb="24px">
                    <Thead>
                        {headerGroups.map((headerGroup, index) => (
                            <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                                {headerGroup.headers.map((column, index) => (
                                    <Th pe="10px" key={index} borderColor={borderColor}>
                                        <Flex
                                            justify="space-between"
                                            align="center"
                                            fontSize={{ sm: "10px", lg: "12px" }}
                                            color="gray.400"
                                        >
                                            {column.render("Header")}
                                        </Flex>
                                    </Th>
                                ))}
                            </Tr>
                        ))}
                    </Thead>
                    <Tbody>
                        {page.map((row, index) => {
                            prepareRow(row);
                            return (
                                <Tr {...row.getRowProps()} key={index}>
                                    {row.cells.map((cell, index) => {
                                        let data = "";
                                        if (cell.column.Header === "User") {
                                            data = (
                                                <Flex align="center">
                                                    <Text
                                                        color={textColor}
                                                        fontSize="sm"
                                                        fontWeight="700"
                                                    >
                                                        {cell.value}
                                                    </Text>
                                                </Flex>
                                            );
                                        } else if (cell.column.Header === "") {
                                            data = (
                                                <Icon
                                                    w={4}
                                                    h={5}
                                                    color={deleteIcon}
                                                    cursor={"pointer"}
                                                    onClick={() => { setActiveModal(cell); onOpen(true) }}
                                                    as={MdDelete}
                                                />
                                            );
                                        }
                                        return (
                                            <Td
                                                key={index}
                                                fontSize={{ sm: "14px" }}
                                                minW={{ sm: "150px", md: "200px", lg: "auto" }}
                                                borderColor="transparent"
                                            >
                                                {data}
                                            </Td>
                                        );
                                    })}
                                </Tr>
                            );
                        })}
                    </Tbody>
                </Table>
            </Card>
            <Modal
                modalHead={activeModal === 'adduser' ? "Add new user" : 'Confirm'}
                isOpen={isOpen}
                onClose={onClose}
                onProcced={() => activeModal === 'adduser' ? onAddUser() : onDeleteUser(activeModal)}
                confirmText={activeModal === 'adduser' ? "Save" : 'Delete'}
            >
                {activeModal === "adduser" ? (
                    <AddForm
                        users={selectedUser}
                        changeUser={setSelectedUser}
                        nonUsers={nonAllocatedUsers}
                    />
                ) : (
                    <Text>Are you sure you want to delete this user ?</Text>
                )}
            </Modal>
        </>
    );
}
