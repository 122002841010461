import React from "react";
import { Box, Text } from "@chakra-ui/react";
import Loading from "components/common/Loading";
import { useSelector } from "react-redux";
import { getSettings } from "store/selector";

function SpinnerPage() {
  const { loading } = useSelector(getSettings);
  return (
    <Box
      borderRadius={10}
      overflow={"hidden"}
      minH={"70vh"}
      bgColor={"white"}
      mt={"2rem"}
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
      marginInline={15}
    >
      <Loading
        isloading={loading}
        borderColor="rgb(94, 92, 92) transparent transparent transparent"
      />
      <Text>Loading...</Text>
    </Box>
  );
}

export default SpinnerPage;
