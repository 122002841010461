import { createSlice } from "@reduxjs/toolkit";
import store from "store/store";

const slice = createSlice({
  name: "general",
  initialState: {
    loading: false,
  },
  reducers: {
    loadingEnabled: (general) => {
      general.loading = true;
    },
    loadingDisabled: (general) => {
      general.loading = false;
    },
  },
});

const { loadingEnabled, loadingDisabled,settingsUnlocked } = slice.actions;

export default slice.reducer;

export const enableLoading = () => store.dispatch(loadingEnabled());
export const disableLoading = () => store.dispatch(loadingDisabled());

