import React from "react";
import { useSelector } from "react-redux";

import DataTable from "components/DataTable";
import { getSettings } from "store/selector";
import { updateProjectDetails } from "store/slices/entities/settings";
import { getDefaultTableValue } from "utils/formatter";
import { settings } from "utils/dataFormat";

function SettingsTable({ tableName, ...props }) {
  const { projectDetails } = useSelector(getSettings);
  const { newValue, columns } = getDefaultTableValue(
    settings,
    tableName,
    projectDetails
  );
  const onChange = ({ target: { value, name } }) => {
    const latestvalues = { ...projectDetails };
    latestvalues[name] = value;
    updateProjectDetails(latestvalues);
  };


  return (
    <DataTable
      editable
      showGridlines
      columnFontSize='17px'
      formatObj={settings}
      tableValue={newValue}
      columns={columns}
      header={tableName}
      onChange={onChange}
      
      {...props}
    />
  );
}

export default SettingsTable;
