import React from "react";
import {
    Modal as BaseModal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    ModalContent,
    ModalCloseButton,
    Button,
} from "@chakra-ui/react";

function Modal({
    modalHead = "Modal head",
    size,
    maxWidth,
    isOpen,
    onClose,
    onProcced,
    children,
    confirmText = "Confirm",
}) {
    return (
        <BaseModal
            isCentered
            isOpen={isOpen}
            onClose={onClose}
            overFlow={"hidden"}
            size={size || "xl"}
            useInert={false}
        >
            <ModalOverlay bg="blackAlpha.00" backdropFilter="blur(10px)" />
            <ModalContent overflow={'auto'} maxWidth={maxWidth}>
                <ModalHeader>{modalHead}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>{children}</ModalBody>
                {onProcced && (
                    <ModalFooter>
                        <Button onClick={onProcced}>{confirmText}</Button>
                        <Button onClick={onClose}>Close</Button>
                    </ModalFooter>
                )}
            </ModalContent>
        </BaseModal>
    );
}

export default Modal;
