import { configureStore } from "@reduxjs/toolkit";

import reducer from "store/reducers";
import request from "store/middleware/request";
import toast from "store/middleware/message";

export default configureStore({
  reducer,
  middleware: (defaultMiddleware) => [...defaultMiddleware(), request, toast],
});
