import React from "react";
import tw from "twin.macro";
import styled from "styled-components";

import LogoImage from "images/logo_22.png";
import { ReactComponent as FacebookIcon } from "images/linkedin-icon.svg";
import { ReactComponent as TwitterIcon } from "images/twitter-icon.svg";
import { ReactComponent as YoutubeIcon } from "images/instagram.svg";

const Container = tw.div`relative bg-other-contactusBg text-gray-100 -mb-8  px-8 py-2`;
const Content = tw.div`max-w-screen-xl mx-auto relative z-10`;

const ThreeColRow = tw.div`flex flex-col md:flex-row items-center justify-between`;

const LogoContainer = tw.div`flex items-center justify-center md:justify-start `;
const LogoImg = tw.img`w-full h-16`;
const LogoText = tw.h5`ml-2 text-xl font-black tracking-wider text-gray-100`;

const CopywrightNotice = tw.p`text-center text-sm sm:text-base mt-8 md:mt-0 font-medium text-black`;

const SocialLinksContainer = tw.div`mt-8 md:mt-0 flex`;
const SocialLink = styled.a`
  ${tw`cursor-pointer p-2 rounded-full bg-other-buttonBG text-gray-900 hover:bg-gray-400 transition duration-300 mr-4 last:mr-0`}
  svg {
    ${tw`w-4 h-4`}
  }
`;



function Footer() {
  return (
    <Container>
      <Content>
        <ThreeColRow>
          <CopywrightNotice>
            &copy; {new Date().getFullYear()} TEDAPS | All Rights Reserved{" "}
          </CopywrightNotice>
          <LogoContainer>
            <LogoImg src={LogoImage} />
            {/* <LogoText>TDAPS</LogoText> */}
          </LogoContainer>
          <SocialLinksContainer>
            <SocialLink href="https://facebook.com">
              <FacebookIcon />
            </SocialLink>
            <SocialLink href="https://twitter.com">
              <TwitterIcon />
            </SocialLink>
            <SocialLink href="https://youtube.com">
              <YoutubeIcon />
            </SocialLink>
          </SocialLinksContainer>
        </ThreeColRow>
      </Content>
    </Container>
  );
}

export default Footer;
