import { Button } from "@chakra-ui/react";
import { colors } from "components/misc/colors";
import React from "react";
import { useSelector } from "react-redux";
import { getSettings } from "store/selector";
import { settings } from "utils/dataFormat";
import EditMode from "./EditMode";
import { StatusField, DefaultField } from "./fields";
import { AiFillCheckCircle } from "react-icons/ai";
import { MdCancel } from "react-icons/md";
import { approveUser,rejectUser } from "store/slices/auth/user";

function ColumnBody({ rowData, col, editable, ...otherProps }) {
  const value = rowData[col?.field || col];
  const { unlockedProjects } = useSelector(getSettings);
  const isSettingsEditable = unlockedProjects.includes(rowData?.optionalID)
    ? true
    : !settings["disabled_fields"].includes(rowData[`${col}_reference`]);

  //special col disbale for material property
  const disableObj = {
    Concrete: ["min_yield_stress", "min_tensile_stress", "shear_modulus"],
    Rebar: [
      "shear_modulus",
      "compressive_strength_cube_test",
      "compressive_strength_cylinder_test",
      "light_weight_lambda_factor",
    ],
    Steel: [
      "shear_modulus",
      "compressive_strength_cube_test",
      "compressive_strength_cylinder_test",
      "light_weight_lambda_factor",
    ],
  };

  editable =
    !disableObj[rowData?.material]?.includes(col?.field) && !col?.presentation
      ? true
      : false;

  const Actions = () => {

    return (
      <React.Fragment>
        <Button
          leftIcon={<AiFillCheckCircle size={25} />}
          iconSpacing={0}
          paddingInline={2}
          colorScheme={colors.add}
          variant="outline"
          onClick={() => approveUser(rowData?.id)}
        />
        <Button
          leftIcon={<MdCancel size={25} />}
          paddingInline={2}
          iconSpacing={0}
          colorScheme={colors.delete}
          variant="solid"
          marginLeft={5}
          onClick={() => rejectUser(rowData?.id)}

        />
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      {col?.header === "Action" ? (
        <Actions />
      ) : (
        <>
          {editable && isSettingsEditable ? (
            <EditMode value={value} obj={rowData} col={col} {...otherProps} />
          ) : col?.field === "status" ? (
            <StatusField value={value} />
          ) : (
            <DefaultField value={value} reference={col?.field} />
          )}
        </>
      )}
    </React.Fragment>
  );
}

export default ColumnBody;
