import { useState } from "react";
import "./appliedload.css";
import { useEffect } from "react";
import { updateFile } from "store/slices/entities/projectCanvas";

const AppliedLoad = ({ data }) => {

    const [selectedSides, setSelectedSides] = useState({ sides: [], corner: data?.corner });
    const [payload, setPayload] = useState(null);

    const adjacentValues = {
        "side-1": ["side-3", "side-4"],
        "side-3": ["side-1", "side-2"],
        "side-4": ["side-1", "side-2"],
        "side-2": ["side-3", "side-4"]
    };

    const cornerMapping = {
        "A": ["side-1", "side-3"],
        "B": ["side-1", "side-4"],
        "C": ["side-2", "side-3"],
        "D": ["side-2", "side-4"]
    };


    useEffect(() => {
        updatePayload();
    }, [selectedSides]);

    const handleSideSelection = (event) => {
        const selectedId = event.target.id;

        setSelectedSides(prevSelection => {
            let newSides;
            if (prevSelection.sides.includes(selectedId)) {
                newSides = prevSelection.sides.filter(id => id !== selectedId);
            } else {
                if (prevSelection.sides.length === 2) {
                    console.log("Limit reached");
                    return prevSelection;
                } else if (prevSelection.sides.length === 1) {
                    const adjacent = adjacentValues[prevSelection.sides[0]].includes(selectedId);
                    if (!adjacent) {
                        console.log("You can only select adjacent checkboxes.");
                        return prevSelection;
                    }
                }
                newSides = [...prevSelection.sides, selectedId];
            }

            const newCorner = getCornerValue(newSides);

            return { sides: newSides, corner: newCorner };
        });
    };

    const getCornerValue = (selectedSides) => {
        for (let [corner, sides] of Object.entries(cornerMapping)) {
            if (selectedSides.includes(sides[0]) && selectedSides.includes(sides[1])) {
                return corner;
            }
        }
        updatePayload()
        return "";
    };

    function updatePayload() {
        if (selectedSides?.sides.length === 1) {
            console.log(selectedSides);
            const newData = { ...data, case: "3", edge: selectedSides?.sides[0]?.split("-")[1] };
            updateFile(newData);
        } else if (selectedSides?.sides.length === 2) {
            setPayload({
                case: "2",
                corner: selectedSides?.corner
            })
            const newData = { ...data, case: "2", corner: selectedSides?.corner };
            updateFile(newData);
        } else {
            setPayload(null)
        }
    }

    const isCheckboxDisabled = (sideId) => {
        return selectedSides.length === 2 && !selectedSides.some(side => adjacentValues[side]?.includes(sideId));
    };

    useEffect(() => {
        let sides;
        switch (data?.case) {
            case 2:
                sides = cornerMapping[data?.corner];
                setSelectedSides({ sides: sides, corner: data?.corner });
                break;
            case 3:
                sides = `side-${data?.edge}`
                setSelectedSides({ sides: [sides], edge: data?.edge });
                break;
            default:
                setSelectedSides({ sides: [], corner: "", edge: "" });
                break;
        }
    }, [data]);

    return (
        <div className="content">
            <div className="corners">
                <span>A</span>
                <span>B</span>
                <span>C</span>
                <span>D</span>
            </div>
            <div className="rectangle-container">
                <div className="side-checkbox" style={{ gridArea: 'top' }}>
                    <input
                        type="checkbox"
                        id="side-1"
                        checked={selectedSides?.sides.includes('side-1')}
                        onChange={handleSideSelection}
                        disabled={isCheckboxDisabled('side-1')}
                    />
                    <label for="side-1">Side 1</label>
                </div>
                <div className="side-checkbox" style={{ gridArea: 'left' }}>
                    <input
                        type="checkbox"
                        id="side-3"
                        checked={selectedSides?.sides.includes('side-3')}
                        onChange={handleSideSelection}
                        disabled={isCheckboxDisabled('side-3')}
                    />
                    <label for="side-3">Side 3</label>
                </div>
                <div className="rectangle"></div>
                <div className="side-checkbox" style={{ gridArea: 'right' }}>
                    <input
                        type="checkbox"
                        id="side-4"
                        checked={selectedSides?.sides.includes('side-4')}
                        onChange={handleSideSelection}
                        disabled={isCheckboxDisabled('side-4')}
                    />
                    <label for="side-4">Side 4</label>
                </div>
                <div className="side-checkbox" style={{ gridArea: 'bottom' }}>
                    <input
                        type="checkbox"
                        id="side-2"
                        checked={selectedSides?.sides.includes('side-2')}
                        onChange={handleSideSelection}
                        disabled={isCheckboxDisabled('side-2')}
                    />
                    <label for="side-2">Side 2</label>
                </div>
            </div>
            {console.log(payload)}
        </div>
    )
}

export default AppliedLoad;