import React from "react";
import { Box, Text } from "@chakra-ui/react";

function DefaultTable() {
    return (
        <Box
            borderRadius={10}
            overflow={"hidden"}
            minH={"40vh"}
            bgColor={"white"}
            mt={"2rem"}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
        >
            <Text fontSize={"2rem"} fontWeight={"bold"} className="cursor-default">
                There is no calculation found!
            </Text>
        </Box>
    );
}

export default DefaultTable;
