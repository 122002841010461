import routes from "api/routes";
import { method } from "lodash";
import { apiCallBegin, apiCallFailed } from "store/api";
import store from "store/store";
import { Toast } from "utils/Toast";

const { createSlice } = require("@reduxjs/toolkit");

const slice = createSlice({
    name: "projectCanvas",
    initialState: {
        selectedProject: false,
        selectedFile: false,
        fileDetails: [],
        updatedFileDetails: [],
        loading: false,
        selectedFileRows: [],
    },
    reducers: {
        canvasReseted: (canvas) => {
            canvas.fileDetails = []
            canvas.selectedProject = false
            canvas.selectedFile = false
            canvas.updatedFileDetails = []
            canvas.fileDetails = []
            canvas.selectedFileRows = []
        },
        detailsRequested: (canvas) => {
            canvas.loading = true;
        },
        detailsRequestFailed: (canvas) => {
            canvas.loading = false;
        },
        projectChanged: (canvas, action) => {
            canvas.selectedProject = action.payload.project;
            canvas.selectedFile = false;
            canvas.fileDetails = [];
            canvas.updatedFileDetails = [];
        },
        fileChanged: (canvas, action) => {
            canvas.selectedFile = action.payload.file;
            canvas.updatedFileDetails = [];
            canvas.selectedFileRows = [];
        },
        fileDetailsReceived: (canvas, action) => {
            canvas.fileDetails = action.payload;
            canvas.loading = false;
        },
        fileDetailsRowAdded: (canvas, action) => {
            const latestRowLists = canvas.fileDetails.rows;
            latestRowLists.push(action.payload);
            canvas.fileDetails.rows = latestRowLists;
        },
        fileRowRemoved: (canvas, action) => {
            const deletedRowIds = Object.keys(action.payload).map((id) => `https://tedaps.com/api/columnrow/${id}/`)
            const newRows = canvas.fileDetails.rows?.filter(
                (row) => !deletedRowIds.includes(row.url)
            );
            canvas.fileDetails.rows = newRows
        },

        fileDetailsUpdated: (canvas, action) => {
            const previousList = canvas.updatedFileDetails;
            if (previousList.length == 0) {
                canvas.updatedFileDetails.push(action.payload);
            } else {
                const updateList = previousList.filter(
                    (item) => item.url != action.payload.url
                );
                updateList.push(action.payload);
                canvas.updatedFileDetails = updateList;
            }
        },
        updatedFileDetailsRemoved: (canvas, action) => {
            const removedLists = canvas.updatedFileDetails.filter(
                (item) => item.url != action.payload.url
            );
            canvas.updatedFileDetails = removedLists;
        },

        fileDetailsSaved: (canvas, action) => {
            const latestLists = canvas.fileDetails?.rows?.map((item) =>
                item.url === action.payload.url ? { ...action.payload } : item
            );
            canvas.fileDetails.rows = latestLists;
            canvas.loading = false;
        },
    },
});

const {
    projectChanged,
    fileChanged,
    fileDetailsReceived,
    detailsRequestFailed,
    detailsRequested,
    fileDetailsUpdated,
    updatedFileDetailsRemoved,
    fileDetailsSaved,
    fileDetailsRowAdded,
    fileRowRemoved,
    canvasReseted
} = slice.actions;
export default slice.reducer;

export const changeProject = (project) =>
    store.dispatch(projectChanged({ project }));

export const changeFile = (file) => store.dispatch(fileChanged({ file }));

export const loadFileDetails = (url) => {
    store.dispatch(
        apiCallBegin({
            url,
            onSuccess: fileDetailsReceived.type,
            onStart: detailsRequested.type,
            onError: detailsRequestFailed.type,
        })
    );
};

export const updateFile = (obj) => {
    return store.dispatch(fileDetailsUpdated({ ...obj }));
};

export const removeUpdatedFile = (url) => {
    return store.dispatch(updatedFileDetailsRemoved({ url }));
};

export const saveFileDetails = (rowData) => {
    const fileDetails = store
        .getState()
        .entities.projectCanvas.updatedFileDetails?.filter(
            (file) => file.url === rowData?.url
        );
    // commented : to refresh design summary without refreshing entire project table
    //if (fileDetails.length === 0) return Toast("There is no change on this row", 'warning')

    const details = { ...fileDetails[0] }
    const materials = store.getState().entities.projectCanvas.fileDetails.materials

    //these information will added only first calculation
    if (details?.fc_material) {
        let selectedItem = materials?.filter(item => item.name === details?.fc_material)
        if (selectedItem?.length > 0) {
            details['ec'] = selectedItem[0]?.modulus_of_elasticity
            details['wc'] = selectedItem[0]?.density
            details['fc'] = selectedItem[0]?.compressive_strength_cylinder_test
            details['lamda'] = selectedItem[0]?.light_weight_lambda_factor
        }
    }

    if (details?.fy_material) {
        let selectedItem = materials?.filter(item => item.name === details?.fy_material)
        if (selectedItem?.length > 0) {
            details['es'] = selectedItem[0]?.modulus_of_elasticity
            details['fy'] = selectedItem[0]?.min_yield_stress
            details['ws'] = selectedItem[0]?.density
        }
    }

    if (details?.fyv_material) {
        let selectedItem = materials?.filter(item => item.name === details?.fyv_material)
        if (selectedItem?.length > 0) {
            details['fyv'] = selectedItem[0]?.min_yield_stress
        }
    }

    return store.dispatch(
        apiCallBegin({
            url: details?.url,
            jsonData: details,
            method: "put",
            onStart: detailsRequested.type,
            onError: detailsRequestFailed.type,
            onSuccess: fileDetailsSaved.type,
        })
    );
};

export const addFileDetailsRow = (link) => {
    const canvasBaseUrl = "https://tedaps.com"
    store.dispatch(
        apiCallBegin({
            url: `${canvasBaseUrl}${link}add_row/`,
            method: "post",
            onSuccess: fileDetailsRowAdded.type,
        })
    );
};


export const removeFileRow = (listOfIds) => {
    store.dispatch(
        apiCallBegin({
            url: routes.canvas.removeRow,
            method: "delete",
            deleteData: listOfIds,
            onSuccess: fileRowRemoved.type,
        })
    );
};

export const resetCanvas = () => store.dispatch(canvasReseted())