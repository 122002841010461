const column = {
    //main column calculation format
    url: {
        editor: "hidden",
        type: "string",
        required: false,
        unit1: "Url",
        unit2: "Url",
        order: 2,
        table: "Hidden",
        multiple: true, //for c1,c2
        column: 1,
    },
    name: {
        editor: "input",
        type: "string",
        required: false,
        unit1: "Column ID",
        unit2: "Column ID",
        max_length: 40,
        order: 1,
        table: "Section Details",
        presentation: true,
    },
    _type: {
        editor: "select",
        required: true,
        unit1: "Shape",
        unit2: "Shape",
        options: [
            {
                id: "Rectangle",
                value: "Rectangle",
            },
            {
                id: "Circular",
                value: "Circular",
            },
        ],
        labelname: "id",
        labelvalue: "value",
        order: 3,
        table: "Section Details",
        presentation: true,
    },
    width: {
        editor: "input",
        type: "float",
        required: true,
        unit1: "Width (mm)",
        unit2: "Width",
        order: 4,
        table: "Section Details",
        presentation: true,
        min: 300,
        max: 400,
    },

    depth: {
        editor: "input",
        type: "float",
        required: true,
        unit1: "Depth (mm)",
        unit2: "Depth",
        order: 5,
        table: "Section Details",
        presentation: true,
    },
    rebar_percent: {
        editor: "output",
        type: "string",
        required: false,
        unit1: "Rebar Percent",
        unit2: "Column",
        max_length: 40,
        order: 1,
        table: "",
        presentation: true,
    },
    vertical_rebar_no_z: {
        editor: "input",
        type: "integer",
        required: false,
        unit1: "Z direction",
        unit2: "Z direction",
        order: 1,
        table: "Longitudinal Rebar",
    },
    vertical_rebar_no_y: {
        editor: "input",
        type: "integer",
        required: false,
        unit1: "Y direction",
        unit2: "Y direction",
        order: 2,
        table: "Longitudinal Rebar",
    },
    vertical_rebar_dia: {
        editor: "input",
        type: "float",
        required: false,
        unit1: "Diameter (mm)",
        unit2: "Diameter (mm)",
        order: 3,
        table: "Longitudinal Rebar",
    },
    vertical_rebar_cover: {
        editor: "input",
        type: "float",
        required: false,
        unit1: "Cover (mm)",
        unit2: "Cover (mm)",
        order: 4,
        table: "Longitudinal Rebar",
    },
    al_pro_percent: {
        editor: "input",
        type: "float",
        required: false,
        unit1: "Torsion Rebar (%)",
        unit2: "Torsion Rebar",
        order: 5,
        table: "Longitudinal Rebar",
    },
    horizontal_rebar_type: {
        editor: "select",
        required: false,
        unit1: "Type",
        unit2: "Type",
        options: [
            {
                id: "Ties",
                value: "Ties",
            },
            {
                id: "Spiral",
                value: "Spiral",
            },
        ],
        labelname: "id",
        labelvalue: "value",
        order: 1,
        table: "Transverse Rebar",
    },
    horizontal_rebar_dia: {
        editor: "input",
        type: "float",
        required: false,
        unit1: "Diameter (mm)",
        unit2: "Diameter (mm)",
        order: 2,
        table: "Transverse Rebar",
    },
    horizontal_rebar_spacing: {
        editor: "input",
        type: "float",
        required: false,
        unit1: "Spacing (mm)",
        unit2: "Spacing (mm)",
        order: 3,
        table: "Transverse Rebar",
    },
    leg_z: {
        editor: "input",
        type: "integer",
        required: false,
        unit1: "Z direction",
        unit2: "Z direction",
        order: 4,
        table: "Transverse Rebar",
    },
    leg_y: {
        editor: "input",
        type: "integer",
        required: false,
        unit1: "Y direction",
        unit2: "Y direction",
        order: 5,
        table: "Transverse Rebar",
    },
    fc_material: {
        editor: "output",
        required: true,
        unit1: "Concrete",
        unit2: "Concrete",
        options: "external",
        external_options: [],
        external_mapper: {
            "Material Properties": {
                ec: "modulus_of_elasticity",
                wc: "density",
                fc: "compressive_strength_cylinder_test",
                lamda: "light_weight_lambda_factor",
            },
        },
        external_link: `api/project/id/materials/?name=Concrete`,
        labelname: "name",
        labelvalue: "name",
        url: "materials/?name=Concrete",
        order: 1,
        table: "Material Properties",
    },
    fy_material: {
        editor: "output",
        required: true,
        unit1: "Longitudinal Rebar",
        unit2: "Longitudinal Rebar",
        options: "external",
        external_options: [],
        external_mapper: {
            "Material Properties": {
                es: "modulus_of_elasticity",
                fy: "min_yield_stress",
                ws: "density",
            },
        },
        labelname: "name",
        labelvalue: "name",
        external_link: `api/project/id/materials/?name=Rebar`,
        url: "materials/?name=Rebar",
        order: 2,
        table: "Material Properties",
    },
    fyv_material: {
        editor: "output",
        required: true,
        unit1: "Transverse Rebar",
        unit2: "Transverse Rebar",
        options: "external",
        external_options: [],
        external_mapper: {
            "Material Properties": {
                fyv: "min_yield_stress",
            },
        },
        labelname: "name",
        labelvalue: "name",
        external_link: `api/project/id/materials/?name=Rebar`,
        url: "materials/?name=Rebar",
        order: 3,
        table: "Material Properties",
    },
    fc: {
        editor: "output",
        type: "string",
        required: false,
        unit1: "fc' (MPa)",
        unit2: "fc' (MPa)",
        order: 4,
        table: "Material Properties",
    },
    fy: {
        editor: "output",
        type: "string",
        required: false,
        unit1: "fy (MPa)",
        unit2: "fy (MPa)",
        order: 5,
        table: "Material Properties",
    },
    fyv: {
        editor: "output",
        type: "string",
        required: false,
        unit1: "fyv (MPa)",
        unit2: "fyv (MPa)",
        order: 6,
        table: "Material Properties",
    },
    wc: {
        editor: "output",
        type: "float",
        required: false,
        unit1: "Wc (kg/m  3)",
        unit2: "Wc (kg/m  3)",
        order: 11,
        table: "Material Properties",
    },
    ws: {
        editor: "output",
        type: "float",
        required: false,
        unit1: "Ws (kg/m  3)",
        unit2: "Ws (kg/m  3)",
        order: 12,
        table: "Material Properties",
    },
    lamda: {
        editor: "output",
        type: "float",
        required: false,
        unit1: "λ",
        unit2: "λ",
        order: 13,
        table: "Material Properties",
    },
    slendernes: {
        editor: "checkbox",
        required: false,
        unit1: "Consider Slendernes",
        unit2: "Consider Slendernes",
        max_length: 5,
        order: 1,
        table: "Slenderness Data",
    },

    _headerslendernes_1: {
        editor: "output",
        required: false,
        unit1: "Direction",
        value: "Z",
        order: 0,
        table: "Slenderness Data",
    },
    _headerslendernes_2: {
        editor: "output",
        required: false,
        unit1: "Direction",
        value: "Y",
        order: 0,
        table: "Slenderness Data",
    },

    pdel_z: {
        editor: "select",
        required: false,
        unique_name: "P-δ effects",
        unit1: "P-δ effects z",
        unit2: "P-δ effects z",
        options: [
            {
                id: "yes",
                value: "Yes",
            },
            {
                id: "no",
                value: "No",
            },
        ],
        labelname: "id",
        labelvalue: "value",
        column_index: 2,
        table: "Slenderness Data",
        order: 2,
    },
    pDelta_z: {
        editor: "select",
        required: false,
        unique_name: "P-Δ effects",
        unit1: "P-Δ effects z",
        unit2: "P-Δ effects z",
        options: [
            {
                id: "yes",
                value: "Yes",
            },
            {
                id: "no",
                value: "No",
            },
        ],
        labelname: "id",
        labelvalue: "value",
        column_index: 2,
        max_length: 5,
        table: "Slenderness Data",
        order: 3,
    },

    pdel_y: {
        editor: "select",
        required: false,
        unique_name: "P-δ effects",
        unit1: "P-δ effects y",
        unit2: "P-δ effects y",
        options: [
            {
                id: "yes",
                value: "Yes",
            },
            {
                id: "no",
                value: "No",
            },
        ],
        labelname: "id",
        labelvalue: "value",
        column_index: 1,
        max_length: 5,
        table: "Slenderness Data",
        order: 2,
    },
    pDelta_y: {
        editor: "select",
        required: false,
        unique_name: "P-Δ effects",
        unit1: "P-Δ effects y",
        unit2: "P-Δ effects y",
        options: [
            {
                id: "yes",
                value: "Yes",
            },
            {
                id: "no",
                value: "No",
            },
        ],
        labelname: "id",
        labelvalue: "value",
        column_index: 1,
        max_length: 5,
        table: "Slenderness Data",
        order: 3,
    },
    sway_z: {
        editor: "select",
        required: false,
        unique_name: "Consider Sway",
        options: [
            {
                id: "Sway",
                value: "Sway",
            },
            {
                id: "Non-sway",
                value: "Non-sway",
            },
        ],
        labelname: "id",
        labelvalue: "value",
        unit1: "Sway z",
        unit2: "Sway z",
        column_index: 2,
        table: "Slenderness Data",
        order: 4,
    },
    sway_y: {
        editor: "select",
        required: false,
        unique_name: "Consider Sway",
        options: [
            {
                id: "Sway",
                value: "Sway",
            },
            {
                id: "Non-sway",
                value: "Non-sway",
            },
        ],
        labelname: "id",
        labelvalue: "value",
        unit1: "Sway y",
        unit2: "Sway y",
        column_index: 1,
        table: "Slenderness Data",
        order: 4,
    },
    ms_z: {
        editor: "input",
        type: "float",
        min_value: 0,
        max_value: 1,
        max_length: 5,
        required: false,
        unique_name: "Mns/Ms",
        unit1: "Mns/Ms",
        unit2: "Mns/Ms",
        column_index: 2,
        order: 5,
        table: "Slenderness Data",
    },
    ms_y: {
        editor: "input",
        type: "float",
        min_value: 0,
        max_value: 1,
        max_length: 5,
        unique_name: "Mns/Ms",
        required: false,
        unit1: "Mns/Ms % y",
        unit2: "Mns/Ms % y",
        column_index: 1,
        order: 5,
        table: "Slenderness Data",
    },
    lz: {
        editor: "input",
        type: "float",
        unique_name: "Clear Height (mm)",
        required: false,
        unit1: "Clear Height z",
        unit2: "Clear Height z",
        column_index: 2,
        order: 6,
        table: "Slenderness Data",
    },
    ly: {
        editor: "input",
        type: "float",
        unique_name: "Clear Height (mm)",
        required: false,
        unit1: "Clear Height y",
        unit2: "Clear Height y",
        column_index: 1,
        order: 6,
        table: "Slenderness Data",
    },
    sum_pc_pc_z: {
        editor: "input",
        type: "float",
        unique_name: "ΣPc/Pc (%)",
        required: false,
        unit1: "ΣPc/Pc z",
        unit2: "ΣPc/Pc z",
        column_index: 2,
        order: 7,
        table: "Slenderness Data",
    },
    sum_pc_pc_y: {
        editor: "input",
        type: "float",
        unique_name: "ΣPc/Pc (%)",
        required: false,
        unit1: "ΣPc/Pc y",
        unit2: "ΣPc/Pc y",
        column_index: 1,
        order: 7,
        table: "Slenderness Data",
    },
    sum_pu_pu_z: {
        editor: "input",
        type: "float",
        unique_name: "ΣPu/Pu (%)",
        required: false,
        unit1: "ΣPu/Pu z",
        unit2: "ΣPu/Pu z",
        column_index: 2,
        order: 8,
        table: "Slenderness Data",
    },
    sum_pu_pu_y: {
        editor: "input",
        type: "float",
        unique_name: "ΣPu/Pu (%)",
        required: false,
        unit1: "ΣPu/Pu y",
        unit2: "ΣPu/Pu y",
        column_index: 1,
        order: 8,
        table: "Slenderness Data",
    },
    concrete_quality: {
        editor: "input",
        type: "string",
        required: false,
        unit1: "Concrete (m3/m)",
        unit2: "Concrete (m3/m)",
        order: 2,
        table: "Quality Summary",
    },
    vertical_rebar_quality: {
        editor: "input",
        type: "string",
        required: false,
        unit1: "Longitudinal Rebar (kg/m)",
        unit2: "Longitudinal Rebar (kg/m)",
        order: 3,
        table: "Quality Summary",
    },
    horizontal_rebar_quality: {
        editor: "input",
        type: "string",
        required: false,
        unit1: "Transverse Rebar (kg/m)",
        unit2: "Transverse Rebar (kg/m)",
        order: 4,
        table: "Quality Summary",
    },
    res1_phi_pn_max: {
        editor: "output",
        type: "float",
        required: false,
        unit1: "φ Pn Max (kN)",
        unit2: "φ Pn Max (kN)",
        order: 1,
        table: "Section Summary",
    },
    res1_phi_pn_t: {
        editor: "output",
        type: "float",
        required: false,
        unit1: "φ Pn Min (kN)",
        unit2: "φ Pn Min (kN)",
        order: 2,
        table: "Section Summary",
    },
    res1_k: {
        editor: "output",
        type: "float",
        required: false,
        unit1: "k",
        unit2: "k",
        order: 3,
        table: "Section Summary",
    },
    res1_ks: {
        editor: "output",
        type: "float",
        required: false,
        unit1: "ks",
        unit2: "ks",
        order: 4,
        table: "Section Summary",
    },
    izz: {
        editor: "output",
        type: "float",
        required: false,
        unit1: "Izz (m^4)",
        unit2: "Izz (m^4)",
        order: 5,
        table: "Section Summary",
    },
    iyy: {
        editor: "output",
        type: "float",
        required: false,
        unit1: "Iyy (m^4)",
        unit2: "Iyy (m^4)",
        order: 6,
        table: "Section Summary",
    },
    rzz: {
        editor: "output",
        type: "float",
        required: false,
        unit1: "rzz (m)",
        unit2: "rzz (m)",
        table: "Section Summary",
        order: 7,
    },
    ryy: {
        editor: "output",
        type: "float",
        required: false,
        unit1: "ryy (m)",
        unit2: "ryy (m)",
        order: 8,
        table: "Section Summary",
    },

    _headerdesignsummary_1: {
        editor: "output",
        required: false,
        unit1: "Check",
        value: "Ultization",
        order: 0,
        table: "Design Summary",
    },
    _headerdesignsummary_2: {
        editor: "output",
        required: false,
        unit1: "Check",
        value: "Load",
        order: 0,
        table: "Design Summary",
    },

    res2_pmm_ratio: {
        editor: "output",
        type: "string",
        unique_name: "P-M-M",
        required: false,
        unit1: "P-M-M Utilization",
        unit2: "P-M-M Utilization",
        column_index: 1,
        order: 1,
        table: "Design Summary",
        presentation: true,
    },
    res2_pmm_load: {
        editor: "output",
        type: "string",
        unique_name: "P-M-M",
        required: false,
        unit1: "PMM y",
        unit2: "PMM y",
        column_index: 2,
        order: 1,
        table: "Design Summary",
    },
    res2_vuy_phi_vny_ratio: {
        editor: "output",
        type: "string",
        unique_name: "Vy/ϕvn'y",
        required: false,
        unit1: "Shear Utilization (Z)",
        unit2: "Shear Utilization (Z)",
        column_index: 1,
        order: 2,
        table: "Design Summary",
        presentation: true,
    },
    res2_vuy_phi_vny_load: {
        editor: "output",
        type: "string",
        unique_name: "Vy/ϕvn'y",
        required: false,
        unit1: "Vy/ϕvn'y",
        unit2: "Vy/ϕvn'y",
        column_index: 2,
        order: 2,
        table: "Design Summary",
    },
    res2_vuz_phi_vnz_ratio: {
        editor: "output",
        type: "string",
        unique_name: "Vz/ϕvn'z",
        required: false,
        unit1: "Shear Utilization (Y)",
        unit2: "Shear Utilization (Y)",
        column_index: 1,
        order: 3,
        table: "Design Summary",
        presentation: true,
    },
    res2_vuz_phi_vnz_load: {
        editor: "output",
        type: "string",
        unique_name: "Vz/ϕvn'z",
        required: false,
        unit1: "Clear Vz/ϕvn'z",
        unit2: "Clear Height Vz/ϕvn'z",
        column_index: 2,
        order: 3,
        table: "Design Summary",
    },
    res2_tu_phi_tn_ratio: {
        editor: "output",
        type: "string",
        unique_name: "Tu/ϕTn",
        required: false,
        unit1: "Shear & Torsion Utilization",
        unit2: "Shear & Torsion Utilization",
        column_index: 1,
        order: 4,
        table: "Design Summary",
        presentation: true,
    },
    res2_tu_phi_tn_load: {
        editor: "output",
        type: "string",
        unique_name: "Tu/ϕTn",
        required: false,
        unit1: "Tu/ϕTn",
        unit2: "Tu/ϕTn",
        column_index: 2,
        order: 4,
        table: "Design Summary",
    },
    res2_vuy_tu_phi_vny_ratio: {
        editor: "output",
        type: "string",
        unique_name: "(Vuy&Tu)/ϕvny",
        required: false,
        unit1: "(Vuy&Tu)/ϕvny",
        unit2: "(Vuy&Tu)/ϕvny",
        column_index: 1,
        order: 5,
        table: "Design Summary",
    },
    res2_vuy_tu_phi_vny_load: {
        editor: "output",
        type: "string",
        unique_name: "(Vuy&Tu)/ϕvny",
        required: false,
        unit1: "(Vuy&Tu)/ϕvny",
        unit2: "(Vuy&Tu)/ϕvny",
        column_index: 2,
        order: 5,
        table: "Design Summary",
    },
    res2_vuz_tu_phi_vnz_ratio: {
        editor: "output",
        type: "string",
        unique_name: "(Vuz&Tu)/ϕvnz",
        required: false,
        unit1: "(Vuz&Tu)/ϕvnz",
        unit2: "(Vuz&Tu)/ϕvnz",
        column_index: 1,
        order: 6,
        table: "Design Summary",
    },
    res2_vuz_tu_phi_vnz_load: {
        editor: "output",
        type: "string",
        unique_name: "(Vuz&Tu)/ϕvnz",
        required: false,
        unit1: "(Vuz&Tu)/ϕvnz",
        unit2: "(Vuz&Tu)/ϕvnz",
        column_index: 2,
        order: 6,
        table: "Design Summary",
    },
    res2_long_torsion_rebar_ratio: {
        editor: "output",
        type: "string",
        unique_name: "Torsion rebar",
        required: false,
        unit1: "Longitudinal Torsion rebar",
        unit2: "Longitudinal Torsion rebar",
        column_index: 1,
        order: 7,
        table: "Design Summary",
    },
    res2_long_torsion_rebar_load: {
        editor: "output",
        type: "string",
        unique_name: "Torsion rebar",
        required: false,
        unit1: "Longitudinal Torsion rebar",
        unit2: "Longitudinal Torsion rebar",
        column_index: 2,
        order: 7,
        table: "Design Summary",
    },
    status: {
        editor: "hidden",
        type: "string",
        required: false,
        unit1: "Status",
        unit2: "Status",
        max_length: 40,
        order: 9,
        presentation: true,
        table: "Hidden",
    },

    _StaticValues: {
        "Slenderness Data": [
            {
                c1: "Direction",
                c2: "Y",
                c3: "Y",
                c4: "Z",
                c5: "Z",
                static: true,
                position: 1,
            },
        ],
        "Design Summary": [
            {
                c1: "Utilization Check",
                c2: "Ultization Ratio",
                c3: "Ultization Ratio",
                c4: "Load Case",
                c5: "Load Case",
                static: true,
                position: 1,
            },
        ],
    },
    designed_by: {
        editor: "input",
        type: "string",
        required: false,
        unit1: "Designed By",
        unit2: "Designed By",
        max_length: 50,
        order: 1,
        table: "User Feedback",
    },
    approved_by: {
        editor: "input",
        type: "string",
        required: false,
        unit1: "Approved By",
        unit2: "Approved By",
        max_length: 50,
        order: 2,
        table: "Design Approval",
    },
    // remarks: {
    //   editor: "input",
    //   type: "string",
    //   required: false,
    //   unit1: "Remark",
    //   unit2: "Remark",
    //   order: 3,
    //   table: "User Feedback",
    // },

    //rebar settings format
    x: {
        editor: "input",
        type: "string",
        required: false,
        unit1: "X(mm)",
        table: "Rebar Settings",
    },
    y: {
        editor: "input",
        type: "string",
        required: false,
        unit1: "Y(mm)",
        table: "Rebar Settings",
    },
    dia: {
        editor: "input",
        type: "string",
        required: false,
        unit1: "Rebar Diameter",
        table: "Rebar Settings",
    },
    no: {
        editor: "output",
        required: false,
        unit1: "No",
        table: "Rebar Settings",
    },
    nearest: {
        editor: "output",
        required: false,
        unit1: "Distance to adjacent Rebar (mm)",
        table: "Rebar Settings",
    },

    //end condition

    Above: {
        editor: "input",
        type: "string",
        required: false,
    },
    Below: {
        editor: "input",
        type: "string",
        required: false,
    },

    'Above-Left': {
        editor: "input",
        type: "string",
        required: false,
    },

    'Above-Right': {
        editor: "input",
        type: "string",
        required: false,
    },
    'Below-Left': {
        editor: "input",
        type: "string",
        required: false,
    },

    'Below-Right': {
        editor: "input",
        type: "string",
        required: false,
    },
    yesOrNo: {
        editor: "select",
        required: true,
        options: [
            {
                id: "yes",
                value: "Yes",
            },
            {
                id: "no",
                value: "No",
            },
        ],
    },
    userSpecific: {
        editor: "select",
        required: true,
        options: [
            {
                id: "User Defined",
                value: "User Defined",
            },
            {
                id: "Fixed",
                value: "Fixed",
            },
            {
                id: "Pinned",
                value: "Pinned",
            },

        ],
    },
}
const columnSection = {

    _headerslendernes_1: {
        editor: "output",
        required: false,
        unit1: "Direction",
        value: "Z",
        order: 0,
        table: "Slenderness Data",
    },
    _headerslendernes_2: {
        editor: "output",
        required: false,
        unit1: "Direction",
        value: "Y",
        order: 0,
        table: "Slenderness Data",
    },

    pdel_z: {
        editor: "output",
        required: false,
        unique_name: "P-δ effects",
        unit1: "P-δ effects z",
        unit2: "P-δ effects z",
        column_index: 2,
        table: "Slenderness Data",
        order: 2,
    },
    pDelta_z: {
        editor: "output",
        required: false,
        unique_name: "P-Δ effects",
        unit1: "P-Δ effects z",
        unit2: "P-Δ effects z",
        column_index: 2,
        max_length: 5,
        table: "Slenderness Data",
        order: 3,
    },

    pdel_y: {
        editor: "output",
        required: false,
        unique_name: "P-δ effects",
        unit1: "P-δ effects y",
        unit2: "P-δ effects y",
        column_index: 1,
        max_length: 5,
        table: "Slenderness Data",
        order: 2,
    },
    pDelta_y: {
        editor: "output",
        required: false,
        unique_name: "P-Δ effects",
        unit1: "P-Δ effects y",
        unit2: "P-Δ effects y",
        column_index: 1,
        max_length: 5,
        table: "Slenderness Data",
        order: 3,
    },
    sway_z: {
        editor: "output",
        required: false,
        unique_name: "Consider Sway",
        unit1: "Sway z",
        unit2: "Sway z",
        column_index: 2,
        table: "Slenderness Data",
        order: 4,
    },
    sway_y: {
        editor: "output",
        required: false,
        unique_name: "Consider Sway",
        unit1: "Sway y",
        unit2: "Sway y",
        column_index: 1,
        table: "Slenderness Data",
        order: 4,
    },
    ms_z: {
        editor: "output",
        type: "float",
        min_value: 0,
        max_value: 1,
        max_length: 5,
        required: false,
        unique_name: "Mns/Ms",
        unit1: "Mns/Ms",
        unit2: "Mns/Ms",
        column_index: 2,
        order: 5,
        table: "Slenderness Data",
    },
    ms_y: {
        editor: "output",
        type: "float",
        min_value: 0,
        max_value: 1,
        max_length: 5,
        unique_name: "Mns/Ms",
        required: false,
        unit1: "Mns/Ms % y",
        unit2: "Mns/Ms % y",
        column_index: 1,
        order: 5,
        table: "Slenderness Data",
    },
    lz: {
        editor: "output",
        type: "float",
        unique_name: "Clear Height (mm)",
        required: false,
        unit1: "Clear Height z",
        unit2: "Clear Height z",
        column_index: 2,
        order: 6,
        table: "Slenderness Data",
    },
    ly: {
        editor: "output",
        type: "float",
        unique_name: "Clear Height (mm)",
        required: false,
        unit1: "Clear Height y",
        unit2: "Clear Height y",
        column_index: 1,
        order: 6,
        table: "Slenderness Data",
    },
    sum_pc_pc_z: {
        editor: "output",
        type: "float",
        unique_name: "ΣPc/Pc (%)",
        required: false,
        unit1: "ΣPc/Pc z",
        unit2: "ΣPc/Pc z",
        column_index: 2,
        order: 7,
        table: "Slenderness Data",
    },
    sum_pc_pc_y: {
        editor: "output",
        type: "float",
        unique_name: "ΣPc/Pc (%)",
        required: false,
        unit1: "ΣPc/Pc y",
        unit2: "ΣPc/Pc y",
        column_index: 1,
        order: 7,
        table: "Slenderness Data",
    },
    sum_pu_pu_z: {
        editor: "output",
        type: "float",
        unique_name: "ΣPu/Pu (%)",
        required: false,
        unit1: "ΣPu/Pu z",
        unit2: "ΣPu/Pu z",
        column_index: 2,
        order: 8,
        table: "Slenderness Data",
    },
    sum_pu_pu_y: {
        editor: "output",
        type: "float",
        unique_name: "ΣPu/Pu (%)",
        required: false,
        unit1: "ΣPu/Pu y",
        unit2: "ΣPu/Pu y",
        column_index: 1,
        order: 8,
        table: "Slenderness Data",
    },
    vertical_rebar_no_z: {
        editor: "output",
        type: "integer",
        required: false,
        unit1: "Z direction",
        unit2: "Z direction",
        order: 1,
        table: "Longitudinal Rebar",
    },
    vertical_rebar_no_y: {
        editor: "output",
        type: "integer",
        required: false,
        unit1: "Y direction",
        unit2: "Y direction",
        order: 2,
        table: "Longitudinal Rebar",
    },
    vertical_rebar_dia: {
        editor: "output",
        type: "float",
        required: false,
        unit1: "Diameter (mm)",
        unit2: "Diameter (mm)",
        order: 3,
        table: "Longitudinal Rebar",
    },
    vertical_rebar_cover: {
        editor: "output",
        type: "float",
        required: false,
        unit1: "Cover (mm)",
        unit2: "Cover (mm)",
        order: 4,
        table: "Longitudinal Rebar",
    },
    al_pro_percent: {
        editor: "output",
        type: "float",
        required: false,
        unit1: "Torsion Rebar (%)",
        unit2: "Torsion Rebar",
        order: 5,
        table: "Longitudinal Rebar",
    },
    horizontal_rebar_type: {
        editor: "output",
        required: false,
        unit1: "Type",
        unit2: "Type",
        order: 1,
        table: "Transverse Rebar",
    },
    horizontal_rebar_dia: {
        editor: "output",
        type: "float",
        required: false,
        unit1: "Diameter (mm)",
        unit2: "Diameter (mm)",
        order: 2,
        table: "Transverse Rebar",
    },
    horizontal_rebar_spacing: {
        editor: "output",
        type: "float",
        required: false,
        unit1: "Spacing (mm)",
        unit2: "Spacing (mm)",
        order: 3,
        table: "Transverse Rebar",
    },
    leg_z: {
        editor: "output",
        type: "integer",
        required: false,
        unit1: "Z direction",
        unit2: "Z direction",
        order: 4,
        table: "Transverse Rebar",
    },
    leg_y: {
        editor: "output",
        type: "integer",
        required: false,
        unit1: "Y direction",
        unit2: "Y direction",
        order: 5,
        table: "Transverse Rebar",
    },
    url: {
        editor: "hidden",
        type: "string",
        required: false,
        unit1: "Url",
        unit2: "Url",
        order: 2,
        table: "Hidden",
        multiple: true, //for c1,c2
        column: 1,
    },
    name: {
        editor: "output",
        type: "string",
        required: false,
        unit1: "Column",
        unit2: "Column",
        max_length: 40,
        order: 1,
        table: "Section Details",
        presentation: true,
    },
    _type: {
        editor: "output",
        required: true,
        unit1: "Shape",
        unit2: "Shape",
        order: 3,
        table: "Section Details",
        presentation: true,
    },
    width: {
        editor: "output",
        type: "float",
        required: true,
        unit1: "Width (mm)",
        unit2: "Width",
        order: 4,
        table: "Section Details",
        presentation: true,
        min: 300,
        max: 400,
    },

    depth: {
        editor: "output",
        type: "float",
        required: true,
        unit1: "Depth (mm)",
        unit2: "Depth",
        order: 5,
        table: "Section Details",
        presentation: true,
    },

    concrete_quality: {
        editor: "output",
        type: "string",
        required: false,
        unit1: "Concrete (m3/m)",
        unit2: "Concrete (m3/m)",
        order: 2,
        table: "Quantity Summary",
    },
    vertical_rebar_quality: {
        editor: "output",
        type: "string",
        required: false,
        unit1: "Longitudinal Rebar (kg/m)",
        unit2: "Longitudinal Rebar (kg/m)",
        order: 3,
        table: "Quantity Summary",
    },
    horizontal_rebar_quality: {
        editor: "output",
        type: "string",
        required: false,
        unit1: "Transverse Rebar (kg/m)",
        unit2: "Transverse Rebar (kg/m)",
        order: 4,
        table: "Quantity Summary",
    },

}

const columnMaterial = {
    fc_material: {
        editor: "select",
        required: true,
        unit1: "Concrete",
        unit2: "Concrete",
        options: "external",
        external_options: [],
        external_mapper: {
            "Material Properties": {
                ec: "modulus_of_elasticity",
                wc: "density",
                fc: "compressive_strength_cylinder_test",
                lamda: "light_weight_lambda_factor",
            },
        },
        external_link: `api/project/id/materials/?name=Concrete`,
        labelname: "name",
        labelvalue: "name",
        url: "materials/?name=Concrete",
        order: 1,
        table: "Material Properties",
    },
    fy_material: {
        editor: "select",
        required: true,
        unit1: "Longitudinal Rebar",
        unit2: "Longitudinal Rebar",
        options: "external",
        external_options: [],
        external_mapper: {
            "Material Properties": {
                es: "modulus_of_elasticity",
                fy: "min_yield_stress",
                ws: "density",
            },
        },
        labelname: "name",
        labelvalue: "name",
        external_link: `api/project/id/materials/?name=Rebar`,
        url: "materials/?name=Rebar",
        order: 2,
        table: "Material Properties",
    },
    fyv_material: {
        editor: "select",
        required: true,
        unit1: "Transverse Rebar",
        unit2: "Transverse Rebar",
        options: "external",
        external_options: [],
        external_mapper: {
            "Material Properties": {
                fyv: "min_yield_stress",
            },
        },
        labelname: "name",
        labelvalue: "name",
        external_link: `api/project/id/materials/?name=Rebar`,
        url: "materials/?name=Rebar",
        order: 3,
        table: "Material Properties",
    },
    fc: {
        editor: "output",
        type: "string",
        required: false,
        unit1: "fc' (MPa)",
        unit2: "fc' (MPa)",
        order: 4,
        table: "Material Properties",
    },
    fy: {
        editor: "output",
        type: "string",
        required: false,
        unit1: "fy (MPa)",
        unit2: "fy (MPa)",
        order: 5,
        table: "Material Properties",
    },
    fyv: {
        editor: "output",
        type: "string",
        required: false,
        unit1: "fyv (MPa)",
        unit2: "fyv (MPa)",
        order: 6,
        table: "Material Properties",
    },
    wc: {
        editor: "output",
        type: "float",
        required: false,
        unit1: "Wc (kg/m  3)",
        unit2: "Wc (kg/m  3)",
        order: 11,
        table: "Material Properties",
    },
    ws: {
        editor: "output",
        type: "float",
        required: false,
        unit1: "Ws (kg/m  3)",
        unit2: "Ws (kg/m  3)",
        order: 12,
        table: "Material Properties",
    },
    lamda: {
        editor: "output",
        type: "float",
        required: false,
        unit1: "λ",
        unit2: "λ",
        order: 13,
        table: "Material Properties",
    },
}

const punchingShear = {
    name: {
        editor: "input",
        type: "string",
        required: false,
        unit1: "Column ID",
        unit2: "Column ID",
        max_length: 40,
        order: 1,
        table: "Section Details",
        presentation: true,
    },
    shape: {
        editor: "select",
        required: true,
        unit1: "Shape",
        unit2: "Shape",
        options: [
            {
                id: "Rectangle",
                value: "Rectangle",
            },
            {
                id: "Circular",
                value: "Circular",
            },
        ],
        labelname: "id",
        labelvalue: "value",
        order: 3,
        table: "Section Details",
        presentation: false,
    },
    c1: {
        editor: "input",
        type: "float",
        required: true,
        unit1: "Column Dia/Width",
        unit2: "Column Dia/Width",
        order: 4,
        table: "Section Details",
        presentation: false,
        min: 300,
        max: 400,
    },
    c2: {
        editor: "input",
        type: "float",
        required: true,
        unit1: "Column Depth",
        unit2: "Column Depth",
        order: 5,
        table: "Section Details",
        presentation: false,
    },
    dc_eff: {
        editor: "input",
        type: "float",
        required: true,
        unit1: "Effective cover",
        unit2: "Effective cover",
        order: 7,
        table: "Section Details",
        presentation: false,
        min: 300,
        max: 400,
    },
    h: {
        editor: "input",
        type: "float",
        required: true,
        unit1: "Slab Depth",
        unit2: "Slab Depth",
        order: 6,
        table: "Section Details",
        presentation: true,
        min: 300,
        max: 400,
    },
    pos: {
        editor: "select",
        required: true,
        unit1: "Load Position",
        unit2: "Load Position",
        options: [
            {
                id: "Shear Centre",
                value: "Shear Centre",
            },
            {
                id: "Face of Column",
                value: "Face of Column",
            },
        ],
        labelname: "shape",
        labelvalue: "shape",
        order: 8,
        table: "Section Details",
        presentation: false,
    },
    rx: {
        editor: "input",
        type: "float",
        required: true,
        unit1: "Edge distance, rx",
        unit2: "Edge distance, rx",
        order: 10,
        table: "Section Details",
        presentation: false,
    },
    ry: {
        editor: "input",
        type: "float",
        required: true,
        unit1: "Edge distance, ry",
        unit2: "Edge distance, ry",
        order: 11,
        table: "Section Details",
        presentation: false,
    },
    _headerdesignsummary_1: {
        editor: "output",
        required: false,
        unit1: "Check",
        value: "Ultization",
        order: 2,
        table: "Design Summary",
    },
    _headerdesignsummary_2: {
        editor: "output",
        required: false,
        unit1: "Check",
        value: "Load",
        order: 2,
        table: "Design Summary",
    },
    res2_vu_by_phi_vc: {
        editor: "input",
        type: "float",
        required: true,
        unit1: "Vu/∅Vc",
        unit2: "Vu/∅Vc",
        order: 3,
        table: "Design Summary",
        presentation: true,
    },
    res2_vu_by_phi_vn: {
        editor: "input",
        type: "float",
        required: true,
        unit1: "Vu/∅Vn",
        unit2: "Vu/∅Vn",
        order: 4,
        table: "Design Summary",
        presentation: true,
    },
    res2_beam_length: {
        editor: "input",
        type: "float",
        required: true,
        unit1: "Beam length",
        unit2: "Beam length",
        order: 5,
        table: "Design Summary",
        presentation: true,
    },
    res2_leg_spacing: {
        editor: "input",
        type: "float",
        required: true,
        unit1: "Leg spacing",
        unit2: "Leg spacing",
        order: 6,
        table: "Design Summary",
        presentation: true,
    },
    res2_vu_by_phi_vc_outer: {
        editor: "input",
        type: "float",
        required: true,
        unit1: "Vu/∅Vc (Outer)",
        unit2: "Vu/∅Vc (Outer)",
        order: 7,
        table: "Design Summary",
        presentation: true,
    },
    res2_b0_net: {
        editor: "input",
        type: "float",
        required: true,
        unit1: "b0",
        unit2: "b0",
        order: 1,
        table: "Section Summary",
        presentation: false,
    },
    res2_shear_centre_abt_y: {
        editor: "input",
        type: "float",
        required: true,
        unit1: "x0",
        unit2: "x0",
        order: 2,
        table: "Section Summary",
        presentation: false,
    },
    res2_shear_centre_abt_x: {
        editor: "input",
        type: "float",
        required: true,
        unit1: "y0",
        unit2: "y0",
        order: 3,
        table: "Section Summary",
        presentation: false,
    },
    res1_gamma_v_abt_x: {
        editor: "input",
        type: "float",
        required: true,
        unit1: "gvy",
        unit2: "gvy",
        order: 4,
        table: "Section Summary",
        presentation: false,
    },
    res1_gamma_v_abt_y: {
        editor: "input",
        type: "float",
        required: true,
        unit1: "gvx",
        unit2: "gvx",
        order: 5,
        table: "Section Summary",
        presentation: false,
    },
    fc_material: {
        editor: "select",
        required: true,
        unit1: "Concrete",
        unit2: "Concrete",
        options: "external",
        external_options: [],
        external_mapper: {
            "Material Properties": {
                ec: "modulus_of_elasticity",
                wc: "density",
                fc: "compressive_strength_cylinder_test",
                lamda: "light_weight_lambda_factor",
            },
        },
        external_link: `api/projectsetting/?project_id=29f67043-ac1b-4370-9e67-3656bb335188`,
        labelname: "name",
        labelvalue: "name",
        url: "materials/?name=Concrete",
        order: 1,
        table: "Material Properties",
    },
    reinf_type: {
        editor: "select",
        required: false,
        unit1: "Type",
        unit2: "Type",
        options: [
            {
                id: "Stirrups",
                value: "Stirrups",
            },
            {
                id: "Studs",
                value: "Studs",
            },
        ],
        labelname: "id",
        labelvalue: "value",
        order: 2,
        table: "Material Properties",
    },
    fy_material: {
        editor: "select",
        required: true,
        unit1: "Rebar grade",
        unit2: "Rebar grade",
        options: "external",
        external_options: [],
        external_mapper: {
            "Material Properties": {
                es: "modulus_of_elasticity",
                fy: "min_yield_stress",
                ws: "density",
            },
        },
        labelname: "name",
        labelvalue: "name",
        external_link: `api/project/id/materials/?name=Rebar`,
        url: "materials/?name=Rebar",
        order: 3,
        table: "Material Properties",
    },
    fc: {
        editor: "output",
        type: "string",
        required: false,
        unit1: "fc'",
        unit2: "fc'",
        order: 4,
        table: "Material Properties",
    },
    fy: {
        editor: "output",
        type: "string",
        required: false,
        unit1: "fyv",
        unit2: "fyv",
        order: 5,
        table: "Material Properties",
    },
    lamda: {
        editor: "output",
        type: "float",
        required: false,
        unit1: "λ",
        unit2: "λ",
        order: 6,
        table: "Material Properties",
    },
    approved_by: {
        editor: "input",
        type: "string",
        required: false,
        unit1: "Approved by",
        unit2: "Approved by",
        max_length: 40,
        order: 2,
        table: "Design Approval",
        presentation: true,
    },

    sv_dia: {
        editor: "input",
        type: "float",
        required: true,
        unit1: "Diameter, mm",
        unit2: "Diameter, mm",
        order: 1,
        table: "Rebar Data",
        presentation: false,
    },
    sv_nos_c1_side: {
        editor: "input",
        type: "float",
        required: true,
        unit1: "No of legs, C1 side",
        unit2: "No of legs, C1 side",
        order: 2,
        table: "Rebar Data",
        presentation: false,
    },
    sv_nos_c2_side: {
        editor: "input",
        type: "float",
        required: true,
        unit1: "No of legs, C2 side",
        unit2: "No of legs, C2 side",
        order: 3,
        table: "Rebar Data",
        presentation: false,
    },
    sv_prov: {
        editor: "input",
        type: "float",
        required: true,
        unit1: "Leg spacing, sv1",
        unit2: "Leg spacing, sv1",
        order: 4,
        table: "Rebar Data",
        presentation: false,
    },
    sv_lat_spacing: {
        editor: "input",
        type: "float",
        required: true,
        unit1: "Leg spacing, sv2",
        unit2: "Leg spacing, sv2",
        order: 5,
        table: "Rebar Data",
        presentation: false,
    },
    outer_b0_dist_prov: {
        editor: "input",
        type: "float",
        required: true,
        unit1: "Rebar length, mm",
        unit2: "Rebar length, mm",
        order: 6,
        table: "Rebar Data",
        presentation: false,
    },
    status: {
        editor: "input",
        type: "string",
        required: true,
        unit1: "Status",
        unit2: "Status",
        order: 1,
        table: "Design Summary",
        presentation: true,
    },

    res1_corner_shear_stress_a: {
        editor: "input",
        type: "float",
        required: true,
        unit1: "A",
        unit2: "A",
        order: 1,
        table: "Corner Shear Stresses",
        presentation: false,
    },
    res1_corner_shear_stress_b: {
        editor: "input",
        type: "float",
        required: true,
        unit1: "B",
        unit2: "B",
        order: 2,
        table: "Corner Shear Stresses",
        presentation: false,
    },
    res1_corner_shear_stress_c: {
        editor: "input",
        type: "float",
        required: true,
        unit1: "C",
        unit2: "C",
        order: 3,
        table: "Corner Shear Stresses",
        presentation: false,
    },
    res1_corner_shear_stress_d: {
        editor: "input",
        type: "float",
        required: true,
        unit1: "D",
        unit2: "D",
        order: 4,
        table: "Corner Shear Stresses",
        presentation: false,
    },
}

const punchingShearSection = {
    name: {
        editor: "output",
        type: "string",
        required: false,
        unit1: "Column ID",
        unit2: "Column ID",
        max_length: 40,
        order: 1,
        table: "Geometry",
        presentation: false,
    },
    shape: {
        editor: "output",
        required: true,
        unit1: "Shape",
        unit2: "Shape",
        order: 3,
        table: "Geometry",
        presentation: false,
    },
    c1: {
        editor: "output",
        type: "float",
        required: true,
        unit1: "Column Dia/Width",
        unit2: "Column Dia/Width",
        order: 4,
        table: "Geometry",
        presentation: false,
        min: 300,
        max: 400,
    },
    c2: {
        editor: "input",
        type: "float",
        required: true,
        unit1: "Column Depth",
        unit2: "Column Depth",
        order: 5,
        table: "Section Details",
        presentation: false,
    },
    dc_eff: {
        editor: "output",
        type: "float",
        required: true,
        unit1: "Effective cover",
        unit2: "Effective cover",
        order: 7,
        table: "Geometry",
        presentation: false,
        min: 300,
        max: 400,
    },
    h: {
        editor: "output",
        type: "float",
        required: true,
        unit1: "Slab Depth",
        unit2: "Slab Depth",
        order: 6,
        table: "Geometry",
        presentation: true,
        min: 300,
        max: 400,
    },
    pos: {
        editor: "output",
        required: true,
        unit1: "Load Position",
        unit2: "Load Position",
        order: 8,
        table: "Geometry",
        presentation: false,
    },
    rx: {
        editor: "output",
        type: "float",
        required: true,
        unit1: "Edge distance, rx",
        unit2: "Edge distance, rx",
        order: 10,
        table: "Geometry",
        presentation: false,
    },
    ry: {
        editor: "output",
        type: "float",
        required: true,
        unit1: "Edge distance, ry",
        unit2: "Edge distance, ry",
        order: 11,
        table: "Geometry",
        presentation: false,
    },
    sv_dia: {
        editor: "output",
        type: "float",
        required: true,
        unit1: "Diameter, mm",
        unit2: "Diameter, mm",
        order: 1,
        table: "Rebar Data",
        presentation: false,
    },
    sv_nos_c1_side: {
        editor: "output",
        type: "float",
        required: true,
        unit1: "No of legs, C1 side",
        unit2: "No of legs, C1 side",
        order: 2,
        table: "Rebar Data",
        presentation: false,
    },
    sv_nos_c2_side: {
        editor: "output",
        type: "float",
        required: true,
        unit1: "No of legs, C2 side",
        unit2: "No of legs, C2 side",
        order: 3,
        table: "Rebar Data",
        presentation: false,
    },
    sv_prov: {
        editor: "output",
        type: "float",
        required: true,
        unit1: "Leg spacing, sv1",
        unit2: "Leg spacing, sv1",
        order: 4,
        table: "Rebar Data",
        presentation: false,
    },
    sv_lat_spacing: {
        editor: "output",
        type: "float",
        required: true,
        unit1: "Leg spacing, sv2",
        unit2: "Leg spacing, sv2",
        order: 5,
        table: "Rebar Data",
        presentation: false,
    },
    outer_b0_dist_prov: {
        editor: "output",
        type: "float",
        required: true,
        unit1: "Rebar length, mm",
        unit2: "Rebar length, mm",
        order: 6,
        table: "Rebar Data",
        presentation: false,
    },
    fc_material: {
        editor: "output",
        required: true,
        unit1: "Concrete",
        unit2: "Concrete",
        url: "materials/?name=Concrete",
        order: 1,
        table: "Material Properties",
    },
    reinf_type: {
        editor: "output",
        required: false,
        unit1: "Rebar Type",
        unit2: "Rebar Type",
        order: 2,
        table: "Material Properties",
    },
    fy_material: {
        editor: "output",
        required: true,
        unit1: "Rebar grade",
        unit2: "Rebar grade",
        order: 3,
        table: "Material Properties",
    },
    fc: {
        editor: "output",
        type: "string",
        required: false,
        unit1: "fc'",
        unit2: "fc'",
        order: 4,
        table: "Material Properties",
    },
    fy: {
        editor: "output",
        type: "string",
        required: false,
        unit1: "fyv",
        unit2: "fyv",
        order: 5,
        table: "Material Properties",
    },
    lamda: {
        editor: "output",
        type: "float",
        required: false,
        unit1: "λ",
        unit2: "λ",
        order: 6,
        table: "Material Properties",
    },

    // section summary


    res1_b0_net: {
        editor: "output",
        type: "float",
        required: true,
        unit1: "b0",
        unit2: "b0",
        order: 1,
        table: "Section Summary",
        presentation: false,
    },
    res1_shear_centre_abt_y: {
        editor: "output",
        type: "float",
        required: true,
        unit1: "x0",
        unit2: "x0",
        order: 2,
        table: "Section Summary",
        presentation: false,
    },
    res1_shear_centre_abt_x: {
        editor: "output",
        type: "float",
        required: true,
        unit1: "y0",
        unit2: "y0",
        order: 3,
        table: "Section Summary",
        presentation: false,
    },
    res1_gamma_v_abt_x: {
        editor: "output",
        type: "float",
        required: true,
        unit1: "gvy",
        unit2: "gvy",
        order: 4,
        table: "Section Summary",
        presentation: false,
    },
    res1_gamma_v_abt_y: {
        editor: "output",
        type: "float",
        required: true,
        unit1: "gvx",
        unit2: "gvx",
        order: 5,
        table: "Section Summary",
        presentation: false,
    },
    res1_polar_moi_abt_y: {
        editor: "output",
        type: "float",
        required: true,
        unit1: "Jy",
        unit2: "Jy",
        order: 6,
        table: "Section Summary",
        presentation: false,
    },
    res1_polar_moi_abt_x: {
        editor: "output",
        type: "float",
        required: true,
        unit1: "Jx",
        unit2: "Jx",
        order: 7,
        table: "Section Summary",
        presentation: false,
    },

    _headerdesignsummary_1: {
        editor: "output",
        required: false,
        unit1: "Check",
        value: "Ultization",
        order: 2,
        table: "Design Summary",
    },
    _headerdesignsummary_2: {
        editor: "output",
        required: false,
        unit1: "Check",
        value: "Load",
        order: 2,
        table: "Design Summary",
    },
    res2_vu_by_phi_vc: {
        editor: "output",
        type: "float",
        required: true,
        unit1: "Vu/∅Vc",
        unit2: "Vu/∅Vc",
        order: 3,
        table: "Design Summary",
        presentation: false,
    },
    res2_vu_by_phi_vn: {
        editor: "output",
        type: "float",
        required: true,
        unit1: "Vu/∅Vn",
        unit2: "Vu/∅Vn",
        order: 3,
        table: "Design Summary",
        presentation: false,
    },
    res2_beam_length: {
        editor: "output",
        type: "float",
        required: true,
        unit1: "Beam length",
        unit2: "Beam length",
        order: 4,
        table: "Design Summary",
        presentation: false,
    },
    res2_beam_length_load: {
        editor: "output",
        type: "float",
        required: true,
        unit1: "Beam length",
        unit2: "Beam length",
        order: 4,
        table: "Design Summary",
        presentation: false,
    },
    res2_leg_spacing: {
        editor: "output",
        type: "float",
        required: true,
        unit1: "Leg spacing",
        unit2: "Leg spacing",
        order: 6,
        table: "Design Summary",
        presentation: false,
    },
    res2_leg_spacing_load: {
        editor: "output",
        type: "float",
        required: true,
        unit1: "Leg spacing",
        unit2: "Leg spacing",
        order: 6,
        table: "Design Summary",
        presentation: false,
    },
    res2_vu_by_phi_vc_outer: {
        editor: "output",
        type: "float",
        required: true,
        unit1: "Vu/∅Vc (Outer)",
        unit2: "Vu/∅Vc (Outer)",
        order: 7,
        table: "Design Summary",
        presentation: false,
    },
    res2_vu_by_phi_vc_outer_load: {
        editor: "output",
        type: "float",
        required: true,
        unit1: "Vu/∅Vc (Outer)",
        unit2: "Vu/∅Vc (Outer)",
        order: 7,
        table: "Design Summary",
        presentation: false,
    },

    res1_corner_shear_stress_a: {
        editor: "output",
        type: "float",
        required: true,
        unit1: "A",
        unit2: "A",
        order: 1,
        table: "Corner Shear Stresses",
        presentation: false,
    },
    res1_corner_shear_stress_b: {
        editor: "output",
        type: "float",
        required: true,
        unit1: "B",
        unit2: "B",
        order: 2,
        table: "Corner Shear Stresses",
        presentation: false,
    },
    res1_corner_shear_stress_c: {
        editor: "output",
        type: "float",
        required: true,
        unit1: "C",
        unit2: "C",
        order: 3,
        table: "Corner Shear Stresses",
        presentation: false,
    },
    res1_corner_shear_stress_d: {
        editor: "output",
        type: "float",
        required: true,
        unit1: "D",
        unit2: "D",
        order: 4,
        table: "Corner Shear Stresses",
        presentation: false,
    },

    _headeropening_1: {
        editor: "output",
        required: false,
        unit1: "Opening ID",
        value: "Opening ID",
        order: 0,
        table: "Openings",
    },
    _headeropening_2: {
        editor: "output",
        required: false,
        unit1: "Center X",
        value: "Center X",
        order: 0,
        table: "Openings",
    },
    _headeropening_3: {
        editor: "output",
        required: false,
        unit1: "Center Y",
        value: "Center Y",
        order: 0,
        table: "Openings",
    },
    _headeropening_4: {
        editor: "output",
        required: false,
        unit1: "Width",
        value: "Width",
        order: 0,
        table: "Openings",
    },
    _headeropening_5: {
        editor: "output",
        required: false,
        unit1: "Depth",
        value: "Depth",
        order: 0,
        table: "Openings",
    },

    openings: {
        editor: "output",
        required: false,
        unit1: "openings",
        value: "openings",
        table: "Openings",
    }

    // ...Object.fromEntries(
    //     Array.from({ length: 6 }, (_, index) => [
    //         [
    //             `opening${index + 1}_id`,
    //             {
    //                 editor: "output",
    //                 type: "int",
    //                 required: true,
    //                 unit1: `Opening ${index + 1}`,
    //                 unit2: `Opening ${index + 1}`,
    //                 order: index + 1,
    //                 table: "Openings",
    //                 presentation: false,
    //                 values: index + 1
    //             }
    //         ],
    //         [
    //             `opening${index + 1}_center_x`,
    //             {
    //                 editor: "output",
    //                 type: "float",
    //                 required: true,
    //                 unit1: `Center X`,
    //                 unit2: `Center X`,
    //                 order: index + 1,
    //                 table: "Openings",
    //                 presentation: false,
    //                 values: 0
    //             }
    //         ],
    //         [
    //             `opening${index + 1}_center_y`,
    //             {
    //                 editor: "output",
    //                 type: "float",
    //                 required: true,
    //                 unit1: `Center Y`,
    //                 unit2: `Center Y`,
    //                 order: index + 1,
    //                 table: "Openings",
    //                 presentation: false,
    //                 values: 0
    //             }
    //         ],
    //         [
    //             `opening${index + 1}_width`,
    //             {
    //                 editor: "output",
    //                 type: "float",
    //                 required: true,
    //                 unit1: `Width`,
    //                 unit2: `Width`,
    //                 order: index + 1,
    //                 table: "Openings",
    //                 presentation: false,
    //                 values: 0
    //             }
    //         ],
    //         [
    //             `opening${index + 1}_depth`,
    //             {
    //                 editor: "output",
    //                 type: "float",
    //                 required: true,
    //                 unit1: `Depth`,
    //                 unit2: `Depth`,
    //                 order: index + 1,
    //                 table: "Openings",
    //                 presentation: false,
    //                 values: 0
    //             }
    //         ]
    //     ]).flat()
    // )
}

const flexureShear = {
    name: {
        editor: "input",
        type: "string",
        required: false,
        unit1: "Section ID",
        unit2: "Section ID",
        max_length: 40,
        order: 1,
        table: "information",
        presentation: true,
    },
    bw: {
        editor: "input",
        type: "float",
        required: true,
        unit1: "Width (mm)",
        unit2: "Width",
        order: 3,
        table: "information",
        presentation: true,
        min: 300,
        max: 400,
    },
    d: {
        editor: "input",
        type: "float",
        required: true,
        unit1: "Depth (mm)",
        unit2: "Depth",
        order: 4,
        table: "information",
        presentation: true,
    },
    be: {
        editor: "input",
        type: "float",
        required: true,
        unit1: "Flange Projection",
        unit2: "Flange Projection",
        order: 5,
        table: "information",
        presentation: true,
    },
    hf: {
        editor: "input",
        type: "float",
        required: true,
        unit1: "Flange Depth",
        unit2: "Flange Depth",
        order: 6,
        table: "information",
        presentation: true,
    },
    res1_at_provided: {
        editor: "output",
        type: "float",
        required: true,
        unit1: "Torsion links area",
        unit2: "Torsion links area",
        order: 8,
        table: "Section Summary",
        presentation: true,
    },
    res1_av_provided: {
        editor: "output",
        type: "float",
        required: true,
        unit1: "Shear links area",
        unit2: "Shear links area",
        order: 9,
        table: "Section Summary",
        presentation: true,
    },
    res1_al_provided: {
        editor: "output",
        type: "float",
        required: true,
        unit1: "Torsion longitudinal rebar",
        unit2: "Torsion longitudinal rebar",
        order: 10,
        table: "Section Summary",
        presentation: true,
    },
    status: {
        editor: "input",
        type: "string",
        required: true,
        unit1: "Status",
        unit2: "Status",
        order: 11,
        table: "Section Details",
        presentation: true,
    },
    section_type: {
        editor: "select",
        required: true,
        unit1: "Section Type",
        unit2: "Section Type",
        options: [
            {
                id: "Singly Reinf. Rectangular Beam",
                value: "Singly Reinf. Rectangular Beam",
            },
            {
                id: "Doubly Reinf. Rectangular Beam",
                value: "Doubly Reinf. Rectangular Beam",
            },
            {
                id: "Signly Reinf. T Beam",
                value: "Signly Reinf. T Beam",
            },
        ],
        labelname: "id",
        labelvalue: "value",
        order: 2,
        table: "information",
        presentation: false,
    },
    top_cover: {
        editor: "input",
        type: "float",
        required: true,
        unit1: "Top Cover",
        unit2: "Top Cover",
        order: 7,
        table: "information",
        presentation: false,
    },
    bottom_cover: {
        editor: "input",
        type: "float",
        required: true,
        unit1: "Bottom Cover",
        unit2: "Bottom Cover",
        order: 8,
        table: "information",
        presentation: false,
    },
    side_cover: {
        editor: "input",
        type: "float",
        required: true,
        unit1: "Side Cover",
        unit2: "Side Cover",
        order: 9,
        table: "information",
        presentation: false,
    },
    spacer: {
        editor: "input",
        type: "float",
        required: true,
        unit1: "Spacer",
        unit2: "Spacer",
        order: 10,
        table: "information",
        presentation: false,
    },
    al_pro_ratio: {
        editor: "input",
        type: "float",
        required: true,
        unit1: "Al Ratio",
        unit2: "Al Ratio",
        order: 11,
        table: "information",
        presentation: false,
    },
    res1_ast_provided: {
        editor: "input",
        type: "float",
        required: true,
        unit1: "Ast provided",
        unit2: "Ast provided",
        order: 1,
        table: "Geometry",
        presentation: false,
    },
    res1_asc_provided: {
        editor: "input",
        type: "float",
        required: true,
        unit1: "Asc provided",
        unit2: "Asc provided",
        order: 2,
        table: "Geometry",
        presentation: false,
    },
    res1_min_required_reinf_area: {
        editor: "input",
        type: "float",
        required: true,
        unit1: "Ast min",
        unit2: "Ast min",
        order: 3,
        table: "Geometry",
        presentation: false,
    },
    res1_max_permitted_reinf_area: {
        editor: "input",
        type: "float",
        required: true,
        unit1: "Ast max",
        unit2: "Ast max",
        order: 4,
        table: "Geometry",
        presentation: false,
    },
    res1_phi_mn_limit_singly: {
        editor: "input",
        type: "float",
        required: true,
        unit1: "∅Mn limit, singly",
        unit2: "∅Mn limit, singly",
        order: 5,
        table: "Geometry",
        presentation: false,
    },
    res1_phi_mn_limit_doubly: {
        editor: "input",
        type: "float",
        required: true,
        unit1: "∅Mn limit, doubly",
        unit2: "∅Mn limit, doubly",
        order: 6,
        table: "Geometry",
        presentation: false,
    },
    res1_phi_mn: {
        editor: "input",
        type: "float",
        required: true,
        unit1: "∅Mn",
        unit2: "∅Mn",
        order: 7,
        table: "Geometry",
        presentation: false,
    },
    // res1_at_provided: {
    //     editor: "input",
    //     type: "float",
    //     required: true,
    //     unit1: "at",
    //     unit2: "at",
    //     order: 8,
    //     table: "Geometry",
    //     presentation: false,
    // },
    // res1_av_provided: {
    //     editor: "input",
    //     type: "float",
    //     required: true,
    //     unit1: "aV + at",
    //     unit2: "aV + at",
    //     order: 9,
    //     table: "Geometry",
    //     presentation: false,
    // },
    // res1_al_provided: {
    //     editor: "input",
    //     type: "float",
    //     required: true,
    //     unit1: "Al",
    //     unit2: "Al",
    //     order: 10,
    //     table: "Geometry",
    //     presentation: false,
    // },

    _headerdesignsummary_1: {
        editor: "output",
        required: false,
        unit1: "Check",
        value: "Ultization",
        order: 0,
        table: "Design Summary",
    },
    _headerdesignsummary_2: {
        editor: "output",
        required: false,
        unit1: "Check",
        value: "Load",
        order: 0,
        table: "Design Summary",
    },
    res2_moment_utilization: {
        editor: "input",
        type: "float",
        required: true,
        unit1: "Moment",
        unit2: "Moment",
        order: 1,
        table: "Design Summary",
        presentation: false,
    },
    res2_moment_utilization_load: {
        editor: "input",
        type: "float",
        required: true,
        unit1: "Moment",
        unit2: "Moment",
        order: 1,
        table: "Design Summary",
        presentation: false,
    },
    res2_shear_utilization: {
        editor: "input",
        type: "float",
        required: true,
        unit1: "Shear",
        unit2: "Shear",
        order: 2,
        table: "Design Summary",
        presentation: false,
    },
    res2_shear_utilization_load: {
        editor: "input",
        type: "float",
        required: true,
        unit1: "Shear",
        unit2: "Shear",
        order: 2,
        table: "Design Summary",
        presentation: false,
    },
    res2_torsion_utilization: {
        editor: "input",
        type: "float",
        required: true,
        unit1: "Torsion",
        unit2: "Torsion",
        order: 3,
        table: "Design Summary",
        presentation: false,
    },
    res2_torsion_utilization_load: {
        editor: "input",
        type: "float",
        required: true,
        unit1: "Torsion",
        unit2: "Torsion",
        order: 3,
        table: "Design Summary",
        presentation: false,
    },
    res2_shear_torsion_utilization: {
        editor: "input",
        type: "float",
        required: true,
        unit1: "Shear & Torsion",
        unit2: "Shear & Torsion",
        order: 4,
        table: "Design Summary",
        presentation: false,
    },
    res2_shear_torsion_utilization_load: {
        editor: "input",
        type: "float",
        required: true,
        unit1: "Shear & Torsion",
        unit2: "Shear & Torsion",
        order: 4,
        table: "Design Summary",
        presentation: false,
    }, res2_ast_reinf_utilization: {
        editor: "input",
        type: "float",
        required: true,
        unit1: "Ast",
        unit2: "Ast",
        order: 5,
        table: "Design Summary",
        presentation: false,
    },
    res2_ast_reinf_utilization_load: {
        editor: "input",
        type: "float",
        required: true,
        unit1: "Ast",
        unit2: "Ast",
        order: 5,
        table: "Design Summary",
        presentation: false,
    }, res2_asc_reinf_utilization: {
        editor: "input",
        type: "float",
        required: true,
        unit1: "Asc",
        unit2: "Asc",
        order: 6,
        table: "Design Summary",
        presentation: false,
    },
    res2_asc_reinf_utilization_load: {
        editor: "input",
        type: "float",
        required: true,
        unit1: "Asc",
        unit2: "Asc",
        order: 6,
        table: "Design Summary",
        presentation: false,
    },
    res2_at_reinf_utilization: {
        editor: "input",
        type: "float",
        required: true,
        unit1: "At",
        unit2: "At",
        order: 7,
        table: "Design Summary",
        presentation: false,
    }, res2_at_reinf_utilization_load: {
        editor: "input",
        type: "float",
        required: true,
        unit1: "At",
        unit2: "At",
        order: 7,
        table: "Design Summary",
        presentation: false,
    },
    res2_av_reinf_utilization: {
        editor: "input",
        type: "float",
        required: true,
        unit1: "Av",
        unit2: "Av",
        order: 8,
        table: "Design Summary",
        presentation: false,
    },
    res2_av_reinf_utilization_load: {
        editor: "input",
        type: "float",
        required: true,
        unit1: "Av",
        unit2: "Av",
        order: 8,
        table: "Design Summary",
        presentation: false,
    },
    res2_at_av_reinf_utilization: {
        editor: "input",
        type: "float",
        required: true,
        unit1: "At+Av",
        unit2: "At+Av",
        order: 9,
        table: "Design Summary",
        presentation: false,
    },
    res2_at_av_reinf_utilization_load: {
        editor: "input",
        type: "float",
        required: true,
        unit1: "At+Av",
        unit2: "At+Av",
        order: 9,
        table: "Design Summary",
        presentation: false,
    },
    res2_at_long_reinf_utilization: {
        editor: "input",
        type: "float",
        required: true,
        unit1: "At long",
        unit2: "At long",
        order: 10,
        table: "Design Summary",
        presentation: false,
    },
    res2_at_long_reinf_utilization_load: {
        editor: "input",
        type: "float",
        required: true,
        unit1: "At long",
        unit2: "At long",
        order: 10,
        table: "Design Summary",
        presentation: false,
    },
    approved_by: {
        editor: "input",
        type: "string",
        required: false,
        unit1: "Approved by",
        unit2: "Approved by",
        max_length: 40,
        order: 2,
        table: "Design Approval",
        presentation: false,
    },

    _headermainrebar_1: {
        editor: "output",
        required: false,
        unit1: "Location",
        value: "Diameter",
        order: 0,
        table: "Main Rebar",
    },
    _headermainrebar_2: {
        editor: "output",
        required: false,
        unit1: "Location",
        value: "No of Bars",
        order: 0,
        table: "Main Rebar",
    },

    db1: {
        editor: "input",
        type: "float",
        required: true,
        unit1: "Bottom, Layer 1",
        unit2: "Bottom, Layer 1",
        order: 1,
        table: "Main Rebar",
        presentation: false,
    },
    bar_nos_lay1: {
        editor: "input",
        type: "float",
        required: true,
        unit1: "Bottom, Layer 1",
        unit2: "Bottom, Layer 1",
        order: 1,
        table: "Main Rebar",
        presentation: false,
    },
    db2: {
        editor: "input",
        type: "float",
        required: true,
        unit1: "Bottom, Layer 2",
        unit2: "Bottom, Layer 2",
        order: 2,
        table: "Main Rebar",
        presentation: false
    },
    bar_nos_lay2: {
        editor: "input",
        type: "float",
        required: true,
        unit1: "Bottom, Layer 2",
        unit2: "Bottom, Layer 2",
        order: 2,
        table: "Main Rebar",
        presentation: false
    },
    db3: {
        editor: "input",
        type: "float",
        required: true,
        unit1: "Bottom, Layer 3",
        unit2: "Bottom, Layer 3",
        order: 3,
        table: "Main Rebar",
        presentation: false
    },
    bar_nos_lay3: {
        editor: "input",
        type: "float",
        required: true,
        unit1: "Bottom, Layer 3",
        unit2: "Bottom, Layer 3",
        order: 3,
        table: "Main Rebar",
        presentation: false
    },
    dt1: {
        editor: "input",
        type: "float",
        required: true,
        unit1: "Top, Layer 1",
        unit2: "Top, Layer 1",
        order: 4,
        table: "Main Rebar",
        presentation: false
    },
    bar_nos_lay4: {
        editor: "input",
        type: "float",
        required: true,
        unit1: "Top, Layer 1",
        unit2: "Top, Layer 1",
        order: 4,
        table: "Main Rebar",
        presentation: false
    },
    dt2: {
        editor: "input",
        type: "float",
        required: true,
        unit1: "Top, Layer 2",
        unit2: "Top, Layer 2",
        order: 5,
        table: "Main Rebar",
        presentation: false
    },
    bar_nos_lay5: {
        editor: "input",
        type: "float",
        required: true,
        unit1: "Top, Layer 2",
        unit2: "Top, Layer 2",
        order: 5,
        table: "Main Rebar",
        presentation: false
    },
    dt3: {
        editor: "input",
        type: "float",
        required: true,
        unit1: "Top, Layer 3",
        unit2: "Top, Layer 3",
        order: 6,
        table: "Main Rebar",
        presentation: false
    },
    bar_nos_lay6: {
        editor: "input",
        type: "float",
        required: true,
        unit1: "Top, Layer 3",
        unit2: "Top, Layer 3",
        order: 6,
        table: "Main Rebar",
        presentation: false
    },
    sideface_dia: {
        editor: "input",
        type: "float",
        required: true,
        unit1: "Side face rebars",
        unit2: "Side face rebars",
        order: 7,
        table: "Main Rebar",
        presentation: false
    },
    sideface_nos: {
        editor: "input",
        type: "float",
        required: true,
        unit1: "Side face rebars",
        unit2: "Side face rebars",
        order: 7,
        table: "Main Rebar",
        presentation: false
    },
    _headertransverserebar_1: {
        editor: "output",
        required: false,
        unit1: "Location",
        value: "Diameter",
        order: 0,
        table: "Transverse Rebar",
    },
    _headertransverserebar_2: {
        editor: "output",
        required: false,
        unit1: "Location",
        value: "No of Legs",
        order: 0,
        table: "Transverse Rebar",
    },
    _headertransverserebar_3: {
        editor: "output",
        required: false,
        unit1: "Location",
        value: "Spacing",
        order: 0,
        table: "Transverse Rebar",
    },
    outer_stirrup_dia: {
        editor: "input",
        type: "float",
        required: true,
        unit1: "Outer Links",
        unit2: "Outer Links",
        order: 1,
        table: "Transverse Rebar",
        presentation: false
    },
    outer_legs_nos: {
        editor: "output",
        type: "float",
        required: true,
        unit1: "Inner Links",
        unit2: "Inner Links",
        order: 1,
        table: "Transverse Rebar",
        presentation: false
    },
    outer_stirrups_spacing: {
        editor: "input",
        type: "float",
        required: true,
        unit1: "Outer Links",
        unit2: "Outer Links",
        order: 1,
        table: "Transverse Rebar",
        presentation: false
    },
    inner_legs_dia: {
        editor: "input",
        type: "float",
        required: true,
        unit1: "Inner Links",
        unit2: "Inner Links",
        order: 2,
        table: "Transverse Rebar",
        presentation: false
    },
    inner_legs_nos: {
        editor: "input",
        type: "float",
        required: true,
        unit1: "Inner Links",
        unit2: "Inner Links",
        order: 2,
        table: "Transverse Rebar",
        presentation: false
    },
    inner_legs_spacing: {
        editor: "input",
        type: "float",
        required: true,
        unit1: "Inner Links",
        unit2: "Inner Links",
        order: 2,
        table: "Transverse Rebar",
        presentation: false
    },
    fc_material: {
        editor: "select",
        required: true,
        unit1: "Concrete",
        unit2: "Concrete",
        options: "external",
        external_options: [],
        external_mapper: {
            "Material Properties": {
                ec: "modulus_of_elasticity",
                wc: "density",
                fc: "compressive_strength_cylinder_test",
                lamda: "light_weight_lambda_factor",
            },
        },
        external_link: `api/project/id/materials/?name=Concrete`,
        labelname: "name",
        labelvalue: "name",
        url: "materials/?name=Concrete",
        order: 1,
        table: "Material Properties",
    },
    fy_material: {
        editor: "select",
        required: true,
        unit1: "Longitudinal Rebar",
        unit2: "Longitudinal Rebar",
        options: "external",
        external_options: [],
        external_mapper: {
            "Material Properties": {
                es: "modulus_of_elasticity",
                fy: "min_yield_stress",
                ws: "density",
            },
        },
        labelname: "name",
        labelvalue: "name",
        external_link: `api/project/id/materials/?name=Rebar`,
        url: "materials/?name=Rebar",
        order: 2,
        table: "Material Properties",
    },
    fyv_material: {
        editor: "select",
        required: true,
        unit1: "Transverse Rebar",
        unit2: "Transverse Rebar",
        options: "external",
        external_options: [],
        external_mapper: {
            "Material Properties": {
                fyv: "min_yield_stress",
            },
        },
        labelname: "name",
        labelvalue: "name",
        external_link: `api/project/id/materials/?name=Rebar`,
        url: "materials/?name=Rebar",
        order: 3,
        table: "Material Properties",
    },
    fc: {
        editor: "output",
        type: "string",
        required: false,
        unit1: "fc'",
        unit2: "fc'",
        order: 4,
        table: "Material Properties",
    },
    fy: {
        editor: "output",
        type: "string",
        required: false,
        unit1: "fy",
        unit2: "fy",
        order: 5,
        table: "Material Properties",
    },
    fyv: {
        editor: "output",
        type: "string",
        required: false,
        unit1: "fyv",
        unit2: "fyv",
        order: 6,
        table: "Material Properties",
    },
    Ec: {
        editor: "output",
        type: "float",
        required: false,
        unit1: "Ec",
        unit2: "Ec",
        order: 7,
        table: "Material Properties",
    },
    Es: {
        editor: "output",
        type: "float",
        required: false,
        unit1: "Es",
        unit2: "Es",
        order: 8,
        table: "Material Properties",
    },
}

const flexureShearSection = {
    name: {
        editor: "output",
        type: "string",
        required: false,
        unit1: "Section ID",
        unit2: "Section ID",
        max_length: 40,
        order: 1,
        table: "Geometry",
        presentation: true,
    },
    section_type: {
        editor: "output",
        type: "float",
        required: true,
        unit1: "Section Type",
        unit2: "Section Type",
        order: 2,
        table: "Geometry",
        presentation: false,
    },
    bw: {
        editor: "output",
        type: "float",
        required: true,
        unit1: "Width (mm)",
        unit2: "Width",
        order: 3,
        table: "Geometry",
        presentation: true,
        min: 300,
        max: 400,
    },

    be: {
        editor: "output",
        type: "float",
        required: true,
        unit1: "Flange Projection",
        unit2: "Flange Projection",
        order: 4,
        table: "information",
        presentation: true,
    },
    d: {
        editor: "output",
        type: "float",
        required: true,
        unit1: "Depth (mm)",
        unit2: "Depth",
        order: 5,
        table: "Geometry",
        presentation: true,
    },
    hf: {
        editor: "output",
        type: "float",
        required: true,
        unit1: "Flange Depth",
        unit2: "Flange Depth",
        order: 6,
        table: "Geometry",
        presentation: true,
    },

    top_cover: {
        editor: "output",
        type: "float",
        required: true,
        unit1: "Top Cover",
        unit2: "Top Cover",
        order: 7,
        table: "Geometry",
        presentation: false,
    },
    bottom_cover: {
        editor: "output",
        type: "float",
        required: true,
        unit1: "Bottom Cover",
        unit2: "Bottom Cover",
        order: 8,
        table: "Geometry",
        presentation: false,
    },
    side_cover: {
        editor: "output",
        type: "float",
        required: true,
        unit1: "Side Cover",
        unit2: "Side Cover",
        order: 9,
        table: "Geometry",
        presentation: false,
    },
    spacer: {
        editor: "output",
        type: "float",
        required: true,
        unit1: "Spacer",
        unit2: "Spacer",
        order: 10,
        table: "Geometry",
        presentation: false,
    },
    al_pro_ratio: {
        editor: "output",
        type: "float",
        required: true,
        unit1: "Al Ratio",
        unit2: "Al Ratio",
        order: 11,
        table: "Geometry",
        presentation: false,
    },

    //section summary

    res1_ast_provided: {
        editor: "output",
        type: "float",
        required: true,
        unit1: "Ast provided",
        unit2: "Ast provided",
        order: 1,
        table: "Section Summary",
        presentation: false,
    },
    res1_asc_provided: {
        editor: "output",
        type: "float",
        required: true,
        unit1: "Asc provided",
        unit2: "Asc provided",
        order: 2,
        table: "Section Summary",
        presentation: false,
    },
    res1_min_required_reinf_area: {
        editor: "output",
        type: "float",
        required: true,
        unit1: "Ast min",
        unit2: "Ast min",
        order: 3,
        table: "Section Summary",
        presentation: false,
    },
    res1_max_permitted_reinf_area: {
        editor: "output",
        type: "float",
        required: true,
        unit1: "Ast max",
        unit2: "Ast max",
        order: 4,
        table: "Section Summary",
        presentation: false,
    },
    res1_phi_mn_limit_singly: {
        editor: "output",
        type: "float",
        required: true,
        unit1: "∅Mn limit, singly",
        unit2: "∅Mn limit, singly",
        order: 5,
        table: "Section Summary",
        presentation: false,
    },
    res1_phi_mn_limit_doubly: {
        editor: "output",
        type: "float",
        required: true,
        unit1: "∅Mn limit, doubly",
        unit2: "∅Mn limit, doubly",
        order: 6,
        table: "Section Summary",
        presentation: false,
    },
    res1_phi_mn: {
        editor: "output",
        type: "float",
        required: true,
        unit1: "∅Mn",
        unit2: "∅Mn",
        order: 7,
        table: "Section Summary",
        presentation: false,
    },
    res1_at_provided: {
        editor: "output",
        type: "float",
        required: true,
        unit1: "Torsion links area",
        unit2: "Torsion links area",
        order: 8,
        table: "Section Summary",
        presentation: false,
    },
    res1_av_provided: {
        editor: "output",
        type: "float",
        required: true,
        unit1: "Shear links area",
        unit2: "Shear links area",
        order: 9,
        table: "Section Summary",
        presentation: false,
    },
    res1_al_provided: {
        editor: "output",
        type: "float",
        required: true,
        unit1: "Torsion longitudinal rebar",
        unit2: "Torsion longitudinal rebar",
        order: 10,
        table: "Section Summary",
        presentation: false,
    },
    _headertransverserebar_1: {
        editor: "output",
        required: false,
        unit1: "Location",
        value: "Diameter",
        order: 0,
        table: "Transverse Rebar",
    },
    _headertransverserebar_2: {
        editor: "output",
        required: false,
        unit1: "Location",
        value: "No of Legs",
        order: 0,
        table: "Transverse Rebar",
    },
    _headertransverserebar_3: {
        editor: "output",
        required: false,
        unit1: "Location",
        value: "Spacing",
        order: 0,
        table: "Transverse Rebar",
    },
    outer_stirrup_dia: {
        editor: "output",
        type: "float",
        required: true,
        unit1: "Outer Links",
        unit2: "Outer Links",
        order: 1,
        table: "Transverse Rebar",
        presentation: false
    },
    outer_legs_nos: {
        editor: "output",
        type: "float",
        required: true,
        unit1: "Inner Links",
        unit2: "Inner Links",
        order: 1,
        table: "Transverse Rebar",
        presentation: false
    },
    outer_stirrups_spacing: {
        editor: "output",
        type: "float",
        required: true,
        unit1: "Outer Links",
        unit2: "Outer Links",
        order: 1,
        table: "Transverse Rebar",
        presentation: false
    },
    inner_legs_dia: {
        editor: "output",
        type: "float",
        required: true,
        unit1: "Outer Links",
        unit2: "Outer Links",
        order: 2,
        table: "Transverse Rebar",
        presentation: false
    },
    inner_legs_nos: {
        editor: "output",
        type: "float",
        required: true,
        unit1: "Outer Links",
        unit2: "Outer Links",
        order: 2,
        table: "Transverse Rebar",
        presentation: false
    },
    inner_legs_spacing: {
        editor: "output",
        type: "float",
        required: true,
        unit1: "Outer Links",
        unit2: "Outer Links",
        order: 2,
        table: "Transverse Rebar",
        presentation: false
    },


    _headermainrebar_1: {
        editor: "output",
        required: false,
        unit1: "Location",
        value: "Diameter",
        order: 0,
        table: "Main Rebar",
    },
    _headermainrebar_2: {
        editor: "output",
        required: false,
        unit1: "Location",
        value: "No of Bars",
        order: 0,
        table: "Main Rebar",
    },

    db1: {
        editor: "output",
        type: "float",
        required: true,
        unit1: "Bottom, Layer 1",
        unit2: "Bottom, Layer 1",
        order: 1,
        table: "Main Rebar",
        presentation: false,
    },
    bar_nos_lay1: {
        editor: "output",
        type: "float",
        required: true,
        unit1: "Bottom, Layer 1",
        unit2: "Bottom, Layer 1",
        order: 1,
        table: "Main Rebar",
        presentation: false,
    },
    db2: {
        editor: "output",
        type: "float",
        required: true,
        unit1: "Bottom, Layer 2",
        unit2: "Bottom, Layer 2",
        order: 2,
        table: "Main Rebar",
        presentation: false
    },
    bar_nos_lay2: {
        editor: "output",
        type: "float",
        required: true,
        unit1: "Bottom, Layer 2",
        unit2: "Bottom, Layer 2",
        order: 2,
        table: "Main Rebar",
        presentation: false
    },
    db3: {
        editor: "output",
        type: "float",
        required: true,
        unit1: "Bottom, Layer 3",
        unit2: "Bottom, Layer 3",
        order: 3,
        table: "Main Rebar",
        presentation: false
    },
    bar_nos_lay3: {
        editor: "output",
        type: "float",
        required: true,
        unit1: "Bottom, Layer 3",
        unit2: "Bottom, Layer 3",
        order: 3,
        table: "Main Rebar",
        presentation: false
    },
    dt1: {
        editor: "output",
        type: "float",
        required: true,
        unit1: "Top, Layer 1",
        unit2: "Top, Layer 1",
        order: 4,
        table: "Main Rebar",
        presentation: false
    },
    bar_nos_lay4: {
        editor: "output",
        type: "float",
        required: true,
        unit1: "Top, Layer 1",
        unit2: "Top, Layer 1",
        order: 4,
        table: "Main Rebar",
        presentation: false
    },
    dt2: {
        editor: "output",
        type: "float",
        required: true,
        unit1: "Top, Layer 2",
        unit2: "Top, Layer 2",
        order: 5,
        table: "Main Rebar",
        presentation: false
    },
    bar_nos_lay5: {
        editor: "output",
        type: "float",
        required: true,
        unit1: "Top, Layer 2",
        unit2: "Top, Layer 2",
        order: 5,
        table: "Main Rebar",
        presentation: false
    },
    dt3: {
        editor: "output",
        type: "float",
        required: true,
        unit1: "Top, Layer 3",
        unit2: "Top, Layer 3",
        order: 6,
        table: "Main Rebar",
        presentation: false
    },
    bar_nos_lay6: {
        editor: "output",
        type: "float",
        required: true,
        unit1: "Top, Layer 3",
        unit2: "Top, Layer 3",
        order: 6,
        table: "Main Rebar",
        presentation: false
    },
    sideface_dia: {
        editor: "output",
        type: "float",
        required: true,
        unit1: "Side face rebars",
        unit2: "Side face rebars",
        order: 7,
        table: "Main Rebar",
        presentation: false
    },
    sideface_nos: {
        editor: "output",
        type: "float",
        required: true,
        unit1: "Side face rebars",
        unit2: "Side face rebars",
        order: 7,
        table: "Main Rebar",
        presentation: false
    },

    status: {
        editor: "output",
        type: "string",
        required: true,
        unit1: "Status",
        unit2: "Status",
        order: 0,
        table: "Design Summary",
        presentation: false,
    },

    _headerdesignsummary_1: {
        editor: "output",
        required: false,
        unit1: "Check",
        value: "Ultization",
        order: 1,
        table: "Design Summary",
    },
    _headerdesignsummary_2: {
        editor: "output",
        required: false,
        unit1: "Check",
        value: "Load",
        order: 1,
        table: "Design Summary",
    },
    res2_moment_utilization: {
        editor: "output",
        type: "float",
        required: true,
        unit1: "Moment",
        unit2: "Moment",
        order: 2,
        table: "Design Summary",
        presentation: false,
    },
    res2_moment_utilization_load: {
        editor: "output",
        type: "float",
        required: true,
        unit1: "Moment",
        unit2: "Moment",
        order: 2,
        table: "Design Summary",
        presentation: false,
    },
    res2_shear_utilization: {
        editor: "output",
        type: "float",
        required: true,
        unit1: "Shear",
        unit2: "Shear",
        order: 3,
        table: "Design Summary",
        presentation: false,
    },
    res2_shear_utilization_load: {
        editor: "output",
        type: "float",
        required: true,
        unit1: "Shear",
        unit2: "Shear",
        order: 3,
        table: "Design Summary",
        presentation: false,
    },
    res2_torsion_utilization: {
        editor: "output",
        type: "float",
        required: true,
        unit1: "Torsion",
        unit2: "Torsion",
        order: 4,
        table: "Design Summary",
        presentation: false,
    },
    res2_torsion_utilization_load: {
        editor: "output",
        type: "float",
        required: true,
        unit1: "Torsion",
        unit2: "Torsion",
        order: 4,
        table: "Design Summary",
        presentation: false,
    },
    res2_shear_torsion_utilization: {
        editor: "output",
        type: "float",
        required: true,
        unit1: "Shear & Torsion",
        unit2: "Shear & Torsion",
        order: 5,
        table: "Design Summary",
        presentation: false,
    },
    res2_shear_torsion_utilization_load: {
        editor: "output",
        type: "float",
        required: true,
        unit1: "Shear & Torsion",
        unit2: "Shear & Torsion",
        order: 5,
        table: "Design Summary",
        presentation: false,
    }, res2_ast_reinf_utilization: {
        editor: "output",
        type: "float",
        required: true,
        unit1: "Ast",
        unit2: "Ast",
        order: 6,
        table: "Design Summary",
        presentation: false,
    },
    res2_ast_reinf_utilization_load: {
        editor: "output",
        type: "float",
        required: true,
        unit1: "Ast",
        unit2: "Ast",
        order: 6,
        table: "Design Summary",
        presentation: false,
    }, res2_asc_reinf_utilization: {
        editor: "output",
        type: "float",
        required: true,
        unit1: "Asc",
        unit2: "Asc",
        order: 7,
        table: "Design Summary",
        presentation: false,
    },
    res2_asc_reinf_utilization_load: {
        editor: "output",
        type: "float",
        required: true,
        unit1: "Asc",
        unit2: "Asc",
        order: 7,
        table: "Design Summary",
        presentation: false,
    },
    res2_at_reinf_utilization: {
        editor: "output",
        type: "float",
        required: true,
        unit1: "At",
        unit2: "At",
        order: 8,
        table: "Design Summary",
        presentation: false,
    }, res2_at_reinf_utilization_load: {
        editor: "output",
        type: "float",
        required: true,
        unit1: "At",
        unit2: "At",
        order: 8,
        table: "Design Summary",
        presentation: false,
    },
    res2_av_reinf_utilization: {
        editor: "output",
        type: "float",
        required: true,
        unit1: "Av",
        unit2: "Av",
        order: 9,
        table: "Design Summary",
        presentation: false,
    },
    res2_av_reinf_utilization_load: {
        editor: "output",
        type: "float",
        required: true,
        unit1: "Av",
        unit2: "Av",
        order: 9,
        table: "Design Summary",
        presentation: false,
    },
    res2_at_av_reinf_utilization: {
        editor: "output",
        type: "float",
        required: true,
        unit1: "At+Av",
        unit2: "At+Av",
        order: 10,
        table: "Design Summary",
        presentation: false,
    },
    res2_at_av_reinf_utilization_load: {
        editor: "output",
        type: "float",
        required: true,
        unit1: "At+Av",
        unit2: "At+Av",
        order: 10,
        table: "Design Summary",
        presentation: false,
    },
    res2_at_long_reinf_utilization: {
        editor: "output",
        type: "float",
        required: true,
        unit1: "At long",
        unit2: "At long",
        order: 11,
        table: "Design Summary",
        presentation: false,
    },
    res2_at_long_reinf_utilization_load: {
        editor: "output",
        type: "float",
        required: true,
        unit1: "At long",
        unit2: "At long",
        order: 11,
        table: "Design Summary",
        presentation: false,
    },

    fy_material: {
        editor: "output",
        required: true,
        unit1: "Longitudinal Rebar",
        unit2: "Longitudinal Rebar",
        options: "external",
        external_options: [],
        external_mapper: {
            "Material Properties": {
                es: "modulus_of_elasticity",
                fy: "min_yield_stress",
                ws: "density",
            },
        },
        labelname: "name",
        labelvalue: "name",
        external_link: `api/project/id/materials/?name=Rebar`,
        url: "materials/?name=Rebar",
        order: 2,
        table: "Material Properties",
    },
    fyv_material: {
        editor: "output",
        required: true,
        unit1: "Transverse Rebar",
        unit2: "Transverse Rebar",
        options: "external",
        external_options: [],
        external_mapper: {
            "Material Properties": {
                fyv: "min_yield_stress",
            },
        },
        labelname: "name",
        labelvalue: "name",
        external_link: `api/project/id/materials/?name=Rebar`,
        url: "materials/?name=Rebar",
        order: 3,
        table: "Material Properties",
    },
    fc: {
        editor: "output",
        type: "string",
        required: false,
        unit1: "fc'",
        unit2: "fc'",
        order: 4,
        table: "Material Properties",
    },
    fy: {
        editor: "output",
        type: "string",
        required: false,
        unit1: "fy",
        unit2: "fy",
        order: 5,
        table: "Material Properties",
    },
    fyv: {
        editor: "output",
        type: "string",
        required: false,
        unit1: "fyv",
        unit2: "fyv",
        order: 6,
        table: "Material Properties",
    },
    Ec: {
        editor: "output",
        type: "float",
        required: false,
        unit1: "Ec",
        unit2: "Ec",
        order: 7,
        table: "Material Properties",
    },
    Es: {
        editor: "output",
        type: "float",
        required: false,
        unit1: "Es",
        unit2: "Es",
        order: 8,
        table: "Material Properties",
    },
}

const crackWidth = {
    name: {
        editor: "input",
        type: "string",
        required: false,
        unit1: "Section ID",
        unit2: "Section ID",
        max_length: 40,
        order: 1,
        table: "Geometry",
        presentation: true,
    },
    b: {
        editor: "input",
        type: "float",
        required: true,
        unit1: "Width (mm)",
        unit2: "Width",
        order: 2,
        table: "Geometry",
        presentation: true,
        min: 300,
        max: 400,
    },
    h: {
        editor: "input",
        type: "float",
        required: true,
        unit1: "Depth (mm)",
        unit2: "Depth",
        order: 3,
        table: "Geometry",
        presentation: true,
    },
    clear_cover: {
        editor: "input",
        type: "float",
        required: true,
        unit1: "Clear Cover",
        unit2: "Clear Cover",
        order: 8,
        table: "Geometry",
        presentation: true,
    },
    // res1_total_steel_area: {
    //     editor: "output",
    //     type: "float",
    //     required: true,
    //     unit1: "Ast",
    //     unit2: "Ast",
    //     order: 5,
    //     table: "Section Details",
    //     presentation: true,
    // },
    // res2_cracked_width_eq_1: {
    //     editor: "output",
    //     type: "float",
    //     required: true,
    //     unit1: "Crack Width",
    //     unit2: "Crack Width",
    //     order: 6,
    //     table: "Section Details",
    //     presentation: true,
    // },
    res2_wcal_wlim: {
        editor: "output",
        type: "float",
        required: true,
        unit1: "Wcal / W lim",
        unit2: "Wcal / W lim",
        order: 7,
        table: "Design Summary",
        presentation: true,
    },
    res1_modulus_of_elasticity: {
        editor: "output",
        type: "string",
        required: true,
        unit1: "Modulus of Elasticty",
        unit2: "Modulus of Elasticty",
        order: 1,
        table: "Section Summary",
        presentation: false,
    },
    res1_modulus_of_rupture: {
        editor: "output",
        type: "string",
        required: true,
        unit1: "Modulus of rupture",
        unit2: "Modulus of rupture",
        order: 2,
        table: "Section Summary",
        presentation: false,
    },
    res1_total_steel_area: {
        editor: "output",
        type: "string",
        required: true,
        unit1: "Total steel area",
        unit2: "Total steel area",
        order: 3,
        table: "Section Summary",
        presentation: true,
    },
    res1_gross_section_moment_of_inertia: {
        editor: "output",
        type: "string",
        required: true,
        unit1: "Moment of inertia, igg",
        unit2: "Moment of inertia, igg",
        order: 4,
        table: "Section Summary",
        presentation: false,
    },
    res1_effective_depth: {
        editor: "output",
        type: "string",
        required: true,
        unit1: "Effective depth",
        unit2: "Effective depth",
        order: 5,
        table: "Section Summary",
        presentation: false,
    },
    res1_cracking_moment: {
        editor: "output",
        type: "string",
        required: true,
        unit1: "Cracking moment, Mcr",
        unit2: "Cracking moment, Mcr",
        order: 6,
        table: "Section Summary",
        presentation: true,
    },
    res1_neutral_axis_depth: {
        editor: "output",
        type: "string",
        required: true,
        unit1: "Depth of neutral axis",
        unit2: "Depth of neutral axis",
        order: 7,
        table: "Section Summary",
        presentation: false,
    },
    res1_cracking_moment_of_inertia: {
        editor: "output",
        type: "string",
        required: true,
        unit1: "Cracked Moment of inertia, Icr",
        unit2: "Cracked Moment of inertia, Icr",
        order: 8,
        table: "Section Summary",
        presentation: false,
    },

    res2_net_steel_stress: {
        editor: "output",
        type: "string",
        required: true,
        unit1: "Steel stress, fs (Mpa)",
        unit2: "Steel stress, fs (Mpa)",
        order: 1,
        table: "Design Summary",
        presentation: false,
    },
    res2_beta: {
        editor: "output",
        type: "string",
        required: true,
        unit1: "β",
        unit2: "β",
        order: 2,
        table: "Design Summary",
        presentation: false,
    },
    res2_cracked_width_eq_1: {
        editor: "output",
        type: "string",
        required: true,
        unit1: "Crack width eq1 (mm)",
        unit2: "Crack width eq1 (mm)",
        order: 3,
        table: "Design Summary",
        presentation: true,
    },
    res2_cracked_width_eq_2: {
        editor: "output",
        type: "string",
        required: true,
        unit1: "Crack width eq2 (mm)",
        unit2: "Crack width eq2 (mm)",
        order: 4,
        table: "Design Summary",
        presentation: false,
    },
    ds1: {
        editor: "input",
        activateEdit: true,
        type: "float",
        required: true,
        unit1: "Layer 1 bar dia",
        unit2: "Layer 1 bar dia",
        order: 4,
        table: "Geometry",
        presentation: false,
    },
    ds2: {
        editor: "input",
        type: "float",
        required: true,
        unit1: "Layer 2 bar dia",
        unit2: "Layer 2 bar dia",
        order: 5,
        table: "Geometry",
        presentation: false,
    },
    no_of_bars: {
        editor: "input",
        type: "float",
        required: true,
        unit1: "Number of bars",
        unit2: "Number of bars",
        order: 6,
        table: "Geometry",
        presentation: false,
    },
    spacer: {
        editor: "input",
        type: "float",
        required: true,
        unit1: "Spacer",
        unit2: "Spacer",
        order: 7,
        table: "Geometry",
        presentation: false,
    },
    // clear_cover: {
    //     editor: "input",
    //     type: "float",
    //     required: true,
    //     unit1: "Clear Cover",
    //     unit2: "Clear Cover",
    //     order: 8,
    //     table: "Geometry",
    //     presentation: false,
    // },
    side_cover: {
        editor: "input",
        type: "float",
        required: true,
        unit1: "Side Cover",
        unit2: "Side Cover",
        order: 9,
        table: "Geometry",
        presentation: false,
    },
    fc_material: {
        editor: "select",
        required: true,
        unit1: "Concrete",
        unit2: "Concrete",
        options: "external",
        external_options: [],
        external_mapper: {
            "Material Properties": {
                ec: "modulus_of_elasticity",
                wc: "density",
                fc: "compressive_strength_cylinder_test",
                lamda: "light_weight_lambda_factor",
            },
        },
        external_link: `api/project/id/materials/?name=Concrete`,
        labelname: "name",
        labelvalue: "slug",
        url: "materials/?name=Concrete",
        order: 1,
        table: "Material Properties Edit",
    },
    fy_material: {
        editor: "select",
        required: true,
        unit1: "Rebar",
        unit2: "Rebar",
        options: "external",
        external_options: [],
        external_mapper: {
            "Material Properties": {
                es: "modulus_of_elasticity",
                fy: "min_yield_stress",
                ws: "density",
            },
        },
        labelname: "name",
        labelvalue: "slug",
        external_link: `api/project/id/materials/?name=Rebar`,
        url: "materials/?name=Rebar",
        order: 2,
        table: "Material Properties Edit",
    },
    fc_prime: {
        editor: "output",
        type: "string",
        required: false,
        unit1: "fc'",
        unit2: "fc'",
        order: 3,
        table: "Material Properties Edit",
    },
    fy: {
        editor: "output",
        type: "string",
        required: false,
        unit1: "fy",
        unit2: "fy",
        order: 4,
        table: "Material Properties Edit",
    },
    ec: {
        editor: "output",
        type: "string",
        required: false,
        unit1: "Ec",
        unit2: "Ec'",
        order: 5,
        table: "Material Properties Edit",
    },
    es: {
        editor: "output",
        type: "string",
        required: false,
        unit1: "Es",
        unit2: "Es'",
        order: 6,
        table: "Material Properties Edit",
    },
    lightWtFact: {
        editor: "output",
        type: "string",
        required: false,
        unit1: "λ",
        unit2: "λ",
        order: 7,
        table: "Material Properties Edit",
    },
    w_lim: {
        editor: "input",
        type: "float",
        required: true,
        unit1: "Crack width limit",
        unit2: "Crack width limit",
        order: 10,
        table: "Geometry",
        presentation: false,
    },
    approved_by: {
        editor: "input",
        type: "string",
        required: false,
        unit1: "Approved by",
        unit2: "Approved by",
        max_length: 40,
        order: 2,
        table: "Design Approval",
        presentation: false,
    },

    status: {
        editor: "output",
        type: "string",
        required: true,
        unit1: "Status",
        unit2: "Status",
        order: 9,
        table: "Section Details",
        presentation: true,
    },
}

const crackWidthSection = {
    name: {
        editor: "output",
        type: "string",
        required: true,
        unit1: "Section ID",
        unit2: "Section ID",
        order: 1,
        table: "Section Details",
        presentation: false,
    },
    b: {
        editor: "output",
        type: "float",
        required: true,
        unit1: "Width",
        unit2: "Width",
        order: 2,
        table: "Section Details",
        presentation: false,
    },
    h: {
        editor: "output",
        type: "float",
        required: true,
        unit1: "Depth",
        unit2: "Depth",
        order: 3,
        table: "Section Details",
        presentation: false,
    },
    ds1: {
        editor: "output",
        activateEdit: true,
        type: "float",
        required: true,
        unit1: "Layer 1 bar dia",
        unit2: "Layer 1 bar dia",
        order: 4,
        table: "Section Details",
        presentation: false,
    },
    ds2: {
        editor: "output",
        type: "float",
        required: true,
        unit1: "Layer 2 bar dia",
        unit2: "Layer 2 bar dia",
        order: 5,
        table: "Section Details",
        presentation: false,
    },
    no_of_bars: {
        editor: "output",
        type: "float",
        required: true,
        unit1: "Number of bars",
        unit2: "Number of bars",
        order: 6,
        table: "Section Details",
        presentation: false,
    },
    spacer: {
        editor: "output",
        type: "float",
        required: true,
        unit1: "Spacer",
        unit2: "Spacer",
        order: 7,
        table: "Section Details",
        presentation: false,
    },
    clear_cover: {
        editor: "output",
        type: "float",
        required: true,
        unit1: "Clear Cover",
        unit2: "Clear Cover",
        order: 8,
        table: "Section Details",
        presentation: false,
    },
    side_cover: {
        editor: "output",
        type: "float",
        required: true,
        unit1: "Side Cover",
        unit2: "Side Cover",
        order: 9,
        table: "Section Details",
        presentation: false,
    },
    fc_material: {
        editor: "output",
        required: true,
        unit1: "Concrete",
        unit2: "Concrete",
        options: "external",
        external_options: [],
        external_mapper: {
            "Material Properties": {
                ec: "modulus_of_elasticity",
                wc: "density",
                fc: "compressive_strength_cylinder_test",
                lamda: "light_weight_lambda_factor",
            },
        },
        external_link: `api/project/id/materials/?name=Concrete`,
        labelname: "name",
        labelvalue: "slug",
        url: "materials/?name=Concrete",
        order: 1,
        table: "Material Properties",
    },
    fy_material: {
        editor: "output",
        required: true,
        unit1: "Rebar",
        unit2: "Rebar",
        options: "external",
        external_options: [],
        external_mapper: {
            "Material Properties": {
                es: "modulus_of_elasticity",
                fy: "min_yield_stress",
                ws: "density",
            },
        },
        labelname: "name",
        labelvalue: "name",
        external_link: `api/project/id/materials/?name=Rebar`,
        url: "materials/?name=Rebar",
        order: 2,
        table: "Material Properties",
    },
    fc_prime: {
        editor: "output",
        type: "string",
        required: false,
        unit1: "fc'",
        unit2: "fc'",
        order: 3,
        table: "Material Properties",
    },
    fy: {
        editor: "output",
        type: "string",
        required: false,
        unit1: "fy",
        unit2: "fy",
        order: 4,
        table: "Material Properties",
    },
    ec: {
        editor: "output",
        type: "string",
        required: false,
        unit1: "Ec",
        unit2: "Ec'",
        order: 5,
        table: "Material Properties",
    },
    es: {
        editor: "output",
        type: "string",
        required: false,
        unit1: "Es",
        unit2: "Es'",
        order: 6,
        table: "Material Properties",
    },
    lightWtFact: {
        editor: "output",
        type: "string",
        required: false,
        unit1: "λ",
        unit2: "λ",
        order: 7,
        table: "Material Properties",
    },
}

const settings = {
    id: {
        editor: 'hidden',
        type: 'string',
        required: false,
        unit1: 'id',
        unit2: 'id',
        order: 13,
        table: 'Hidden'
    },
    unit_system: {
        editor: 'select',
        type: 'string',
        required: false,
        unit1: 'Unit System',
        unit2: 'Unit System',
        options: [
            {
                id: "SI Unit",
                value: "SI Unit",
            },
            {
                id: "Imperial",
                value: "Imperial",
            },
        ],
        labelname: "id",
        labelvalue: "value",
        order: 1,
        table: 'Units'
    },
    dimension_dia: {
        editor: 'input',
        type: 'string',
        required: false,
        unit1: 'Section dimensions/ Rebar dia',
        unit2: 'Section dimensions/ Rebar dia',
        order: 2,
        table: 'Units'
    },
    member_length: {
        editor: 'input',
        type: 'string',
        required: false,
        unit1: 'Member Length',
        unit2: 'Member Length',
        order: 3,
        table: 'Units'
    },
    displacement: {
        editor: 'input',
        type: 'string',
        required: false,
        unit1: 'Displacement',
        unit2: 'Displacement',
        order: 4,
        table: 'Units'
    },
    rot_displacement: {
        editor: 'input',
        type: 'string',
        required: false,
        unit1: 'Rotational Displacement',
        unit2: 'Rotational Displacement',
        order: 5,
        table: 'Units'
    },
    rebar_area: {
        editor: 'input',
        type: 'string',
        required: false,
        unit1: 'Rebar Area',
        unit2: 'Rebar Area',
        order: 6,
        table: 'Units'
    },
    force: {
        editor: 'input',
        type: 'string',
        required: false,
        unit1: 'Force',
        unit2: 'Force',
        order: 7,
        table: 'Units'
    },
    moment: {
        editor: 'input',
        type: 'string',
        required: false,
        unit1: 'Moment',
        unit2: 'Moment',
        order: 8,
        table: 'Units'
    },
    stress: {
        editor: 'input',
        type: 'string',
        required: false,
        unit1: 'Stress',
        unit2: 'Stress',
        order: 9,
        table: 'Units'
    },
    mass: {
        editor: 'input',
        type: 'string',
        required: false,
        unit1: 'Mass',
        unit2: 'Mass',
        order: 10,
        table: 'Units'
    },
    weight: {
        editor: 'input',
        type: 'string',
        required: false,
        unit1: 'Weight',
        unit2: 'Weight',
        order: 11,
        table: 'Units'
    },
    density: {
        editor: 'input',
        type: 'string',
        required: false,
        unit1: 'Density',
        unit2: 'Density',
        order: 12,
        table: 'Units'
    },
    angle: {
        editor: 'input',
        type: 'string',
        required: false,
        unit1: 'Angle',
        unit2: 'Angle',
        order: 13,
        table: 'Units'
    },
    temperature: {
        editor: 'input',
        type: 'string',
        required: false,
        unit1: 'Temperature',
        unit2: 'Temperature',
        order: 14,
        table: 'Units'
    },
    time: {
        editor: 'input',
        type: 'string',
        required: false,
        unit1: 'Time',
        unit2: 'Time',
        order: 15,
        table: 'Units'
    },
    theme: {
        editor: 'select',
        type: 'string',
        required: false,
        unit1: 'Theme',
        unit2: 'Theme',
        options: [
            {
                id: "Orange",
                value: "Orange",
            },
            {
                id: "Blue",
                value: "Blue",
            },
            {
                id: "Red",
                value: "Red",
            },
        ],
        labelname: "id",
        labelvalue: "value",
        order: 1,
        table: 'Miscellaneous'
    },
    language: {
        editor: 'select',
        type: 'string',
        required: false,
        unit1: 'Language',
        unit2: 'Language',
        options: [
            {
                id: "English",
                value: "English",
            },
            {
                id: "Arabic",
                value: "Arabic",
            },
        ],
        labelname: "id",
        labelvalue: "value",
        order: 2,
        table: 'Miscellaneous'
    },
    paper_size: {
        editor: 'input',
        type: 'string',
        required: false,
        unit1: 'Paper Size',
        unit2: 'Paper Size',
        order: 1,
        table: 'Print Settings'
    },
    paper_template: {
        editor: 'input',
        type: 'string',
        required: false,
        unit1: 'Paper template',
        unit2: 'Paper template',
        options: [
            {
                id: "Grid",
                value: "Grid",
            },
            {
                id: "Plain",
                value: "Plain",
            },
        ],
        labelname: "id",
        labelvalue: "value",
        order: 2,
        table: 'Print Settings'
    },
    rebar_designation: {
        editor: 'select',
        type: 'string',
        required: false,
        unit1: 'Rebar designation',
        unit2: 'Rebar designation',
        options: [
            {
                id: "US Rebar Size",
                value: "US Rebar Size",
            },
            {
                id: "European Rebar Size",
                value: "European Rebar Size",
            },
        ],
        labelname: "id",
        labelvalue: "value",
        order: 1,
        table: 'Rebar Settings'
    },
    rebar_settings: {
        editor: 'input',
        type: 'string',
        required: false,
        unit1: 'rebar_settings',
        unit2: 'rebar_settings',
        order: 1,
        table: 'rebar_settings',
    },
    concrete_design_code: {
        editor: 'select',
        type: 'string',
        required: false,
        unit1: 'Concrete Design Code',
        unit2: 'Concrete Design Code',
        options: [
            {
                id: "ACI 318-14",
                value: "ACI 318-14",
            },
            {
                id: "BS-8110",
                value: "BS-8110",
            },
        ],
        labelname: "id",
        labelvalue: "value",
        order: 1,
        table: 'Concrete Settings'
    },
    concrete_stress_limit_factor: {
        editor: 'input',
        type: 'string',
        required: false,
        unit1: 'Concrete Stress Limit Factor',
        unit2: 'Concrete Stress Limit Factor',
        order: 2,
        table: 'Concrete Settings'
    },
    beta: {
        editor: 'input',
        type: 'string',
        required: false,
        unit1: 'Beta',
        unit2: 'Beta',
        order: 3,
        table: 'Concrete Settings'
    },
    concrete_strain_limit: {
        editor: 'input',
        type: 'string',
        required: false,
        unit1: 'Concrete Strain Limit',
        unit2: 'Concrete Strain Limit',
        order: 4,
        table: 'Concrete Settings'
    },
    rebar_strain_limit: {
        editor: 'input',
        type: 'string',
        required: false,
        unit1: 'Rebar Strain limit',
        unit2: 'Rebar Strain limit',
        order: 1,
        table: 'Reinforcing Steel'
    },
    tension_controlled: {
        editor: 'input',
        type: 'string',
        required: false,
        unit1: 'Tension Controlled',
        unit2: 'Tension Controlled',
        order: 1,
        table: 'Strength Reduction (Ø)'
    },
    compression_controlled_spiral: {
        editor: 'input',
        type: 'string',
        required: false,
        unit1: 'Compression Controlled (Spiral)',
        unit2: 'Compression Controlled (Spiral)',
        order: 2,
        table: 'Strength Reduction (Ø)'
    },
    compression_controlled_ties: {
        editor: 'input',
        type: 'string',
        required: false,
        unit1: 'Compression Controlled (Ties)',
        unit2: 'Compression Controlled (Ties)',
        order: 3,
        table: 'Strength Reduction (Ø)'
    },
    shear_torsion: {
        editor: 'input',
        type: 'integer',
        required: false,
        unit1: 'Shear/Torsion',
        unit2: 'Shear/Torsion',
        order: 4,
        table: 'Strength Reduction (Ø)'
    },
    shear_seismic: {
        editor: 'input',
        type: 'string',
        required: false,
        unit1: 'Shear (Seismic)',
        unit2: 'Shear (Seismic)',
        order: 5,
        table: 'Strength Reduction (Ø)'
    },
    utilization_factor_limit: {
        editor: 'input',
        type: 'string',
        required: false,
        unit1: 'Utilization factor limit',
        unit2: 'Utilization factor limit',
        order: 6,
        table: 'Strength Reduction (Ø)'
    },
    steel_design_code: {
        editor: 'input',
        type: 'string',
        required: false,
        unit1: 'Steel Design Code',
        unit2: 'Steel Design Code',
        order: 1,
        table: 'Steel Design'
    },
    modifier_beam: {
        editor: 'input',
        type: 'string',
        required: false,
        unit1: 'Beam',
        unit2: 'Beam',
        order: 1,
        table: 'Cracked Section Modifier'
    },
    modifier_column: {
        editor: 'input',
        type: 'string',
        required: false,
        unit1: 'Column',
        unit2: 'Column',
        order: 2,
        table: 'Cracked Section Modifier'
    },
    stiffness_reduction: {
        editor: 'input',
        type: 'string',
        required: false,
        unit1: 'Stiffness Reduction',
        unit2: 'Stiffness Reduction',
        order: 8,
        table: 'Stiffness Data'
    },
    phi_shear: {
        editor: 'input',
        type: 'string',
        required: false,
        unit1: 'Phi Shear Controlled',
        unit2: 'Phi Shear Controlled',
        order: 1,
        table: 'Steel Strength Reduction'
    },
    phi_axial: {
        editor: 'input',
        type: 'string',
        required: false,
        unit1: 'Phi Axial Controlled',
        unit2: 'Phi Axial Controlled',
        order: 2,
        table: 'Steel Strength Reduction'
    },
    phi_tension: {
        editor: 'input',
        type: 'string',
        required: false,
        unit1: 'Phi Tension Controlled',
        unit2: 'Phi Tension Controlled',
        order: 3,
        table: 'Steel Strength Reduction'
    },
    phi_compression: {
        editor: 'input',
        type: 'string',
        required: false,
        unit1: 'Phi Compression Controlled',
        unit2: 'Phi Compression Controlled',
        order: 4,
        table: 'Steel Strength Reduction'
    },
    grade: {
        editor: 'input',
        type: 'string',
        required: false,
        unit1: 'Grade',
        unit2: 'Grade',
        order: 1,
        table: 'Bolt Settings'
    },
    bolt_type: {
        editor: 'input',
        type: 'integer',
        required: false,
        unit1: 'Type',
        unit2: 'Type',
        order: 2,
        table: 'Bolt Settings'
    },
    ultimate_strength_fu: {
        editor: 'input',
        type: 'string',
        required: false,
        unit1: 'Fu-Ultimate strength (Mpa)',
        unit2: 'Fu-Ultimate strength (Mpa)',
        order: 1,
        table: 'Weld Settings'
    },
    consider_minimum_eccentricity: {
        editor: 'input',
        type: 'string',
        required: false,
        unit1: 'Consider minimum eccentricity',
        unit2: 'Consider minimum eccentricity',
        order: 1,
        table: 'Column Design'
    },
    patter_live_load_factor: {
        editor: 'input',
        type: 'string',
        required: false,
        unit1: 'Patter live load factor',
        unit2: 'Patter live load factor',
        order: 1,
        table: 'Beam Design'
    },
    project: {
        editor: 'input',
        type: 'string',
        required: false,
        unit1: 'project',
        unit2: 'project',
        order: 1,
        table: ''
    },
    materials: {
        editor: 'input',
        type: 'string',
        required: false,
        unit1: 'materials',
        unit2: 'materials',
        order: 1,
        table: 'Material',
        url_table: '',
    },
    id: {
        editor: 'output',
        type: 'string',
        required: false,
        unit1: 'id',
        unit2: 'id',
        order: 1,
        table: 'Material Table'
    },
    name: {
        editor: 'input',
        type: 'string',
        required: false,
        unit1: 'Name',
        unit2: 'name',
        order: 1,
        table: 'Material Table'
    },
    material: {
        editor: 'select',
        type: 'string',
        required: false,
        unit1: 'Material',
        unit2: 'material',
        options: [
            {
                id: "Concrete",
                value: "Concrete",
            },
            {
                id: "Rebar",
                value: "Rebar",
            },
            {
                id: "Steel",
                value: "Steel",
            },
        ],
        labelname: "id",
        labelvalue: "value",
        order: 1,
        table: 'Material Table'
    },
    density: {
        editor: 'input',
        type: 'integer',
        required: false,
        unit1: 'Density',
        unit2: 'density',
        order: 1,
        table: 'Material Table'
    },
    modulus_of_elasticity: {
        editor: 'input',
        type: 'integer',
        required: false,
        unit1: 'Modulus of Elasticity(E) Mpa',
        unit2: 'modulus_of_elasticity',
        order: 1,
        table: 'Material Table'
    },
    poissions_ratio: {
        editor: 'input',
        type: 'integer',
        required: false,
        unit1: 'poissions_ratio',
        unit2: 'poissions_ratio',
        order: 1,
        table: 'Material Table'
    },
    coeff_of_thermal_expansion: {
        editor: 'input',
        type: 'integer',
        required: false,
        unit1: 'coeff_of_thermal_expansion',
        unit2: 'coeff_of_thermal_expansion',
        order: 1,
        table: 'Material Table'
    },
    shear_modulus: {
        editor: 'input',
        type: 'integer',
        required: false,
        unit1: 'shear_modulus',
        unit2: 'shear_modulus',
        order: 1,
        table: 'Material Table'
    },
    compressive_strength_cube_test: {
        editor: 'input',
        type: 'integer',
        required: false,
        unit1: 'compressive_strength_cube_test',
        unit2: 'compressive_strength_cube_test',
        order: 1,
        table: 'Material Table'
    },
    compressive_strength_cylinder_test: {
        editor: 'input',
        type: 'integer',
        required: false,
        unit1: 'compressive_strength_cylinder_test',
        unit2: 'compressive_strength_cylinder_test',
        order: 1,
        table: 'Material Table'
    },
    light_weight_lambda_factor: {
        editor: 'input',
        type: 'integer',
        required: false,
        unit1: 'light_weight_lambda_factor',
        unit2: 'light_weight_lambda_factor',
        order: 1,
        table: 'Material Table'
    },
    min_yield_stress: {
        editor: 'input',
        type: 'integer',
        required: false,
        unit1: 'min_yield_stress',
        unit2: 'min_yield_stress',
        order: 1,
        table: 'Material Table'
    },
    min_tensile_stress: {
        editor: 'input',
        type: 'integer',
        required: false,
        unit1: 'min_tensile_stress',
        unit2: 'min_tensile_stress',
        order: 1,
        table: 'Material Table'
    },

    //rebar settings (project settings)
    csamm2: {
        editor: "csamm2",
        required: false,
        unit1: "No",
        table: "Rebar_Settings",
    },
    mpum: {
        editor: "output",
        required: false,
        unit1: "No",
        table: "Rebar_Settings",
    },
    mbs: {
        editor: "output",
        required: false,
        unit1: "No",
        table: "Rebar_Settings",
    },
    ndmm: {
        editor: "output",
        required: false,
        unit1: "No",
        table: "Rebar_Settings",
    },


    disabled_fields: ['unit_system', 'concrete_design_code', 'concrete_stress_limit_factor', 'beta', 'concrete_strain_limit', 'rebar_strain_limit', 'tension_controlled', 'compression_controlled_spiral', 'compression_controlled_ties', 'shear_torsion', 'shear_seismic', 'utilization_factor_limit', 'modifier_beam', 'modifier_column', 'stiffness_reduction', 'phi_shear', 'phi_axial', 'phi_tension', 'phi_compression', 'consider_minimum_eccentricity'],
    enable_fields: {
        'unit_system': 'select',
        'concrete_design_code': 'input',
        'concrete_stress_limit_factor': 'input',
        'beta': 'input',
        'concrete_strain_limit': 'input',
        'rebar_strain_limit': 'input',
        'tension_controlled': 'input',
        'compression_controlled_spiral': 'input',
        'compression_controlled_ties': 'input',
        'shear_torsion': 'input',
        'shear_seismic': 'input',
        'utilization_factor_limit': 'input',
        'modifier_beam': 'input',
        'modifier_column': 'input',
        'stiffness_reduction': 'input',
        'phi_shear': 'input',
        'phi_axial': 'input',
        'phi_tension': 'input',
        'phi_compression': 'input',
        'consider_minimum_eccentricity': 'input',
    }
}

const loadDesign = {
    url: {
        editor: 'input',
        type: 'integer',
        required: false,
        unit1: 'url',
        unit2: 'url',
        order: 1,
        table: 'Load',
        actualName: 'you can ignore this. it is a hidden data field',
    },
    load_no: {
        editor: 'input',
        type: 'string',
        required: false,
        unit1: 'load_no',
        unit2: 'load_no',
        order: 2,
        table: 'Load',
        actualName: 'Load No'
    },
    n: {
        editor: 'input',
        type: 'integer',
        required: false,
        unit1: 'n',
        unit2: 'n',
        order: 3,
        table: 'Load',
        actualName: 'N'
    },
    t: {
        editor: 'input',
        type: 'integer',
        required: false,
        unit1: 't',
        unit2: 't',
        order: 4,
        table: 'Load',
        actualName: 'T'
    },
    vz: {
        editor: 'input',
        type: 'integer',
        required: false,
        unit1: 'vz',
        unit2: 'vz',
        order: 5,
        table: 'Load',
        actualName: 'Vz'
    },
    vy: {
        editor: 'input',
        type: 'integer',
        required: false,
        unit1: 'vy',
        unit2: 'vy',
        order: 6,
        table: 'Load',
        actualName: 'Vy'
    },
    my_top: {
        editor: 'input',
        type: 'integer',
        required: false,
        unit1: 'my_top',
        unit2: 'my_top',
        order: 7,
        table: 'Load',
        actualName: 'Top Moment'
    },
    mz_top: {
        editor: 'input',
        type: 'integer',
        required: false,
        unit1: 'mz_top',
        unit2: 'mz_top',
        order: 8,
        table: 'Load',
        actualName: 'Top Moment Mz KN-m'
    },
    my_bottom: {
        editor: 'input',
        type: 'integer',
        required: false,
        unit1: 'my_bottom',
        unit2: 'my_bottom',
        order: 9,
        table: 'Load',
        actualName: 'Bottom Moment My KN-m'
    },
    mz_bottom: {
        editor: 'input',
        type: 'integer',
        required: false,
        unit1: 'mz_bottom',
        unit2: 'mz_bottom',
        order: 10,
        table: 'Load',
        actualName: 'Bottom Moment Mz KN-m'
    },
    bdns: {
        editor: 'input',
        type: 'integer',
        required: false,
        unit1: 'bdns',
        unit2: 'bdns',
        order: 11,
        table: 'Load',
        actualName: 'Sustained load %'
    },
    bds: {
        editor: 'input',
        type: 'integer',
        required: false,
        unit1: 'bds',
        unit2: 'bds',
        order: 12,
        table: 'Load',
        actualName: 'Sustained load (Bds) %'
    },
    delta_y: {
        editor: 'output',
        type: 'integer',
        required: false,
        unit1: 'delta_y',
        unit2: 'delta_y',
        order: 13,
        table: 'Load',
        actualName: 'Axial Force and Biaxial Bending factors Y axis Ds'
    },
    cm_y: {
        editor: 'output',
        type: 'integer',
        required: false,
        unit1: 'cm_y',
        unit2: 'cm_y',
        order: 14,
        table: 'Load',
        actualName: 'Axial Force and Biaxial Bending factors Y axis Cm'
    },
    mom_y: {
        editor: 'output',
        type: 'integer',
        required: false,
        unit1: 'mom_y',
        unit2: 'mom_y',
        order: 15,
        table: 'Load',
        actualName: 'Axial Force and Biaxial Bending factors Y axis Mu'
    },
    delta_s_y: {
        editor: 'output',
        type: 'integer',
        required: false,
        unit1: 'delta_s_y',
        unit2: 'delta_s_y',
        order: 16,
        table: 'Load',
        actualName: 'Axial Force and Biaxial Bending factors Y axis Dns'
    },
    mc_top_y: {
        editor: 'output',
        type: 'integer',
        required: false,
        unit1: 'mc_top_y',
        unit2: 'mc_top_y',
        order: 17,
        table: 'Load',
        actualName: 'Axial Force and Biaxial Bending factors Y axis Mmin(top)'
    },
    mc_bottom_y: {
        editor: 'output',
        type: 'integer',
        required: false,
        unit1: 'mc_bottom_y',
        unit2: 'mc_bottom_y',
        order: 18,
        table: 'Load',
        actualName: 'Axial Force and Biaxial Bending factors Y axis Mmin(bottom)'
    },
    delta_z: {
        editor: 'output',
        type: 'integer',
        required: false,
        unit1: 'delta_z',
        unit2: 'delta_z',
        order: 19,
        table: 'Load',
        actualName: 'Axial Force and Biaxial Bending factors Z axis Ds'
    },
    cm_z: {
        editor: 'output',
        type: 'integer',
        required: false,
        unit1: 'cm_z',
        unit2: 'cm_z',
        order: 20,
        table: 'Load',
        actualName: 'Axial Force and Biaxial Bending factors Z axis Cm'
    },
    mom_z: {
        editor: 'output',
        type: 'integer',
        required: false,
        unit1: 'mom_z',
        unit2: 'mom_z',
        order: 21,
        table: 'Load',
        actualName: 'Axial Force and Biaxial Bending factors Z axis Mu'
    },
    delta_s_z: {
        editor: 'output',
        type: 'integer',
        required: false,
        unit1: 'delta_s_z',
        unit2: 'delta_s_z',
        order: 22,
        table: 'Load',
        actualName: 'Axial Force and Biaxial Bending factors Z axis Dns'
    },
    mc_top_z: {
        editor: 'output',
        type: 'integer',
        required: false,
        unit1: 'mc_top_z',
        unit2: 'mc_top_z',
        order: 23,
        table: 'Load',
        actualName: 'Axial Force and Biaxial Bending factors Z axis Mmin(top)'
    },
    mc_bottom_z: {
        editor: 'output',
        type: 'integer',
        required: false,
        unit1: 'mc_bottom_z',
        unit2: 'mc_bottom_z',
        order: 24,
        table: 'Load',
        actualName: 'Axial Force and Biaxial Bending factors Z axis Mmin(bottom)'
    },
    pmm: {
        editor: 'output',
        type: 'integer',
        required: false,
        unit1: 'pmm',
        unit2: 'pmm',
        order: 25,
        table: 'Load',
        actualName: 'P-M-M Ratio'
    },
    vy_phivny: {
        editor: 'output',
        type: 'integer',
        required: false,
        unit1: 'vy_phivny',
        unit2: 'vy_phivny',
        order: 26,
        table: 'Load',
        actualName: "Vz/ ØVn'z"
    },
    vz_phivnz: {
        editor: 'output',
        type: 'integer',
        required: false,
        unit1: 'vz_phivnz',
        unit2: 'vz_phivnz',
        order: 27,
        table: 'Load',
        actualName: "Vy/ ØVn'y"
    },
    t_tn: {
        editor: 'output',
        type: 'integer',
        required: false,
        unit1: 't_tn',
        unit2: 't_tn',
        order: 28,
        table: 'Load',
        actualName: 'T/ØTn'
    },
    tch: {
        editor: 'output',
        type: 'integer',
        required: false,
        unit1: 'tch',
        unit2: 'tch',
        order: 29,
        table: 'Load',
        actualName: 'Tch'
    },
    remark: {
        editor: 'output',
        type: 'string',
        required: false,
        unit1: 'remark',
        unit2: 'remark',
        order: 30,
        table: 'Load',
        actualName: 'Remark'
    },
    vy_util: {
        editor: 'input',
        type: 'integer',
        required: false,
        unit1: 'vy_util',
        unit2: 'vy_util',
        order: 31,
        table: 'Load',
        actualName: ''
    },
    vz_util: {
        editor: 'input',
        type: 'integer',
        required: false,
        unit1: 'vz_util',
        unit2: 'vz_util',
        order: 32,
        table: 'Load',
        actualName: ''
    },
    al_util: {
        editor: 'input',
        type: 'integer',
        required: false,
        unit1: 'al_util',
        unit2: 'al_util',
        order: 33,
        table: 'Load',
        actualName: ''
    },
    row: {
        editor: 'input',
        type: 'integer',
        required: false,
        unit1: 'row',
        unit2: 'row',
        order: 34,
        table: 'Load',
        actualName: ''
    }
}

const materials = {
    id: {
        editor: 'output',
        type: 'string',
        required: false,
        unit1: 'id',
        unit2: 'id',
        order: 1,
        table: 'Material Table'
    },
    name: {
        editor: 'input',
        type: 'string',
        required: false,
        unit1: 'Name',
        unit2: 'name',
        order: 1,
        table: 'Material Table'
    },
    material: {
        editor: 'select',
        type: 'string',
        required: false,
        unit1: 'Material',
        unit2: 'material',
        options: [
            {
                id: "Concrete",
                value: "Concrete",
            },
            {
                id: "Rebar",
                value: "Rebar",
            },
            {
                id: "Steel",
                value: "Steel",
            },
        ],
        labelname: "id",
        labelvalue: "value",
        order: 1,
        table: 'Material Table'
    },
    density: {
        editor: 'input',
        type: 'integer',
        required: false,
        unit1: 'Density',
        unit2: 'density',
        order: 1,
        table: 'Material Table'
    },
    modulus_of_elasticity: {
        editor: 'input',
        type: 'integer',
        required: false,
        unit1: 'Modulus of Elasticity(E) Mpa',
        unit2: 'modulus_of_elasticity',
        order: 1,
        table: 'Material Table'
    },
    poissions_ratio: {
        editor: 'input',
        type: 'integer',
        required: false,
        unit1: 'poissions_ratio',
        unit2: 'poissions_ratio',
        order: 1,
        table: 'Material Table'
    },
    coeff_of_thermal_expansion: {
        editor: 'input',
        type: 'integer',
        required: false,
        unit1: 'coeff_of_thermal_expansion',
        unit2: 'coeff_of_thermal_expansion',
        order: 1,
        table: 'Material Table'
    },
    shear_modulus: {
        editor: 'input',
        type: 'integer',
        required: false,
        unit1: 'shear_modulus',
        unit2: 'shear_modulus',
        order: 1,
        table: 'Material Table'
    },
    compressive_strength_cube_test: {
        editor: 'input',
        type: 'integer',
        required: false,
        unit1: 'compressive_strength_cube_test',
        unit2: 'compressive_strength_cube_test',
        order: 1,
        table: 'Material Table'
    },
    compressive_strength_cylinder_test: {
        editor: 'input',
        type: 'integer',
        required: false,
        unit1: 'compressive_strength_cylinder_test',
        unit2: 'compressive_strength_cylinder_test',
        order: 1,
        table: 'Material Table'
    },
    light_weight_lambda_factor: {
        editor: 'input',
        type: 'integer',
        required: false,
        unit1: 'light_weight_lambda_factor',
        unit2: 'light_weight_lambda_factor',
        order: 1,
        table: 'Material Table'
    },
    min_yield_stress: {
        editor: 'input',
        type: 'integer',
        required: false,
        unit1: 'min_yield_stress',
        unit2: 'min_yield_stress',
        order: 1,
        table: 'Material Table'
    },
    min_tensile_stress: {
        editor: 'input',
        type: 'integer',
        required: false,
        unit1: 'min_tensile_stress',
        unit2: 'min_tensile_stress',
        order: 1,
        table: 'Material Table'
    },
}

const punchingShearMaterials = {
    name: {
        editor: 'input',
        type: 'string',
        required: false,
        unit1: 'Name',
        unit2: 'name',
        order: 2,
        table: 'Material Properties'
    },
    material: {
        editor: 'output',
        type: 'string',
        required: false,
        unit1: 'Material',
        unit2: 'material',
        order: 3,
        table: 'Material Properties'
    },
    compressive_strength_cylinder_test: {
        editor: 'input',
        type: 'string',
        required: false,
        unit1: "fc",
        unit2: 'name',
        order: 4,
        table: 'Material Properties'
    },
    modulus_of_elasticity: {},
    density: {},
    min_yield_stress: {},
    light_weight_lambda_factor: {},

}

export {
    column,
    columnSection,
    columnMaterial,
    punchingShear,
    punchingShearSection,
    punchingShearMaterials,
    crackWidth,
    crackWidthSection,
    flexureShear,
    flexureShearSection,
    settings,
    loadDesign,
    materials
};
