import { values } from "lodash";
import { apiCallBegin } from "store/api";
import store from "store/store";

const { createSlice } = require("@reduxjs/toolkit");

const slice = createSlice({
  name: "endcondiition",
  initialState: {
    loading: false,
    endLists: {},
  },
  reducers: {
    endConditionRequested: (end) => {
      end.loading = true;
    },
    endConditionRequestFailed: (end) => {
      end.loading = false;
    },
    endConditionReceived: (end, action) => {
      end.endLists = action.payload;
      end.loading = false;
    },
    endDetailsUpdated:(end,action) => {
      end.endLists = action.payload
    }
  },
});

const {
  endConditionRequested,
  endConditionRequestFailed,
  endConditionReceived,
  endDetailsUpdated
} = slice.actions;

export default slice.reducer;

export const loadEndCondition = (url) => {
  store.dispatch(
    apiCallBegin({
      url,
      onSuccess: endConditionReceived.type,
      onStart:endConditionRequested.type,
      onError:endConditionRequestFailed.type
    })
  );
};

export const updateEndDetails = (value) => {
  store.dispatch(endDetailsUpdated({...value}))
}

export const uploadEndCondition = (url) => {
  const latestDetails = store.getState().entities.endCondition.endLists
  store.dispatch(apiCallBegin({
    url,
    jsonData:latestDetails,
    method:'put'
  }))
}