import React from "react";
import { useSelector } from "react-redux";

import DataTable from "components/DataTable";
import { getSettings } from "store/selector";
import { getDefaultTableValue, getProjectSettingRebar } from "utils/formatter";
import { settings } from "utils/dataFormat";

function RebarSettings({...props }) {
  const { projectDetails } = useSelector(getSettings);
  const { newValue, columns } = getProjectSettingRebar(projectDetails?.rebar_settings?.data)
  
  return (
    <DataTable
      showGridlines
      columnFontSize='17px'
      formatObj={settings}
      tableValue={newValue}
      columns={columns}
      header={'Rebar_Settings'}
      
      {...props}
    />
  );
}

export default RebarSettings;
