import { Box, Button, IconButton } from "@chakra-ui/react";
import React from "react";
import MainComponent from "../three/Main";
import { loadGraph } from "store/slices/entities/loadGraph";
import { useSelector } from "react-redux";
import { getLoadGraph } from "store/selector";
import { CloseIcon } from "@chakra-ui/icons";
import { useEffect } from "react";

function Graph({ link, onClose }) {
  const { data_points, loading } = useSelector(getLoadGraph);

  useEffect(() => {
    loadGraph(link);
  }, []);
  return (
    <Box
      w="1150px"
      h="650px"
      backgroundColor="gray.300"
      display="flex"
      justifyContent="center"
      alignItems="center"
      position="relative"
      borderRadius="1rem"
    >
      {!loading ? (
        <MainComponent
          wSize={{ width: 1100, height: 600 }}
          mapData={data_points}
          containerId={"graph"}
        />
      ) : (
        <h1>Loading Graph...</h1>
      )}
      <IconButton
        icon={<CloseIcon />}
        variant="outline"
        colorScheme="red"
        position="absolute"
        top="0"
        right="0"
        onClick={onClose}
      />
      {/* <Button position="absolute" top="0" right="0" onClick={onClose}>
        <CloseIcon>
      </Button> */}
    </Box>
  );
}

export default Graph;
