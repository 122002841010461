import axios from "axios";

const baseURL = "https://tedaps.com/";


const axiosInstance = axios.create({
    baseURL: baseURL,
    headers: { "Content-Type": "application/json", accept: "application/json" }
});

axiosInstance.interceptors.response.use(
    (response) => {
        return response;
    },
    async function (error) {
        const originalRequest = error.config;

        if (
            error.response.status === 401 &&
            error.response.statusText === "Unauthorized"
        ) {
            const refreshToken = localStorage.getItem("refresh_token");
            if (refreshToken) {
                const tokenParts = JSON.parse(atob(refreshToken.split(".")[1]));

                // exp date in token is expressed in seconds, while now() returns milliseconds:
                const now = Math.ceil(Date.now() / 1000);
                console.log(tokenParts.exp);

                if (tokenParts.exp > now) {
                    return axiosInstance
                        .post("api/auth/login/refresh/", { refresh: refreshToken })
                        .then(({ data: { access } }) => {
                            if (access) {
                                localStorage.setItem("access_token", access);

                                axiosInstance.defaults.headers["Authorization"] =
                                    "Bearer " + access;
                                originalRequest.headers["Authorization"] = "Bearer " + access;

                                return axiosInstance(originalRequest);
                            }
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                } else {
                    console.log(error);
                    localStorage.removeItem("access_token");
                    console.log("Refresh token is expired", tokenParts.exp, now);
                    window.location.href = "/";
                }
            } else {
                if (typeof window !== "undefined") {
                    localStorage.removeItem("access_token");
                    localStorage.removeItem("refresh_token");
                }
                console.log("Refresh token not available.");
                const error = "Something went wrong,Please try it again or later";
            }
        }

        // specific error handling done elsewhere
        return Promise.reject(error);
    }
);

export default axiosInstance;
