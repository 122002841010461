import React from "react";
import { Box, Text } from "@chakra-ui/react";

function EmptyPage() {
  return (
    <Box
      borderRadius={10}
      overflow={"hidden"}
      minH={"70vh"}
      bgColor={"white"}
      mt={"2rem"}
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
      marginInline={15}
    >
      <Text fontSize={"2rem"} fontWeight={"bold"} className="cursor-default">
        Select any project!..
      </Text>
    </Box>
  );
}

export default EmptyPage;