import React from "react";
import { Box, Text} from "@chakra-ui/react";
import styled from "styled-components";
import tw from "twin.macro";

const Container = tw.div`relative p-6 lg:p-0 `;
const Content = tw.div`max-w-3xl mx-auto `;

const FormContainer = styled.div`
  ${tw`p-10 sm:p-12 md:p-16 bg-white bg-opacity-100 text-other-title rounded-2xl shadow-xl relative`}
  form {
    ${tw`mt-4`}
  }
  h2 {
    ${tw`text-3xl sm:text-4xl font-bold`}
  }
  input,
  textarea {
    ${tw`w-full bg-transparent text-other-paragraph text-base font-medium tracking-wide border-b-2 py-2 border-b-other-title   focus:outline-none transition duration-200`};

    ::placeholder {
      ${tw`text-other-paragraph`}
    }
  }
`;

const TwoColumn = tw.div`flex flex-col sm:flex-row justify-between`;
const Column = tw.div`sm:w-5/12 flex flex-col`;
const InputContainer = tw.div`relative py-5 mt-6`;
const Label = tw.label`absolute top-0 left-0 tracking-wide font-semibold text-sm`;
const Input = tw.input``;
const TextArea = tw.textarea`h-24 sm:h-full resize-none`;
const SubmitButton = tw.button`w-full sm:w-32 mt-6 py-3 bg-other-buttonBG text-white rounded-full`

function Contact() {
 
  return (
    <Box
      flexDirection={{ sm: "column", lg: "row" }}
      justifyContent={{ sm: "start", md: "space-evenly" }}
      minH="100vh"
      pt={{ sm: "100px", md: "75px" }}
    >
     <Container>
      <Content>
        <FormContainer>
          <div tw="mx-auto max-w-4xl">
            {/* <h2>Contact Us</h2> */}
            <form action="#"  onSubmit={(e) => e.preventDefault()}>
              <TwoColumn>
                <Column>
                  <InputContainer>
                    <Label htmlFor="name-input">Your Name</Label>
                    <Input
                      id="name-input"
                      type="text"
                      name="name"
                      placeholder="E.g. John Doe"
                    />
                  </InputContainer>
                  <InputContainer>
                    <Label htmlFor="email-input">Your Email Address</Label>
                    <Input
                      id="email-input"
                      type="email"
                      name="email"
                      placeholder="E.g. john@mail.com"
                    />
                  </InputContainer>
                </Column>
                <Column>
                  <InputContainer tw="flex-1">
                    <Label htmlFor="name-input">Your Message</Label>
                    <TextArea
                      id="message-input"
                      name="message"
                      placeholder="E.g: Detail about your query"
                    />
                  </InputContainer>
                </Column>
              </TwoColumn>

              <SubmitButton type="submit" value="Submit">
                Submit
              </SubmitButton>
            </form>
          </div>
        </FormContainer>
      </Content>
    </Container>
    </Box>
  );
}

export default Contact;
