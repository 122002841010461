import { createSlice } from "@reduxjs/toolkit";
import routes from "api/routes";
import { apiCallBegin } from "store/api";

const slice = createSlice({
  name: "calculations",
  initialState: {
    list: [],
    loading: false,
  },
  reducers: {
    requested: (calculations) => {
      calculations.loading = true;
    },
    calculationReceived: (calculations, action) => {
      calculations.list = action.payload;
      calculations.loading = false
    },
    failed: (calculations) => {
      calculations.loading = false;
    },
  },
});

const { calculationReceived,requested,failed } = slice.actions;
export default slice.reducer;

//action creators

export const loadCalculations = () => {
  return apiCallBegin({
    url: routes.calculation.listOfCalculations,
    method: "get",
    onStart:requested.type,
    onSuccess: calculationReceived.type,
    onError:failed.type
  });
};
