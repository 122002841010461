import React, { useState } from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro";
import styled from "styled-components";

import HeaderBase, { NavLink, NavLinks, PrimaryLink } from "./Header";
import { SectionHeading } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import {
  Container,
  ContentWithVerticalPadding,
} from "components/misc/Layouts.js";

import Modal from "components/common/Modal";
import Login from "components/auth/Login";
import Register from "components/auth/Register";

const Header = tw(HeaderBase)`max-w-none`;
const Row = tw.div`flex flex-col lg:flex-row justify-between items-center py-6 lg:py-24 max-w-screen-2xl mx-auto sm:px-8 px-6`;
const Column = tw.div``;
const TextColumn = tw(
  Column
)`mr-auto lg:mr-0 max-w-lg lg:max-w-xl xl:max-w-2xl`;
const Heading = tw(
  SectionHeading
)`text-left text-other-title leading-snug font-bold text-2xl  xl:text-4xl`;
const Description = tw(
  SectionDescription
)`mt-4 lg:text-lg text-other-paragraph max-w-sm`;
const PrimaryButton = tw(
  PrimaryButtonBase
)`px-4 mt-8 inline-block w-40 tracking-wide text-center py-4 rounded-2xl shadow-2xl`;

const SocialMedia = tw.div`flex  writing-mode[vertical-rl] border-r-2  border-r-other-paragraph border-opacity-15 lg:px-2`;
const LinkItem = tw.a`mx-2 lg:mx-4 mb-4  pt-12  uppercase font-medium text-sm  text-gray-600 cursor-pointer `;
const CardContainer = tw.div`grid grid-cols-2 md:grid-cols-4 mt-16  lg:flex lg:flex-col xl:absolute lg:top-0 lg:right-0 lg:mx-2 xl:mx-16  lg:mt-4`;
const Card = styled(Column)`
  ${tw`py-0 lg:py-1  px-4 flex flex-col justify-center items-center`}
  div {
    ${tw` my-1 `}
  }
  img {
    ${tw`w-16 h-16 `}
  }

  p {
    ${tw`text-center font-semibold `}
  }
`;

function Hero({
  heading = "TEDAPS Engineering Calculation Modules",
  description = "To Collaborate, Build & Organize on the go",
  primaryButtonUrl = "#",
  primaryButtonText = "Get Started",
  buttonRounded = true,
}) {
  const buttonRoundedCss = buttonRounded && tw`rounded-full`;
  const [showModal, setShowModal] = useState(false);
  const [toggleForm,setToggleForm] = useState('login')

  const onModalClose = () => {
    setShowModal(false)
    setToggleForm('login')
  }

  const onScrollView = (areaID) => {
    const content = document.getElementById(areaID)
    content && content.scrollIntoView({behavior:'smooth'})
  }

  const cards = [
    { text: "Access Online", url: "image/01.png" },
    { text: "Cloud Computing", url: "image/03.png" },
    { text: "Collaborate and Share", url: "image/02.png" },
    { text: "Project Based", url: "image/04.png" },
  ];

  const navLinks = [
    <NavLinks key={1}>
      <NavLink href="/#">Home</NavLink>
      <NavLink onClick={() => onScrollView('about')}>About</NavLink>
      <NavLink onClick={() => onScrollView('resource')}>Resources</NavLink>
      <NavLink onClick={() => onScrollView('contactus')}>Contact Us</NavLink>
    </NavLinks>,
    <NavLinks key={2}>
      <PrimaryLink css={buttonRoundedCss} onClick={() => setShowModal(true)}>
        Login
      </PrimaryLink>
    </NavLinks>,
  ];
  const socialMedias = {
    linkedin: "https://www.linkedin.com/",
    twitter: "www.twitter.com",
    instagram: "www.instgram.com",
  };

  return (
    <div
      style={{
        background: "rgb(212,231,254)",
        background:
          "radial-gradient(circle, rgba(212,231,254,1) 0%, rgba(244,249,255,1) 74%, rgba(255,255,255,1) 100%)",
        // backgroundImage:'url('+Vector+')',
        // backgroundPosition: 'center',
        // backgroundSize: 'cover',
        // backgroundRepeat: 'no-repeat'
      }}
    >
      <Header links={navLinks} />
      <Container>
        <SocialMedia>
          {Object.keys(socialMedias).map((item) => (
            <LinkItem key={item}>{item}</LinkItem>
          ))}
        </SocialMedia>
        <ContentWithVerticalPadding>
          <Row>
            <TextColumn>
              <Heading>TEDAPS <br /> Engineering Calculation Modules</Heading>
              <Description>{description}</Description>
              <PrimaryButton
                as="a"
                href={primaryButtonUrl}
                css={buttonRoundedCss}
                onClick={() => {setShowModal(true); setToggleForm('register')}}
              >
                {primaryButtonText}
              </PrimaryButton>
            </TextColumn>
            <CardContainer>
              {cards.map((card, key) => (
                <Card key={key}>
                  <div>
                    <img src={`${card.url}`} width={50} height={50} />
                  </div>
                  <p>{card.text}</p>
                </Card>
              ))}
            </CardContainer>
          </Row>
        </ContentWithVerticalPadding>
      </Container>
      <Modal isOpen={showModal} modalHead="" onClose={onModalClose}>
        {toggleForm === 'login' ? <Login changeForm={setToggleForm}/> : <Register changeForm={setToggleForm} />}
      </Modal>
    </div>
  );
}

export default Hero;
