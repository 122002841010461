import {
    Box,
    Button,
    Stack,
    Grid,
    Flex,
    Select,
    useDisclosure,
    Text,
    Icon,
    Link,
} from "@chakra-ui/react";
import {
    BiExport,
    BiRefresh,
    BiReset,
    BiSearch,
    BiPlus,
    BiTrash,
    BiCopy,
    BiDownload,
    BiLineChart,
} from "react-icons/bi";
import { Link as ReactRouterLink } from 'react-router-dom'

import { FaFile, FaRegFile, FaRegFolder } from "react-icons/fa";
import { FaRegFolderOpen } from "react-icons/fa";

import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getCanvasDetails, getProjects } from "store/selector";

import {
    AddIcon,
    ChevronRightIcon,
    ChevronLeftIcon,
    DeleteIcon,
    ArrowBackIcon,
} from "@chakra-ui/icons";

import ProjectTable from "components/project/ProjectTable";
import {
    addFileDetailsRow,
    changeFile,
    changeProject,
    loadFileDetails,
    removeFileRow,
    resetCanvas,
} from "store/slices/entities/projectCanvas";
import { colors } from "components/misc/colors";
import { Toast } from "utils/Toast";
import Modal from "components/common/Modal";

import { ThemeProvider, createTheme } from "@mui/material/styles";
import { TreeView } from "@mui/x-tree-view/TreeView";
import { TreeItem } from "@mui/x-tree-view/TreeItem";
import { IoArrowBackCircleOutline } from "react-icons/io5";
import { AiFillSetting } from "react-icons/ai";
import { TbPlus } from "react-icons/tb";
import CalculationModel from "components/dashboard/calculation/tests/model";
import SettingsContainer from "components/settings";
import { loadCalculations } from "store/slices/entities/calculations";

import store from "store/store";
import { loadProjects } from "store/slices/entities/projects";

const theme = createTheme({
    components: {
        MuiTree: {
            styleOverrides: `
      div {
        color: red;
      }
    `,
        },
    },
});
function Project() {
    const dispatch = useDispatch();

    const { selectedProject, selectedFile, fileDetails } =
        useSelector(getCanvasDetails);

    const [updatedSelectedProject, setUpdatedSelectedProject] = useState(selectedProject);

    const [selectedRows, setSelectedRows] = useState([]);
    const [expandedRow, setExpandedRow] = useState(null);
    // const [selectedFileView, setSelectedFileView] = useState("");

    const onChangeFile = (id) => {
        const filteredFile = updatedSelectedProject.userfile_set?.filter(
            (file) => file.id === id
        );
        changeFile(filteredFile?.length > 0 && filteredFile[0]);
    };

    // useEffect(() => {
    //     resetCanvas();
    //     store.dispatch(loadProjects());
    // }, []);

    const onAddRow = () => {
        const { link } = selectedFile;
        addFileDetailsRow(link);
    };

    const onDelete = () => {
        if (selectedRows?.length === 0)
            return Toast("No rows has selected!", "warning");
        const deleteRowIds = selectedRows?.map((row) => {
            const value = row.url.split("/");
            return value[value.length - 2];
        });
        removeFileRow(deleteRowIds);
        onClose(true);
        setSelectedRows();
    };

    const { isOpen, onOpen, onClose } = useDisclosure();
    const calculationModel = useDisclosure();
    const settingsModel = useDisclosure();
    const [sidebarExpanded, setSidebarExpanded] = useState(true);

    const toggleSidebar = () => {
        setSidebarExpanded(!sidebarExpanded);
    };

    const projects = useSelector(getProjects);


    const handleCalculationSubmit = () => {
        dispatch(loadCalculations());
        dispatch(loadProjects());
    };

    useEffect(() => {
        if (projects && selectedProject) {
            const updatedProject = projects.list.find(proj => proj.id === selectedProject.id);
            if (updatedProject) {
                setUpdatedSelectedProject({
                    ...selectedProject,
                    userfile_set: updatedProject.userfile_set
                });
            }
        }
    }, [projects, selectedProject]);



    const sidebarWidth = sidebarExpanded ? "14%" : "1%";
    const leftPadding = sidebarExpanded ? "0.5rem" : "1.5rem";

    const buttons = [
        // {
        //   text: "",
        //   leftIcon: <BiCamera />,
        //   onClick: () => {},
        //   color: colors.camera,
        // },
        {
            text: "Add Row",
            leftIcon: <BiPlus />,
            onClick: onAddRow,
            // color: colors.add,
        },
        {
            text: "Refresh",
            leftIcon: <BiRefresh />,
            onClick: () => loadFileDetails(selectedFile.link),
            // color: colors.refresh,
        },
        {
            text: "Duplicate",
            leftIcon: <BiCopy />,
            onClick: () => { },
            disabled: true,
        },
        {
            text: "Upload",
            leftIcon: <BiExport />,
            onClick: () => { },
            disabled: true,
            // color: colors.export,
        },
        {
            text: "Download",
            leftIcon: <BiDownload />,
            onClick: () => { },
            disabled: true,
        },

        {
            text: "Delete",
            leftIcon: <BiTrash />,
            onClick: onOpen,
            disabled: true,
            color: colors.delete,
        },
        {
            text: "Reset All",
            leftIcon: <BiReset />,
            onClick: () => { },
            disabled: true,
            // color: colors.reset,
        },
        {
            text: "Search",
            leftIcon: <BiSearch />,
            onClick: () => { },
            disabled: true,
            // color: colors.search,
        },
    ];

    const projectProps = {
        selectedRows,
        setSelectedRows,
        expandedRow,
        setExpandedRow,
        sidebarExpanded,
    };

    return (
        <Flex>
            <Box
                id="project-sidebar"
                h={"inherit"}
                minH={"inherit"}
                w={sidebarWidth}
                bg={"gray.100"}
                transition="width 0.25s"
                // border={"1px solid gray"}
                boxShadow={"md"}
                rounded={"8px"}
                position="relative"
            // float={"left"}
            // display={"flex"}
            // justifyContent={"space-between"}
            >
                <Box
                    w={"11.8%"}
                    position={"fixed"}
                    display={"flex"}
                    justifyContent={"space-between"}
                // border={"1px solid gray"}
                >
                    <Box
                        flexDir={"column"}
                        alignItems={"center"}
                        w={"100%"}
                        p={"4px"}
                        gap={"4px"}
                        display={sidebarExpanded ? "flex" : "none"}
                        overflowY="auto"
                        maxH="calc(100vh - 6rem)" //max scroll height
                    >
                        <Box
                            display={"flex"}
                            flexDir={"column"}
                            justifyContent={"space-between"}
                            w={"100%"}
                            mb={"1rem"}
                            p={"0.5rem"}
                            gap={2}
                        >
                            <span style={{ fontSize: "1.25rem", fontWeight: "bold" }}>{updatedSelectedProject.name}</span>
                            <Link onClick={calculationModel.onOpen} style={{ padding: "2px", paddingLeft: "4px", fontSize: "14px", color: "white", background: "#21bc5a", display: "flex", alignItems: "center", border: "1px solid #21bc5a", borderRadius: "6px" }}>
                                <TbPlus />
                                <span style={{ marginLeft: "6px" }}>
                                    Add calculation
                                </span>
                            </Link>
                            <Link onClick={settingsModel.onOpen} style={{ marginTop: "4px", padding: "2px", paddingLeft: "4px", fontSize: "14px", background: "#217cf5", color: "white", display: "flex", alignItems: "center", border: "1px solid #217cf5", borderRadius: "6px" }}>
                                <AiFillSetting />
                                <span style={{ marginLeft: "6px" }}>
                                    Project settings
                                </span>
                            </Link>
                        </Box>
                        <Box w={"100%"}>
                            <Box
                                p={"0.5rem"}
                                borderBottom={"1px solid #EBEBEB"}
                                fontWeight={600}
                            >
                                {" "}
                                Files:
                            </Box>
                            <ThemeProvider theme={theme}>
                                <Box
                                    css={{
                                        "#item-root .MuiTreeItem-label": {
                                            marginTop: "0.25rem",
                                            fontSize: "0.75rem ",
                                            fontWeight: 500,
                                            letterSpacing: "0.4px",
                                        },
                                    }}
                                >
                                    <TreeView
                                        id="item-root"
                                        aria-label="file system navigator"
                                        defaultCollapseIcon={<FaRegFolderOpen />}
                                        defaultExpandIcon={<FaRegFolder />}
                                    >
                                        <TreeComponent
                                            projects={updatedSelectedProject}
                                            onChangeFile={onChangeFile}
                                        />
                                        {/* <TreeItem
                                            nodeId="RC Column design to ACI-318-14"
                                            label="RC Column design to ACI-318-14"
                                            sx={{
                                            ".Mui-expanded": {
                                                color: "#217BF4",
                                            },
                                            }}
                                        >
                                            {selectedProject?.userfile_set?.length > 0 &&
                                            selectedProject?.userfile_set.map((file, i) => (
                                                <TreeItem
                                                key={i}
                                                sx={{
                                                    ".Mui-selected": {
                                                    color: "#217BF4",
                                                    },
                                                }}
                                                className="item"
                                                icon={<FaRegFile size={12} />}
                                                nodeId={`2 + ${i}`}
                                                label={file.name}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    onChangeFile(file.id);
                                                }}
                                                />
                                            ))}
                                        </TreeItem> */}
                                    </TreeView>
                                </Box>
                            </ThemeProvider>
                        </Box>
                    </Box>
                    <Box
                        minH={"100vh"}
                        position={"sticky"}
                        w={"16px"}
                        bg={"white"}
                        display={"flex"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        borderRightRadius={"8px"}
                        _hover={{ bg: "#F0F0F0" }}
                        onClick={(e) => {
                            e.stopPropagation();
                            toggleSidebar();
                        }}
                    >
                        <ChevronRightIcon
                            transition="transform 0.25s"
                            style={{
                                transform: sidebarExpanded ? `rotate(180deg)` : `rotate(0deg)`,
                            }}
                        />
                    </Box>
                </Box>
            </Box>
            <Box
                boxSizing="border-box"
                // border={"1px solid gray"}
                flexDirection={{ sm: "column", lg: "row" }}
                justifyContent={"space-center"}
                alignItems={"center"}
                minH="100vh"
                h={"100%"}
                w={"100%"}
                // height={"100vh"}
                pl={{ lg: sidebarExpanded ? "0.5rem" : "1.5rem" }}
                pr={{ lg: "0.5rem" }}
                overflow="hidden"
                // maxW={"1444px"}
                mx="auto"
            >
                <Box
                    display="flex"
                    boxShadow={"sm"}
                    border={"0.8px solid #ddd"}
                    flexDirection={{ sm: "column", xl: "row" }}
                    justifyContent={"space-between"}
                    alignItems="center"
                    borderRadius={8}
                    minH={"2vh"}
                    bgColor={"white"}
                    p={"0.5rem"}
                    pl={"1rem"}
                    mt={"1.5rem"}
                    overflowX="auto"
                >
                    <Stack
                        justifyContent={{ sm: "center", xl: "space-between" }}
                        align={"center"}
                        direction={{ sm: "column", md: "row" }}
                        spacing={4}
                    >
                        {/* <Button
                            size="sm"
                            onClick={(e) => {
                                e.stopPropagation();
                                changeProject("");
                            }}
                        >
                            <IoArrowBackCircleOutline size={32} />
                        </Button> */}
                        {updatedSelectedProject && (
                            <Text fontWeight={500} display={"flex"}>
                                {/* {selectedProject.name}{" "} */}
                                {/* {selectedFile && (
                                    <Text marginInline="0.5rem" fontWeight={400} color="#afafaf">
                                        /
                                    </Text>
                                )} */}
                                {selectedFile?.description}{" "}
                                {selectedFile && (
                                    <Text marginInline="0.5rem" fontWeight={400} color="#afafaf">
                                        /
                                    </Text>
                                )}
                                {selectedFile.name}
                            </Text>
                        )}
                    </Stack>
                    {selectedFile && (
                        <Grid
                            gap={0}
                            display={"flex"}
                            // justifyContent={{ sm: "center" }}
                            flexWrap={{ sm: "wrap", lg: "nowrap" }}
                            mt={{ sm: "1.5rem", xl: "0" }}
                            css={{
                                "& button:last-of-type": {
                                    borderTopRightRadius: "8px",
                                    borderBottomRightRadius: "8px",
                                },
                                "& button:first-of-type": {
                                    borderTopLeftRadius: "8px",
                                    borderBottomLeftRadius: "8px",
                                },
                            }}
                        >
                            {buttons?.map((btn, key) => (
                                <Button
                                    {...btn}
                                    key={key}
                                    outline={"1px solid #dcdddd"}
                                    fontWeight={400}
                                    rounded={"0"}
                                    overflow={"hidden"}
                                    iconSpacing={btn?.text === "" ? 0 : "4px"}
                                    size={"sm"}
                                    fontSize={{ sm: "14px", lg: "1rem" }}
                                    css={{
                                        width: "fit-content",
                                        "& p": {
                                            width: 0,
                                            opacity: 0,
                                            transition:
                                                "width 0.3s cubic-bezier(0.455, 0.030, 0.515, 0.955), opacity 0.3s cubic-bezier(0.455, 0.030, 0.515, 0.955)",
                                            // "transition-delay": "1s",
                                        },
                                        "&:hover": {
                                            "& p": {
                                                width: "70px",
                                                opacity: 1,
                                                // "transition-delay": "0s",
                                            },
                                        },
                                    }}
                                >
                                    <Text>{btn.text}</Text>
                                </Button>
                            ))}
                        </Grid>
                    )}
                </Box>

                {selectedFile && <ProjectTable {...projectProps} />}
                <Text padding={2} color={"GrayText"} fontSize={12}>Beta Version 1.3.7</Text>
            </Box>
            <Modal
                isOpen={isOpen}
                modalHead="Confirm"
                children={<Text>Are you sure you want to delete these rows ?</Text>}
                onClose={onClose}
                onProcced={onDelete}
                confirmText="Delete"
            />
            <Modal
                isOpen={calculationModel.isOpen}
                modalHead="Add Calculation"
                onClose={calculationModel.onClose}
                children={<CalculationModel project={updatedSelectedProject} onSubmit={handleCalculationSubmit} />}
            >
            </Modal>
            <Modal
                isOpen={settingsModel.isOpen}
                modalHead="Settings"
                onClose={settingsModel.onClose}
                maxWidth={"60vw"}
                children={<SettingsContainer project={updatedSelectedProject} />}
            >
            </Modal>
        </Flex>
    );
}

const TreeComponent = ({ projects, onChangeFile }) => {
    const descriptions = Array.from(
        new Set(projects.userfile_set.map((item) => item.description))
    );

    return (
        <div>
            {descriptions.map((description, index) => (
                <TreeItemContainer
                    key={index}
                    description={description}
                    projects={projects}
                    onChangeFile={onChangeFile}
                />
            ))}
        </div>
    );
};

const TreeItemContainer = ({ description, projects, onChangeFile }) => {
    const items = projects.userfile_set.filter(
        (item) => item.description === description
    );

    return (
        <div>
            <TreeItem
                nodeId={description}
                label={description}
                sx={{
                    ".Mui-expanded": {
                        color: "#217BF4",
                    },
                }}
            >
                {items.map((item, index) => (
                    <TreeItem
                        key={`${item.description}-${index}`}
                        nodeId={`${item.description}-${index}`}
                        label={item.name}
                        sx={{
                            ".Mui-selected": {
                                color: "#217BF4",
                            },
                        }}
                        className="item"
                        icon={<FaRegFile size={12} />}
                        onClick={(e) => {
                            e.stopPropagation();
                            onChangeFile(item.id);
                        }}
                    />
                ))}
            </TreeItem>
        </div>
    );
};

export default Project;
