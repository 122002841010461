import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid
import "./style.module.css";

import { useRef, useState, useCallback, useLayoutEffect } from "react";
import { useSelector } from "react-redux";
import { getRebars } from "store/selector";
import { useEffect } from "react";
import _ from 'lodash';
import {
    addNewRebarRow,
    deleteRebarRow,
    loadRebars,
    reorderRebar,
    submitRebarDetails,
    updateRebarRows,
} from "store/slices/entities/rebar";
import { useMemo } from "react";
import { Button, Grid, Text, toast } from "@chakra-ui/react";

import {
    BiExport,
    BiRefresh,
    BiReset,
    BiSearch,
    BiPlus,
    BiTrash,
    BiDownload,
} from "react-icons/bi";
import { FiSend } from "react-icons/fi";

import { colors } from "components/misc/colors";



const RebarTable = ({ link }) => {

    const gridRef = useRef(null);
    const { rebarLists } = useSelector(getRebars);
    const [selectedRows, setSelectedRows] = useState([]);
    const [rowData, setRowData] = useState([]);

    const [colDefs, setColDefs] = useState([
        {
            colId: 'checkbox',
            field: "",
            maxWidth: 80,
            checkboxSelection: true,
            suppressHeaderMenuButton: true,
            headerCheckboxSelection: true,
            rowDrag: true
        },
        { headerName: "No", field: "no", maxWidth: 70 },
        {
            headerName: "Coordinates",
            marryChildren: true,
            children: [
                { headerName: "X(mm)", field: "x", maxWidth: 90, editable: true, cellClass: ["cell-editable"] },
                { headerName: "Y(mm)", field: "y", maxWidth: 90, editable: true, cellClass: ["cell-editable"] },
            ]
        },

        { headerName: "Diameter", field: "dia", maxWidth: 90, editable: true, cellClass: ["cell-editable"] },
        { headerName: "Distance to adjacent Rebar (mm)", field: "nearest" }
    ]);

    const onAddRow = () => {
        const noLIsts = rebarLists?.rebar_rows.map((item) => item.no);
        const largestNo = Math.max.apply(null, noLIsts);

        const idLIsts = rebarLists?.rebar_rows.map((item) => item.id);
        const largestId = Math.max.apply(null, idLIsts);

        const newRow = {
            no: largestNo != "-Infinity" ? largestNo + 1 : 1,
            id: largestId != "-Infinity" ? largestId + 1 : 1,
            x: 0,
            y: 0,
            dia: 0,
            row: 0,
        };

        addNewRebarRow(newRow);
    };

    useEffect(() => {
        loadRebars(link)
    }, [])

    useEffect(() => {
        if (rebarLists?.rebar_rows) {
            setRowData(_.cloneDeep(rebarLists?.rebar_rows))
        }
    }, [rebarLists?.rebar_rows]);

    const onChange = useCallback((event) => {
        const { id } = event.data;
        const field = event.colDef.field;
        const newValue = event.newValue;

        const rowNode = gridRef.current.api.getRowNode(id);

        if (rowNode) {
            rowNode.setDataValue(field, newValue);

            setRowData(prevRowData => {
                const updatedRowData = prevRowData.map(row =>
                    row.id === id ? { ...row, [field]: newValue } : row
                );
                return updatedRowData;
            });
        }
    }, []);


    const onDelete = () => {
        console.log("de")
        if (selectedRows.length == 0)
            return toast("There is no items selected!", "warning");
        const rowIds = selectedRows?.map((row) => row.id);

        deleteRebarRow(rowIds, rebarLists?.rebar_rows[0].row);
        setSelectedRows([]);
    };

    const onSubmit = () => {
        submitRebarDetails(rowData, rebarLists, rebarLists?.url)
        loadRebars(link)
    }

    const defaultColDef = useMemo(() => {
        return {
            initialWidth: 200,
            wrapHeaderText: true,
            autoHeaderHeight: true,
            suppressCellFocus: false,
            singleClickEdit: true,
            enableCellTextSelection: false,
            suppressRowClickSelection: true,
            suppressCellSelection: false,
            stopEditingWhenCellsLoseFocus: true
        };
    }, []);


    const buttons = [
        {
            text: "New",
            leftIcon: <BiPlus />,
            onClick: onAddRow,
            // color: colors.add,
        },
        {
            text: "Submit",
            leftIcon: <FiSend />,
            onClick: onSubmit,
        },
        {
            text: "Delete",
            leftIcon: <BiTrash />,
            onClick: onDelete,
            color: colors.delete,
        },
    ];

    const onGridReady = useCallback((params) => {
        gridRef.current = params.api;
    }, []);

    const gridOptions = {
        resizable: false,
        suppressMovable: false,
    }

    return (
        <div>
            <Grid
                gap={0}
                display={"flex"}
                justifyContent={{ sm: "space-between" }}
                alignItems={{
                    sm: "center",
                    lg: "center"
                }}
                marginBottom={{
                    sm: "1rem"
                }}
                css={{
                    "& button:last-of-type": {
                        borderTopRightRadius: "8px",
                        borderBottomRightRadius: "8px",
                    },
                    "& button:first-of-type": {
                        borderTopLeftRadius: "8px",
                        borderBottomLeftRadius: "8px",
                    },
                }}
            >
                <h3 style={{ fontSize: '16px', fontWeight: 'bold', color: '#010101' }}>
                    Rebar Layout
                </h3>
                <div>
                    {buttons?.map((btn, key) => (
                        <Button
                            {...btn}
                            key={key}
                            outline={"1px solid #dcdddd"}
                            fontWeight={400}
                            rounded={"0"}
                            overflow={"hidden"}
                            iconSpacing={btn?.text === "" ? 0 : 0}
                            size={"sm"}
                            fontSize={{ sm: "14px", lg: "1rem" }}
                            css={{
                                width: "fit-content",
                                "& p": {
                                    width: 0,
                                    opacity: 0,
                                    transition:
                                        "width 0.3s cubic-bezier(0.455, 0.030, 0.515, 0.955), opacity 0.3s cubic-bezier(0.455, 0.030, 0.515, 0.955)",
                                    // "transition-delay": "1s",
                                },
                                "&:hover": {
                                    "& p": {
                                        width: "70px",
                                        opacity: 1,
                                        // "transition-delay": "0s",
                                    },
                                },
                            }}
                        >
                            <Text>{btn.text}</Text>
                        </Button>
                    ))}
                </div>
            </Grid>
            <div className="ag-theme-quartz" style={{ height: 300, width: '100%' }}>
                <AgGridReact
                    ref={gridRef}
                    columnDefs={colDefs}
                    rowData={rowData}
                    domLayout="normal"
                    rowDragManaged={true}
                    suppressRowClickSelection={true}
                    onGridReady={onGridReady}
                    onCellValueChanged={onChange}
                    onDelete={onDelete}
                    onSubmit={onSubmit}
                    singleClickEdit={true}
                    setSelectedRows={setSelectedRows}
                    suppressRowHoverHighlight={false}
                    suppressCellFocus={false}
                    enterNavigatesVerticallyAfterEdit
                    stopEditingWhenCellsLoseFocus
                />
            </div>
        </div>
    )
}

export default RebarTable;