import React from "react";
import { motion } from "framer-motion";
import tw from "twin.macro";
import { css } from "styled-components/macro";

import styled from "styled-components";
import NavToggler from "components/helpers/NavToggler.js";

import logo from "../../images/logo_12.png";
import { ReactComponent as MenuIcon } from "feather-icons/dist/icons/menu.svg";
import { ReactComponent as CloseIcon } from "feather-icons/dist/icons/x.svg";

const Header = tw.header`
  flex justify-evenly items-center
  max-w-screen-xl mx-auto
  border-b-2 border-b-other-paragraph
  border-opacity-15
  
`;

export const NavLinks = tw.div`inline-block`;
export const NavLink = tw.a`
  text-xl my-2  lg:mx-6 lg:my-0 cursor-pointer
  font-semibold tracking-wide transition duration-300
  pb-1 border-b-2 border-transparent hover:border-other-subtitle hocus:text-other-subtitle
`;

export const PrimaryLink = tw(NavLink)`
  lg:mx-0
  px-8 py-3 rounded bg-other-buttonBG text-gray-100 cursor-pointer
   hocus:text-gray-200 focus:shadow-outline
  border-b-0
`;

export const LogoLink = styled(NavLink)`
  ${tw`flex items-center font-black border-b-0 text-3xl! ml-0!`};

  img {
    ${tw`w-16 md:w-20 xl:w-24`}
  }
`;

export const MobileNavLinksContainer = tw.nav`flex flex-1 items-center justify-between p-4`;
export const NavToggle = tw.button`
  lg:hidden z-20 focus:outline-none hocus:text-other-buttonBG transition duration-300
`;
export const MobileNavLinks = motion(styled.div`
  ${tw`lg:hidden z-10 fixed top-0 inset-x-0 mx-4 my-6 p-8 border text-center rounded-lg text-gray-900 bg-white`}
  ${NavLinks} {
    ${tw`flex flex-col items-center`}
  }
`);

export const DesktopNavLinks = tw.nav`
  hidden lg:flex flex-1 justify-between items-center px-8 py-0
`;

export default ({ logoLink, links, className, collapseBreakpointClass = "lg" }) => {
  

  const { showNavLinks, animation, toggleNavbar } = NavToggler();
  const collapseBreakpointCss = collapseBreakPointCssMap[collapseBreakpointClass];

  const defaultLogoLink = (
    <LogoLink href="/">
      <img src={logo} width={100} height={100} />
    </LogoLink>
  );

  logoLink = logoLink || defaultLogoLink;


  return (
    <Header className={className || "header-light"}>
      <DesktopNavLinks css={collapseBreakpointCss.desktopNavLinks}>
        {logoLink}
        {links}
      </DesktopNavLinks>

      <MobileNavLinksContainer  css={collapseBreakpointCss.mobileNavLinksContainer}>
        {logoLink}
        <MobileNavLinks initial={{ x: "150%", display: "none" }} animate={animation} css={collapseBreakpointCss.mobileNavLinks}>
          {links}
        </MobileNavLinks>
        <NavToggle onClick={toggleNavbar} className={showNavLinks ? "open" : "closed"}>
          {showNavLinks ? <CloseIcon tw="w-6 h-6 -mx-10 fixed" /> : <MenuIcon tw="w-6 h-6" />}
        </NavToggle>
      </MobileNavLinksContainer>
    </Header>
  );
};



const collapseBreakPointCssMap = {
  sm: {
    mobileNavLinks: tw`sm:hidden`,
    desktopNavLinks: tw`sm:flex`,
    mobileNavLinksContainer: tw`sm:hidden`
  },
  md: {
    mobileNavLinks: tw`md:hidden`,
    desktopNavLinks: tw`md:flex`,
    mobileNavLinksContainer: tw`md:hidden`
  },
  lg: {
    mobileNavLinks: tw`lg:hidden`,
    desktopNavLinks: tw`lg:flex`,
    mobileNavLinksContainer: tw`lg:hidden`
  },
  xl: {
    mobileNavLinks: tw`lg:hidden`,
    desktopNavLinks: tw`lg:flex`,
    mobileNavLinksContainer: tw`lg:hidden`
  }
};
