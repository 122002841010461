const colors = {
    title: "#0A093D",
    subtitle: "#217BF4",
    paragraph: "#656464",
    buttonBG: "#217BF4",
    contactusBg: "#F1F6FD",
    footerBg: "#20202D",
    add: 'teal',
    delete: 'red',
    refresh: 'blue',
    submit: 'green',
    lock: 'red',
    reset: 'brown',
    camera: 'blue',
    search: 'teal.900',
    export: 'yellow.600',
    download: 'whatsapp',
    unlock: 'teal.600',
    gray: 'gray',
    other: 'black'
};

export { colors };
