import routes from "api/routes";
import { apiCallBegin, apiCallFailed } from "store/api";
import store from "store/store";

const { createSlice } = require("@reduxjs/toolkit");

const slice = createSlice({
    name: "loadDesign",
    initialState: { info: {}, loading: false },
    reducers: {
        loadDesignRequested: (load) => {
            load.loading = true;
        },
        loadDesignRequestFailed: (load) => {
            load.loading = false;
        },

        loadDesignReceived: (load, action) => {
            let noCount = 0;
            const loadRows = action.payload?.load_rows?.map((item) => {
                noCount++;
                const obj = { ...item };
                obj["No"] = noCount;
                return obj;
            });
            const loadDetails = action.payload;
            loadDetails["load_rows"] = loadRows;

            load.info = loadDetails;
            load.loading = false;
        },

        loadDesignUpdated: (load, action) => {
            load.info.load_rows = action.payload.details;
        },

        loadDesignCreated: (load, action) => {
            const noLists = load.info?.load_rows.map((item) => item.No);
            const lastNo = Math.max.apply(null, noLists);
            const newRow = { ...action.payload };
            newRow["No"] = lastNo != "-Infinity" ? lastNo + 1 : 1;

            const latestList = load.info.load_rows;
            latestList.push(newRow);

            load.info.load_rows = latestList;
            load.loading = false
        },
        loadDesignRowRemoved: (load, action) => {
            const deletedRows = Object.keys(action.payload).map((id) => `https://tedaps.com/api/column-load-row/${id}/`)
            const newRows = load?.info?.load_rows?.filter(row => !deletedRows.includes(row.url))
            load.info.load_rows = newRows;


        },
        loadDesignBuldAdd: (load, action) => {
            console.log(load, action)
        },
        loadDesignSaved: (load, action) => {
            load.loading = false;
            const updateLists = load.info.load_rows?.map(previous => {
                const updatedValue = action.payload.load_rows?.filter(update => previous.url === update.url)
                const customObj = {}
                if (updatedValue?.length > 0) {
                    Object.keys(previous).map(item => {
                        customObj[item] = updatedValue[0][item] || previous[item]
                    })
                }
                return updatedValue?.length > 0 ? customObj : previous
            })
            load.info.load_rows = updateLists
        }
    },
});

const {
    loadDesignRequestFailed,
    loadDesignReceived,
    loadDesignRequested,
    loadDesignUpdated,
    loadDesignCreated,
    loadDesignRowRemoved,
    loadDesignBuldAdd,
    loadDesignSaved
} = slice.actions;
export default slice.reducer;

export const loadLoadDesign = (url) => {
    return store.dispatch(
        apiCallBegin({
            url,
            onSuccess: loadDesignReceived.type,
            onStart: loadDesignRequested.type,
            onError: loadDesignRequestFailed.type,
        })
    );
};

export const updateLoadDesign = (details) =>
    store.dispatch(loadDesignUpdated({ details }));

export const createRowLoadDesign = (url, calc) => {
    store.dispatch(
        apiCallBegin({
            url: calc,
            jsonData: { row: url },
            method: "post",
            onSuccess: loadDesignCreated.type,
            onStart: loadDesignRequested.type,
            onError: loadDesignRequestFailed.type,
        })
    );
};

export const removeRowLoadDesign = (data, url) => {
    store.dispatch(
        apiCallBegin({
            url: url,
            method: "delete",
            deleteData: data,
            onSuccess: loadDesignRowRemoved.type
        })
    );
};

export const saveBulkLoadDesign = (data, url) => {
    store.dispatch(
        apiCallBegin({
            url: `${url}add_bulk_load/`,
            method: "put",
            jsonData: {
                load_rows: data
            },
            onSuccess: loadDesignBuldAdd.type
        })
    )
}

export const saveLoadDesign = (updatedData, url, loadLink) => {
    store.dispatch(
        apiCallBegin({
            url,
            method: "put",
            jsonData: {
                url: url,
                load_rows: updatedData,
                row: loadLink
            },
            onSuccess: loadDesignSaved.type,
            onStart: loadDesignRequested.type,
            onError: loadDesignRequestFailed.type
        })
    );
};
