import {
    Button,
    Flex,
    Text,
    useColorModeValue,
    Tabs,
    TabList,
    TabPanels,
    Tab,
    TabPanel,
    Icon,
    Input,
    Box,
    Textarea,
    useDisclosure,
} from "@chakra-ui/react";
import React, { useState } from "react";

import { colors } from "components/misc/colors";
import Scrollbars from "react-custom-scrollbars-2";
import { RiDeleteBin6Line } from "react-icons/ri";
import { projectUpdate, removeProject } from "store/slices/entities/projects";
import store from "store/store";
import ManageFiles from "./ManageFiles";
import ManageUsers from "./ManageUsers";
import Modal from "components/common/Modal";
import { MdEdit } from "react-icons/md";

function ProjectSettings(props) {
    const { title, userFiles, members, projectID, onCloseProject } = props;
    const { isOpen, onOpen, onClose } = useDisclosure();
    const initial = {
        name: title,
        id: projectID,
        created_date: "25-01-2022",
        comment: "",
    };
    const [projectDetails, setProjectDetails] = useState(initial);
    const [projectNameEdit, setProjectNameEdit] = useState(false);

    const textColor = useColorModeValue("secondaryGray.900", "white");
    const shadow = useColorModeValue(
        "0px 18px 40px rgba(112, 144, 176, 0.12)",
        "transparent"
    );
    const deleteIcon = useColorModeValue("red.400");

    const onChange = ({ target: { value, name } }) => {
        const latestValue = { ...projectDetails };
        latestValue[name] = value;
        setProjectDetails(latestValue);
    };

    const projectBaseUpdate = () => {
        store.dispatch(projectUpdate(projectDetails));
    };

    const onProjectDelete = () => {
        store.dispatch(removeProject(projectID))
        onClose()
        onCloseProject(false)
    }

    return (
        <>
            <Flex pt={"35px"}>
                <Flex
                    w={"100%"}
                    flexWrap={"wrap"}
                    minHeight={"36rem"}
                    bg={"white"}
                    p={"25px"}
                    borderRadius={"20px"}
                    marginInline={{ md: "2rem" }}
                    overflow={"hidden"}
                    boxShadow={shadow}
                >
                    <Scrollbars autoHide>
                        <Flex flexDirection={"column"} marginInline={"0.5rem"}>

                            {projectNameEdit ? (
                                <Input
                                    type="text"
                                    mt={6}
                                    fontWeight="700"
                                    textAlign={"center"}
                                    fontSize="xl"
                                    name="name"
                                    color={textColor}
                                    value={projectDetails.name}
                                    onChange={onChange}
                                    focusBorderColor={colors.buttonBG}
                                    onBlur={() => setProjectNameEdit(!projectNameEdit)}
                                />
                            ) : (
                                <Flex mt={6} textAlign={"center"} alignItems={"center"} justifyContent={"center"} gap={2}>
                                    <Text fontSize={"xl"} textAlign={"center"} fontWeight="700">{projectDetails.name}</Text>
                                    <Icon w={5} h={5} cursor={"pointer"} color={"blue.300"} as={MdEdit} onClick={() => setProjectNameEdit(!projectNameEdit)} />
                                </Flex>
                            )}
                        </Flex>
                        <Flex mt={"2rem"}>
                            <Tabs variant="soft-rounded" colorScheme="blue" w={"100%"}>
                                <TabList justifyContent={"space-around"}>
                                    <Tab fontSize={{ sm: "sm", md: "md" }}>Overview</Tab>
                                    <Tab fontSize={{ sm: "sm", md: "md" }}>Manage Files</Tab>
                                    <Tab fontSize={{ sm: "sm", md: "md" }}>Manage User</Tab>
                                </TabList>
                                <TabPanels>
                                    <TabPanel>
                                        <Box
                                            paddingInline={3}
                                            paddingTop={1}
                                            justifyContent="space-between"
                                        >
                                            <Text marginBlock={5}>
                                                Created Date: {projectDetails.created_date}
                                            </Text>
                                            <Text marginBlock={5}>
                                                No of members: {members.length}
                                            </Text>
                                            <Textarea
                                                value={projectDetails.comment}
                                                onChange={onChange}
                                                name="comment"
                                                placeholder="Project comment"
                                                size="sm"
                                                height={"100px"}
                                                borderColor={"purple.400"}
                                            />
                                            <Button
                                                mt={4}
                                                colorScheme="teal"
                                                onClick={projectBaseUpdate}
                                                type="submit"
                                            >
                                                Update
                                            </Button>
                                            <Flex alignItems={"center"} justifyContent={"end"}
                                                onClick={onOpen}
                                                cursor={"pointer"}>
                                                <Icon
                                                    w={6}
                                                    h={7}
                                                    color={deleteIcon}
                                                    as={RiDeleteBin6Line}
                                                />
                                                <Text color={"red"} ml={"6px"}>Delete Project</Text>
                                            </Flex>
                                        </Box>
                                    </TabPanel>
                                    <TabPanel>
                                        <ManageFiles files={userFiles} projectID={projectID} />
                                    </TabPanel>
                                    <TabPanel>
                                        <ManageUsers users={members} projectID={projectID} onCloseSidebar={onCloseProject} />
                                    </TabPanel>
                                </TabPanels>
                            </Tabs>
                        </Flex>
                    </Scrollbars>
                </Flex>
            </Flex>
            <Modal
                isOpen={isOpen}
                modalHead="Confirm"
                children={<Text>Are you sure you want to delete this project ?</Text>}
                onClose={onClose}
                onProcced={onProjectDelete}
            />
        </>
    );
}

export default ProjectSettings;
