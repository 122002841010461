import React, { useEffect, useState } from "react";
import CanvasComponent from "./Canvas";

function getWSize(wWidth, wHeight) {
  const width = wWidth;
  const height = wHeight;
  return { width, height };
}

const MainComponent = ({ wSize, mapData, containerId }) => {
  const [pageKey, setPageKey] = useState("canvas");
  // const [wSize, setWSize] = useState(getWSize(window.innerWidth, window.innerHeight));

  // useEffect(() => {
  //     const handleResize = () => {
  //         setWSize(getWSize(window.innerWidth, window.innerHeight));
  //     };

  //     // Add event listener for window resize
  //     window.addEventListener('resize', handleResize);

  //     // Clean up event listener on component unmount
  //     return () => {
  //         window.removeEventListener('resize', handleResize);
  //     };
  // }, []); // Empty dependency array ensures it only runs once after initial render

  return (
    <div className={`page-wrapper`}>
      <CanvasComponent
        pageKey={pageKey}
        wSize={wSize}
        mapData={mapData}
        containerId={containerId}
      />
    </div>
  );
};

export default MainComponent;
