import {
    Box,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    Text,
} from "@chakra-ui/react";
import { colors } from "components/misc/colors";
import React from "react";
import { useState } from "react";
import ConcreteDesign from "./sections/ConcreteDesign";
import General from "./sections/General";
import MaterialProperties from "./sections/MaterialProperties";
import SteelDesign from "./sections/SteelDesign";
import { useSelector } from "react-redux";
import { getProjects, getSettings } from "store/selector";
import { useEffect } from "react";
import { loadProjectLists, loadProjectMaterials, loadProjectSettingsDetails } from "store/slices/entities/settings";

function SettingsContainer({ project }) {

    const { projectDetails } = useSelector(getSettings);
    const { list: listsProjects } = useSelector(getProjects);

    const [active, setActive] = useState("General");
    const tabs = [
        "General",
        "Material Properties",
        "Concrete Design",
        "Steel Design",
        "Composite Design",
        "Masonry Design",
    ];

    
    return (
        <Box
            overflow={"hidden"}
            minH={"70vh"}
            display={"flex"}
            marginInline={15}
            w="100%"
        >
            <Tabs
                variant="soft-rounded"
                colorScheme="blue"
                overflow="auto"
                borderColor={'white'}
                orientation={"vertical"}
            >
                <TabList m={2}>
                    {tabs.map((tab) => (
                        <Tab
                            key={tab}
                            onClick={() => setActive(tab)}
                            border={active === tab ? '' : ""}
                            borderColor={active === tab ? colors.buttonBG : ""}
                            m={2}
                        >
                            {tab}
                        </Tab>
                    ))}
                </TabList>
                <TabPanels>
                    <TabPanel>
                        <General />
                    </TabPanel>

                    <TabPanel>
                        <MaterialProperties project={project} />
                    </TabPanel>
                    <TabPanel>
                        <ConcreteDesign />
                    </TabPanel>
                    <TabPanel>
                        <SteelDesign />
                    </TabPanel>
                    {/* <TabPanel>
                        <p>Composite Design</p>
                    </TabPanel>
                    <TabPanel>
                        <p>Masonry Design</p>
                    </TabPanel> */}
                </TabPanels>
            </Tabs>
        </Box>
    );
}

export default SettingsContainer;
