import React from "react";
import { useSelector } from "react-redux";

import { getEndConditionValues } from "utils/formatter";
import DataTable from "components/DataTable";
import { ColumnObj } from "components/project/utils/tableColumns";
import { getEndCondition } from "store/selector";
import { updateEndDetails } from "store/slices/entities/endCondition";
import { Button } from "@chakra-ui/react";
import { useState } from "react";
import { useEffect } from "react";
import { saveFileDetails } from "store/slices/entities/projectCanvas";
import { useDispatch } from "react-redux";

function EndTable({ tableName, columns, ...props }) {
    const { endLists, loading } = useSelector(getEndCondition);
    const values = getEndConditionValues(endLists[tableName]);

    const dispatch = useDispatch();

    const [rows, setRows] = useState([]);

    const valuess = [
        {
            "c1": "Width",
            "head": "Width",
            "c2": "1",
            "c2_reference": "Width",
        },
        {
            "c1": "Depth",
            "head": "Depth",
            "c2": "1",
            "c2_reference": "Width",
        }
    ]

    //const valuesss = getEndConditionValues(rows[tableName]);

    // useEffect(() => {
    //     const { b, h } = props?.data;
    //     setRows([
    //         {
    //             "c1": "Width(along Z)",
    //             "head": "Width(along Z)",
    //             "c2": "10",
    //             "c2_reference": "Above",
    //             "c3": "10",
    //             "c3_reference": "Below"
    //         },
    //         {
    //             "c1": "Depth(along Y)",
    //             "head": "Depth(along Y)",
    //             "c2": "10",
    //             "c2_reference": "Above",
    //             "c3": "10",
    //             "c3_reference": "Below"
    //         },
    //     ]);
    // }, [props?.data])

    const columnGroup = ColumnObj[tableName]();

    const onChange = ({ target: { value, name, id } }) => {
        const updatedRows = rows.map((row, index) => {
            if (index === parseInt(id, 10)) {
                return { ...row, [name]: value };
            }
            return row;
        });
        setRows(updatedRows);

        let updateField = { ...endLists[tableName][id] };
        updateField[name] = value;

        let allFields = { ...endLists[tableName] };
        allFields[id] = updateField;

        let allTables = { ...endLists };
        allTables[tableName] = allFields;

        dispatch(updateEndDetails(allTables));
    };

    function handleOnClick() {
        saveFileDetails(rows);
    }

    return (
        <div style={{ display: "flex" }}>
            <DataTable
                editable
                style={{ fontSize: "14px" }}
                columnFontSize="14px"
                tableValue={values}
                columns={columns}
                columnGroup={columnGroup}
                onChange={onChange}
                {...props}
            />
        </div>
    );
}

export default EndTable;
