import { Box, Flex, Portal } from "@chakra-ui/react";
import { FaExclamationTriangle } from "react-icons/fa";
import {
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverHeader,
    PopoverBody,
    PopoverFooter,
    PopoverArrow,
    PopoverCloseButton,
    PopoverAnchor,
} from '@chakra-ui/react'

const WarningInformation = (params) => {
    if (params.value && params.value.length > 0) {
        return (
            <Popover>
                <PopoverTrigger>
                    <Flex align="center" cursor="pointer">
                        <FaExclamationTriangle style={{ color: 'orange', marginRight: '5px' }} />
                        Check Warning
                    </Flex>
                </PopoverTrigger>
                <Portal>
                    <Box sx={{
                        "& .chakra-popover__popper": {
                            zIndex: "popover"
                        }
                    }}>
                        <PopoverContent zIndex={1500}>
                            <PopoverArrow />
                            <PopoverCloseButton />
                            <PopoverHeader>Warnings</PopoverHeader>
                            <PopoverBody maxH={250} overflowY={"scroll"}>
                                {params.value.split(",")}
                            </PopoverBody>
                        </PopoverContent>
                    </Box>

                </Portal>
            </Popover>
        );
    }
    return null;
};

export default WarningInformation;