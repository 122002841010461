import React, { useEffect, useState, useRef } from "react";
import { Box, Button, Stack, Grid, Select, Text, Flex } from "@chakra-ui/react";
import { FiSend } from "react-icons/fi";
import { AiFillUnlock } from "react-icons/ai";
import { Menubar } from "primereact/menubar";
import { OverlayPanel } from "primereact/overlaypanel";
import {
    loadProjectLists,
    loadProjectMaterials,
    loadProjectSettingsDetails,
    saveSettingsDetails,
    unlockSettings,
} from "store/slices/entities/settings";
import { useSelector } from "react-redux";
import { getProjects, getSettings } from "store/selector";
import SettingsContainer from "components/settings";
import EmptyPage from "components/settings/EmptyPage";
import SpinnerPage from "components/settings/SpinnerPage";
import { settings } from "utils/dataFormat";
import { colors } from "components/misc/colors";
import Load from "components/project/calculation types/column/other/Load";
import Rebar from "store/slices/entities/rebar";

function Settings() {
    const op1 = useRef(null);
    const op2 = useRef(null);
    const { loading, projectLists, projectDetails, unlockedProjects } =
        useSelector(getSettings);
    const { list: listsProjects } = useSelector(getProjects);

    const projects = listsProjects?.length > 0 ? listsProjects : projectLists;

    const onChangeProject = ({ target: { value } }) =>
        value && loadProjectSettingsDetails(value);

    useEffect(() => {
        projects?.length === 0 && loadProjectLists();
        projectDetails?.id && loadProjectMaterials(projectDetails?.id);
    }, [projectDetails]);
    useEffect(() => {
        if (!projectDetails?.project && projects?.length > 0) {
            loadProjectSettingsDetails(projects[0].id);
        }
    }, [projects, projectDetails]);

    const buttons = [
        {
            text: "Unlock",
            icon: <AiFillUnlock />,
            onClick: () => unlockSettings(projectDetails?.id),
            color: colors.unlock,
            disabled: unlockedProjects.includes(projectDetails?.id),
        },
        {
            text: "Submit",
            icon: <FiSend />,
            onClick: () => saveSettingsDetails(projectDetails, projectDetails?.id),
            color: colors.submit,
        },
    ];

    return (
        <Box
            flexDirection={{ sm: "column", lg: "row" }}
            justifyContent={{ sm: "start", md: "space-evenly" }}
            minH="100vh"
        >
            <Box
                flexDirection={{ sm: "column", lg: "row" }}
                display="flex"
                justifyContent={"space-between"}
                alignItems="center"
                m="1.5rem"
                mb="0.3rem"
            >
                <Box
                    boxShadow={"lg"}
                    borderRadius={15}
                    bgColor={"white"}
                    p={"0.5rem"}
                    overflow="hidden"
                    minW={{ sm: "12rem", lg: "16rem" }}
                >
                    <Select
                        mt={"1rem"}
                        name="project"
                        borderColor={colors.buttonBG}
                        onChange={onChangeProject}
                        value={projectDetails?.project || (projects && projects.length > 0 ? projects[0].id : '')}
                    >
                        {projects?.length > 0 ? (
                            <>
                                <option value={""}>
                                    Choose Project
                                </option>
                                {projects.map((project, index) => (
                                    <option key={project.id} value={project.id}>
                                        {project.name}
                                    </option>
                                ))}
                            </>
                        ) : (
                            <option>No projects</option>
                        )}
                    </Select>
                </Box>
                {projectDetails?.id && (
                    <Box mt={4}>
                        <Grid
                            gap={3}
                            display={"flex"}
                            justifyContent={{ sm: "center" }}
                            flexWrap={{ sm: "wrap", lg: "nowrap" }}
                        >
                            {buttons.map((btn, i) => (
                                <Button
                                    size={"sm"}
                                    bg="white"
                                    variant={"outline"}
                                    colorScheme={btn.color}
                                    textColor={btn.color}
                                    borderColor={colors.buttonBG}
                                    key={i}
                                    disabled={btn.disabled}
                                    onClick={btn.onClick}
                                    leftIcon={btn.icon}
                                >
                                    {btn.text}
                                </Button>
                            ))}
                        </Grid>
                    </Box>
                )}
            </Box>
            {projectDetails?.id ? (
                <SettingsContainer />
            ) : loading ? (
                <SpinnerPage />
            ) : (
                <EmptyPage />
            )}
        </Box>
    );
}

export default Settings;
