import { Select } from "@chakra-ui/react";
import React from "react";
import { useSelector } from "react-redux";
import { getCanvasDetails } from "store/selector";

function SelectField({ onChange, value, name, setEdit, fieldObj, id }) {
    const { fileDetails } = useSelector(getCanvasDetails);

    const getExternal = () => {
        const lists = [];
        const ref = fieldObj?.unit1 == "Concrete" ? "Concrete" : "Rebar";

        fileDetails?.materials?.map((item) => {
            if (ref == item.material) {
                lists.push({
                    id: item.slug,
                    value: item.name,
                });
            }
        });

        return lists;

    };

    const options = fieldObj?.options == "external" ? getExternal() : fieldObj?.options;

    const handleChange = (event) => {
        onChange(event);
        setEdit();
    };
    return (
        <Select id={id} onChange={handleChange} name={name} value={value} size={3} textAlign='center'>
            <option value={""} defaultChecked>
                Choose
            </option>
            {options?.map((item, i) => (
                <option key={i} value={item.id}>
                    {item.value}
                </option>
            ))}
        </Select>
    );
}

export default SelectField;
