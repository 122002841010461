import React from "react";
import * as NavigationMenu from "@radix-ui/react-navigation-menu";
import Load from "./other/Load";
import Rebar from "./other/Rebar";
import End from "./other/End";
import "./navmenu.css";
import { ChevronUpIcon } from "@chakra-ui/icons";
import GridExample from "components/tests/DataTable";
import RebarTable from "components/tests/RebarTable";
import { Button, Img } from "@chakra-ui/react";
import { BiFile, BiInfoCircle } from "react-icons/bi";
import { TbArrowsDown, TbArticle, TbBoxPadding, TbChartGridDotsFilled, TbChartSankey, TbChartScatter } from 'react-icons/tb';
import SectionTest from "./Section";

const NavMenu = (props) => {
    const preventHover = (event) => {
        event.preventDefault();
    };
    return (
        <NavigationMenu.Root className="NavigationMenuRoot" orientation="vertical">
            <NavigationMenu.List className="NavigationMenuList">

                <Button onClick={props.showDetails} height={8} borderRadius={2}>
                    <BiInfoCircle size={22} />
                </Button>

                <NavigationMenu.Item>
                    <NavigationMenu.Trigger
                        onPointerLeave={preventHover}
                        onPointerEnter={preventHover}
                        onPointerMove={preventHover}
                        className="NavigationMenuTrigger"
                    >
                        Section
                        <ChevronUpIcon className="CaretDown" />
                    </NavigationMenu.Trigger>
                    <NavigationMenu.Content
                        onInteractOutside={preventHover}
                        onPointerLeave={preventHover}
                        onPointerEnter={preventHover}
                        className="NavigationMenuContent"
                        style={{
                            width: "50vw",
                            maxHeight: "50vh",
                            overflowX: "auto",
                        }}
                    >
                        <SectionTest link={props?.endCondition} />
                    </NavigationMenu.Content>
                </NavigationMenu.Item>

                <NavigationMenu.Item>
                    <NavigationMenu.Trigger
                        onPointerLeave={preventHover}
                        onPointerEnter={preventHover}
                        onPointerMove={preventHover}
                        className="NavigationMenuTrigger"
                    >
                        Design
                        <ChevronUpIcon className="CaretDown" />
                    </NavigationMenu.Trigger>
                    <NavigationMenu.Content
                        onInteractOutside={preventHover}
                        onPointerLeave={preventHover}
                        onPointerEnter={preventHover}
                        className="NavigationMenuContent"
                        style={{
                            width: "80vw",
                            maxHeight: "50vh",
                            overflowX: "auto",
                        }}
                    >
                        <Load link={props?.linkLoad} />
                    </NavigationMenu.Content>
                </NavigationMenu.Item>

                <NavigationMenu.Item >
                    <NavigationMenu.Trigger
                        onPointerLeave={preventHover}
                        onPointerEnter={preventHover}
                        onPointerMove={preventHover}
                        className="NavigationMenuTrigger"
                    >
                        Graph
                        <ChevronUpIcon className="CaretDown" />
                    </NavigationMenu.Trigger>
                    <NavigationMenu.Content
                        onInteractOutside={preventHover}
                        onPointerLeave={preventHover}
                        onPointerEnter={preventHover}
                        className="NavigationMenuContent"
                        style={{
                            width: "80vw",
                            maxHeight: "50vh",
                            overflowX: "auto",
                        }}
                    >
                    </NavigationMenu.Content>
                </NavigationMenu.Item>

                <NavigationMenu.Indicator className="NavigationMenuIndicator">
                    <div className="Arrow" />
                </NavigationMenu.Indicator>
            </NavigationMenu.List>

            <div className="ViewportPosition">
                <NavigationMenu.Viewport className="NavigationMenuViewport" />
            </div>
        </NavigationMenu.Root>
    );
};

export default NavMenu;
