import { Box, Textarea, Accordion, AccordionItem } from "@chakra-ui/react";
import React from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { getCanvasDetails } from "store/selector";
import { loadFileDetails, updateFile } from "store/slices/entities/projectCanvas";
import CircleDraw from "./CircleDraw";
import Table from "./Table";
import AccordionComp from "./AccordionComp";
import RebarSettings from "./other/RebarSettings";
import { useEffect } from "react";
import Openings from "./Openings";

function ExpanderTables(props) {
    const { updatedFileDetails } = useSelector(getCanvasDetails);
    const [remark, setRemark] = useState(props?.remarks);
    const onChange = ({ target: { value, name } }) => {
        const updatedRow = updatedFileDetails?.filter(
            (item) => item.url === props.url
        );
        const finalRow = updatedRow?.length > 0 ? updatedRow[0] : props;
        const latest = {
            ...finalRow,
        };
        latest[name] = value;
        updateFile(latest);
        setRemark(latest?.remarks);
    };

    const { selectedFile, fileDetails } = useSelector(getCanvasDetails);

    useEffect(() => {
        selectedFile.link && loadFileDetails(selectedFile.link);
    }, [selectedFile]);


    return (
        <Box
            display={"flex"}
            flexDirection={{ sm: "column", md: "row" }}
            justifyContent={"space-around"}
            height={"100%"}
        >
            <Box
                display={"flex"}
                flexDirection={"column"}
                justifyContent={"space-between"}
                gap={"4px"}
                zIndex={2}
                marginRight={"auto"}
            >
                <Box display={"flex"} gap={"6px"}>
                    <Table headerless={false} tableName="Geometry" section="punchingShearSection" data={props} font={"10px"} />
                    <Table headerless={false} tableName="Material Properties" section="punchingShearSection" data={props} font={"10px"} />

                </Box>
                <Box>
                    <Openings data={props} editable={false} fontSize={"10px"} />
                    {/* <Table headerless={false} tableName="Openings" section="punchingShearSection" data={props} font={"9px"} /> */}

                    <Table headerless={false} tableName="Rebar Data" section="punchingShearSection" data={props} font={"10px"} />
                </Box>
            </Box>

            <Box>
                {/* <CircleDraw
      link={props?.link_rebar}
      w={props?.width}
      h={props?.depth}
    /> */}
            </Box>

            <Box
                display={"flex"}
                flexDirection={"column"}
                justifyContent={"space-between"}
                gap={"4px"}
                zIndex={2}
                marginLeft={"auto"}
            >
                <Box>
                    <Table headerless={false} tableName="Section Summary" section="punchingShearSection" data={props} font={"10px"} />
                </Box>
                <Box >
                    <Table headerless={false} tableName="Corner Shear Stresses" section="punchingShearSection" data={props} font={"10px"} />
                </Box>
                <Box >
                    <Table headerless={false} tableName="Design Summary" section="punchingShearSection" data={props} font={"10px"} />
                </Box>

            </Box>


        </Box>
    );
}

export default ExpanderTables;
