import { Box, color, Grid } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { BiLockOpen, BiRefresh, BiUpload } from "react-icons/bi";

import {
  loadEndCondition,
  uploadEndCondition,
} from "store/slices/entities/endCondition";
import FakeImage from "../FakeImage";
import EndTable from "./EndTable";
import { Button } from "components/misc/Buttons";
import { colors } from "components/misc/colors";
import { CloseIcon } from "@chakra-ui/icons";
import EndImage from "images/endcondition.png";

function End({ link }) {
  useEffect(() => {
    loadEndCondition(link);
  }, []);

  const buttons = [
    {
      text: "",
      icon: <BiLockOpen />,
      onClick: () => {},
      color: colors.lock,
    },
    {
      text: "Submit",
      icon: <BiUpload />,
      onClick: () => uploadEndCondition(link),
      color: colors.submit,
    },
    {
      text: "Refresh",
      icon: <BiRefresh />,
      onClick: () => loadEndCondition(link),
      color: colors.refresh,
    },
  ];

  return (
    <Box minH="20vh" p={"1rem"} borderRadius={15} bgColor={"gray.300"}>
      <Box
        p={{ sm: "1rem", lg: "0" }}
        minWidth={"43rem"}
        display={"flex"}
        flexDirection={"row"}
      >
        <Box
          display={"flex"}
          flexDirection={"column"}
          alignContent="space-between"
        >
          <Box
            display={"flex"}
            flexDirection={"row"}
            justifyContent={"space-between"}
          >
            <Box width={"17.7rem"}>
              <EndTable
                tableName={"End Condition"}
                columns={["c1", "c2", "c3"]}
                // style={{ width: "300px" }}
              />
            </Box>
            <Box mr="10">
              <Grid
                gap={3}
                display={"flex"}
                position="relative"
                justifyContent={{ sm: "center" }}
                flexWrap={{ sm: "wrap", lg: "nowrap" }}
              >
                {buttons.map((btn, i) => (
                  <Button
                    size={"xl"}
                    colorScheme={btn.color}
                    textColor={btn.color}
                    key={i}
                    borderColor={colors.buttonBG}
                    onClick={btn.onClick}
                    leftIcon={btn.icon}
                    iconSpacing={btn?.text == "" ? 0 : 1}
                  >
                    {btn.text}
                  </Button>
                ))}
                {/* <Button
                  size={"sm"}
                  position={"absolute"}
                  left={"195%"}
                  colorScheme={colors.delete}
                  textColor={colors.delete}
                  borderColor={colors.buttonBG}
                  iconSpacing={0}
                  //   onClick={onClose}
                  leftIcon={<CloseIcon />}
                /> */}
              </Grid>
            </Box>
          </Box>
          <Box display={"flex"} flexDirection={"row"}>
            <EndTable
              tableName={"Column"}
              columns={["c1", "c2", "c3"]}
              // style={{ width: "315px" }}
            />

            <Box marginInline={3}>
              <EndTable
                tableName={"Beams parallel to Z axis"}
                columns={["c1", "c2", "c3", "c4", "c5"]}
              />
            </Box>
            <EndTable
              tableName={"Beams parallel to Y axis"}
              columns={["c1", "c2", "c3", "c4", "c5"]}
            />
          </Box>
        </Box>
        <Box ml="3" mt={"8rem"}>
          <FakeImage url={EndImage} width={"250px"} height="97%" />
        </Box>
      </Box>
    </Box>
  );
}

export default End;
