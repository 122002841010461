import { Box, Textarea, Accordion, AccordionItem } from "@chakra-ui/react";
import React from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { getCanvasDetails } from "store/selector";
import { updateFile } from "store/slices/entities/projectCanvas";
import CircleDraw from "./CircleDraw";
import Table from "./Table";
import AccordionComp from "./AccordionComp";
import RebarSettings from "./other/RebarSettings";

function ExpanderTables(props) {
    const { updatedFileDetails } = useSelector(getCanvasDetails);
    const [remark, setRemark] = useState(props?.remarks);
    const onChange = ({ target: { value, name } }) => {
        const updatedRow = updatedFileDetails?.filter(
            (item) => item.url === props.url
        );
        const finalRow = updatedRow?.length > 0 ? updatedRow[0] : props;
        const latest = {
            ...finalRow,
        };
        latest[name] = value;
        updateFile(latest);
        setRemark(latest?.remarks);
    };

    return (
        <Box
            display={"flex"}
            flexDirection={{ sm: "column", md: "row" }}
            justifyContent={"space-around"}
            height={"100%"}
        >
            <Box
                display={"flex"}
                flexDirection={"column"}
                justifyContent={"space-between"}
                gap={"4px"}
                zIndex={2}
                marginRight={"auto"}
            >
                <Box>
                    <Table headerless={false} tableName="Section Details" section="crackWidthSection" data={props} font={"10px"} />
                </Box>
                <Box>
                    <Table headerless={false} tableName="Material Properties" section="crackWidthSection" data={props} font={"10px"} />
                </Box>
            </Box>

            <Box>
                {/* <CircleDraw
          link={props?.link_rebar}
          w={props?.width}
          h={props?.depth}
        /> */}
            </Box>

            <Box
                display={"flex"}
                flexDirection={"column"}
                justifyContent={"space-between"}
                gap={"4px"}
                zIndex={2}
                marginLeft={"auto"}
            >
                <Box>
                    <Table headerless={false} tableName="Section Summary" section="crackWidth" data={props} font={"10px"} />
                </Box>
                <Box >
                    <Table headerless={false} tableName="Design Summary" section="crackWidth" data={props} font={"10px"} />
                </Box>

            </Box>


        </Box>
    );
}

export default ExpanderTables;
