import React from "react";
import tw from "twin.macro";
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";
import { ContentWithVerticalPadding } from "components/misc/Layouts.js";
import { ReactComponent as CheckboxIcon } from "feather-icons/dist/icons/check-circle.svg";

const Row = tw.div`flex flex-col mx-auto  items-center    max-w-screen-2xl px-2 sm:px-8`;
const Column = tw.div``;
const TextColumn = tw(
  Column
)``;

const Subheading = tw(
  SubheadingBase
)`mb-4 text-xl text-center md:text-2xl text-other-subtitle`;
const Heading = tw(
  SectionHeading
)`w-full text-2xl text-other-title leading-snug font-bold   xl:text-4xl `;
const Description = tw(SectionDescription)`w-full text-center text-lg max-w-lg`;

const VerticalSpacer = tw.div`mt-10 w-full`;

const FeatureList = tw.ul` ml-2 leading-loose`;
const Feature = tw.li`flex items-start lg:items-center`;
const FeatureIcon = tw(CheckboxIcon)` h-4 text-other-buttonBG `;
const FeatureText = tw.p`ml-2 text-base  text-black`;
const SectionhContainer = tw.div`lg:mr-0 mt-6 lg:mt-0 grid  grid-cols-1  lg:grid-cols-3 items-center`;

const Section = tw.p`mx-2 px-4 py-2 rounded-2xl shadow-lg my-4 max-w-xl  lg:w-11/12 bg-white  border-2 border-other-buttonBG`;
const ExtraSection = tw.p`mt-2`;
const SpanText = tw.span`font-extrabold text-lg`

const Container = tw.div`relative lg:py-10 bg-white`;
const Span = tw.p`mt-2`

function About({
  subheading = "About",
  heading = "Why Join to TEDAPS Network?",
  description = "TEDAPS brings the power of cloud computing in its engineering calculation modules, to build and manage your project design calculations in collaboration with the team, from wherever they are;",
}) {
  return (
    <Container>
      <ContentWithVerticalPadding>
        <Row>
          <TextColumn>
            {subheading && <Subheading>{subheading}</Subheading>}
            <Heading>{heading}</Heading>
            {description && <Description>{description}</Description>}
            <VerticalSpacer />
          </TextColumn>
          <SectionhContainer>
            <Section>
              <SpanText>T</SpanText>he calculation you perform in TEDAPS are categorized on a project
              basis and allow multiple users, offering better file and data
              management. <br /> <Span>The project and team-based workflow is
              accompanied with other advantages such as options to set the
              design criteria for the entire team to follow, ease of progress
              tracking, review, and coordination during entire project life
              cycle.</Span>
            </Section>
            <Section>
            <SpanText>D</SpanText>eveloped and verified by experienced engineers on interest of the
              wider community of engineers.We provide 
              Unlimited free access* to budding engineers and students.
              <ExtraSection>
                *Unlimited free access to perform following:
                <FeatureList>
                  <Feature>
                    <FeatureIcon />
                    <FeatureText>To create and manage two projects</FeatureText>
                  </Feature>
                  <Feature>
                    <FeatureIcon />
                    <FeatureText>
                      To perform two calculations per design module, in each project
                    </FeatureText>
                  </Feature>
                </FeatureList>
              </ExtraSection>
            </Section>
            <Section>
            <SpanText>P</SpanText>erform your engineering calculations on a secure web portal and
              obtain seamless benefits of technology:
              <ExtraSection>
                <FeatureList>
                  <Feature>
                    <FeatureIcon />
                    <FeatureText>
                      Accessibility from anywhere, anytime.
                    </FeatureText>
                  </Feature>
                  <Feature>
                    <FeatureIcon />
                    <FeatureText>
                      Automatic updates, no installations.
                    </FeatureText>
                  </Feature>
                  <Feature>
                    <FeatureIcon />
                    <FeatureText>
                      Ease of collaboration and sharing.
                    </FeatureText>
                  </Feature>
                </FeatureList>
              </ExtraSection>
            </Section>
          </SectionhContainer>
        </Row>
      </ContentWithVerticalPadding>
    </Container>
  );
}

export default About;
