import routes from "api/routes";

export const status = {
  "Not Accepted": "danger",
  "Not Designed": "info",
  Pass: "success",
  failed: "danger",
  "Check warning": "warning",
};

export const statusCode = {
  200: "success",
  202: "success",
  204: "success",
  201: "success",
  400: "warning",
  401:'error',
  404: "warning",
  500: "error",

  undefined: "warning",
};

export const regObj = {
  1: { exp: /column/gi, url: routes.canvas.baseURl },
  2: { exp: /add_row/gi, url: routes.canvas.addRow },
  3: { exp: /columnrow/gi, url: routes.canvas.updateRow },
  4: { exp: /column-rebar/gi, url: routes.canvas.rebarRow },
  // 4: { exp: /column-rebar-row/gi, url: routes.canvas.deleteRebarRow },
  5: { exp: /column-endcondition/gi, url: routes.canvas.endRow },
  6: { exp: /column-load/gi, url: routes.canvas.loadRow },
  // 12: { exp: /bulk_update/gi, url: routes.settings.projectMaterialBulkUpdate },
  7: { exp: /projectsetting/gi, url: routes.settings.projectDetails },
  8: { exp: /project-materials/gi, url: routes.settings.projectMaterial },
  9: { exp: /column-load-row/gi, url: routes.canvas.addLoadDesignRow },
  10: { exp: /delete_calcs/gi, url: routes.settings.projectUnlock },
  11: { exp: /userfile/gi, url: routes.project.file },
  12: { exp: /delete_load_row/gi, url: routes.canvas.load_design_remove_row },
  13 : {exp:/login/gi,url:routes.auth.login},
  14 : {exp:/users/gi,url:routes.project.adduser},
  15 : {exp:/profile/gi,url:routes.auth.profile},
};
