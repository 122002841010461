import { createSlice } from "@reduxjs/toolkit";
import routes from "api/routes";
import { apiCallBegin } from "store/api";
import store from "store/store";
import { Toast } from "utils/Toast";

const slice = createSlice({
  name: "user",
  initialState: {
    token: localStorage.getItem("access_token") || false,
    loading: false,
    isAuthenticated: localStorage.getItem("access_token") ? true : false,
    profile: {},
    isAdminUser: localStorage.getItem("admin_loggedIn") ? true : false,
    approveUsers: [],
    approvedUsers:[],
    rejectedUsers:[]
  },
  reducers: {
    requested: (user) => {
      user.loading = true;
    },

    userLoggedIn: (user, action) => {
      user.token = action.payload.access;
      localStorage.setItem("access_token", user.token);
      localStorage.setItem("refresh_token", action.payload.refresh);

      if (action.payload?.is_admin) {
        user.isAdminUser = true;
        localStorage.setItem("admin_loggedIn", true);
      } else {
        user.isAuthenticated = true;
      }

      user.loading = false;
    },
    userLoggedOut: (user) => {
      user.token = false;
      user.profile = {};
      localStorage.removeItem("access_token");
      localStorage.removeItem("refresh_token");
      localStorage.removeItem("admin_loggedIn");
      user.isAuthenticated = false;
      user.isAdminUser = false;
    },
    requestFailed: (user) => {
      user.loading = false;
    },
    userProfileReceived: (user, action) => {
      user.profile = action.payload;
      user.loading = false;
    },
    approveUsersReceived: (user, action) => {
      user.approveUsers = action.payload;
      user.loading = false;
    },
    userRegistered:(user,action) => {
      user.loading = false;
      Toast('Thanks for your interest in registering with tedaps.com. You will receive an email notification within 48 hours stating the status of your registration request.','success')

    },
    userApproved:(user,action) => {
      const unapprovedUsers = user.approveUsers?.filter(user => user.id !== action.previous.userid)
      user.loading = false
      user.approveUsers = unapprovedUsers
    },
    approvedUsers:(user,action) => {
      user.approvedUsers = action.payload
      user.loading = false
    },
    rejectedUsers:(user,action) => {
      user.rejectedUsers = action.payload
      user.loading = false
    }

  },
});

const {
  userLoggedIn,
  userLoggedOut,
  requestFailed,
  userProfileReceived,
  requested,
  approveUsersReceived,
  userRegistered,
  userApproved,
  approvedUsers,
  rejectedUsers
} = slice.actions;
export default slice.reducer;

//action creator functions

export const loginUser = ({ username, password }) => {
  store.dispatch(
    apiCallBegin({
      url: routes.auth.login,
      method: "post",
      formData: { username, password },
      onStart: requested.type,
      onSuccess: userLoggedIn.type,
      onError: requestFailed.type,
    })
  );
};

export const logOutUser = () => userLoggedOut();

export const registerUser = (registerObj) => {
  const obj = {
    username: registerObj?.username,
    email: registerObj?.email,
    password: registerObj?.password,
    password2:registerObj?.password,
    first_name: registerObj?.fname,
    last_name:registerObj?.lname,
    profession:registerObj?.profession,
    college:registerObj?.company,
  };

  store.dispatch(
    apiCallBegin({
      url: routes.auth.register,
      method: "post",
      formData: obj,
      onStart: requested.type,
      onError: requestFailed.type,
      onSuccess: userRegistered.type,
    })
  );
};

export const userProfile = () =>
  store.dispatch(
    apiCallBegin({
      url: routes.auth.profile,
      onStart: requested.type,
      onSuccess: userProfileReceived.type,
      onError: requestFailed.type,
    })
  );

export const approveUserLists = () =>
  store.dispatch(
    apiCallBegin({
      url: routes.auth.approveUsers,
      onStart: requested.type,
      onSuccess: approveUsersReceived.type,
      onError: requestFailed.type,
    })
  );


export const approveUser = (userid) => {
  store.dispatch(apiCallBegin({
    method:'post',
    url:`${routes.auth.approveUsers}${userid}/approve/`,
    onStart: requested.type,
    onError: requestFailed.type,
    onSuccess:userApproved.type,
    jsonData:{userid}
  }))
}

export const rejectUser = (userid) => {
  store.dispatch(apiCallBegin({
    method:'post',
    url:`${routes.auth.approveUsers}${userid}/reject/`,
    onStart: requested.type,
    onError: requestFailed.type,
    onSuccess:userApproved.type,
    jsonData:{userid}
  }))
}

export const fetchLists = (type) => {
  store.dispatch(
    apiCallBegin({
      url: type === 'approved' ? routes.auth.approveLists : routes.auth.rejectLists,
      onStart: requested.type,
      onSuccess: type === 'approved' ? approvedUsers.type : rejectedUsers.type,
      onError: requestFailed.type,
    })
  );
}