import { Box } from "@chakra-ui/react";
import DataTable from "components/DataTable";
import React from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { getAuth } from "store/selector";
import { approveUserLists } from "store/slices/auth/user";

export function Dashboard() {
  const { approveUsers, loading } = useSelector(getAuth);
  useEffect(() => {
    approveUserLists();
  }, []);

  const columns = [
    { header: "User name", field: "username" },
    { header: "First Name", field: "first_name" },
    { header: "Last Name", field: "last_name" },
    { header: "Email", field: "email" },
    { header: "Profession", field: "profession" },
    { header: "College", field: "college" },
    { header: "Action", field: "first_name" },
  ];
  return (
    <Box
      flexDirection={{ sm: "column", lg: "row" }}
      justifyContent={{ sm: "start", md: "space-evenly" }}
      minH="100vh"
      pt={"100px"}
      paddingInline={10}
      overflow='hidden'
    >
      <Box
        borderRadius={5}
        overflow={"hidden"}
        bgColor={"white"}
        overflowX={"auto"}
        maxWidth={"100%"}
        
      >
        <DataTable
          showGridlines
          columnFontSize="17px"
          tableValue={approveUsers}
          columns={columns}
          loading={loading}
          // ExpandTemplate={Expander}
          emptyMessage="No rows found !"
          responsiveLayout={"stack"}
          breakpoint="960px"
          columnPadding={"0.5rem 1rem"}
          headerStyle={{textAlign:'center'}}
          // onRowCollapse={(e) => removeUpdatedFile(e.data?.url)}
        />
      </Box>
    </Box>
  );
}
