import { regObj, status } from "./constant";
import _ from "lodash";

export const getTableFormat = (formatObj) => {
    const newFormat = [];
    Object.keys(formatObj)?.map((row) => {
        const data = formatObj[row];
        if (data.presentation) {
            newFormat.push({ header: data.unit1, field: row, presentation: true });
        }
        return false
    });

    return newFormat;
};

export const getDefaultTableValue = (formatObj, tableName, rowData) => {
    const listOfObject = [];
    const newValue = [];
    const columns = ["c1", "c2",];

    //getting objects for coming tablename from formatObj
    Object.keys(formatObj).map((key) => {
        const data = formatObj[key];
        if (data.table === tableName) {
            listOfObject.push({ ...data, referenceKey: key });
        }
        return false;
    });

    //grouping same order object
    const groupedObj = _.groupBy(listOfObject, (obj) => obj.order);

    Object.keys(groupedObj).map((item) => {
        const calc_value = groupedObj[item];
        const obj = {};
        if (calc_value.length === 1) {
            obj["c1"] = calc_value[0].unique_name || calc_value[0].unit1;
            obj["c2"] = rowData[calc_value[0].referenceKey];
            obj['c2_reference'] = calc_value[0].referenceKey
            obj['optionalID'] = rowData?.id
        }

        if (calc_value.length > 1) {
            let c_count = 1;
            obj["c1"] = calc_value[0].unique_name || calc_value[0].unit1;

            calc_value.map((row) => {
                c_count++;
                let c_name = `c${c_count}`;
                obj[c_name] = row?.value || rowData[row.referenceKey]
                obj[`${c_name}_reference`] = row.referenceKey
                obj['optionalID'] = rowData?.id

                if (!columns.includes(c_name)) columns.push(c_name);
                return false;
            });
        }

        obj?.c1 && newValue.push(obj);
        return false;
    });

    return { newValue, columns };
};

export const getStatusColor = (statusText) => status[statusText];


export const getEndConditionValues = (endObj) => {
    const endRowList = []
    try {
        Object.keys(endObj).map(row => {
            const obj = { c1: row, head: row }
            let c_count = 1
            Object.keys(endObj[row]).map(item => {
                c_count++;
                let c_name = `c${c_count}`
                if (['Column present', 'Beam Present'].includes(row)) {
                    obj[`endSelect`] = 'yesOrNo'
                } else if (['Parralel to Z', 'Parralel to Y'].includes(row)) {
                    obj['endSelect'] = 'userSpecific'
                }

                obj[c_name] = endObj[row][item]
                obj[`${c_name}_reference`] = item
                return false;
            })
            endRowList.push(obj)
            return false;
        })
    } catch (error) {
        console.log(error);
    }
    return endRowList
}

export const listMaterialColumns = (formatObj, tableName) => {
    const newFormat = [];
    try {
        Object.keys(formatObj).map(row => {
            const obj = { c1: row, head: row };
            let c_count = 1;
            Object.keys(formatObj[row]).map(item => {
                c_count++;
                let c_name = `c${c_count}`;
                obj[c_name] = formatObj[row][item];
                obj[`${c_name}_reference`] = item;
                return false;
            });
            newFormat.push(obj);
            return false;
        });
    } catch (error) {
        console.log(error);
    }
    return newFormat;
}

export const getMaterialColumns = (formatObj, tableName) => {
    const newFormat = [];
    Object.keys(formatObj)?.map((row) => {
        const data = formatObj[row];
        if (data.table === tableName) {
            newFormat.push({ header: data.unit1, field: row });
        }
        return false;
    });
    return newFormat;
}

export const getMatchUrl = (url) => {
    let dynamicUrl = ''
    Object.keys(regObj).map(item => {
        try {
            const properties = regObj[item]
            const match = url.match(new RegExp(properties.exp))
            if (match?.length > 0) {
                dynamicUrl = properties.url
            }
        } catch (error) {

        }
        return false;
    })

    return dynamicUrl || url
}

export const getProjectSettingRebar = (rebarData) => {
    const newValue = []
    const columns = []

    const rowCount = rebarData['Imperial Bar Size'] ? rebarData['Imperial Bar Size'].length : rebarData['Cross-Sectional Area(mm2)'] ? rebarData['Cross-Sectional Area(mm2)'].length : 0
    const rows = [...Array(rowCount).keys()]
    rows.map(row => {
        const rowValues = {}
        Object.keys(rebarData)?.map((rebar) => {
            rebarData[rebar]?.map((data, key) => {
                if (row === key) {
                    rowValues[rebar.toLowerCase()] = data
                }
            })

            return false;
        })
        newValue.push(rowValues)
        return false;
    })

    Object.keys(rebarData).map(rebar => {
        columns.push({ header: rebar, field: rebar.toLowerCase() })
        return false;
    })

    return { newValue, columns }
}