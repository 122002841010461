import { toast as notifier } from "react-toastify";

export const Toast = (message, type) => {
  return notifier[type](message, {
    position: "top-center",
    autoClose: type === 'error' ? false : 6000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    progress: undefined,
    toastId: message,
  });
};
