import { AddIcon } from "@chakra-ui/icons";
import {
    Avatar,
    AvatarGroup,
    Badge,
    Button,
    Flex,
    Icon,
    FormControl,
    Input,
    Text,
    useColorModeValue,
    Box,
    Tooltip,
    useDisclosure,
    Modal,
} from "@chakra-ui/react";
import React, { useState, useRef, useLayoutEffect } from "react";
import { MdDeleteOutline, MdEdit } from "react-icons/md";
import { Scrollbars } from "react-custom-scrollbars-2";

import {
    kanbanRenderThumb,
    kanbanRenderTrack,
    kanbanRenderView,
} from "components/misc/scroll/Scrollbar";
import { Select } from "chakra-react-select";

import { CustomSideBar } from "components/sidebar/Sidebar";
import { colors } from "components/misc/colors";
import ProjectSettings from "components/dashboard/project";

import store from "store/store";
import { createProject, loadProjects } from "store/slices/entities/projects";
import { useSelector } from "react-redux";

import {
    getAuth,
    getMembers,
    getProjects,
    getCanvasDetails,
} from "store/selector";

import { loadMembers } from "store/slices/entities/members";
import Loading from "components/common/Loading";

import { changeProject } from "store/slices/entities/projectCanvas";
import Project from "./Project";

function ProjectDash() {
    const { list: projects, loading: loadingPro } = useSelector(getProjects);
    const { selectedProject } = useSelector(getCanvasDetails);
    const { profile } = useSelector(getAuth);
    const members = useSelector(getMembers);

    let initialBoard = [
        {
            boardID: 1,
            title: "Projects",
            cards: projects?.map((project, key) => ({
                id: `1.${key + 1}`,
                projectID: project.id,
                title: project.name,
                status: profile?.id === project.created_by.id ? "Owner" : "Participant",
                members: project.users,
                userFiles: project.userfile_set,
                owner: project.created_by,
            })),
        },
    ];

    const initial = { project: "", members: [] };
    const board = initialBoard;
    const [sideBarContent, setSideBarContent] = useState(false);
    const [newProject, setNewProject] = useState(initial);
    const projectForm = useRef(null);
    const formInput = useRef(null);

    const textColor = useColorModeValue("secondaryGray.900", "white");
    const shadow = useColorModeValue(
        "0px 18px 40px rgba(112, 144, 176, 0.12)",
        "transparent"
    );
    const bgButton = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
    const brandText = useColorModeValue("brand.500", "white");
    const addBtnBg = useColorModeValue(colors.buttonBG);
    const projectBg = useColorModeValue("#f4f7fe");

    const handleChange = (event) => {
        const { target } = event;
        const latestValues = { ...newProject };
        if (!target) {
            latestValues.members = event;
        } else {
            latestValues[target.name] = target.value;
        }
        setNewProject(latestValues);
    };

    const onProjectSubmit = () => {
        // const user_ids = newProject.members?.map((member) => member.id);
        store.dispatch(createProject({ name: newProject.project }));
        setNewProject(initial);
    };
    const listOfProjects = projects;
    const onChangeProject = (id) => {
        const filteredProject = listOfProjects?.filter(
            (project) => project.id === id
        );
        changeProject(filteredProject?.length > 0 && filteredProject[0]);
    };


    const projectSettingsSidebar = useDisclosure();

    useLayoutEffect(() => {
        projects?.length === 0 && store.dispatch(loadProjects());
    }, []);
    return (
        <>
            {(selectedProject && <Project />) || (
                <Flex
                    // border={"1px solid yellow"}
                    flexDirection={{ sm: "column", lg: "row" }}
                    justifyContent={"space-evenly"}
                    minH="100vh"
                    pt={{ sm: "100px", lg: "30px" }}
                    overflow="hidden"
                >
                    {!selectedProject &&
                        board.map((item, key) => {
                            let { title, cards, boardID } = item;

                            function formOpen() {
                                projectForm.current.style.display = "flex";
                                store.dispatch(loadMembers());
                            }
                            function formClose() {
                                projectForm.current.style.display = "none";
                                setNewProject(initial);
                            }
                            function formSubmit() {
                                formInput.current.value = "";
                                projectForm.current.style.display = "none";
                                onProjectSubmit();
                            }

                            return (
                                <Flex
                                    key={key}
                                    w={{ sm: "100%", md: "80%" }}
                                    minHeight={"36rem"}
                                    bg={"white"}
                                    p={"25px"}
                                    display="flex"
                                    justifyContent="center"
                                    borderRadius={"20px"}
                                    marginBlock={"2rem"}
                                    marginInline={{ md: "2rem" }}
                                    overflow={"hidden"}
                                    height={"100%"}
                                >
                                    {loadingPro ? (
                                        <Loading
                                            isloading={loadingPro}
                                            borderColor="rgb(94, 92, 92) transparent transparent transparent"
                                        />
                                    ) : (
                                        <Scrollbars
                                            autoHide
                                            renderTrackHorizontal={kanbanRenderTrack}
                                            renderThumbHorizontal={kanbanRenderThumb}
                                            renderView={kanbanRenderView}
                                        >
                                            <Flex
                                                key={boardID}
                                                flexDirection={"column"}
                                                mb="7px"
                                                fontWeight="bold"
                                                w="100%"
                                            >
                                                <Flex justify="space-between" align="center" mb="24px">
                                                    <Text fontSize="22px" mt="5px" color={textColor}>
                                                        {title}
                                                    </Text>
                                                    {boardID === 1 && (
                                                        <Button
                                                            h="35px"
                                                            fontSize={15}
                                                            borderRadius="12px"
                                                            px={4}
                                                            onClick={formOpen}
                                                            borderColor={"#217cf5"}
                                                            bg={"#217cf5"}
                                                            textColor={"white"}
                                                            leftIcon={
                                                                <AddIcon w="12px" h="12px" color={"white"} />
                                                            }
                                                            css={{
                                                                "&:hover": {
                                                                    backgroundColor: "#217cf5",
                                                                }
                                                            }
                                                            }
                                                        >
                                                            Add Project
                                                        </Button>
                                                    )}
                                                </Flex>
                                                {boardID === 1 && (
                                                    <Flex
                                                        flexDirection="column"
                                                        ref={projectForm}
                                                        display="none"
                                                    >
                                                        <FormControl>
                                                            <Box mb="10px" paddingInline={1}>
                                                                <Input
                                                                    ref={formInput}
                                                                    borderRadius={10}
                                                                    variant="main"
                                                                    className="project__name"
                                                                    placeholder="Project name"
                                                                    name="project"
                                                                    value={newProject.project}
                                                                    textColor="black"
                                                                    onChange={(event) =>
                                                                        handleChange(
                                                                            event,
                                                                            newProject,
                                                                            setNewProject
                                                                        )
                                                                    }
                                                                />
                                                            </Box>
                                                            {/* <Box mb="10px" paddingInline={1}>
                                                                <Select
                                                                    isMulti
                                                                    name="members"
                                                                    colorScheme="purple"
                                                                    options={members}
                                                                    placeholder="Select user"
                                                                    closeMenuOnSelect={true}
                                                                    value={newProject.members}
                                                                    onChange={(event) =>
                                                                        handleChange(
                                                                            event,
                                                                            newProject,
                                                                            setNewProject
                                                                        )
                                                                    }
                                                                />
                                                            </Box> */}
                                                            <Flex>
                                                                <Button
                                                                    borderColor={colors.add}
                                                                    textColor={colors.add}
                                                                    variant="outline"
                                                                    me="14px"
                                                                    onClick={formSubmit}
                                                                >
                                                                    Add Project
                                                                </Button>
                                                                <Button
                                                                    borderColor={colors.delete}
                                                                    textColor={colors.delete}
                                                                    variant="outline"
                                                                    onClick={formClose}
                                                                >
                                                                    Cancel
                                                                </Button>
                                                            </Flex>
                                                        </FormControl>
                                                    </Flex>
                                                )}
                                            </Flex>
                                            <Box
                                                display="flex"
                                                gap="24px"
                                                justifyContent="flex-start"
                                                flexWrap="wrap"
                                                width="100%"
                                            >
                                                {cards.map((card) => {
                                                    const {
                                                        image,
                                                        title,
                                                        desc,
                                                        owner,
                                                        status,
                                                        members,
                                                        id,
                                                    } = card;

                                                    return (
                                                        <Flex
                                                            key={id}
                                                            cursor={boardID === 1 && "pointer"}
                                                            boxShadow={boardID === 1 && projectBg}
                                                            mt="10px"
                                                            flexDirection="column"
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                e.stopPropagation();
                                                                onChangeProject(card.projectID);
                                                            }}
                                                            // border={"1px solid red"}
                                                            p="25px"
                                                            borderRadius="15px"
                                                            w="30%"
                                                            overflow={"none"}
                                                            bg={boardID === 1 && projectBg}
                                                            css={{
                                                                transition: "background-color 0.25s",
                                                                "&:hover": {
                                                                    backgroundColor: "#CBD5E0",
                                                                },
                                                                // "&:active": {
                                                                //   outline: "2px solid #63B3ED",
                                                                // },
                                                            }}
                                                        >
                                                            <Flex align="center">
                                                                {boardID === 1 && (
                                                                    <>
                                                                        <Text
                                                                            fontWeight="700"
                                                                            fontSize="lg"
                                                                            color={textColor}

                                                                        >
                                                                            <Text>{title}</Text>
                                                                            <Text
                                                                                fontWeight="500"
                                                                                fontSize={"14px"}
                                                                                color={colors.buttonBG}
                                                                            >
                                                                                {profile?.id !== owner.id &&
                                                                                    `Owner: ${owner.username}`}
                                                                            </Text>
                                                                        </Text>
                                                                        <Flex
                                                                            ms="auto"
                                                                            cursor="pointer"
                                                                            h="34px"
                                                                            borderRadius="0.5rem"
                                                                            bg="gray.200"
                                                                            w="34px"
                                                                            justifyContent={"center"}
                                                                            align="center"
                                                                            transition="background-color 0.125s"
                                                                            _hover={{
                                                                                bg: "gray.100",
                                                                            }}
                                                                            onClick={(e) => {
                                                                                e.stopPropagation();
                                                                                setSideBarContent({
                                                                                    ...card,
                                                                                    target: "project",
                                                                                });
                                                                                projectSettingsSidebar.onOpen();

                                                                            }}
                                                                        >

                                                                            <Icon
                                                                                color={textColor}
                                                                                as={MdEdit}
                                                                                w="17px"
                                                                                h="17px"
                                                                            />
                                                                        </Flex>
                                                                    </>
                                                                )}

                                                            </Flex>

                                                            {(status || members) && (
                                                                <Flex w="100%" mt="20px" align="center">
                                                                    {members ? (
                                                                        <AvatarGroup size="sm" max={3}>
                                                                            {members.map((member, key) => (
                                                                                <Box key={key}>
                                                                                    <Tooltip hasArrow label={member.username}>
                                                                                        <Avatar name={member.username} size='sm' />
                                                                                    </Tooltip>
                                                                                </Box>
                                                                            ))}
                                                                        </AvatarGroup>
                                                                    ) : null}
                                                                    {status ? (
                                                                        <Badge
                                                                            fontSize="10px"
                                                                            fontWeight="bold"
                                                                            variant="solid"
                                                                            h="28px"
                                                                            w="85px"
                                                                            ms="auto"
                                                                            display="flex"
                                                                            p="8px"
                                                                            borderRadius="8px"
                                                                            alignItems="center"
                                                                            justifyContent="center"
                                                                            bg={
                                                                                status === "Owner"
                                                                                    ? "red.500"
                                                                                    : status === "Participant"
                                                                                        ? "blue.300"
                                                                                        : status === "DONE"
                                                                                            ? "green.500"
                                                                                            : status === "UPDATES"
                                                                                                ? "blue.400"
                                                                                                : "teal"
                                                                            }
                                                                            colorScheme={
                                                                                status === "Owner"
                                                                                    ? "red"
                                                                                    : status === "Participant"
                                                                                        ? "orange"
                                                                                        : status === "DONE"
                                                                                            ? "green"
                                                                                            : status === "UPDATES"
                                                                                                ? "blue"
                                                                                                : "teal"
                                                                            }
                                                                        >
                                                                            <Text fontSize={12}>{status}</Text>
                                                                        </Badge>
                                                                    ) : null}
                                                                </Flex>
                                                            )}
                                                        </Flex>
                                                    );
                                                })}
                                            </Box>
                                        </Scrollbars>
                                    )}

                                </Flex>

                            );
                        })}


                </Flex>
            )}

            {/* <Modal
                isOpen={projectSettingsSidebar.isOpen}
                modalHead="Project Settings"
                onClose={projectSettingsSidebar.onClose}
                maxWidth="80vw"
                children={<ProjectSettings project={selectedProject} {...sideBarContent} />}
            /> */}
            <CustomSideBar isOpen={sideBarContent} onOpen={setSideBarContent}>
                {sideBarContent?.target === "project" && (
                    <ProjectSettings
                        {...sideBarContent}
                        onCloseProject={setSideBarContent}
                    />
                )}
            </CustomSideBar>
        </>
    );
}

export default ProjectDash;
