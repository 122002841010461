import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Box, Grid, Text, useDisclosure } from "@chakra-ui/react";
import { FiSend } from "react-icons/fi";
import { AiFillDelete, AiOutlinePlus } from "react-icons/ai";

import DataTable from "components/DataTable";
import { Button } from "components/misc/Buttons";
import { getCanvasDetails, getLoadDesign } from "store/selector";
import {
    createRowLoadDesign,
    loadLoadDesign,
    removeRowLoadDesign,
    saveLoadDesign,
    updateLoadDesign,
} from "store/slices/entities/loadDesign";
import { ColumnObj } from "components/project/utils/tableColumns";
import { BiDownload, BiRefresh } from "react-icons/bi";
import { useHistory } from "react-router-dom";
import { loadDesign } from "utils/dataFormat";
import { colors } from "components/misc/colors";
import { CloseIcon } from "@chakra-ui/icons";
import { Toast } from "utils/Toast";
import Modal from "components/common/Modal";
import { loadFileDetails, } from "store/slices/entities/projectCanvas";
import AGGridTable from "components/tests/DataTable";


/*
Author: Emmanuel Martin
Task: Design Summary for Column Calculation
Description:
Tag: Column, Design Summary, onSave
*/


import WarningInformation from "../../../../tests/DataTable/WarningCellRenderer";

const statusColors = {
    "Not Acceptable": "red",
    "Not Designed": "blue",
    "Pass": "green",
    "Failed": "red",
    "Warning": "orange"
};

const status = [
    { "status": "Not Acceptable" },
    { "status": "Pass" },
    { "status": "Warning" },
    { "status": "Failed" }
];

const StatusRenderer = (params) => {
    const status = params.value;
    const color = statusColors[status] || "gray";
    return <Text color={color}>{status}</Text>
}

function Load({ link }) {
    const { info, loading } = useSelector(getLoadDesign);
    const { selectedFile } = useSelector(getCanvasDetails);
    const [selectedRows, setSelectedRows] = useState([]);
    const columns = [
        { header: "No", field: "No" },
        { header: "Load No.", field: "load_no" },
        { header: "KN", field: "n" },
        { header: "KN.", field: "t" },
        { header: "KN", field: "vz" },
        { header: "KN.", field: "vy" },

        { header: "KN-m", field: "my_top" },
        { header: "KN-m.", field: "mz_top" },
        { header: "KN-m", field: "my_bottom" },
        { header: "KN-m", field: "mz_bottom" },
        { header: "Sustained load %", field: "bdns" },
        { header: "Sustained load (Bds) %", field: "bds" },

        { header: "Ds", field: "delta_y" },
        { header: "Cm", field: "cm_y" },
        { header: "Dns", field: "mom_y" },
        { header: "Mu", field: "delta_s_y" },

        { header: "Mmin(top)", field: "mc_top_y" },
        { header: "Mmin(bottom)", field: "mc_bottom_y" },
        { header: "Ds", field: "delta_z" },
        { header: "Cm", field: "cm_z" },

        { header: "Dns", field: "mom_z" },
        { header: "Mu", field: "delta_s_z" },
        { header: "Mmin(top)", field: "mc_top_z" },
        { header: "Mmin(bottom)", field: "mc_bottom_z" },

        { header: "P-M-M Ratio", field: "pmm" },
        { header: "Vz/ ØVn'z", field: "vy_phivny" },
        { header: "Vy/ØVn'y", field: "vz_phivnz" },
        { header: "T/ØTn", field: "t_tn" },

        { header: "Tch", field: "tch" },
        { header: "Remarks", field: "remark" },
    ];
    const columnGroup = ColumnObj["Load Design"]();

    useEffect(() => {
        loadLoadDesign(link);
    }, []);

    const onChange = ({ target: { value, name, id } }) => {
        const updatedValue = info?.load_rows.map((item) => {
            if (item?.url === id) {
                const obj = { ...item };
                obj[name] = value;
                obj["updated"] = true;
                return obj;
            } else {
                return item;
            }
        });


        updateLoadDesign(updatedValue);
    };

    const headers = [
        {
            headerName: "",
            colId: 'checkbox',
            checkboxSelection: true,
            suppressHeaderMenuButton: true,
            headerCheckboxSelection: true,
            maxWidth: 50
        },
        {
            headerName: "url",
            field: "url",
            hide: true
        },
        {
            headerName: "row",
            field: "row",
            hide: true
        },
        { headerName: "No", field: "No", valueGetter: params => params.node.rowIndex + 1, maxWidth: 50 },
        {
            headerName: "Load No", field: "load_no", valueGetter: params => params.node.rowIndex + 1, maxWidth: 90, editable: true,
            cellClass: ["cell-editable"],
            valueGetter: params => {
                return params.data.load_no;
            },
            valueSetter: params => {
                params.data.load_no = params.newValue;
                return true
            }
        },

        { headerName: "n (kN)", field: "n", maxWidth: 90, cellClass: ["cell-editable"], editable: true },
        {
            headerName: "T (kN-m)", field: "t", maxWidth: 100, cellClass: ["cell-editable"],
            valueGetter: params => {
                return parseFloat(params.data.t);
            },
            valueSetter: params => {
                const newValue = parseFloat(params.newValue);

                if (!isNaN(newValue)) {
                    params.data.t = newValue;
                    return true;
                } else {
                    console.error("Invalid input:", params.newValue);
                    return false;
                }
            },
            editable: true
        },
        { headerName: "Vz (kN)", field: "vz", maxWidth: 90, cellClass: ["cell-editable"], editable: true },
        { headerName: "Vy (kN)", field: "vy", maxWidth: 90, cellClass: ["cell-editable"], editable: true },

        {
            headerName: "Top",
            marryChildren: true,
            class: "ag-header-group-cell",
            children: [
                { headerName: "My (KN)", field: "my_top", maxWidth: 90, cellClass: ["cell-editable"], editable: true },
                { headerName: "Mz (KN)", field: "mz_top", maxWidth: 90, cellClass: ["cell-editable"], editable: true },
            ]
        },

        {
            headerName: "Bottom",
            marryChildren: true,
            class: "ag-header-group-cell",
            children: [
                { headerName: "My (KN)", field: "my_bottom", maxWidth: 90, cellClass: ["cell-editable"], editable: true },
                { headerName: "Mz (KN)", field: "mz_bottom", maxWidth: 90, cellClass: ["cell-editable"], editable: true },
            ]
        },

        { headerName: "(Bds) %", field: "bdns", maxWidth: 90, cellClass: ["cell-editable"], editable: true },
        { headerName: "(Bds) %", field: "bds", maxWidth: 90, cellClass: ["cell-editable"], editable: true },

        {
            headerName: "Z Axis",
            marryChildren: true,
            class: "ag-header-group-cell",
            children: [
                { headerName: "Ds", field: "delta_z", maxWidth: 90 },
                { headerName: "Cm", field: "cm_z", maxWidth: 90 },
                { headerName: "Dns", field: "delta_s_z", maxWidth: 90 },
                { headerName: "Mmin", field: "mom_z", maxWidth: 90 },
                { headerName: "Mu(top)", field: "mc_top_z", maxWidth: 140 },
                { headerName: "Mu(bottom)", field: "mc_bottom_z", maxWidth: 140 },
            ]
        },

        {
            headerName: "Y Axis",
            marryChildren: true,
            class: "ag-header-group-cell",
            children: [
                { headerName: "Ds", field: "delta_y", maxWidth: 90 },
                { headerName: "Cm", field: "cm_y", maxWidth: 90 },
                { headerName: "Dns", field: "delta_s_y", maxWidth: 90 },
                { headerName: "Mmin", field: "mom_y", maxWidth: 90 },
                { headerName: "Mu(top)", field: "mc_top_y", maxWidth: 140 },
                { headerName: "Mu(bottom)", field: "mc_bottom_y", maxWidth: 140 },
            ],
        },

        { headerName: "P-M-M Ratio", field: "pmm", maxWidth: 120 },
        { headerName: "Vz/ ØVn'z", field: "vy_phivny", maxWidth: 120 },
        { headerName: "Vy/ØVn'y", field: "vz_phivnz", maxWidth: 90 },
        {
            headerName: "T/ØTn", field: "t_tn",
            cellRenderer: params => params.data.t_tn === 0 ? '0' : params.data.t_tn,
            maxWidth: 90
        },

        { headerName: "Tch", field: "tch", maxWidth: 90 },
        { headerName: "Status", field: "status", maxWidth: 120, cellRenderer: StatusRenderer },
        { headerName: "Warnings", field: "warning", maxWidth: 160, cellRenderer: WarningInformation },
        { headerName: "Remarks", field: "remark", maxWidth: 120 },

    ];

    const { isOpen, onClose: modalClose, onOpen } = useDisclosure();

    const onDelete = () => {
        if (selectedRows?.length === 0)
            return Toast("No rows has selected!", "warning");
        const deleteIds = selectedRows.map((item) => {
            const urlSplit = item.url.split("/");
            return urlSplit[urlSplit.length - 2];
        });

        removeRowLoadDesign(deleteIds);
        modalClose(true);
        setSelectedRows([]);
    };

    const onSubmit = () => {
        const modifiedItems = info.load_rows?.filter((item) => item.updated);
        const oldValues = { ...info };
        oldValues["load_rows"] = modifiedItems;
        if (modifiedItems?.length === 0)
            return Toast("There is no changes found!", "warning");
        saveLoadDesign(oldValues, info?.url, link);
    };

    const buttons = [
        {
            text: "New",
            icon: <AiOutlinePlus />,
            onClick: () => createRowLoadDesign(info?.url),
            color: colors.add,
        },
        {
            text: "Download",
            icon: <BiDownload />,
            onClick: () => window.open(`${info?.url}download/`, "_blank"),
            color: colors.download,
        },
        {
            text: "Delete",
            icon: <AiFillDelete />,
            onClick: onOpen,
            color: colors.delete,
        },
        {
            text: "Submit",
            icon: <FiSend />,
            onClick: onSubmit,
            color: colors.submit,
        },
        {
            text: "Refresh",
            icon: <BiRefresh />,
            onClick: () => loadLoadDesign(link),
            color: colors.refresh,
        },
    ];

    return (
        <>
            <Box
                minH="20vh"
                p={"1rem"}
                // mt="6rem"
                borderRadius={8}
                bgColor={"gray.100"}
                overflow={"auto"}
            >
                <Box
                    maxH={"35rem"}
                    overflow={"auto"}
                >
                    <AGGridTable link={link} headers={headers} representation={"column"} />
                </Box>
            </Box>
            <Modal
                isOpen={isOpen}
                modalHead="Confirm"
                children={<Text>Are you sure you want to delete these rows ?</Text>}
                onClose={modalClose}
                onProcced={onDelete}
                confirmText="Delete"
            />
        </>
    );
}

export default Load;
