import React, { useState } from "react";
import { Portal, Box, useDisclosure } from "@chakra-ui/react";
import { ChakraProvider } from "@chakra-ui/react";
import { Redirect, Route, Switch } from "react-router-dom";
import "primereact/resources/themes/lara-light-indigo/theme.css";  
import "primereact/resources/primereact.min.css";                 
import "primeicons/primeicons.css";                               

import {adminRoutes} from "router/routes";
import theme from "styles/theme/theme";
import Navbar from "components/navbar/Navbar.js";
import Sidebar from "components/sidebar/Sidebar.js";
import { SidebarContext } from "contexts/SidebarContext";
import { useEffect } from "react";
import { userProfile } from "store/slices/auth/user";
import { useSelector } from "react-redux";
import { getAuth } from "store/selector";

function AdminDashboard(props) {
  const { ...rest } = props;
  const routes = adminRoutes;
  const [fixed] = useState(false);
  const [toggleSidebar, setToggleSidebar] = useState(false);
  const {profile} = useSelector(getAuth)
  
  const getRoute = () => {
    return window.location.pathname !== "/admin/full-screen-maps";
  };
  
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      return (
        <Route
          path={prop.layout + prop.path}
          component={prop.component}
          key={key}
        />
      );
    });
  };
  document.documentElement.dir = "ltr";
  const { onOpen } = useDisclosure();

  useEffect(()=>{
    !profile?.username && userProfile();
  },[])


  return (
    <ChakraProvider theme={theme}>
      <Box>
        <SidebarContext.Provider
          value={{
            toggleSidebar,
            setToggleSidebar,
          }}
        >
          <Sidebar routes={routes} display="none" {...rest} />
          <Box
            float="right"
            minHeight="100vh"
            height="100%"
            overflow="auto"
            position="relative"
            maxHeight="100%"
            w={{ base: "100%", xl: "100%" }}
            maxWidth={{ base: "100%", xl: "96%" }}
            transition="all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)"
            transitionDuration=".2s, .2s, .35s"
            transitionProperty="top, bottom, width"
            transitionTimingFunction="linear, linear, ease"
          >
            <Portal>
              <Box>
                <Navbar
                  onOpen={onOpen}
                  logoText={"TEDAPS"}
                  fixed={fixed}
                  {...rest}
                />
              </Box>
            </Portal>
            {getRoute() ? (
              <Box
                mx="auto"
                p={{ base: "20px", md: "30px" }}
                minH="100vh"
                pt="50px"
              >
                <Switch>
                  {getRoutes(routes)}
                  
                </Switch>
              </Box>
            ) : null}
          </Box>
        </SidebarContext.Provider>
      </Box>
    </ChakraProvider>
  );
}

export default AdminDashboard;
