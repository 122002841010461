import { combineReducers } from "redux";

import projectReducer from "store/slices/entities/projects";
import calculationReducer from "store/slices/entities/calculations";
import memberReducer from "store/slices/entities/members";
import canvasReducer from "store/slices/entities/projectCanvas";
import rebarReducer from "store/slices/entities/rebar";
import loadDesignReducer from "store/slices/entities/loadDesign";
import endConditionReducer from "store/slices/entities/endCondition";
import settingsReducer from "store/slices/entities/settings";
import graphReducer from "store/slices/entities/loadGraph";
import geometryReducer from "store/slices/entities/loadGeometry";

export default combineReducers({
  projects: projectReducer,
  calculations: calculationReducer,
  members: memberReducer,
  projectCanvas: canvasReducer,
  rebars: rebarReducer,
  loadDesign: loadDesignReducer,
  loadGraph: graphReducer,
  loadGeometry: geometryReducer,
  endCondition: endConditionReducer,
  settings: settingsReducer,
});
