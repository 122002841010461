import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import {
    SectionHeading,
    Subheading as SubheadingBase,
} from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";

import { ChakraProvider } from "@chakra-ui/react";
import { CustomSideBar } from "components/sidebar/Sidebar";
import { useState } from "react";
import SidebarContent from "components/landingpage/SidebarContent";
import Reinforced from "../../images/r1.png";
import Steel from "../../images/r2.png";
import Composite from "../../images/r3.png";
import Masonry from "../../images/r4.png";
import Load from "../../images/r5.png";
import Quick from "../../images/r6.png";

const Container = tw.div`relative`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-lg mx-auto py-0 md:py-4 `}
`;
const Subheading = tw(
    SubheadingBase
)`mb-4 text-xl md:text-2xl text-other-subtitle`;
const Heading = tw(
    SectionHeading
)`w-full text-2xl text-other-title leading-snug font-bold   xl:text-4xl `;
const Description = tw(SectionDescription)`w-full text-center text-lg max-w-lg`;

const VerticalSpacer = tw.div`mt-10 w-full`;

const Column = styled.div`
  ${tw`grid grid-cols-1 sm:grid-cols-2 sm:place-items-center`}
`;
const CardContainer = tw.div`m-4 `;

const Card = styled.div`
  ${tw`flex flex-col sm:flex-row bg-white min-h-48 shadow-xl items-center sm:items-start text-center rounded-2xl  sm:text-left h-full  px-4 py-6`}
  .imageContainer {
    ${tw`border text-center  text-blue-300 shadow-xl rounded-full p-2 flex-shrink-0`}
    img {
      ${tw`w-12 h-12  `}
    }
  }

  .textContainer {
    ${tw`sm:ml-4 mt-4 sm:mt-2`}
  }

  .title {
    ${tw`mt-4 tracking-wide text-other-title font-bold text-2xl leading-none cursor-pointer`}
  }

  .description {
    ${tw`mt-1 sm:mt-4  font-medium text-secondary-100 leading-loose`}
  }
`;

function Services({
    cards = null,
    heading = "TEDAPS  Main Calculations",
    subheading = "Resources",
    description = "Structural Engineering",
}) {
    const defaultCards = [
        {
            iconSrc: Reinforced,
            title: "Reinforced Concrete Design",
            desc: "Calculation modules for the design of reinforced concrete elements, to ACI 318-14.",
        },
        {
            iconSrc: Steel,
            title: "Structural Steel design",
            desc: "Calculation modules for the design of Structural steel members and connections to AISC 360-16.",
        },
        {
            iconSrc: Composite,
            title: "Composite Member Design",
            desc: "Calculation modules for the design of Reinforced Concrete and Steel composite members to ACI 318 -14 and AISC 360-16.",
        },
        {
            iconSrc: Masonry,
            title: "Masonry Design",
            desc: "Calculation modules for the design of masonry structures TMS 402/602.",
        },
        {
            iconSrc: Load,
            title: "Load Calculations",
            desc: "Gravity and lateral load calculation to various codes and reference standards.",
        },
        {
            iconSrc: Quick,
            title: "Quick Reference Tables",
            desc: "Tables of data from various codes and standards for easy reference.",
        },
    ];

    if (!cards) cards = defaultCards;

    const [sidebarObj, setSideBarObj] = useState(false);
    return (
        <div
            style={{
                background: "rgb(212,231,254)",
                background:
                    "radial-gradient(circle, rgba(212,231,254,1) 0%, rgba(244,249,255,1) 74%, rgba(255,255,255,1) 100%)",
            }}
        >
            <ChakraProvider>
                <Container>
                    <ThreeColumnContainer>
                        {subheading && <Subheading>{subheading}</Subheading>}
                        <Heading>{heading}</Heading>
                        {description && <Description>{description}</Description>}
                        <VerticalSpacer />
                        <Column>
                            {cards.map((card, i) => (
                                <CardContainer key={i}>
                                    <Card>
                                        <span className="imageContainer">
                                            <img src={card.iconSrc} alt="" />
                                        </span>
                                        <span className="textContainer">
                                            <span
                                                className="title"
                                                onClick={() => setSideBarObj(card)}
                                            >
                                                {card.title}
                                            </span>
                                            <p className="description">{card?.desc}</p>
                                        </span>
                                    </Card>
                                </CardContainer>
                            ))}
                        </Column>
                    </ThreeColumnContainer>
                </Container>
                <CustomSideBar isOpen={sidebarObj} onOpen={setSideBarObj}>
                    <SidebarContent title={sidebarObj?.title} />
                </CustomSideBar>
            </ChakraProvider>
        </div>
    );
}

export default Services;
