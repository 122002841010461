import routes from "api/routes";

export const MessageObj = {
    [routes.auth.login]: {
        post: {
            200: 'User has logged in successfully',
            401: 'No active account found with the given credentials'
        }
    },
    [routes.project.baseUrl]: {
        get: {
            200: "",
            500: "Projects is not found",
        },
        post: {
            201: "Project has created successfully",
            500: "Something went wrong. while creating new project",
        },
        put: {
            200: "Project has updated",
            500: "Something went wrong. while updating  project",
        },
        delete: {
            204: 'Project has removed',
            undefined: 'Project is not removed'
        }
    },

    [routes.project.file]: {
        post: {
            201: "Calculation file has added on project successfully",
            500: "Something went wrong. while adding file",
            undefined: 'Something went wrong'
        },
        put: {
            200: "Project file has updated",
            500: "Something went wrong. while updating file",
        },
        delete: {
            204: 'File has removed successfully',
            500: "Something went wrong. while deleting the file",
        }
    },
    [routes.canvas.baseURl]: {
        get: {
            // 200: "Latest rows  has  received",
            500: "Something went wrong, while receiving file rows",
        },
    },
    [routes.canvas.addRow]: {
        get: {
            201: "New row has created successfully",
            500: 'Something went wrong,while adding new row'
        },
    },
    [routes.canvas.removeRow]: {
        delete: {
            200: "Selected row has removed",
            500: "Something went wrong. while deleting rows",
        },
    },
    [routes.canvas.updateRow]: {
        put: {
            202: "Row details has updated",
            500: "Something went wrong. while updating row",
        },
    },
    [routes.canvas.rebarRow]: {
        put: {
            202: "Rebar settings details has saved",
            500: "Something went wrong. while updating row",
        },
        delete: {
            204: 'Rebar settings rows has removed successfully',
            500: "Something went wrong. while deleting row",
        },

    },
    [routes.canvas.deleteRebarRow]: {
        delete: {
            200: "Rebar row has removed successfully",
        },
    },
    [routes.project.adduser]: {
        post: {
            202: "New user has added successfully",
            500: "Something went wrong. while adding new user",
        },
        delete: {
            204: "User has removed successfully",
            500: "Something went wrong. while deleting the user",
        },
    },
    [routes.settings.projectMaterialBulkUpdate]: {
        put: {
            202: "Material properties has updated successfully",
            500: "Something went wrong. while updating material properties row",
        },
    },
    [routes.canvas.endRow]: {
        put: {
            200: "End Condition details has saved",
            500: "Something went wrong. while updating end condition",
        },

    },
    [routes.canvas.loadRow]: {
        put: {
            202: "Load & design details has saved",
            500: "Something went wrong. while updating load & design details",
        },
    },
    [routes.settings.projectDetails]: {
        get: {
            200: "Project setting details has received",
            500: "Something went wrong. while receiving project details",
        },
        put: {
            200: 'Project setting has updated successfully',
            500: 'Project settings update failed'
        }
    },
    [routes.settings.projectMaterial]: {
        get: {
            200: "",
        },
        post: {
            201: ' New material property row has created successfully',
            500: "Something went wrong. while adding new material properties row",
        },
        delete: {
            204: 'Material row has removed successfully',
            500: "Something went wrong. while deleting  row",
        },
        put: {
            202: 'Material property details has updated successfully'
        }
    },
    [routes.canvas.addLoadDesignRow]: {
        post: {
            201: 'New row has created on load design',
            200: '',
            500: "Something went wrong. while adding new  row",
        }
    },
    [routes.settings.projectUnlock]: {
        get: {
            202: 'Project settings has unlocked successfully'
        }
    },
    [routes.canvas.load_design_remove_row]: {
        get: {
            200: ''
        },
        delete: {
            200: 'Load design row has removed successfully'
        }
    },
    [routes.auth.profile]: {
        get: {
            //   200:'User logged in successfully',
            500: "User has not authenticated!",
        },

    },
};
