import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Box, Grid, Text, useDisclosure } from "@chakra-ui/react";
import { ColumnGroup } from "primereact/columngroup";
import { Column } from "primereact/column";
import { Row } from "primereact/row";
import { FiSend } from "react-icons/fi";
import { AiFillDelete, AiOutlinePlus } from "react-icons/ai";

import DataTable from "components/DataTable";
import { Button } from "components/misc/Buttons";
import { getRebars } from "store/selector";
import {
  addNewRebarRow,
  deleteRebarRow,
  loadRebars,
  reorderRebar,
  submitRebarDetails,
  updateRebarRows,
} from "store/slices/entities/rebar";
import { Toast } from "utils/Toast";
import { colors } from "components/misc/colors";
import { CloseIcon } from "@chakra-ui/icons";
import Modal from "components/common/Modal";

function RebarSettings({ link, onClose }) {
  const { rebarLists, loading } = useSelector(getRebars);
  const [selectedRows, setSelectedRows] = useState([]);

  const onChange = ({ target: { value, name, id } }) => {
    const latestUpdates = rebarLists.rebar_rows?.filter(
      (row) => row.id === parseInt(id)
    );
    const latest = latestUpdates.length > 0 && { ...latestUpdates[0] };
    latest[name] = value;
    updateRebarRows(latest);
  };

  const onReorder = (e) => reorderRebar(e.value);

  const onSubmit = () => {
    submitRebarDetails(rebarLists, rebarLists?.url);
  };

  const onAddRow = () => {
    const noLIsts = rebarLists?.rebar_rows.map((item) => item.no);
    const largestNo = Math.max.apply(null, noLIsts);

    const idLIsts = rebarLists?.rebar_rows.map((item) => item.id);
    const largestId = Math.max.apply(null, idLIsts);

    const newRow = {
      no: largestNo != "-Infinity" ? largestNo + 1 : 1,
      id:largestId != "-Infinity" ? largestId + 1 : 1,
      x: 0,
      y: 0,
      dia: 0,
      row: 0,
    };

    addNewRebarRow(newRow);
  };

  const {isOpen,onClose:modalClose,onOpen} = useDisclosure()


  const onDelete = () => {
    if (selectedRows.length == 0)
      return Toast("There is no items selected!", "warning");
    const rowIds = selectedRows?.map((row) => row.id);

    deleteRebarRow(rowIds, rebarLists?.rebar_rows[0].row);
    setSelectedRows([]);
    modalClose(true)
  };

  useEffect(() => {
    loadRebars(link);
  }, []);

  const buttons = [
    {
      text: "New",
      icon: <AiOutlinePlus />,
      onClick: onAddRow,
      color: colors.add,
    },
    {
      text: "Delete",
      icon: <AiFillDelete />,
      onClick: onOpen,
      color: colors.delete,
      disabled: rebarLists?.rebar_rows?.length === 4 ? true : false,
    },
    {
      text: "Submit",
      icon: <FiSend />,
      onClick: onSubmit,
      color: colors.submit,
    },
  ];

  const columns = [
    { header: "No", field: "no" },
    { header: "X(mm)", field: "x" },
    { header: "Y(mm)", field: "y" },
    { header: "Rebar Diameter", field: "dia" },
    { header: "Distance to adjacent Rebar (mm)", field: "nearest" },
  ];

  const columnGroup = (
    <ColumnGroup>
      <Row>
        <Column rowSpan={2} colSpan={1} />
        <Column header="Order" rowSpan={2} field="order" />
        <Column header="No" rowSpan={2} field="no" />
        <Column header="Coordinates" colSpan={2} />
        <Column header="Diameter" rowSpan={2} />
        <Column header="Distance to adjacent Rebar (mm)" rowSpan={2} />
      </Row>
      <Row>
        <Column header="X(mm)" field="x" />
        <Column header="Y(mm)" field="y" />
      </Row>
    </ColumnGroup>
  );


  return (
    <>
      <Box
        minH="20vh"
        p={"1rem"}
        borderRadius={15}
        bgColor={"gray.300"}
        marginTop="6rem"
      >
        <Box>
          <Grid
            gap={3}
            display={"flex"}
            justifyContent={{ sm: "center" }}
            position="relative"
            flexWrap={{ sm: "wrap", lg: "nowrap" }}
          >
            {buttons.map((btn, i) => (
              <Button
                size={"lg"}
                colorScheme={btn.color}
                textColor={btn.color}
                borderColor={colors.buttonBG}
                disabled={btn?.disabled}
                key={i}
                onClick={btn.onClick}
                leftIcon={btn.icon}
              >
                {btn.text}
              </Button>
            ))}

            <Button
              size={"sm"}
              position={"absolute"}
              left={"93%"}
              colorScheme={colors.delete}
              textColor={colors.delete}
              borderColor={colors.buttonBG}
              iconSpacing={0}
              onClick={onClose}
              leftIcon={<CloseIcon />}
            />
          </Grid>
        </Box>
        <Box
          p={{ sm: "1rem", lg: "0" }}
          mt={"1rem"}
          maxH={"35rem"}
          overflow={"auto"}
        >
          <DataTable
            editable
            selection
            rowReorder
            reorderableColumns
            selectionMode="checkbox"
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
            onChange={onChange}
            tableValue={rebarLists?.rebar_rows}
            columns={columns}
            loading={loading}
            header={"Rebar Layout"}
            onRowReorder={onReorder}
            columnGroup={columnGroup}
          />
        </Box>
      </Box>
      <Modal
        isOpen={isOpen}
        modalHead="Confirm"
        children={<Text>Are you sure you want to delete these rows ?</Text>}
        onClose={modalClose}
        onProcced={onDelete}
        confirmText="Delete"
      />
    </>
  );
}

export default RebarSettings;
