import React from "react";
import { DefaultField, GenerateField } from "./fields";

function EditMode(props) {
    const { obj, col, value, setEdit, edit, onChange, formatObj } = props;


    const reference = col?.field
        ? col.field
        : col == "c1"
            ? ""
            : obj[`${col}_reference`];


    const fieldObj = formatObj[obj?.endSelect || reference]
    const isEditable = ["input", "select"].includes(fieldObj?.editor) && true;

    return (
        <React.Fragment>
            {isEditable ? (
                edit?.name === reference &&
                    edit?.rowId === (obj?.head || obj?.id || obj?.no || obj?.url) ? (
                    <GenerateField
                        fieldObj={fieldObj}
                        onChange={onChange}
                        name={reference}
                        value={value}
                        setEdit={setEdit}
                        edit={edit}
                        id={obj?.head || obj?.id || obj?.no || obj?.url}
                        type={fieldObj?.editor}
                    />
                ) : col == "c1" ? (
                    <DefaultField value={value} />
                ) : (
                    <DefaultField
                        onClick={setEdit}
                        color={isEditable && "blue.300"}
                        name={reference}
                        rowId={obj?.id || obj?.no || obj?.url || obj?.head}
                        value={!["", null].includes(value) ? value : "nil"}
                    />
                )
            ) : (
                <DefaultField value={value} />
            )}
        </React.Fragment>
    );
}

export default EditMode;
