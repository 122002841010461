import routes from "api/routes";
import { apiCallFailed, apiCallSuccess } from "store/api";
import { statusCode } from "utils/constant";
import { getMatchUrl } from "utils/formatter";
import { MessageObj } from "utils/messageFormater";
import { Toast } from "utils/Toast";

const toast = (store) => (next) => (action) => {
    if (![apiCallSuccess.type, apiCallFailed.type].includes(action.type))
        return next(action);

    next(action);

    const {
        status,
        code,
        config,
        message: MSG,
    } = action.payload;

    const ignoreUrls = [
        routes.calculation.listOfCalculations,
        routes.member.listOfMembers,
    ];

    if (ignoreUrls.includes(config?.url)) return;

    const matchUrl = getMatchUrl(config?.url);

    // console.log(action,'status error');

    // console.log(config?.url, config?.method, config?.status, "message store");

    const messagType =
        statusCode[
        status ||
        config?.status || (500)
        ];



    try {
        const message = code === ""
            ? "Network Error.Please check your internet connection"
            : status === false
                ? MSG
                : MessageObj[matchUrl][config?.method][config?.status || status];

        message && Toast(message, messagType);
    } catch (error) {
        // console.log(error,'message error');
    }
};

export default toast;
