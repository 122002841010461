import { createSlice } from "@reduxjs/toolkit";
import routes from "api/routes";
import { apiCallBegin } from "store/api";
// import store from "store/store";

const slice = createSlice({
    name: "projects",
    initialState: {
        list: [],
        loading: false,
        nonAllocatedUsers: [],
    },
    reducers: {
        requested: (projects) => {
            projects.loading = true;
        },
        projectReceived: (projects, action) => {
            projects.list = action.payload;
            projects.loading = false;
        },
        projectCreated: (projects, action) => {
            projects.list.push(action.payload);
        },
        projectUpdated: (projects, action) => {
            const updateValue = { ...projects };
            updateValue.list = projects.list.map((project) =>
                project.id === action.payload.id
                    ? { ...project, ...action.payload }
                    : project
            );
            return updateValue;
        },
        projectFileDeleted: (projects, action) => {
            const { previous } = action

            const updatedLists = projects.list.map(project => {
                if (project?.id === previous?.projectID) {
                    const projectObj = { ...project }
                    const fileLists = projectObj.userfile_set.filter(file => file.id !== previous?.fileId)
                    projectObj['userfile_set'] = fileLists
                    return projectObj
                } else {
                    return project
                }
            })

            projects.list = updatedLists
        },
        projectRemoved: (projects, action) => {
            const projectList = projects.list?.filter(project => project?.id !== action.previous?.projectID)
            projects.list = projectList
        },
        failed: (projects) => {
            projects.loading = false;
        },
        nonAllocatedUserAdded: (project, action) => {
            project.nonAllocatedUsers = action.payload
        },
        newUserAdded: (project, action) => {
            const projects = project.list.map(project => {
                if (project.id === action?.previous[0]?.projectId) {
                    const projectInfo = { ...project }
                    projectInfo['users'] = action.payload
                    return projectInfo
                } else {
                    return project
                }
            })

            project.list = projects
            project.loading = false
        },

    },
});

const {
    projectReceived,
    failed,
    requested,
    projectCreated,
    projectUpdated,
    projectFileDeleted,
    projectRemoved,
    nonAllocatedUserAdded,
    newUserAdded
} = slice.actions;
export default slice.reducer;

//action creators

export const loadProjects = () => {
    return apiCallBegin({
        url: routes.project.baseUrl,
        method: "get",
        onStart: requested.type,
        onSuccess: projectReceived.type,
        onError: failed.type,
    });
};

export const createProject = (newProject) => {
    return apiCallBegin({
        url: routes.project.baseUrl,
        method: "post",
        formData: newProject,
        onSuccess: projectCreated.type,
    });
};

export const projectUpdate = (updatObj) => {
    return apiCallBegin({
        url: `${routes.project.baseUrl + updatObj.id}/`,
        method: "put",
        formData: updatObj,
        onSuccess: projectUpdated.type,
    });
};

export const deleteFile = (fileId, projectID) => {
    return apiCallBegin({
        url: `${routes.project.file + fileId}/`,
        method: "delete",
        onSuccess: projectFileDeleted.type,
        jsonData: { fileId, projectID }
    });
};

export const removeProject = (projectID) => {
    return apiCallBegin({
        url: `${routes.project.baseUrl + projectID}/`,
        method: "delete",
        onSuccess: projectRemoved.type,
        jsonData: { projectID }
    });
};

export const assignFile = (fileObj) => {
    return apiCallBegin({
        url: routes.project.file,
        method: "post",
        formData: fileObj,
    });
};

export const editProjectFile = (fileId, name) => {
    return apiCallBegin({
        url: `${routes.project.file}${fileId}/`,
        method: 'put',
        jsonData: { name },
    })

}

export const getNonAllocatedUsers = (projectId) => apiCallBegin({
    url: `${routes.project.baseUrl}${projectId}/non_users/`,
    onSuccess: nonAllocatedUserAdded.type
})

export const addNewUser = (projectId, userID) => apiCallBegin({
    url: `${routes.project.baseUrl}${projectId}/users/`,
    method: 'post',
    jsonData: { "pks": userID, projectId },
    onSuccess: newUserAdded.type,
    onStart: requested.type,
    onError: failed.type,
})

export const deleteUser = (projectId, userID) => apiCallBegin({
    url: `${routes.project.baseUrl}${projectId}/users/`,
    method: 'delete',
    deleteData: [parseInt(userID)],
})