import { createSlice } from "@reduxjs/toolkit";
import routes from "api/routes";
import { apiCallBegin } from "store/api";
import store from "store/store";

const slice = createSlice({
    name: "settings",
    initialState: {
        loading: false,
        projectDetails: {},
        projectLists: [],
        projectMaterials: [],
        unlockedProjects: [],
    },
    reducers: {
        detailsRequested: (settings) => {
            settings.loading = true;
        },
        projectDetailsReceived: (settings, action) => {
            settings.projectDetails = action.payload[0];
            settings.loading = false;
        },
        projectDetailsUpdated: (settings, action) => {
            settings.projectDetails = action.payload;
        },
        detailsRequestFailed: (settings) => {
            settings.loading = false;
        },
        projectListReceived: (settings, action) => {
            settings.projectLists = action.payload;
        },
        projectMaterialsAdded: (settings, action) => {
            const latestMaterial = [...settings.projectMaterials];
            latestMaterial.push(action.payload);
            settings.projectMaterials = latestMaterial;
        },
        projectMaterialsReceived: (settings, action) => {
            settings.projectMaterials = action.payload;
            settings.loading = false;
        },

        materialPropertieUpdated: (settings, action) => {
            settings.projectMaterials = action.payload.details;
        },
        materialRowRemoved: (settings, action) => {
            const deletedRows = action.previous?.ids
            const newRows = settings.projectMaterials?.filter((row) =>
                !deletedRows.includes(row?.id)
            );
            settings.projectMaterials = newRows;
        },
        settingsUnlocked: (settings, action) => {
            const projects = [...settings.unlockedProjects];
            projects.push(action.previous.projectID);
            settings.unlockedProjects = projects;
        },
        materialDetailsSaved: (settings, action) => {
            const updateLists = settings.projectMaterials?.map(previous => {
                const updatedValue = action.payload?.filter(update => previous.id === update.id)
                return updatedValue?.length > 0 ? updatedValue[0] : previous
            })
            settings.projectMaterials = updateLists
        }
    },
});

const {
    projectDetailsReceived,
    detailsRequestFailed,
    detailsRequested,
    projectListReceived,
    projectMaterialsReceived,
    projectDetailsUpdated,
    materialPropertieUpdated,
    projectMaterialsAdded,
    materialRowRemoved,
    settingsUnlocked,
    materialDetailsSaved
} = slice.actions;
export default slice.reducer;

export const loadProjectMaterials = (projectID) => {
    store.dispatch(
        apiCallBegin({
            url: routes.settings.projectMaterial + "?projectsetting_id=" + projectID,
            onSuccess: projectMaterialsReceived.type,
            onStart: detailsRequested.type,
            onError: detailsRequestFailed.type,
        })
    );
};

export const loadProjectLists = () => {
    store.dispatch(
        apiCallBegin({
            url: routes.project.baseUrl,
            onSuccess: projectListReceived.type,
        })
    );
};

export const loadProjectSettingsDetails = (projectID) => {
    store.dispatch(
        apiCallBegin({
            url: routes.settings.projectDetails + "?project_id=" + projectID,
            onSuccess: projectDetailsReceived.type,
            onStart: detailsRequested.type,
            onError: detailsRequestFailed.type,
        })
    );
};

export const updateProjectDetails = (details) => {
    store.dispatch(projectDetailsUpdated({ ...details }));
};

//onchange data will update
export const updateMaterial = (details) => {
    store.dispatch(materialPropertieUpdated({ details }));
};

export const addMaterial = (url, data) => {
    store.dispatch(apiCallBegin({
        url,
        method: 'post',
        jsonData: data,
        onSuccess: projectMaterialsAdded.type
    }));
};

export const deleteMaterial = (deleteIds) => {
    store.dispatch(
        apiCallBegin({
            url: routes.settings.projectMaterial,
            deleteData: deleteIds,
            method: "delete",
            onSuccess: materialRowRemoved.type,
        })
    );
};

export const saveMaterials = (details) => {
    store.dispatch(
        apiCallBegin({
            url: routes.settings.projectMaterialBulkUpdate,
            jsonData: details,
            method: "put",
            onSuccess: materialDetailsSaved.type
        })
    );

    // store.dispatch(loadProjectMaterials(details[0]?.projectsetting))
};

export const saveSettingsDetails = (projectDetails, projectID) => {
    store.dispatch(
        apiCallBegin({
            url: routes.settings.projectDetails + `${projectID}/`,
            jsonData: projectDetails,
            onSuccess: projectDetailsUpdated.type,
            method: "put",
        })
    );
};

export const unlockSettings = (projectID) => {
    store.dispatch(
        apiCallBegin({
            url: routes.settings.projectDetails + `${projectID}/delete_calcs`,
            method: "get",
            jsonData: { projectID },
            onSuccess: settingsUnlocked.type,
        })
    );
};
