import { Box, Grid, GridItem } from "@chakra-ui/react";
import RebarSettings from "components/settings/sections/RebarSettings";
import React from "react";
import { useSelector } from "react-redux";
import { getSettings } from "store/selector";
import SettingsTable from "../SettingsTable";

function General() {
  const {projectDetails} = useSelector(getSettings)

  return (
    <Grid gap={4} templateColumns="repeat(3, 1fr)" p={3}>
      <GridItem rowSpan={3} colSpan={1}>
        <SettingsTable
          tableName={"Units"}
          style={{ width: "400px" }}
          columnPadding="0.5rem 0.5rem"
        />
      </GridItem>
      <GridItem  colSpan={1}>
        <SettingsTable
          tableName={"Print Settings"}
          style={{ width: "400px" }}
          columnPadding="0.5rem 0.5rem"
        />
      </GridItem>
      <GridItem  colSpan={1}>
        <SettingsTable
          tableName={"Miscellaneous"}
          style={{ width: "400px" }}
          columnPadding="0.5rem 0.5rem"
        />
      </GridItem>
      <GridItem colSpan={1}>
        <SettingsTable
          tableName={"Project teams"}
          style={{ width: "400px" }}
          columnPadding="0.5rem 0.5rem"
        />
      </GridItem>
      <GridItem colSpan={1}>
        <SettingsTable
          tableName={"Rebar Settings"}
          style={{ width: "400px" }}
          columnPadding="0.5rem 0.5rem"
        />
      </GridItem>
      {projectDetails?.rebar_settings?.data && <GridItem colSpan={1}>
        <RebarSettings 
          style={{ width: "420px" }}
          columnPadding="0.5rem 0.5rem"
      
        />
      </GridItem>}

    </Grid>
  );
}

export default General;
