import { Badge } from "primereact/badge";
import { getStatusColor } from "utils/formatter";
import React from "react";

function StatusField({ value }) {
  const color = getStatusColor(value);
  return (
    <Badge value={value} style={{ whiteSpace: "nowrap",fontSize:'16px' }} severity={color}  />
  );
}

export default StatusField;
