import routes from "api/routes";
import { apiCallBegin } from "store/api";
import store from "store/store";

const { createSlice } = require("@reduxjs/toolkit");

const slice = createSlice({
  name: "rebar",
  initialState: { rebarLists: [], loading: false,twoDlists:[] },
  reducers: {
    rebarRequested: (rebar) => {
      rebar.loading = true;
    },
    rebarRequestFailed: (rebar) => {
      rebar.loading = false;
    },


    rebarReceived: (rebar, action) => {
      rebar.rebarLists = action.payload;
      rebar.loading = false;
    },
    rebarReordered: (rebar, action) => {
      rebar.rebarLists.rebar_rows = action.payload.rebars;
    },
    rebarRowUpdated: (rebar, action) => {
      const rebarRows = rebar.rebarLists?.rebar_rows?.map((row) =>
        row.no == action.payload.no ? { ...action.payload } : row
      );
      rebar.rebarLists.rebar_rows = rebarRows;
    },
    rebarRowAdded: (rebar, action) => {
      const latestListRows = rebar.rebarLists.rebar_rows;
      latestListRows.push(action.payload);
      rebar.rebarLists.rebar_rows = latestListRows;
    },
    rebarRowDeleted:(rebar,action) => {
      const deletedRows = action.previous?.ids
      const newRows = rebar.rebarLists?.rebar_rows.filter(row => !deletedRows.includes(row.id))
      rebar.rebarLists.rebar_rows = newRows
    },
    rebarRow2d:(rebar, action) => {
      const latestValue = {...rebar.twoDlists}
      latestValue[action.payload.url] = action.payload.rebar_rows
      rebar.twoDlists = latestValue
      rebar.loading = false;
    },

  },
});

const {
  rebarRequestFailed,
  rebarReceived,
  rebarRequested,
  rebarReordered,
  rebarRowUpdated,
  rebarRowDeleted,
  rebarRowAdded,
  rebarRow2d
} = slice.actions;
export default slice.reducer;

export const loadRebars = (url) => {
  store.dispatch(
    apiCallBegin({
      url,
      onSuccess: rebarReceived.type,
      onStart: rebarRequested.type,
      onError: rebarRequestFailed.type,
    })
  );
};

export const loadRebarFor2d = (url) => {
  store.dispatch(
    apiCallBegin({
      url,
      onSuccess: rebarRow2d.type,
      onStart: rebarRequested.type,
      onError: rebarRequestFailed.type,
    })
  );
};

export const reorderRebar = (rebars) => {
  store.dispatch(rebarReordered({ rebars }));
};

export const updateRebarRows = (rowData) =>
  store.dispatch(rebarRowUpdated({ ...rowData }));

export const addNewRebarRow = (newObj) => {
  store.dispatch(rebarRowAdded({ ...newObj }));
};

export const submitRebarDetails = (rebarObj,list, url) =>{
  store.dispatch(apiCallBegin({
    url,
    method:'put',
    jsonData: {
        row: list.row,
        rebar_rows: rebarObj,
        url: list.url,
        use: list.use
    },
  }))

loadRebars(url)
}

export const deleteRebarRow = (selectedRows,rowId) => {
  const url = `${routes.canvas.rebarRow}${rowId}`
  store.dispatch(apiCallBegin({
    url,
    method:'delete',
    deleteData:selectedRows,
    onSuccess:rebarRowDeleted.type
  }))
}