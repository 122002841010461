import React from "react";
import { Container as ContainerBase } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import logo from "images/logo_12.png";
import googleIconImageSrc from "images/google-icon.png";
import twitterIconImageSrc from "images/twitter-icon.png";
import { ReactComponent as SignUpIcon } from "feather-icons/dist/icons/user-plus.svg";
import { useState } from "react";
import { useSelector } from "react-redux";
import { registerUser } from "store/slices/auth/user";
import Loading from "components/common/Loading";
import { getAuth } from "store/selector";
import { Toast } from "utils/Toast";

const Container = tw(
    ContainerBase
)`min-h-px  text-white font-medium flex justify-center  `;
const Content = tw.div`max-w-screen-lg m-0 sm:my-16 text-gray-900  sm:rounded-lg flex justify-center flex-1`;
const MainContainer = tw.div`bg-other-contactusBg rounded-xl  px-6 py-2 sm:px-12 `;
const LogoLink = tw.a``;
const LogoImage = tw.img`h-16 mx-auto`;
const MainContent = tw.div` flex flex-col items-center`;
const FormContainer = tw.div`w-full flex-1 mt-2`;

const SocialButtonsContainer = tw.div`flex flex-col items-center `;
const SocialButton = styled.a`
  ${tw`w-full max-w-xs font-semibold  rounded-lg py-3  text-black bg-white border-2 border-other-buttonBG  flex items-center justify-center transition-all duration-300 focus:outline-none focus:shadow-outline text-sm mt-5 first:mt-0`}
  .iconContainer {
    ${tw`bg-white shadow-2xl p-2 rounded-full`}
  }
  .icon {
    ${tw`w-4`}
  }
  .text {
    ${tw`ml-4`}
  }
`;

const DividerTextContainer = tw.div`my-6 border-b text-center relative`;
const DividerText = tw.div`leading-none px-2  inline-block text-2xl   text-black tracking-wide font-bold bg-white transform -translate-y-1/2  inset-x-0 top-1/2 bg-transparent`;

const Form = tw.form`mx-auto max-w-xs`;
const Input = tw.input`w-full px-8 py-4  rounded-lg font-medium bg-white border border-gray-200 placeholder-black text-sm focus:outline-none focus:border-gray-400 mt-5 first:mt-0`;
const SubmitButton = styled.button`
  ${tw`mt-6 mb-6 tracking-wide font-semibold bg-other-buttonBG text-gray-100 w-full py-4 rounded-full transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none`}
  .icon {
    ${tw`w-6 h-6 -ml-2`}
  }
  .text {
    ${tw`ml-3`}
  }
`;

const Register = ({
    logoLinkUrl = "#",

    socialButtons = [
        {
            iconImageSrc: googleIconImageSrc,
            text: "Sign Up With Google",
            url: "https://google.com",
        },
        {
            iconImageSrc: twitterIconImageSrc,
            text: "Sign Up With Twitter",
            url: "https://twitter.com",
        },
    ],
    submitButtonText = "Sign Up",
    SubmitButtonIcon = SignUpIcon,
    tosUrl = "/terms",
    changeForm,
}) => {
    const initial = {
        username: "",
        password: "",
        email: "",
        fname: '',
        lname: "",
        company: '',
        profession: '',
        agreed: ''
    };
    const [info, setInfo] = useState(initial);
    const { loading } = useSelector(getAuth);

    const handleChange = ({ target: { value, name } }) => {
        const latestInfo = { ...info };
        latestInfo[name] = value;
        setInfo(latestInfo);
    };

    const handleSubmit = (event) => {
        if (!info?.agreed) return Toast('You have to agree teams and conditions before sign up.', 'warning')
        console.log(info, 'register')
        registerUser(info);
        setInfo(initial);
    };


    return (
        <Container>
            <Content>
                <MainContainer>
                    <LogoLink href={logoLinkUrl}>
                        <LogoImage src={logo} />
                    </LogoLink>
                    <MainContent>
                        <FormContainer>
                            <DividerTextContainer>
                                <DividerText>Sign Up</DividerText>
                            </DividerTextContainer>
                            <Form onSubmit={handleSubmit}>
                                <Input
                                    type="text"
                                    required
                                    placeholder="First name"
                                    name="fname"
                                    value={info.fname}
                                    onChange={handleChange}
                                />
                                <Input
                                    type="text"
                                    required
                                    placeholder="Last name"
                                    name="lname"
                                    value={info.lname}
                                    onChange={handleChange}
                                />
                                <Input
                                    type="text"
                                    required
                                    placeholder="Username"
                                    name="username"
                                    value={info.username}
                                    onChange={handleChange}
                                />
                                <Input
                                    type="email"
                                    required
                                    placeholder="Email"
                                    name="email"
                                    value={info.email}
                                    onChange={handleChange}
                                />
                                <Input
                                    type="password"
                                    required
                                    placeholder="Password"
                                    name="password"
                                    value={info.password}
                                    onChange={handleChange}
                                />
                                <select
                                    name="profession"

                                    value={info.profession}
                                    onChange={handleChange}
                                    tw=" w-full  px-8 py-4 rounded-lg font-medium bg-white border border-gray-200 placeholder-secondary-300 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5 first:mt-0"
                                >
                                    <option value="">Select Profession</option>
                                    <option value="Professional Engineer">Professional Engineer</option>
                                    <option value="Trainee Engineer">Trainee Engineer</option>
                                    <option value="Other">Other</option>
                                </select>
                                <Input
                                    type="text"
                                    required
                                    placeholder="Company/University or College name "
                                    name="company"
                                    value={info.company}
                                    onChange={handleChange}
                                />

                                <p tw="mt-6 text-sm text-black text-center">
                                    <input
                                        type={"checkbox"}
                                        value={info.agreed}
                                        onChange={(e) => handleChange({ target: { name: 'agreed', value: e.target.checked } })}
                                        tw="mr-2 w-4 h-4 border-4 border-b-blue-600"
                                    />
                                    By ticking agree to our terms and that you have read  {" "}
                                    <a
                                        href={tosUrl}
                                        tw="border-b text-blue-500 border-gray-500 border-dotted"
                                        target={'_blank'}
                                    >
                                        Terms and Condition
                                    </a>{" "}
                                    including our Cookie Use

                                </p>


                                <SubmitButton type={loading ? "none" : "submit"}>
                                    {" "}
                                    {loading ? (
                                        <Loading isloading={loading} height={"0.7rem"} />
                                    ) : (
                                        <>
                                            <SubmitButtonIcon className="icon" />
                                            <span className="text">{submitButtonText}</span>
                                        </>
                                    )}
                                </SubmitButton>
                            </Form>
                            <SocialButtonsContainer>
                                {socialButtons.map((socialButton, index) => (
                                    <SocialButton key={index} href={socialButton.url}>
                                        <span className="iconContainer">
                                            <img
                                                src={socialButton.iconImageSrc}
                                                className="icon"
                                                alt=""
                                            />
                                        </span>
                                        <span className="text">{socialButton.text}</span>
                                    </SocialButton>
                                ))}
                            </SocialButtonsContainer>
                        </FormContainer>
                    </MainContent>
                </MainContainer>
            </Content>
        </Container>
    );
};

export default Register;
