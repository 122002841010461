import { apiCallBegin } from "store/api";
import store from "store/store";

const { createSlice } = require("@reduxjs/toolkit");

const slice = createSlice({
  name: "loadGeometry",
  initialState: {
    data_points: {
      pointData: [],
      surfaceData: [],
      lineData: [],
    },
    loading: false,
  },
  reducers: {
    geometryRequested: (geometry) => {
      geometry.loading = true;
    },
    geometryRequestFailed: (geometry) => {
      geometry.data_points = {
        pointData: [],
        surfaceData: [],
        lineData: [],
      };
      geometry.loading = false;
    },

    geometryReceived: (geometry, action) => {
      if (action.payload && action.payload.error) {
        geometry.data_points = {
          pointData: [],
          surfaceData: [],
          lineData: [],
        };
      } else {
        geometry.data_points = action.payload;
      }
      geometry.loading = false;
    },
  },
});

const { geometryRequestFailed, geometryReceived, geometryRequested } =
  slice.actions;
export default slice.reducer;

export const loadGeometry = (url) => {
  return store.dispatch(
    apiCallBegin({
      url,
      onSuccess: geometryReceived.type,
      onStart: geometryRequested.type,
      onError: geometryRequestFailed.type,
    })
  );
};
