import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Box, Grid, Text, useDisclosure } from "@chakra-ui/react";
import { ColumnGroup } from "primereact/columngroup";
import { Column } from "primereact/column";
import { Row } from "primereact/row";
import { FiSend } from "react-icons/fi";
import { AiFillDelete, AiOutlinePlus } from "react-icons/ai";

import DataTable from "components/DataTable";
import { Button } from "components/misc/Buttons";
import { getRebars } from "store/selector";
import {
    addNewRebarRow,
    deleteRebarRow,
    loadRebars,
    reorderRebar,
    submitRebarDetails,
    updateRebarRows,
} from "store/slices/entities/rebar";
import { Toast } from "utils/Toast";
import { colors } from "components/misc/colors";
import { CloseIcon } from "@chakra-ui/icons";
import Modal from "components/common/Modal";
import RebarTable from "components/tests/RebarTable";

function Rebar({ link }) {
    const { rebarLists, loading } = useSelector(getRebars);
    const [selectedRows, setSelectedRows] = useState([]);

    const { isOpen, onClose: modalClose, onOpen } = useDisclosure();

    const onDelete = () => {
        if (selectedRows.length === 0)
            return Toast("There is no items selected!", "warning");
        const rowIds = selectedRows?.map((row) => row.id);

        deleteRebarRow(rowIds, rebarLists?.rebar_rows[0].row);
        setSelectedRows([]);
        modalClose(true);
    };

    useEffect(() => {
        loadRebars(link)
    },[])


    return (
        <>
            <Box
                p={"1rem"}
                borderRadius={8}
                bgColor={"gray.100"}

            >

                <Box
                    p={{ sm: "1rem", lg: "0" }}
                    // mt={"1rem"}
                    overflow={"none"}
                >
                     <RebarTable link={link}/>
                </Box>

            </Box>
            <Modal
                isOpen={isOpen}
                modalHead="Confirm"
                children={<Text>Are you sure you want to delete these rows ?</Text>}
                onClose={modalClose}
                onProcced={onDelete}
                confirmText="Delete"
            />
        </>
    );
}

export default Rebar;
