import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro";
import { Button } from "@chakra-ui/react";
import { BiCloset } from "react-icons/bi";
import { IoClose } from "react-icons/io5";

const Container = tw.div`fixed  items-center z-50 inset-0 overflow-auto`;
const Content = tw.div`flex flex-row  justify-center items-center min-h-screen pt-4 px-10 pb-20 text-center  `;
const Overlay = tw.div`fixed inset-0 bg-gray-500 bg-opacity-75 backdrop-blur-[10px]  `;
function ComponentModal({ children, isOpen = true, onClose }) {
  return (
    <>
      {isOpen ? (
        <>
          <Overlay
            style={{
              backgroundColor: "rgba(255, 255, 255, 0.4)",
              backdropFilter: "blur(8px)",
            }}
          />
          <Container>
            {/* <Button
              position={"absolute"}
              left="50%"
              top={{ sm: "3%", md: "10%", lg: "5%" }}
              leftIcon={<IoClose />}
              bgColor="red.200"
              iconSpacing={0}
              onClick={onClose}
            /> */}
            <Content>{children}</Content>
          </Container>
        </>
      ) : (
        <></>
      )}
    </>
  );
}

export default ComponentModal;
