import { Box, Image } from "@chakra-ui/react";
import React from "react";

function FakeImage({ width,height,url }) {
  return (
    <Box
      objectFit={"contain"}
      width={width}
      height={height || '98%'}
      bgImage={url || "https://i.pinimg.com/564x/76/1e/2c/761e2c776bf04b90ef65eda1bd25f75f--civil-engineering-columns.jpg"}
      bgRepeat={"no-repeat"}
      bgSize={url ? 'contain' : 'cover'}
      paddingBottom={"1rem"}
    >
      <Image />
    </Box>
  );
}

export default FakeImage;
