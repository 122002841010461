import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import {
    Box,
    Flex,
    Text,
    useColorModeValue,
    useDisclosure,
} from "@chakra-ui/react";
import Modal from "components/common/Modal";
import Scrollbars from "react-custom-scrollbars-2";

import { getCalcus } from "store/selector";
import { loadCalculations } from "store/slices/entities/calculations";
import store from "store/store";
import { useState } from "react";

function SidebarContent({ title }) {
    const { list: calculations } = useSelector(getCalcus);
    const calusDetails = calculations?.filter((cal) => cal.name === title);

    const textColor = useColorModeValue("secondaryGray.900", "white");
    const shadow = useColorModeValue(
        "0px 18px 40px rgba(112, 144, 176, 0.12)",
        "transparent"
    );
    const { isOpen, onClose, onOpen } = useDisclosure();
    const [modalContent, setModalContent] = useState();

    const onModalOpen = (props) => {
        setModalContent(props);
        onOpen(true);
    };

    useEffect(() => {
        calculations?.length === 0 && store.dispatch(loadCalculations());
    }, []);

    return (
        <>
            <Flex pt={"35px"}>
                <Flex
                    w={"100%"}
                    flexWrap={"wrap"}
                    minHeight={"36rem"}
                    bg={"white"}
                    p={"25px"}
                    borderRadius={"20px"}
                    marginInline={{ md: "2rem" }}
                    overflow={"hidden"}
                    boxShadow={shadow}
                >
                    <Scrollbars autoHide>
                        <Flex flexDirection={"column"} marginInline={"0.5rem"}>
                            <Text
                                mt={6}
                                fontWeight="700"
                                textAlign={"center"}
                                fontSize="xl"
                                color={textColor}
                            >
                                {title}
                            </Text>
                        </Flex>
                        <Flex flexWrap={"wrap"} flexDirection="column">
                            {calusDetails[0]?.files?.map((item, key) => (
                                <Text
                                    key={key}
                                    fontWeight="semibold"
                                    cursor={"pointer"}
                                    p={"10px"}
                                    marginInline={2}
                                    borderRadius={8}
                                    mt={6}
                                    onClick={() => onModalOpen(item)}
                                    style={{ border: "2px solid #3375b8" }}
                                >
                                    {item.description}
                                </Text>
                            ))}
                        </Flex>
                    </Scrollbars>
                </Flex>
            </Flex>
            <Modal modalHead="Description" isOpen={isOpen} onClose={onClose}>
                <Box>
                    <Text>
                        {modalContent?.name === "Column" ? (
                            <p>
                                The RC column design module calculates column axial, biaxial
                                bending, shear and torsional capacity and utilization based on
                                ACI 318-14. With this module the entire columns in a project can
                                be designed or verified and files organized. <br /> <br />
                                The P-M-M capacity ratio is calculated for each load of action.
                                The program considers slenderness ratios, Sway or Non-sway
                                cases, Effective length factor based on the end conditions and
                                permits user defined reinforcement arrangement.
                            </p>
                        ) : (
                            "Calculation module is being developed."
                        )}
                    </Text>
                </Box>
            </Modal>
        </>
    );
}

export default SidebarContent;
