import { FormControl, FormLabel, Select, Input, Button } from "@chakra-ui/react";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { getMembers, getProjects } from "store/selector";
import { assignFile, loadProjects } from "store/slices/entities/projects";
import { getCalcus } from "store/selector";

import { loadCalculations } from "store/slices/entities/calculations";


import store from "store/store";
import { Toast } from "utils/Toast";
const CalculationModel = (props) => {
    const { list: projects } = useSelector(getProjects);

    // const { list: calculations } = useSelector(getCalcus);

    const calculations = [
        {
            "id": "9b68adc1-e4a1-4149-99bc-2f5289068f46",
            "name": "Reinforced Concrete Design",
            "files": [
                {
                    "id": "0e992ae5-969f-4789-ba35-266a919a3a98",
                    "calc_type": "Column",
                    "description": "RC Column design to ACI-318-14",
                    "category": "9b68adc1-e4a1-4149-99bc-2f5289068f46"
                },
                // {
                //     "id": "e734b913-28dc-4cdb-ba92-030e389ff46e",
                //     "calc_type": "Footing",
                //     "description": "RC Footing design to ACI-318-14",
                //     "category": "9b68adc1-e4a1-4149-99bc-2f5289068f46"
                // },
                {
                    "id": "5620424e-ca25-4738-8670-990c0d54c7bb",
                    "calc_type": "FlexureShearTorsion",
                    "description": "RC Section check to ACI-318-14",
                    "category": "9b68adc1-e4a1-4149-99bc-2f5289068f46"
                },
                {
                    "id": "20b401fc-e98c-456f-91fa-0770f81bec05",
                    "calc_type": "PunchingShear",
                    "description": "Punching shear to ACI-318-14",
                    "category": "9b68adc1-e4a1-4149-99bc-2f5289068f46"
                },
                // {
                //     "id": "82dde562-03d3-43ea-84ab-5c7c4296ccd6",
                //     "calc_type": "ShearWall",
                //     "description": "RC Shear wall design to ACI-318-14",
                //     "category": "9b68adc1-e4a1-4149-99bc-2f5289068f46"
                // },
                // {
                //     "id": "7226526c-5c18-4a26-89e4-40ff8fc4c9e9",
                //     "calc_type": "MultiSpanRCBeam",
                //     "description": "Multi span RC beam analysis & design to ACI-318-14",
                //     "category": "9b68adc1-e4a1-4149-99bc-2f5289068f46"
                // },
                // {
                //     "id": "a7a557fa-abf9-40ca-8e36-dcb1c7a6f822",
                //     "calc_type": "TwoAndOneWayRCSlabs",
                //     "description": "Two and one way RC slabs with coefficients to ACI-318-14",
                //     "category": "9b68adc1-e4a1-4149-99bc-2f5289068f46"
                // },
                // {
                //     "id": "d4d9cef1-8035-4641-a850-f7667a6c98f9",
                //     "calc_type": "RetainingWall",
                //     "description": "RC Retaining wall design to ACI-318-14",
                //     "category": "9b68adc1-e4a1-4149-99bc-2f5289068f46"
                // },
                // {
                //     "id": "e85a4358-5be0-4cf5-ba14-0507f6fb1346",
                //     "calc_type": "BasementWall",
                //     "description": "RC Basement wall design to ACI-318-14",
                //     "category": "9b68adc1-e4a1-4149-99bc-2f5289068f46"
                // },
                // {
                //     "id": "35768794-6dea-4637-8a4d-f8e8bb3a9ab7",
                //     "calc_type": "QuickReference",
                //     "description": "Quick reference tables",
                //     "category": "9b68adc1-e4a1-4149-99bc-2f5289068f46"
                // },
                {
                    "id": "4915946d-ca9d-4874-be62-a822a71c31b9",
                    "calc_type": "CrackWidth",
                    "description": "Crack width checks to ACI-318-14",
                    "category": "9b68adc1-e4a1-4149-99bc-2f5289068f46"
                },
                // {
                //     "id": "865da5ee-6b51-4ddf-b6b3-f18787e2ff2e",
                //     "calc_type": "PileGroup",
                //     "description": "Pile group analysis",
                //     "category": "9b68adc1-e4a1-4149-99bc-2f5289068f46"
                // },
                // {
                //     "id": "69523532-2833-48df-9e6b-6e015e4005d7",
                //     "calc_type": "Pile",
                //     "description": "Pile Design to ACI-318-14",
                //     "category": "9b68adc1-e4a1-4149-99bc-2f5289068f46"
                // },
                // {
                //     "id": "f5bacda4-8791-4419-bb54-3fd7556463d9",
                //     "calc_type": "CorebelAndNib",
                //     "description": "Corbel and nib design to ACI-318-14",
                //     "category": "9b68adc1-e4a1-4149-99bc-2f5289068f46"
                // },
                // {
                //     "id": "d2916b5e-4084-4c4d-998f-d1fdc29abeee",
                //     "calc_type": "ColumnShortening",
                //     "description": "Column shortening to ACI 209 R92",
                //     "category": "9b68adc1-e4a1-4149-99bc-2f5289068f46"
                // },
                // {
                //     "id": "e8e17159-1403-4061-acd8-7a775ec2bf09",
                //     "calc_type": "PileCaps",
                //     "description": "Pile Caps Design to ACI-318-14",
                //     "category": "9b68adc1-e4a1-4149-99bc-2f5289068f46"
                // },
                // {
                //     "id": "b358a463-0151-48cf-adab-c82fe76eb68e",
                //     "calc_type": "SectionBuilder",
                //     "description": "Section builder & Property calculator",
                //     "category": "9b68adc1-e4a1-4149-99bc-2f5289068f46"
                // }
            ]
        },
        // {
        //     "id": "70b85ac4-d5d4-4984-bc6e-557dfeb61fe6",
        //     "name": "Structural Steel design",
        //     "files": [
        //         {
        //             "id": "acf6f634-c437-484b-92ef-f35bbd76ccb6",
        //             "calc_type": "ShearConnection",
        //             "description": "Steel Shear connection to AISC-360-10",
        //             "category": "70b85ac4-d5d4-4984-bc6e-557dfeb61fe6"
        //         },
        //         {
        //             "id": "fa108785-bd98-49ae-b2b4-bce864b827f1",
        //             "calc_type": "MomentConnection",
        //             "description": "Steel moment connection to AISC-360-10",
        //             "category": "70b85ac4-d5d4-4984-bc6e-557dfeb61fe6"
        //         },
        //         {
        //             "id": "34bad3c7-969e-49e8-be19-69cdafe292f2",
        //             "calc_type": "BeamColumn",
        //             "description": "Steel Beam - Column Design to AISC-360-10",
        //             "category": "70b85ac4-d5d4-4984-bc6e-557dfeb61fe6"
        //         },
        //         {
        //             "id": "247973f9-76f7-4b9e-8b48-9ba03116975c",
        //             "calc_type": "BasePlate",
        //             "description": "Steel Base Plate Design to AISC-360-10",
        //             "category": "70b85ac4-d5d4-4984-bc6e-557dfeb61fe6"
        //         },
        //         {
        //             "id": "539c403b-4eb1-468a-a4db-20c7210f387a",
        //             "calc_type": "Truss",
        //             "description": "Steel Truss analysis and design to AISC-360-10",
        //             "category": "70b85ac4-d5d4-4984-bc6e-557dfeb61fe6"
        //         }
        //     ]
        // },
        // {
        //     "id": "60cdb60b-146b-4eb4-ba01-bc092f619425",
        //     "name": "Structural Analysis and Load Calculations",
        //     "files": [
        //         {
        //             "id": "f07be9e2-87d3-48bb-a220-47cf59f2498b",
        //             "calc_type": "Frame",
        //             "description": "Frame analysis and Design",
        //             "category": "60cdb60b-146b-4eb4-ba01-bc092f619425"
        //         },
        //         {
        //             "id": "3a6a3fe1-d784-4c0e-addb-3c9fd98dd790",
        //             "calc_type": "WindLoads",
        //             "description": "Saudi/UAE wind loads from google map",
        //             "category": "60cdb60b-146b-4eb4-ba01-bc092f619425"
        //         },
        //         {
        //             "id": "b7e2ae9f-a078-4730-b19e-442efb1debde",
        //             "calc_type": "SeismicLoads",
        //             "description": "Saudi/UAE seismic loads from google map",
        //             "category": "60cdb60b-146b-4eb4-ba01-bc092f619425"
        //         }
        //     ]
        // },
        // {
        //     "id": "2b2ea140-aec2-47a7-843e-4e4e45cc2ddd",
        //     "name": "Composite Member Design",
        //     "files": [
        //         {
        //             "id": "07c672be-5879-46e4-ae9a-88d927f10439",
        //             "calc_type": "BeamDesign",
        //             "description": "Composite beam design to AISC-360-10",
        //             "category": "2b2ea140-aec2-47a7-843e-4e4e45cc2ddd"
        //         },
        //         {
        //             "id": "117ffece-94d9-4540-85e1-c9b3bff6fd96",
        //             "calc_type": "column",
        //             "description": "Composite column to AISC-360-10",
        //             "category": "2b2ea140-aec2-47a7-843e-4e4e45cc2ddd"
        //         },
        //         {
        //             "id": "f277e598-276c-44e2-a536-176e662d77d4",
        //             "calc_type": "ColumnSection",
        //             "description": "Irregular Column section Design(AISC-360-10/ACI 318-14)",
        //             "category": "2b2ea140-aec2-47a7-843e-4e4e45cc2ddd"
        //         }
        //     ]
        // },
        // {
        //     "id": "1ece9bef-cc13-4bfe-82b2-d1fcf9970da2",
        //     "name": "Masonry Design",
        //     "files": [
        //         {
        //             "id": "be0d8030-1bf9-4342-b2c9-8141a8481fe4",
        //             "calc_type": "WallPanel",
        //             "description": "Wall Panel Design",
        //             "category": "1ece9bef-cc13-4bfe-82b2-d1fcf9970da2"
        //         }
        //     ]
        // }
    ]

    const { title, files } = props;
    const initial = { file: "", name: "", project: props?.project?.id };
    const [fileDetails, setFileDetails] = useState(initial);

    const [selectedModule, setSelectedModule] = useState('');
    const [filteredFiles, setFilteredFiles] = useState([]);

    const onChange = ({ target: { value, name } }) => {
        const file = { ...fileDetails };
        file[name] = value;
        console.log(file);
        setFileDetails(file);
    };

    const onChangeModule = (event) => {
        const moduleId = event.target.value;
        setSelectedModule(moduleId);

        const selectedModuleData = calculations.find(calculation => calculation.id === moduleId);
        if (selectedModuleData) {
            setFilteredFiles(selectedModuleData.files);
        } else {
            setFilteredFiles([]);
        }
    }


    const addCalculationFile = () => {
        if (fileDetails?.project === "" || fileDetails?.name === "")
            return Toast("There is no file name or project selected", "warning");

        store.dispatch(assignFile(fileDetails));
        store.dispatch(loadProjects());
        props.onSubmit();
        setFileDetails(initial);

    };

    useEffect(() => {
        calculations?.length === 0 && store.dispatch(loadCalculations());
    })


    return (
        <div style={{ padding: "4px" }}>
            <FormControl>
                <FormLabel>File name</FormLabel>
                <Input
                    placeholder="File name"
                    name="name"
                    onChange={onChange}
                    value={fileDetails.name}
                />
            </FormControl>

            <FormControl mt={4}>
                <FormLabel>Calculation Modules</FormLabel>
                <Select
                    placeholder="Choose Module"
                    name="module"
                    onChange={onChangeModule}
                >
                    {calculations?.map((calculation) => (
                        <option key={calculation.id} value={calculation.id}>
                            {calculation.name}
                        </option>
                    ))}
                </Select>
            </FormControl>
            <FormControl mt={4}>
                <FormLabel>Calculation</FormLabel>
                <Select
                    placeholder="Choose Option"
                    name="file"
                    onChange={onChange}
                >
                    {filteredFiles.map((file) => (
                        <option key={file.id} value={file.id}>
                            {file.description}
                        </option>
                    ))}
                </Select>
            </FormControl>

            <Button colorScheme='blue' mt={6} onClick={addCalculationFile}>Submit</Button>
        </div>
    )
}

export default CalculationModel;