import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Box, Grid, Text, useDisclosure } from "@chakra-ui/react";
import { FiSend } from "react-icons/fi";
import { AiFillDelete, AiOutlinePlus } from "react-icons/ai";

import DataTable from "components/DataTable";
import { Button } from "components/misc/Buttons";
import { getCanvasDetails, getLoadDesign } from "store/selector";
import {
  createRowLoadDesign,
  loadLoadDesign,
  removeRowLoadDesign,
  saveLoadDesign,
  updateLoadDesign,
} from "store/slices/entities/loadDesign";
import { ColumnObj } from "components/project/utils/tableColumns";
import { BiDownload, BiRefresh } from "react-icons/bi";
import { useHistory } from "react-router-dom";
import { loadDesign } from "utils/dataFormat";
import { colors } from "components/misc/colors";
import { CloseIcon } from "@chakra-ui/icons";
import { Toast } from "utils/Toast";
import Modal from "components/common/Modal";
import { loadFileDetails } from "store/slices/entities/projectCanvas";

function LoadDesignSummary({ link, onClose }) {
  const { info, loading } = useSelector(getLoadDesign);
  const { selectedFile } = useSelector(getCanvasDetails);
  const [selectedRows, setSelectedRows] = useState([]);
  const columns = [
    { header: "No", field: "No" },
    { header: "Load No.", field: "load_no" },
    { header: "KN", field: "n" },
    { header: "KN.", field: "t" },
    { header: "KN", field: "vz" },
    { header: "KN.", field: "vy" },

    { header: "KN-m", field: "my_top" },
    { header: "KN-m.", field: "mz_top" },
    { header: "KN-m", field: "my_bottom" },
    { header: "KN-m", field: "mz_bottom" },
    { header: "Sustained load %", field: "bdns" },
    { header: "Sustained load (Bds) %", field: "bds" },

    { header: "Ds", field: "delta_y" },
    { header: "Cm", field: "cm_y" },
    { header: "Dns", field: "mom_y" },
    { header: "Mu", field: "delta_s_y" },

    { header: "Mmin(top)", field: "mc_top_y" },
    { header: "Mmin(bottom)", field: "mc_bottom_y" },
    { header: "Ds", field: "delta_z" },
    { header: "Cm", field: "cm_z" },

    { header: "Dns", field: "mom_z" },
    { header: "Mu", field: "delta_s_z" },
    { header: "Mmin(top)", field: "mc_top_z" },
    { header: "Mmin(bottom)", field: "mc_bottom_z" },

    { header: "P-M-M Ratio", field: "pmm" },
    { header: "Vz/ ØVn'z", field: "vy_phivny" },
    { header: "Vy/ØVn'y", field: "vz_phivnz" },
    { header: "T/ØTn", field: "t_tn" },

    { header: "Tch", field: "tch" },
    { header: "Remarks", field: "remark" },
  ];
  const columnGroup = ColumnObj["Load Design"]();

  useEffect(() => {
    loadLoadDesign(link);
  }, []);

  const onChange = ({ target: { value, name, id } }) => {
    const updatedValue = info?.load_rows.map((item) => {
      if (item?.url === id) {
        const obj = { ...item };
        obj[name] = value;
        obj["updated"] = true;
        return obj;
      } else {
        return item;
      }
    });

    updateLoadDesign(updatedValue);
  };

  const { isOpen, onClose: modalClose, onOpen } = useDisclosure();

  const onDelete = () => {
    if (selectedRows?.length === 0)
      return Toast("No rows has selected!", "warning");
    const deleteIds = selectedRows.map((item) => {
      const urlSplit = item.url.split("/");
      return urlSplit[urlSplit.length - 2];
    });

    removeRowLoadDesign(deleteIds);
    modalClose(true);
    setSelectedRows([]);
  };

  const onSubmit = () => {
    const modifiedItems = info.load_rows?.filter((item) => item.updated);
    const oldValues = { ...info };
    oldValues["load_rows"] = modifiedItems;
    if (modifiedItems?.length === 0)
      return Toast("There is no changes found!", "warning");
    saveLoadDesign(oldValues, info?.url, link);
  };

  const buttons = [
    {
      text: "New",
      icon: <AiOutlinePlus />,
      onClick: () => createRowLoadDesign(info?.url),
      color: colors.add,
    },
    {
      text: "Download",
      icon: <BiDownload />,
      onClick: () => window.open(`${info?.url}download/`, "_blank"),
      color: colors.download,
    },
    {
      text: "Delete",
      icon: <AiFillDelete />,
      onClick: onOpen,
      color: colors.delete,
    },
    {
      text: "Submit",
      icon: <FiSend />,
      onClick: onSubmit,
      color: colors.submit,
    },
    {
      text: "Refresh",
      icon: <BiRefresh />,
      onClick: () => loadLoadDesign(link),
      color: colors.refresh,
    },
  ];

  return (
    <>
      <Box
        minH="20vh"
        p={"1rem"}
        mt="6rem"
        borderRadius={15}
        bgColor={"gray.300"}
        overflow={"auto"}
      >
        <Box>
          <Grid
            gap={3}
            display={"flex"}
            position="relative"
            justifyContent={{ sm: "center" }}
            flexWrap={{ sm: "wrap", lg: "nowrap" }}
          >
            {buttons.map((btn, i) => (
              <Button
                size={{ md: "sm", lg: "lg" }}
                colorScheme={btn.color}
                textColor={btn.color}
                borderColor={colors.buttonBG}
                key={i}
                onClick={btn.onClick}
                leftIcon={btn.icon}
              >
                {btn.text}
              </Button>
            ))}
            <Button
              size={"sm"}
              position={"absolute"}
              left={"96%"}
              colorScheme={colors.delete}
              textColor={colors.delete}
              borderColor={colors.buttonBG}
              iconSpacing={0}
              onClick={() => {
                onClose();
                loadFileDetails(selectedFile.link);
              }}
              leftIcon={<CloseIcon />}
            />
          </Grid>
        </Box>
        <Box
          p={{ sm: "1rem", lg: "0" }}
          mt={"1rem"}
          maxH={"35rem"}
          overflow={"auto"}
        >
          <DataTable
            editable
            selection
            columnFontSize="17px"
            selectionMode="checkbox"
            formatObj={loadDesign}
            onChange={onChange}
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
            tableValue={info?.load_rows}
            columns={columns}
            loading={loading}
            columnGroup={columnGroup}
            header={"Forces of action and results Summary"}
            style={{ whiteSpace: "nowrap" }}
          />
        </Box>
      </Box>
      <Modal
        isOpen={isOpen}
        modalHead="Confirm"
        children={<Text>Are you sure you want to delete these rows ?</Text>}
        onClose={modalClose}
        onProcced={onDelete}
        confirmText="Delete"
      />
    </>
  );
}

export default LoadDesignSummary;
