import React from "react";

// chakra imports
import {
    Box,
    Flex,
    Drawer,
    DrawerBody,
    Icon,
    useColorModeValue,
    DrawerOverlay,
    useDisclosure,
    DrawerContent,
    DrawerCloseButton,
} from "@chakra-ui/react";
import Content from "components/sidebar/components/Content";
import {
    renderThumb,
    renderTrack,
    renderView,
} from "components/misc/scroll/Scrollbar";
import { Scrollbars } from "react-custom-scrollbars-2";
import PropTypes from "prop-types";

// Assets
import { IoMenuOutline } from "react-icons/io5";
// import Controller from "layouts/dashboard";

function Sidebar(props) {
    const { routes } = props;

    let variantChange = "0.2s linear";
    let shadow = useColorModeValue(
        "14px 17px 40px 4px rgba(112, 144, 176, 0.08)",
        "unset"
    );
    // Chakra Color Mode
    let sidebarBg = useColorModeValue("white", "navy.800");
    let sidebarMargins = "0px";

    // SIDEBAR
    return (
        <Box
            display={{ sm: "none", xl: "block" }}
            position="fixed"
            minH="100%"
            zIndex={800}
        // border={"1px solid purple"}
        >
            <Box
                bg={sidebarBg}
                transition={variantChange}
                w="50px"
                h="100vh"
                m={sidebarMargins}
                minH="100%"
                overflowX="hidden"
                boxShadow={shadow}
            >
                <Scrollbars
                    autoHide
                    renderTrackVertical={renderTrack}
                    renderThumbVertical={renderThumb}
                    renderView={renderView}
                >
                    <Content routes={routes} />
                </Scrollbars>
            </Box>
        </Box>
    );
}

// FUNCTIONS
export function SidebarResponsive(props) {
    let sidebarBackgroundColor = useColorModeValue("white", "navy.800");
    let menuColor = useColorModeValue("gray.400", "white");

    const { isOpen, onOpen, onClose } = useDisclosure();
    const btnRef = React.useRef();

    const { routes } = props;

    return (
        <Flex display={{ sm: "flex", xl: "none" }} alignItems="center">
            <Flex ref={btnRef} w="max-content" h="max-content" onClick={onOpen}>
                <Icon
                    as={IoMenuOutline}
                    color={menuColor}
                    my="auto"
                    w="20px"
                    h="20px"
                    me="10px"
                    _hover={{ cursor: "pointer" }}
                />
            </Flex>
            <Drawer
                isOpen={isOpen}
                onClose={onClose}
                placement={document.documentElement.dir === "rtl" ? "right" : "left"}
                finalFocusRef={btnRef}
            >
                <DrawerOverlay />
                <DrawerContent w="285px" maxW="285px" bg={sidebarBackgroundColor}>
                    <DrawerCloseButton
                        zIndex="3"
                        onClose={onClose}
                        _focus={{ boxShadow: "none" }}
                        _hover={{ boxShadow: "none" }}
                    />
                    <DrawerBody maxW="285px" px="0rem" pb="0">
                        <Scrollbars
                            autoHide
                            renderTrackVertical={renderTrack}
                            renderThumbVertical={renderThumb}
                            renderView={renderView}
                        >
                            <Content routes={routes} />
                        </Scrollbars>
                    </DrawerBody>
                </DrawerContent>
            </Drawer>
        </Flex>
    );
}

export function CustomSideBar(props) {
    const { isOpen, onOpen, children } = props;
    let sidebarBackgroundColor = useColorModeValue("white", "navy.800");

    const btnRef = React.useRef();

    const containerBg = useColorModeValue("secondaryGray.300");

    return (
        <Flex display="flex" alignItems="center">
            <Drawer
                isOpen={isOpen}
                onClose={onOpen}
                placement={"right"}
                finalFocusRef={btnRef}
            >
                <DrawerOverlay />
                <DrawerContent
                    maxW={{ sm: "100%", md: "50%", lg: "40%" }}
                    bg={sidebarBackgroundColor}
                >
                    <DrawerCloseButton
                        zIndex="3"
                        onClose={onOpen}
                        _focus={{ boxShadow: "none" }}
                        _hover={{ boxShadow: "none" }}
                    />
                    <DrawerBody
                        maxW="100%"
                        px={{ sm: "1rem", md: "0rem" }}
                        pb="0"
                        bg={containerBg}
                    >
                        <Scrollbars
                            autoHide
                            renderTrackVertical={renderTrack}
                            renderThumbVertical={renderThumb}
                            renderView={renderView}
                        >
                            {children}
                        </Scrollbars>
                    </DrawerBody>
                </DrawerContent>
            </Drawer>
        </Flex>
    );
}

Sidebar.propTypes = {
    logoText: PropTypes.string,
    routes: PropTypes.arrayOf(PropTypes.object),
    variant: PropTypes.string,
};

export default Sidebar;
