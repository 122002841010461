import React, { useState, useEffect } from "react";

import { getDefaultTableValue } from "utils/formatter";
import { crackWidth, crackWidthSection } from "utils/dataFormat";
import DataTable from "components/DataTable";
import { updateFile } from "store/slices/entities/projectCanvas";
import { useSelector } from "react-redux";
import { getCanvasDetails } from "store/selector";

function Table({
    tableName = "Default table",
    headerless = false,
    section,
    data,
    ...otherProps
}) {
    const [rowData, setRowData] = useState();
    const { updatedFileDetails } = useSelector(getCanvasDetails);

    let tableDetails, formatObj;
    switch (section) {
        case 'crackWidth':
            formatObj = crackWidth;
            tableDetails = rowData && getDefaultTableValue(crackWidth, tableName, rowData);
            break;
        case 'crackWidthSection':
            formatObj = crackWidthSection;
            tableDetails = rowData && getDefaultTableValue(crackWidthSection, tableName, rowData);
            break;
        default:
            break;
    }

    const onChange = ({ target: { value, name } }) => {
        const latestUpdates = updatedFileDetails?.filter(
            (item) => item.url === data.url
        );
        const latest =
            latestUpdates.length > 0 ? { ...latestUpdates[0] } : { ...rowData };
        latest[name] = value;
        updateFile(latest);
        setRowData(latest);
    };


    useEffect(() => {
        setRowData(data);
    }, []);

    return (
        <DataTable
            editable
            columnFontSize={otherProps?.font ? otherProps?.font : "16px"}
            tableValue={tableDetails?.newValue}
            columns={tableDetails?.columns}
            header={headerless ? "" : tableName}
            formatObj={formatObj}
            onChange={onChange}
            columnWidth={"8rem"}
            responsiveLayout={{ sm: "stack", md: "scroll" }}
            {...otherProps}
        />
    );
}

export default Table;
