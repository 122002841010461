import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Box, Grid, Text, useDisclosure } from "@chakra-ui/react";
import { FiSend } from "react-icons/fi";
import { AiFillDelete, AiOutlinePlus } from "react-icons/ai";

import DataTable from "components/DataTable";
import { Button } from "components/misc/Buttons";
import { getCanvasDetails, getLoadDesign } from "store/selector";
import {
    createRowLoadDesign,
    loadLoadDesign,
    removeRowLoadDesign,
    saveLoadDesign,
    updateLoadDesign,
} from "store/slices/entities/loadDesign";
import { ColumnObj } from "components/project/utils/tableColumns";
import { BiDownload, BiRefresh } from "react-icons/bi";
import { useHistory } from "react-router-dom";
import { loadDesign } from "utils/dataFormat";
import { colors } from "components/misc/colors";
import { CloseIcon } from "@chakra-ui/icons";
import { Toast } from "utils/Toast";
import Modal from "components/common/Modal";
import { loadFileDetails } from "store/slices/entities/projectCanvas";
import AGGridTable from "components/tests/DataTable";
import { useRef } from "react";

import WarningInformation from "../../../../tests/DataTable/WarningCellRenderer";

const statusColors = {
    "Not Acceptable": "red",
    "Not Designed": "blue",
    "Pass": "green",
    "Failed": "red",
    "Warning": "orange"
};

const status = [
    { "status": "Not Acceptable" },
    { "status": "Pass" },
    { "status": "Warning" },
    { "status": "Failed" }
];

const StatusRenderer = (params) => {
    const status = params.value;
    const color = statusColors[status] || "gray";
    return <Text color={color}>{status}</Text>
}

function Load({ link }) {
    const { info, } = useSelector(getLoadDesign);
    const { selectedFile } = useSelector(getCanvasDetails);
    const [selectedRows, setSelectedRows] = useState([]);

    const gridRef = useRef(null);

    const headers = [
        {
            headerName: "",
            colId: 'checkbox',
            checkboxSelection: true,
            suppressHeaderMenuButton: true,
            headerCheckboxSelection: true,
            maxWidth: 50
        },
        {
            headerName: "url",
            field: "url",
            hide: true,
        },
        {
            headerName: "row",
            field: "row",
            hide: true,
        },
        {
            headerName: "Load No", field: "load_no", valueGetter: params => params.node.rowIndex + 1, maxWidth: 90, editable: true,
            cellClass: ["cell-editable"],
            valueGetter: params => {
                return params.data.load_no;
            },
            valueSetter: params => {
                params.data.load_no = params.newValue;
                return true
            }
        },
        { headerName: "Mu (kN-m)", field: "mu", minWidth: 120, editable: true, cellClass: ["cell-editable"] },
        { headerName: "Vu (kN)", field: "vu", minWidth: 120, editable: true, cellClass: ["cell-editable"] },
        { headerName: "Tu (kN-m)", field: "tu", minWidth: 120, editable: true, cellClass: ["cell-editable"] },
        {
            headerName: "Utilization",
            marryChildren: true,
            class: "ag-header-group-cell",
            children: [
                { headerName: "Moment", field: "moment_utilization", editable: false },
                { headerName: "Shear", field: "shear_utilization" },
                { headerName: "Torsion", field: "torsion_utilization" },
                { headerName: "Shear & Torsion", field: "shear_torsion_utilization" }
            ]
        },
        {
            headerName: "Reinf. Utilization",
            marryChildren: true,
            class: "ag-header-group-cell",
            children: [
                { headerName: "Ast", field: "ast_reinf_utilization", editable: false },
                { headerName: "Asc", field: "asc_reinf_utilization" },
                { headerName: "At", field: "at_reinf_utilization" },
                { headerName: "Av", field: "av_reinf_utilization" },
                { headerName: "At+Av", field: "at_av_reinf_utilization" },
                { headerName: "At long", field: "at_long_reinf_utilization" }
            ]
        },
        { headerName: "Status", field: "status", maxWidth: 120, cellRenderer: StatusRenderer },
        { headerName: "Warnings", field: "warning", maxWidth: 160, cellRenderer: WarningInformation },
        { headerName: "Remarks", field: "remark", maxWidth: 120 },
    ];


    useEffect(() => {
        loadLoadDesign(link);
    }, []);

    const onChange = ({ target: { value, name, id } }) => {
        const updatedValue = info?.load_rows.map((item) => {
            if (item?.url === id) {
                const obj = { ...item };
                obj[name] = value;
                obj["updated"] = true;
                return obj;
            } else {
                return item;
            }
        });

        updateLoadDesign(updatedValue);
    };

    const { isOpen, onClose: modalClose, onOpen } = useDisclosure();

    const onDelete = () => {
        if (selectedRows?.length === 0)
            return Toast("No rows has selected!", "warning");
        const deleteIds = selectedRows.map((item) => {
            const urlSplit = item.url.split("/");
            return urlSplit[urlSplit.length - 2];
        });

        removeRowLoadDesign(deleteIds);
        modalClose(true);
        setSelectedRows([]);
    };

    const onSubmit = () => {
        const modifiedItems = info.load_rows?.filter((item) => item.updated);
        const oldValues = { ...info };
        oldValues["load_rows"] = modifiedItems;
        if (modifiedItems?.length === 0)
            return Toast("There is no changes found!", "warning");
        saveLoadDesign(oldValues, info?.url, link);
    };

    const buttons = [
        {
            text: "New",
            icon: <AiOutlinePlus />,
            onClick: () => createRowLoadDesign(info?.url),
            color: colors.add,
        },
        {
            text: "Download",
            icon: <BiDownload />,
            onClick: () => window.open(`${info?.url}download/`, "_blank"),
            color: colors.download,
        },
        {
            text: "Delete",
            icon: <AiFillDelete />,
            onClick: onOpen,
            color: colors.delete,
        },
        {
            text: "Submit",
            icon: <FiSend />,
            onClick: onSubmit,
            color: colors.submit,
        },
        {
            text: "Refresh",
            icon: <BiRefresh />,
            onClick: () => loadLoadDesign(link),
            color: colors.refresh,
        },
    ];

    return (
        <>
            <Box
                minH="20vh"
                p={"1rem"}
                // mt="6rem"
                borderRadius={8}
                bgColor={"gray.100"}
                overflow={"auto"}
            >
                <Box
                    maxH={"35rem"}
                    overflow={"auto"}
                >
                    {/* <DataTable
            editMode="cell"
            selection
            columnFontSize="14px"
            selectionMode="checkbox"
            formatObj={loadDesign}
            onChange={onChange}
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
            tableValue={info?.load_rows}
            columns={columns}
            loading={loading}
            columnGroup={columnGroup}
            header={"Forces of action and results Summary"}
            style={{ whiteSpace: "nowrap", fontSize: "14px" }}
          /> */}
                    <AGGridTable ref={gridRef} link={link} headers={headers} representation={"flexure-shear"} />
                </Box>
            </Box>
            <Modal
                isOpen={isOpen}
                modalHead="Confirm"
                children={<Text>Are you sure you want to delete these rows ?</Text>}
                onClose={modalClose}
                onProcced={onDelete}
                confirmText="Delete"
            />
        </>
    );
}

export default Load;
