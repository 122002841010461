import { Input } from "@chakra-ui/react";
import React from "react";


function InputField({ onChange, type, name, value, setEdit, id }) {
    return (
        <Input
            onChange={onChange}
            name={name}
            value={value}
            size={3}
            id={id}
            padding={"1px"}

            type={type}
            textAlign={"center"}
            color={"blue.300"}
            onKeyDown={(e) => {
                if (e.key === "Enter") setEdit({});
            }}
        />
    );
}

export default InputField;
