import React, { useState } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line

import { Container as ContainerBase } from "components/misc/Layouts";
import logo from "images/logo_12.png";
import { ReactComponent as LoginIcon } from "feather-icons/dist/icons/log-in.svg";
import { loginUser } from "store/slices/auth/user";
import { useSelector } from "react-redux";
import { getAuth } from "store/selector";
import Loading from "components/common/Loading";

const Container = tw(
  ContainerBase
)`min-h-px  text-white font-medium flex justify-center  `;
const Content = tw.div`max-w-screen-lg m-0 sm:my-16 text-gray-900  sm:rounded-lg flex justify-center flex-1`;
const MainContainer = tw.div`bg-other-contactusBg rounded-xl  p-6 sm:p-12`;
const LogoLink = tw.a``;
const LogoImage = tw.img`h-24 mx-auto`;
const MainContent = tw.div`mt-6 flex flex-col items-center`;
const Heading = tw.h1`text-2xl xl:text-3xl font-extrabold`;
const FormContainer = tw.div`w-full flex-1 mt-8`;

const Form = tw.form`mx-auto max-w-xs`;
const Input = tw.input`w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5 first:mt-0`;
const SubmitButton = styled.button`
  ${tw`mt-6 tracking-wide font-semibold bg-other-buttonBG text-gray-100 w-full py-4 rounded-full transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none`}
  .icon {
    ${tw`w-6 h-6 -ml-2`}
  }
  .text {
    ${tw`ml-3`}
  }
`;

function Login({
  logoLinkUrl = "#",
  headingText = "Sign In",
  submitButtonText = "Sign In",
  SubmitButtonIcon = LoginIcon,
  forgotPasswordUrl = "#",
  changeForm
}) {
  const [info, setInfo] = useState({ username: "", password: "" });
  const { loading } = useSelector(getAuth);

  const handleChange = ({ target: { value, name } }) => {
    const latestInfo = { ...info };
    latestInfo[name] = value;
    setInfo(latestInfo);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    loginUser(info);
  };

  return (
    <Container style={{ boxShadow: "none", WebkitBoxShadow: "none" }}>
      <Content>
        <MainContainer>
          <LogoLink href={logoLinkUrl}>
            <LogoImage src={logo} />
          </LogoLink>
          <MainContent>
            <Heading>{headingText}</Heading>
            <FormContainer>
              <Form onSubmit={handleSubmit}>
                <Input
                  type="text"
                  required
                  placeholder="Username"
                  name="username"
                  value={info.username}
                  onChange={handleChange}
                />
                <Input
                  type="password"
                  required
                  placeholder="Password"
                  name="password"
                  value={info.password}
                  onChange={handleChange}
                />
                <SubmitButton type={loading ? 'none' : "submit"}>
                  {loading ? (
                    <Loading isloading={loading} height={'0.7rem'}/>
                  ) : (
                    <>
                      <SubmitButtonIcon className="icon" />
                      <span className="text">{submitButtonText}</span>
                    </>
                  )}
                </SubmitButton>
              </Form>
              <p tw="mt-6 text-xs text-gray-600 text-center">
                <a
                  href={forgotPasswordUrl}
                  tw="border-b border-gray-500 border-dotted"
                >
                  Forgot Password ?
                </a>
              </p>
              <p tw="mt-8 text-sm text-gray-600 text-center">
                Dont have an account?{" "}
                <button onClick={() => changeForm('register')} tw="border-b border-gray-500 border-dotted">
                  Sign Up
                </button>
              </p>
            </FormContainer>
          </MainContent>
        </MainContainer>
      </Content>
    </Container>
  );
}

export default Login;
