import * as THREE from "three";

import imgEnvFr from "../assets/images/back_FR.jpg";
import imgEnvBk from "../assets/images/back_BK.jpg";
import imgEnvUp from "../assets/images/back_UP.jpg";
import imgEnvDn from "../assets/images/back_DN.jpg";
import imgEnvLf from "../assets/images/back_LF.jpg";
import imgEnvRt from "../assets/images/back_RT.jpg";

// export const imgEnv0Arr = [imgEnvPX, imgEnvNX, imgEnvPY, imgEnvNY, imgEnvPZ, imgEnvNZ];
export const imgEnv0Arr = [
  imgEnvFr,
  imgEnvBk,
  imgEnvUp,
  imgEnvDn,
  imgEnvLf,
  imgEnvRt,
];
export const lineR = 10;

export function RGB2Int(str) {
  const realStr = str.substring(1);
  const realInt = Number("0x" + realStr);
  return realInt;
}

export function GetRGBCol(arr) {
  const col = { r: arr[0], g: arr[1], b: arr[2] };
  return "rgb(" + col.r + "," + col.g + "," + col.b + ")";
}

export function GetRoundNum(val, size = 4) {
  return Math.round(val * Math.pow(10, size)) / Math.pow(10, 4);
}

const posGeo = new THREE.SphereGeometry(1, 32, 32),
  posMat = new THREE.MeshStandardMaterial({ color: 0xff0000 }),
  posMesh = new THREE.Mesh(posGeo, posMat);
posMesh.visible = false;

const divCount = 50;
const angPart = (Math.PI * 2) / divCount,
  angUnit = (Math.PI * 2) / (divCount * divCount),
  angDetail = (Math.PI * 2) / (divCount * divCount * divCount);

export function GetShapePosArr(posArr, lastNode) {
  const pos2DArr = [],
    testGroup = new THREE.Group();
  var minDis = 1000,
    mainAng,
    partAng,
    manualAng;

  posArr.forEach((pos) => {
    const testNode = posMesh.clone();
    testNode.material = new THREE.MeshPhongMaterial({ color: 0x0000ff });
    testNode.position.set(
      pos.x - lastNode.x,
      pos.y - lastNode.y,
      pos.z - lastNode.z
    );
    testGroup.add(testNode);
  });

  for (let i = -Math.PI / 2; i <= Math.PI / 2; i += angPart) {
    for (let j = -Math.PI / 2; j <= Math.PI / 2; j += angPart) {
      testGroup.rotation.set(i, j, 0);
      const tempArr = [];
      testGroup.children.forEach((test) => {
        tempArr.push(test.getWorldPosition(new THREE.Vector3()));
      });
      const maxY = Math.max.apply(
        Math,
        tempArr.map(function (o) {
          return o.y;
        })
      );
      const minY = Math.min.apply(
        Math,
        tempArr.map(function (o) {
          return o.y;
        })
      );
      if (minDis > maxY - minY) {
        minDis = maxY - minY;
        mainAng = { x: i, y: j };
        manualAng = { x: i, y: j };
      }
    }
  }
  for (
    let i = mainAng.x - angPart / 2;
    i <= mainAng.x + angPart / 2;
    i += angUnit
  ) {
    for (
      let j = mainAng.y - angPart / 2;
      j <= mainAng.y + angPart / 2;
      j += angUnit
    ) {
      testGroup.rotation.set(i, j, 0);
      const tempArr = [];
      testGroup.children.forEach((test) => {
        tempArr.push(test.getWorldPosition(new THREE.Vector3()));
      });
      const maxY = Math.max.apply(
        Math,
        tempArr.map(function (o) {
          return o.y;
        })
      );
      const minY = Math.min.apply(
        Math,
        tempArr.map(function (o) {
          return o.y;
        })
      );
      if (minDis > maxY - minY) {
        minDis = maxY - minY;
        manualAng = { x: i, y: j };
      }
    }
  }

  testGroup.rotation.set(manualAng.x, manualAng.y, 0);
  testGroup.children.forEach((test) => {
    const posW = test.getWorldPosition(new THREE.Vector3());
    pos2DArr.push(posW);
  });
  pos2DArr.forEach((pos) => {
    pos.x = Math.round(pos.x);
    pos.y = Math.round(pos.y);
    pos.z = Math.round(pos.z);
  });
  return { pos2DArr, manualAng };
}

export function GetShapePath(pos2DArr) {
  const shapePath = new THREE.Shape(),
    lastPos = pos2DArr[pos2DArr.length - 1];
  shapePath.moveTo(lastPos.x, lastPos.z);
  pos2DArr.forEach((pos) => {
    shapePath.lineTo(pos.x, pos.z);
  });
  return shapePath;
}

export function GetShapeMesh(posArr, colStr, opa, thick) {
  const lastNode = posArr[posArr.length - 1];
  const { pos2DArr, manualAng } = GetShapePosArr(posArr, lastNode);
  const testShape = GetShapePath(pos2DArr);
  const extrudeSettings = { depth: thick || 1, bevelEnabled: false };
  const shapeGeo = new THREE.ExtrudeGeometry(testShape, extrudeSettings),
    shapeMat = new THREE.MeshStandardMaterial({
      color: colStr,
      transparent: true,
      opacity: opa,
    }),
    shapeMesh = new THREE.Mesh(shapeGeo, shapeMat);

  shapeGeo.translate(0, 0, thick / -2);
  return { shapeMesh, manualAng, lastNode };
}

export function GetDis(pos0, pos1, type = "3d") {
  if (type === "2d")
    return Math.sqrt(
      Math.pow(pos0.x - pos1.x, 2) + Math.pow(pos0.z - pos1.z, 2)
    );
  else
    return Math.sqrt(
      Math.pow(pos0.x - pos1.x, 2) +
        Math.pow(pos0.y - pos1.y, 2) +
        Math.pow(pos0.z - pos1.z, 2)
    );
}

export function GetRebar(posArr, lineR, color, opa) {
  const lastNode = posArr[posArr.length - 1];
  const { pos2DArr, manualAng } = GetShapePosArr(posArr, lastNode);
  const lineDis = GetDis(posArr[0], posArr[1]);
  const rebarGeo = new THREE.CylinderGeometry(lineR, lineR, lineDis),
    rebarMat = new THREE.MeshStandardMaterial({
      color,
      transparent: true,
      opacity: opa,
    }),
    rebarMesh = new THREE.Mesh(rebarGeo, rebarMat);
  rebarGeo.translate(0, lineDis / 2, 0);
  return { rebarMesh, manualAng, lastNode, pos2DArr };
}

export function AddRebar(
  group,
  posArr,
  width,
  depth,
  color,
  opa,
  type = "box"
) {
  const pos0 = posArr[0],
    pos1 = posArr[1],
    lineDis = GetDis(pos0, pos1);
  const rebarGroup = new THREE.Group(),
    rebarGeo =
      type === "box"
        ? new THREE.BoxGeometry(width * 1, depth * 1, lineDis) // width * 1, lineDis, depth * 1
        : new THREE.CylinderGeometry(width, width, lineDis, 18),
    // rebarGeo = new THREE.CylinderGeometry(lineR, lineR, lineDis),
    rebarMat = new THREE.MeshStandardMaterial({
      color,
      transparent: opa === 1 ? false : true,
      opacity: opa,
    }),
    rebarMesh = new THREE.Mesh(rebarGeo, rebarMat);
  if (type !== "box") {
    rebarMesh.rotation.x = Math.PI / 2;
  }
  rebarGroup.add(rebarMesh);
  rebarGroup.position.set(
    (pos0.x + pos1.x) / 2,
    (pos0.y + pos1.y) / 2,
    (pos0.z + pos1.z) / 2
  );

  rebarGroup.lookAt(pos1.x, pos1.y, pos1.z);
  group.add(rebarGroup);
}

export function AddManualMesh(group, mesh, manualAng, lastNode) {
  mesh.rotation.x = Math.PI / 2;
  const parTest = new THREE.Group();
  group.add(parTest);
  const subTest = new THREE.Group();
  parTest.add(subTest);
  subTest.add(mesh);
  subTest.rotation.set(-manualAng.x, 0, 0);
  parTest.rotation.set(0, -manualAng.y, 0);
  parTest.position.set(lastNode.x, lastNode.y, lastNode.z);
}

export function ExportJson(data) {
  const a = document.createElement("a");
  const json = JSON.stringify(data),
    blob = new Blob([json], { type: "octet/stream" }),
    url = window.URL.createObjectURL(blob);
  a.href = url;
  a.download = "test.json";
  a.click();
  window.URL.revokeObjectURL(url);
}
