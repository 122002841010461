import { Icon } from "@chakra-ui/react";
import { MdHome, MdPeopleAlt } from "react-icons/md";

import {
    AiOutlineFundProjectionScreen,
    AiFillContacts,
    AiFillSetting,
} from "react-icons/ai";

import { FaGraduationCap, FaUserAltSlash, FaUserCheck } from "react-icons/fa";
import Dashboard from "layouts/dashboard";
import Project from "layouts/dashboard/Project";
import Settings from "layouts/dashboard/Settings";
import Contact from "layouts/dashboard/Contact";
import { Dashboard as AdminDashboard } from "layouts/Admin";
import { ApprovedUsers } from "layouts/Admin/ApproveUsers";
import { RejectedUsers } from "layouts/Admin/RejectUsers";
import ProjectDash from "layouts/dashboard/ProjectDash";
import FeedBackForm from "components/feedback";

const routes = [
    {
        name: "Dashboard",
        layout: "/user",
        path: "/dashboard",
        icon: <Icon as={MdHome} width="25px" height="25px" color="inherit" />,
        component: ProjectDash,
    },
    {
        name: "Project",
        layout: "/user",
        path: "/calculation",
        icon: (
            <Icon
                as={AiOutlineFundProjectionScreen}
                width="25px"
                height="25px"
                color="inherit"
            />
        ),
        component: ProjectDash,
        secondary: true,
    },
    {
        name: "Settings",
        layout: "/user",
        icon: (
            <Icon as={AiFillSetting} width="25px" height="25px" color="inherit" />
        ),
        path: "/settings",
        component: Settings,
    },
    {
        name: "E-learning",
        layout: "/user",
        icon: (
            <Icon as={FaGraduationCap} width="25px" height="25px" color="inherit" />
        ),
        path: "/elearning",
        component: "elearning",
    },
    {
        name: "Contact Us",
        layout: "/user",
        icon: (
            <Icon as={AiFillContacts} width="25px" height="25px" color="inherit" />
        ),
        path: "/contact",
        component: FeedBackForm,
    },
];

export const adminRoutes = [
    {
        name: "Admin Dashboard",
        layout: "/admin",
        path: "/dashboard",
        icon: <Icon as={MdHome} width="25px" height="25px" color="inherit" />,
        component: AdminDashboard,
    },
    {
        name: "Approved Users",
        layout: "/admin",
        path: "/approves",
        icon: <Icon as={FaUserCheck} width="25px" height="25px" color="inherit" />,
        component: ApprovedUsers,
    },
    {
        name: "Rejected Users",
        layout: "/admin",
        path: "/rejects",
        icon: (
            <Icon as={FaUserAltSlash} width="25px" height="25px" color="inherit" />
        ),
        component: RejectedUsers,
    },
];
export default routes;
