import { useEffect } from "react";
import { useState } from "react";
import { updateFile } from "store/slices/entities/projectCanvas";

const convertToGeometry = (openings) => {
    return openings?.map((opening, index) => ({
        id: index + 1,
        centerX: opening[0],
        centerY: opening[1],
        width: opening[2],
        depth: opening[3],
    }));
}


const Openings = ({ data, editable, fontSize }) => {

    const initial = convertToGeometry(data?.openings);
    const cellStyle = {
        fontSize: fontSize,
        border: "1px solid #ddd",
        padding: "4px"
    };
    const [geometry, setGeometry] = useState(initial);

    const handleChange = (e, id, field) => {
        const { value } = e.target;
        setGeometry(prevGeometry => {
            const updatedGeometry = prevGeometry.map(opening =>
                opening.id === id ? { ...opening, [field]: value } : opening
            );

            // Update converted based on the new geometry
            const newConverted = updatedGeometry.map(({ centerX, centerY, width, depth }) => [
                Number(centerX),
                Number(centerY),
                Number(width),
                Number(depth)
            ]);

            const newData = { ...data, openings: newConverted };
            updateFile(newData);

            return updatedGeometry;
        });

    };



    return (
        <table border="1" style={{ fontSize: fontSize, marginBottom: "6px", borderCollapse: "collapse", background: "white" }}>
            <thead>
                <tr style={{ textAlign: "center", background: "#f8f9fa" }}>
                    <th colSpan="5" style={cellStyle}>Opening Geometry</th>
                </tr>
                <tr style={{ textAlign: "center" }}>
                    <th style={cellStyle}>Opening ID</th>
                    <th style={cellStyle}>Center X</th>
                    <th style={cellStyle}>Centre Y</th>
                    <th style={cellStyle}>Width</th>
                    <th style={cellStyle}>Depth</th>
                </tr>
            </thead>
            <tbody style={{ textAlign: "center" }}>
                {geometry?.map((opening, index) => (
                    <tr key={index} style={{ fontSize: fontSize, border: "1px solid #ddd" }}>
                        <td style={cellStyle}>{opening.id}</td>
                        <td style={cellStyle}>
                            {editable ? (
                                <input
                                    type="number"
                                    value={opening.centerX}
                                    onChange={(e) => handleChange(e, opening.id, 'centerX')}
                                    style={{ width: "100%", color: "dodgerblue" }}
                                />
                            ) : (
                                opening.centerX
                            )}
                        </td>
                        <td style={cellStyle}>
                            {editable ? (
                                <input
                                    type="number"
                                    value={opening.centerY}
                                    onChange={(e) => handleChange(e, opening.id, 'centerY')}
                                    style={{ width: "100%", color: "dodgerblue" }}
                                />
                            ) : (
                                opening.centerY
                            )}
                        </td>
                        <td style={cellStyle}>
                            {editable ? (
                                <input
                                    type="number"
                                    value={opening.width}
                                    onChange={(e) => handleChange(e, opening.id, 'width')}
                                    style={{ width: "100%", color: "dodgerblue" }}
                                />
                            ) : (
                                opening.width
                            )}
                        </td>
                        <td style={cellStyle}>
                            {editable ? (
                                <input
                                    type="number"
                                    value={opening.depth}
                                    onChange={(e) => handleChange(e, opening.id, 'depth')}
                                    style={{ width: "100%", color: "dodgerblue" }}
                                />
                            ) : (
                                opening.depth
                            )}
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    )
}

export default Openings;
