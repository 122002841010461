import React from "react";

import { HelmetProvider, Helmet } from "react-helmet-async";
import { ToastContainer } from "react-toastify";
import { useSelector } from "react-redux";
import {
  Switch,
  BrowserRouter as Router,
  Route,
  Redirect,
} from "react-router-dom";

//styles
import "react-toastify/dist/ReactToastify.css";
import "styles/assets/css/App.css";

//components
import Dashboard from "./Dashboard";
import LandingPage from "./LandingPage";
import { getAuth } from "store/selector.js";
import Terms from "layouts/landingpage/Terms";
import AdminDashboard from "./AdminDashboad";

function App() {
  const { isAuthenticated, isAdminUser } = useSelector(getAuth);

  return (
    <HelmetProvider>
      <Helmet>
        <title>TEDAPS</title>
      </Helmet>
      <Router>
        <Switch>
          <Route
            exact
            path="/"
            render={(props) =>
              isAdminUser ? (
                <>{(window.location.href = "/admin/dashboard")}</>
              ) : isAuthenticated ? (
                <>{(window.location.href = "/user/dashboard")}</>
              ) : (
                <LandingPage {...props} />
              )
            }
          />
          <Route
            path="/user"
            render={(props) =>
              isAuthenticated ? <Dashboard {...props} /> : <Redirect to="/" />
            }
          />
          <Route
            path="/admin"
            render={(props) =>
              isAdminUser ? <AdminDashboard {...props} /> : <Redirect to="/" />
            }
          />
          <Route path={"/terms"} children={<Terms />} />
        </Switch>
      </Router>

      <ToastContainer />
    </HelmetProvider>
  );
}

export default App;
