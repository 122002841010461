import { Box } from "@chakra-ui/react";

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { getCanvasDetails } from "store/selector";
import {
    loadFileDetails,
    removeUpdatedFile,
} from "store/slices/entities/projectCanvas";
import { column } from "utils/dataFormat";
import { getTableFormat } from "utils/formatter";
import Expander from "./Expander";
import DataTable from "./DataTable";

function ColumnHome({
    selectedRows,
    setSelectedRows,
    expandedRow,
    setExpandedRow,
    sidebarExpanded,
}) {
    const { selectedFile, fileDetails, loading } = useSelector(getCanvasDetails);
    const columns = getTableFormat(column);

    useEffect(() => {
        if (selectedFile.calc_type == 'Column') {
            selectedFile.link && loadFileDetails(selectedFile.link);
        }
    }, [selectedFile]);

    return (
        <Box
            borderRadius={18}
            // border={"1px solid red"}
            overflow={"hidden"}
            bgColor={"white"}
            mt={"0.5rem"}
            overflowX={"auto"}
            maxWidth={"100%"}
        >
            <DataTable
                selection
                expand
                showGridlines
                columnFontSize="16px"
                selectedRows={selectedRows}
                setSelectedRows={setSelectedRows}
                expandedRow={expandedRow}
                setExpandedRow={setExpandedRow}
                tableValue={fileDetails?.rows}
                columns={columns}
                loading={loading}
                sidebarExpanded={sidebarExpanded}
                ExpandTemplate={Expander}
                emptyMessage="No rows found !"
                // border="1px solid black"
                // columnPadding={"1rem 1rem"}
                //columnPadding={"0.5rem 0.5rem"}
                onRowCollapse={(e) => {
                    removeUpdatedFile(e.data?.url);
                    setExpandedRow(null)
                }}
                responsiveLayout={"stack"}
            />
        </Box>
    );
}

export default ColumnHome;
