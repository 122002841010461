import { Avatar, Box, Flex, Menu, MenuButton, MenuItem, MenuList, Stack, Text } from "@chakra-ui/react";
//   Custom components
import Brand from "./Brand";
import Links from "./Links";
import React from "react";
import store from "store/store";
import { logOutUser } from "store/slices/auth/user";
// FUNCTIONS

function SidebarContent(props) {
    const { routes } = props;
    const handleLogout = () => {
        store.dispatch(logOutUser());
    };
    // SIDEBAR
    return (
        <Flex direction="column" height="100%" pt="25px" borderRadius="30px" position="relative" zIndex={999}>
            <Brand />
            <Stack direction="column" mb="auto" mt="8px">
                <Box ps="20px" pe={{ md: "16px", "2xl": "1px" }}>
                    <Links routes={routes} />
                </Box>
                <Box ps="12px" pe={{ md: "14px", "2xl": "1px" }} bottom={"30"} position={"absolute"}>
                    <Box zIndex={999}>
                        <Menu>
                            <MenuButton p="0px">
                                <Avatar
                                    _hover={{ cursor: "pointer" }}
                                    color="white"
                                    bg={"rgba(51,117,184,1)"}
                                    size="sm"
                                    w="25px"
                                    h="25px"
                                    name={"Navya"}
                                />
                            </MenuButton>
                            <MenuList
                                p="0px"
                                mt="10px"
                                borderRadius="20px"
                                border="none"
                                zIndex={1000} // Ensure this is higher than any surrounding content
                            >
                                <Flex w="100%" mb="0px">
                                    <Text
                                        ps="20px"
                                        pt="16px"
                                        pb="10px"
                                        w="100%"
                                        textTransform={"capitalize"}
                                        borderBottom="1px solid"
                                        fontSize="sm"
                                        fontWeight="700"
                                    >
                                        {/* 👋&nbsp; Hey, {profile?.username} */} Hey there,
                                    </Text>
                                </Flex>
                                <Flex flexDirection="column" p="10px">
                                    <MenuItem
                                        _hover={{ bg: "none" }}
                                        _focus={{ bg: "none" }}
                                        color="red.400"
                                        borderRadius="8px"
                                        px="14px"
                                    >
                                        <Text fontSize="sm" onClick={handleLogout}>Logout</Text>
                                    </MenuItem>
                                </Flex>
                            </MenuList>
                        </Menu>
                    </Box>
                </Box>
            </Stack>
        </Flex>
    );
}

export default SidebarContent;
