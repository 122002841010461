import { Box, Button, Grid, Text, useDisclosure } from "@chakra-ui/react";
import { FiRefreshCw, FiSend } from "react-icons/fi";
import { AiFillDelete, AiOutlinePlus } from "react-icons/ai";
import React from "react";
import { useState } from "react";
import { useSelector } from "react-redux";

import DataTable from "components/DataTable";
import { ColumnObj } from "components/project/utils/tableColumns";
import { getSettings } from "store/selector";
import {
    addMaterial,
    deleteMaterial,
    loadProjectMaterials,
    loadProjectSettingsDetails,
    saveMaterials,
    updateMaterial,
} from "store/slices/entities/settings";
import { materials } from "utils/dataFormat";
import { colors } from "components/misc/colors";
import { Toast } from "utils/Toast";
import Modal from "components/common/Modal";
import { useEffect } from "react";
import { Item } from "@radix-ui/react-navigation-menu";

function MaterialProperties({ project }) {
    const [selectedRow, setSelectedRow] = useState([]);
    const { projectMaterials, projectDetails } = useSelector(getSettings);

    useEffect(() => {
        if (project) {
            loadProjectSettingsDetails(project?.id);
        }
    }, [project])

    useEffect(() => {
       if(projectDetails){
        loadProjectMaterials(projectDetails?.id);
       }
    },[projectDetails])


    const columnsGroup = ColumnObj["Material Properties"]();

    const columns = [
        { header: "Name", field: "name" },
        { header: "Material", field: "material" },
        { header: "Density", field: "density" },
        { header: "Mpa", field: "modulus_of_elasticity" },
        { header: "Possions ratio", field: "poissions_ratio" },

        { header: "", field: "coeff_of_thermal_expansion" },
        { header: "", field: "shear_modulus" },
        { header: "", field: "compressive_strength_cube_test" },
        { header: "MPA", field: "compressive_strength_cylinder_test" },

        { header: "", field: "light_weight_lambda_factor" },
        { header: "MPA", field: "min_yield_stress" },

        { header: "MPA", field: "min_tensile_stress" },
    ];

    const onChange = ({ target: { value, name, id } }) => {

        const updatedValue = projectMaterials?.map((material) => {

            if (material?.id == id) {
                const obj = { ...material };
                obj[name] = value;
                obj["updated"] = true;
                return obj;
            } else {
                return material;
            }
        });

        updateMaterial(updatedValue);
    };

    const onSubmit = () => {
        const newRows = projectMaterials.filter(
            (material) => material.rowType === "new"
        );
        const updatedRows = projectMaterials.filter((material) => material.updated);
        const dataLists = [...newRows, ...updatedRows];
        if (dataLists?.length === 0)
            return Toast("There is no change on material rows", "warning");
        saveMaterials(dataLists);
    };
    const onAddRow = () => {
        const data = {
            name: "temp_name",
            projectsetting: projectDetails?.id,
        };
        addMaterial(projectDetails?.materials, data);
    };

    const { isOpen, onClose, onOpen } = useDisclosure();

    const onDelete = () => {
        if (selectedRow?.length === 0)
            return Toast("No rows has selected!", "warning");

        const deleteItems = selectedRow?.map((item) => item.id);
        deleteMaterial(deleteItems);
        onClose(true)
        setSelectedRow([])
    };

    const onRefresh = () => loadProjectMaterials(projectDetails?.id);

    const onCellEditInit = (e) => {
        if (e.rowData.disabled) {
            e.preventDefault();
        }
    };

    const buttons = [
        {
            text: "New",
            icon: <AiOutlinePlus />,
            onClick: onAddRow,
            color: colors.add,
        },
        {
            text: "Submit",
            icon: <FiSend />,
            onClick: onSubmit,
            color: colors.submit,
        },
        {
            text: "Delete",
            icon: <AiFillDelete />,
            onClick: onOpen,
            color: colors.delete,
        },
        {
            text: "Refresh",
            icon: <FiRefreshCw />,
            onClick: onRefresh,
            color: colors.refresh,
        },
    ];

    return (
        <>
            <Box p="3">
                <Box mb={4}>
                    <Grid
                        gap={3}
                        display={"flex"}
                        justifyContent={{ sm: "center" }}
                        flexWrap={{ sm: "wrap", lg: "nowrap" }}
                    >
                        {buttons.map((btn, i) => (
                            <Button
                                size={"sm"}
                                textColor={btn.color}
                                borderColor={colors.buttonBG}
                                key={i}
                                onClick={btn.onClick}
                                leftIcon={btn.icon}
                                disabled={btn?.disabled}
                            >
                                {btn.text}
                            </Button>
                        ))}
                    </Grid>
                </Box>
                <Box>
                    <DataTable
                        editable
                        selection
                        showGridlines
                        columnFontSize="16px"
                        selectionMode="checkbox"
                        selectionAutoFocus={false}
                        formatObj={materials}
                        onChange={onChange}
                        tableValue={projectMaterials}
                        columns={columns}
                        columnGroup={columnsGroup}
                        selectedRows={selectedRow}
                        setSelectedRows={setSelectedRow}
                        header="Material Properties"
                        onCellEditInit={onCellEditInit}
                    />
                </Box>
            </Box>
            <Modal
                isOpen={isOpen}
                modalHead="Confirm"
                children={<Text>Are you sure you want to delete these rows ?</Text>}
                onClose={onClose}
                onProcced={onDelete}
                confirmText="Delete"
            />
        </>
    );
}

export default MaterialProperties;
