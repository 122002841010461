import { Text } from "@chakra-ui/react";
import React from "react";

function DefaultField({ value, name, onClick, color, rowId, reference }) {
    const customFields = [
        "compressive_strength_cube_test",
        "compressive_strength_cylinder_test",
        "light_weight_lambda_factor",
        "min_yield_stress",
        "min_tensile_stress",
    ];
    return (
        <>
            {(name && (
                <Text color={color} onClick={() => onClick({ name, rowId })}>
                    {value}
                </Text>
            )) || <Text>{customFields.includes(reference) ? 'N/A' : value}</Text>}
        </>
    );
}

export default DefaultField;
