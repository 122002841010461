import { Column } from "primereact/column";
import { ColumnGroup } from "primereact/columngroup";
import { Row } from "primereact/row";

const endColumns = () => (
  <ColumnGroup>
    <Row>
      <Column
        header="End Condition"
        field="c1"
        style={{ whiteSpace: "nowrap", padding: "0.3rem " }}
      />
      <Column header="Above" field="c2" />
      <Column header="Below" field="c3" />
    </Row>
  </ColumnGroup>
);

const columnData = () => (
  <ColumnGroup>
    <Row>
      <Column header="Column Details" field="c1" rowSpan={2} />
      <Column header="Column" rowSpan={1} colSpan={2} />
    </Row>
    <Row>
      <Column
        header="Above"
        field="c2"
        style={{ whiteSpace: "nowrap", padding: "0.3rem " }}
      />
      <Column
        header="Below"
        field="c3"
        style={{ whiteSpace: "nowrap", padding: "0.3rem " }}
      />
    </Row>
  </ColumnGroup>
);



const beamDataY = () => (
  <ColumnGroup>
    <Row>
      <Column
        header="Beam Details"
        field="c1"
        rowSpan={2}
        style={{ whiteSpace: "nowrap", padding: "0.3rem " }}
      />
      <Column header="Beams parallel to Y axis" rowSpan={1} colSpan={4} />
    </Row>
    <Row>
      <Column
        header="A-Left"
        field="c2"
        style={{ whiteSpace: "nowrap", padding: "0.3rem " }}
      />
      <Column
        header="A-Right"
        field="c3"
        style={{ whiteSpace: "nowrap", padding: "0.3rem " }}
      />
      <Column
        header="B-Left"
        field="c4"
        style={{ whiteSpace: "nowrap", padding: "0.3rem " }}
      />
      <Column
        header="B-Right"
        field="c5"
        style={{ whiteSpace: "nowrap", padding: "0.3rem " }}
      />
    </Row>
  </ColumnGroup>
);

const beamDataZ = () => (
  <ColumnGroup>
    <Row>
      <Column
        header="Beam Details"
        field="c1"
        rowSpan={2}
        style={{ whiteSpace: "nowrap", padding: "0.3rem " }}
      />
      <Column header="Beams parallel to Z axis" rowSpan={1} colSpan={4} />
    </Row>
    <Row>
      <Column
        header="A-Left"
        field="c2"
        style={{ whiteSpace: "nowrap", padding: "0.3rem " }}
      />
      <Column
        header="A-Right"
        field="c3"
        style={{ whiteSpace: "nowrap", padding: "0.3rem " }}
      />
      <Column
        header="B-Left"
        field="c4"
        style={{ whiteSpace: "nowrap", padding: "0.3rem " }}
      />
      <Column
        header="B-Right"
        field="c5"
        style={{ whiteSpace: "nowrap", padding: "0.3rem " }}
      />
    </Row>
  </ColumnGroup>
);



const loadDesign = () => (
  <ColumnGroup >
    <Row>
      <Column rowSpan={3}  style={{ width: "100px" }} header={""} />
      <Column header="No" rowSpan={3} field="No" />
      <Column header="Load No" rowSpan={3} field="load_no" />
      <Column header="N (kN)" rowSpan={2} />
      <Column header="T(kN-m)" rowSpan={2} />
      <Column header="Vz(kN)" rowSpan={2} />
      <Column header="Vy(kN)" rowSpan={2} />
      <Column header="Top" colSpan={2} />
      <Column header="Bottom" colSpan={2} />
      <Column header="(Bds) %" rowSpan={3} />
      <Column header="(Bds) %" rowSpan={3} />
      {/* <Column header="Axial Force and Biaxial Bending factors" colSpan={12} /> */}
      <Column header="Z axis" field="" colSpan={6} />
      <Column header="Y axis" field="" colSpan={6} />
      <Column header="P-M-M Ratio" rowSpan={3} />
      <Column header="Vz/ ØVn'z" rowSpan={3} />
      <Column header="Vy/ØVn'y" rowSpan={3} />
      <Column header="T/ØTn" rowSpan={3} />
      <Column header="Tch" rowSpan={3} />
      <Column header="Remarks" rowSpan={3} />
    </Row>
    <Row>
      <Column header="My(KN)" field="" />
      <Column header="Mz(KN)" field="" />
      <Column header="My(KN)" field="" />
      <Column header="Mz(KN)" field="" />
      <Column header="Ds" field="" />
      <Column header="Cm" field="" />
      <Column header="Dns" field="" />
      <Column header="Mu" field="" />
      <Column header="Mmin(top)" field="" />
      <Column header="Mmin(bottom)" field="" />

      <Column header="Ds" field="" />
      <Column header="Cm" field="" />
      <Column header="Dns" field="" />
      <Column header="Mu" field="" />
      <Column header="Mmin(top)" field="" />
      <Column header="Mmin(bottom)" field="" />
      
    </Row>
  </ColumnGroup>
);

const materilProperties = () => (
  <ColumnGroup>
    <Row>
      <Column header="" rowSpan={3} field="" />
      <Column header="Name" rowSpan={3} field="name" />
      <Column header="Material" rowSpan={3} field='material' />
      <Column header="Density" rowSpan={3} field='density' />
      <Column header="Modulus of Elasticity (Mpa)"  rowSpan={2} />
      <Column header="Possions ratio" rowSpan={2} />
      <Column header="Coeff. Of thermal expansion" rowSpan={2} />
      <Column header="Shear Modulus" rowSpan={2} />
      <Column header="" colSpan={2} />
      <Column header="Light weight lambda factor" rowSpan={2} />
      <Column header="Yield strength (Mpa)" rowSpan={2} />
      <Column header="Tensile strength (Mpa)" rowSpan={2} />
    </Row>
    <Row>
      <Column header="Cube strength" colSpan={1} />
      <Column header="Cylinder strength" colSpan={1} />
    </Row>
  </ColumnGroup>
);

export const ColumnObj = {
  //end condition table
  "End Condition": endColumns,
  Column: columnData,
  "Beams parallel to Z axis": beamDataZ,
  "Beams parallel to Y axis": beamDataY,

  //load design
  "Load Design": loadDesign,

  //settings
  "Material Properties": materilProperties,
};
