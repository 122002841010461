import React from "react";

function Loading({
  isloading,
  height,
  borderColor = "rgb(255, 255, 255) transparent transparent transparent",
}) {
  return (
    <>
      {isloading && (
        <div className="lds-ring" style={{ height, borderColor }}>
          <div style={{ borderColor }} />
          <div style={{ borderColor }} />
          <div style={{ borderColor }} />
          <div style={{ borderColor }} />
        </div>
      )}
    </>
  );
}

export default Loading;
