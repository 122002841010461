import React from "react";
import { useSelector } from "react-redux";

import { getEndConditionValues } from "utils/formatter";
import DataTable from "components/DataTable";
import { ColumnObj } from "components/project/utils/tableColumns";
import { getEndCondition } from "store/selector";
import { updateEndDetails } from "store/slices/entities/endCondition";

function EndTable({ tableName, columns, ...props }) {
  const { endLists, loading } = useSelector(getEndCondition);

  const values = getEndConditionValues(endLists[tableName]);
  const columnGroup = ColumnObj[tableName]();

  const onChange = ({ target: { value, name, id } }) => {
    let updateField = { ...endLists[tableName][id] };
    updateField[name] = value;

    let allFields = { ...endLists[tableName] };
    allFields[id] = updateField;

    let allTables = { ...endLists };
    allTables[tableName] = allFields;

    updateEndDetails(allTables);
  };

  return (
    <DataTable
      editable
      style={{ fontSize: "14px" }}
      columnFontSize="14px"
      tableValue={values}
      columns={columns}
      columnGroup={columnGroup}
      onChange={onChange}
      {...props}
    />
  );
}

export default EndTable;
