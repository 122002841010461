import React from "react";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading } from "components/misc/Headings";

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;
const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3 my-4 text-justify`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;

const Terms = ({ headingText = "Terms And Condition" }) => {
  return (
    <Container>
      <ContentWithPaddingXl>
        <HeadingRow>
          <Heading>{headingText}</Heading>
        </HeadingRow>
        <Text>
          <p>Last updated: July 2, 2022</p>

          <p>End User License Agreement</p>

          <p tw="font-bold">
            This agreement is between you and TEDAPS. By using any TEDAPS design
            modules, you understand and agree to the following terms and
            conditions. You must not use this software, if you do not agree to
            all the terms and conditions of this Agreement or if you do not have
            the authority to agree to all the terms and conditions of this
            Agreement on behalf of the licensee.
          </p>

          <h2>Definitions</h2>
          <ul>
            <li>
              The TEDAPS services, all website contents and design calculation
              modules are the intellectual property of TEDAPS. TEDAPS accounts
              are not to be shared without our consent, and website contents and
              results are not to be redistributed as the work of another party.
              Any violations of this will be considered as an infringement of
              Copyright laws.
            </li>
            <li>
              As a registered user, you agree to have your personal information
              collected
            </li>
            <li>
              TEDAPS accounts must not be shared and the same is to be used by a
              single user only. You will experience lockouts or suspensions if
              the account is used simultaneously in two or more locations or on
              more than a pre-determined number of devices or IP addresses per
              month. This is to protect unlawful use of TEDAPS products and
              software.{" "}
            </li>
            <li>
              TEDAPS has the right to postpone or suspend the free accounts and
              unlimited free access without notice.
            </li>
            <li>
              The TEDAPS Student and budding engineers account shall be used for
              educational purpose only. TEDAPS reserves the right to suspend the
              account at any time, if found used for commercial purpose.{" "}
            </li>
            <li>
              TEDAPS has the right to request student information from the user
              at any time to verify the user's student status on a TEDAPS
              Student Account. This information is purely used to verify their
              student status and is not to be stored or shared.
            </li>
            <li>
              You, at your sole expense, will defend, indemnify and hold TEDAPS
              harmless from and with respect to losses or damages (including but
              not limited to all fees and costs) incurred in connection with,
              <ul tw="ml-10">
                <li>
                  any suit or proceeding brought by a third party against TEDAPS
                  insofar as such suit or proceeding based upon any claim
                  arising out of or relating to your use of the Product, except
                  where such claim alleges that the Software infringes or
                  constitutes wrongful use of any copyright, trade secret,
                  patent or trade mark of any third party; and/or
                </li>
                <li>
                  any claim arising out of or in connection with any act or
                  omission by the user
                </li>
              </ul>
            </li>
            <li>
              A party shall not be liable for any failure of or delay in the
              performance of this Agreement for the period that such failure or
              delay is due to causes beyond its reasonable control, including
              but not limited to acts of God, war, strikes or labor disputes,
              embargoes, government orders, malware attacks or any other force
              majeure event.
            </li>
            <li>
              TEDAPS reserves the right to use your calculations and ﬁles as
              marketing material in the visual mediums. To opt out contact us
              via <strong>Request@tedaps.com</strong>
            </li>
            <li>
              You agree to accept communication in the form of personal and
              marketing emails. You can opt out of these at any time by
              contacting us via <strong>Request@tedaps.com</strong>
            </li>
            <li>
              The TEDAPS design modules, web and cloud services are under
              testing and evaluation phase. As such, we oﬀer no guarantees.
            </li>
            <li>Please remove any sensitive ﬁles after use. </li>
            <li>
              We reserve the right to delete data ﬁles from the service without
              notice.{" "}
            </li>
            <li>Please download any valuable information after use. </li>
            <li>
              We reserve the right to suspend or terminate the service without
              notice.{" "}
            </li>
            <li>
              This is a test phase of this service and will be available free of
              cost for a limited time. On conclusion of this testing phase, we
              will re-evaluate the situation, and the feedback received. Users
              will be notified about the further plans.{" "}
            </li>
            <li>
              TEDAPS website contents and calculation modules are under
              development and testing phase. As such there could be automatic
              changes to the website contents and calculation modules without
              prior notice.{" "}
            </li>
            <li>
              As a user of TEDAPS software, particularly professional engineers,
              you agree to use suﬃcient duty of care when using TEDAPS products.
              This includes not preparing engineering calculations outside of
              your abilities and knowledge as an engineer. As with all
              engineering software, one should always question the results and
              perform suﬃcient checks that the results are logical, correct and
              in line with your engineering judgment. TEDAPS and its individual
              creators take no responsibility for any damages, liabilities or
              consequences resulting from any use of our products/services.
            </li>
            <li>
              Results from TEDAPS should always be double-checked when applied
              in real-life applications. As a TEDAPS user, we would like to
              reaffirm loudly that it is your responsibility to verify and
              validate the output of the software with some other independent
              means and/or to get reviewed and signed oﬀ by a professional and
              competent engineer / subject matter expert in the respective field
              of engineering.
            </li>
            <li>
              As with any software there may be bugs or errors that cause it to
              function incorrectly, at any point of time. As a user you
              understand that although all TEDAPS Software goes through
              extensive testing, the risk of deviated results due to software
              failure is still possible, and no damages, liabilities or
              consequences shall be claimed to TEDAPS.
            </li>
          </ul>

          <h1>Contact Us</h1>
          <p>
            If you have any questions or concerns about our policies, terms, and
            conditions, please do not hesitate to contact us:
            
          </p>

          <ul>
            <li>By email: contact@tedaps.com</li>
          </ul>
        </Text>
      </ContentWithPaddingXl>
    </Container>
  );
};

export default Terms;
