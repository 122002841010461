import { Box, Button, Checkbox, Flex, Input, Stack, Text, Textarea } from "@chakra-ui/react";
import { Table, Thead, Tbody, Tr, Th, Td, RadioGroup, Radio } from '@chakra-ui/react';

import { useState } from "react";
import tw from "twin.macro";

const Container = tw.div`relative p-6 lg:p-0 `;
const Content = tw.div`max-w-3xl mx-auto `;

const FeedBackForm = () => {
    const feedbackOptions = ['Very satisfied', 'Satisfied', 'Neutral', 'Unsatisfied', 'Very unsatisfied'];
    const feedbackCriteria = ['Ease of use', 'User Interface Design', 'Project based setup', 'Performance & speed', 'Accuracy of calculations', 'Overall Satisfaction'];

    const [feedback, setFeedback] = useState({
        'Ease of use': '',
        'User Interface Design': '',
        'Project based setup': '',
        'Performance & speed': '',
        'Accuracy of calculations': '',
        'Overall Satisfaction': '',
    });
    const [feedbackDescription, setFeedbackDescription] = useState('');
    const [fullName, setFullName] = useState('');
    const [emailAddress, setEmailAddress] = useState('');
    const [feedbackType, setFeedbackType] = useState({
        comments: false,
        suggestions: false,
        questions: false,
    });

    const handleChange = (criteria, value) => {
        setFeedback({ ...feedback, [criteria]: value });
    };


    const handleSubmit = () => {
        const email = "contact@tedaps.com";
        const subject = "Feedback";
        const _feedback = Object.entries(feedback)
            .map(([key, value]) => `${key}: ${value}`)
            .join('\n');
        const body = `
Feedback Type:
${feedbackType.comments ? "Comments," : ""}${feedbackType.suggestions ? ", Suggestions" : ""}${feedbackType.questions ? ", Questions" : ""}

Describe Your Feedback:
${feedbackDescription}

Full Name: ${fullName}
Email Address: ${emailAddress}

Overall Satisfaction
${_feedback}
`;

        const mailtoLink = `mailto:${email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
        window.location.href = mailtoLink;
    }

    const handleCheckboxChange = (type) => {
        setFeedbackType({ ...feedbackType, [type]: !feedbackType[type] });
    };



    return (
        <Box
            flexDirection={{ sm: "column", lg: "row" }}
            justifyContent={{ sm: "start", md: "space-evenly" }}
            minH="100vh"
            pt={{ sm: "100px", md: "75px" }}
        >
            <Container>
                <Content>
                    <Flex direction={"column"} p="2rem" border={"1px solid #ddd"} background={"white"} borderTopRadius={"6px"}>
                        <Flex direction={"column"}>
                            <Text fontSize={"x-large"} fontWeight={"bold"}>
                                Feedback Form
                            </Text>
                            <Text color={"gray.600"}>
                                We would love to hear your thoughts, suggestions, concerns, or any problems you encountered. Your feedback is invaluable in helping us improve our structural engineering calculation software!
                            </Text>
                            <div style={{ border: "1px solid #ddd", marginTop: "16px" }}></div>
                        </Flex>
                        <Flex textAlign={"left"} mt={8} direction={"column"}>
                            <Text fontWeight={"bold"}>Feedback Type</Text>
                            <Stack spacing={5} direction='row' mt={2}>
                                <Checkbox isChecked={feedbackType.comments} onChange={() => handleCheckboxChange('comments')}>
                                    Comments
                                </Checkbox>
                                <Checkbox isChecked={feedbackType.suggestions} onChange={() => handleCheckboxChange('suggestions')}>
                                    Suggestions
                                </Checkbox>
                                <Checkbox isChecked={feedbackType.questions} onChange={() => handleCheckboxChange('questions')}>
                                    Questions
                                </Checkbox>
                            </Stack>
                        </Flex>
                        <Flex textAlign={"left"} mt={8} direction={"column"}>
                            <Text fontWeight={"bold"}>Describe Your Feedback:</Text>
                            <Textarea value={feedbackDescription} onChange={(e) => setFeedbackDescription(e.target.value)} placeholder='' mt={2} />
                        </Flex>
                        <Flex textAlign={"left"} mt={4} direction={"column"}>
                            <Text fontWeight={"bold"}>Full Name:</Text>
                            <Input value={fullName} onChange={(e) => setFullName(e.target.value)} placeholder='' mt={2} />
                        </Flex>
                        <Flex textAlign={"left"} mt={4} direction={"column"}>
                            <Text fontWeight={"bold"}>Email Address:</Text>
                            <Input value={emailAddress} onChange={(e) => setEmailAddress(e.target.value)} placeholder='' mt={2} />
                        </Flex>
                        <Flex mt={4} direction={"column"}>
                            <Text fontWeight={"bold"}>
                                Overall satisfaction
                            </Text>
                            <Table variant="simple" size='sm' mt={4}>
                                <Thead>
                                    <Tr>
                                        <Th fontSize={"x-small"}></Th>
                                        {feedbackOptions.map(option => (
                                            <Th key={option} background={"gray.200"} border="1px solid #ddd" fontSize={"x-small"}>{option}</Th>
                                        ))}
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {feedbackCriteria.map(criteria => (
                                        <Tr key={criteria}>
                                            <Td background={"gray.200"} fontSize={"small"} border="1px solid #ddd"> {criteria}</Td>
                                            {feedbackOptions.map(option => (
                                                <Td key={option} textAlign={"center"} border="1px solid #ddd">
                                                    <RadioGroup
                                                        onChange={value => handleChange(criteria, value)}
                                                        value={feedback[criteria]}
                                                    >
                                                        <Radio value={option} />
                                                    </RadioGroup>
                                                </Td>
                                            ))}
                                        </Tr>
                                    ))}
                                </Tbody>
                            </Table>
                        </Flex>
                        <Flex>
                            <Button colorScheme='blue' mt={6} onClick={handleSubmit}>Send Feedback</Button>
                        </Flex>
                    </Flex>
                </Content></Container></Box>


    )
}

export default FeedBackForm;