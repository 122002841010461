import React, { useEffect } from "react";
import "tailwindcss/dist/base.css";

import Hero from "layouts/landingpage/Hero";
import About from "layouts/landingpage/About";
import Services from "layouts/landingpage/Services";
import ContactUs from "layouts/landingpage/ContactUs";
import Footer from "layouts/landingpage/Footer";

function LandingPage() {
  return (
    <>
      <Hero />
      <section id="about">
        <About />
      </section>
      <section id="resource">
        <Services />
      </section>
      <section id="contactus">
        <ContactUs />
      </section>
      <Footer />
    </>
  );
}

export default LandingPage;
