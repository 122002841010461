import { Box, Grid, GridItem } from "@chakra-ui/react";
import React from "react";
import SettingsTable from "../SettingsTable";

function ConcreteDesign() {
  return (
    <Grid gap={4} templateColumns="repeat(3, 1fr)" p={3}>
      <GridItem rowSpan={3} colSpan={1} height={"2rem"}>
        <SettingsTable
          tableName={"Strength Reduction (Ø)"}
          columnPadding="1rem 1rem"
        />
      </GridItem>
      <GridItem colSpan={1}>
        <SettingsTable
          tableName={"Reinforcing Steel"}
          columnPadding="0.75rem 1rem"
        />
      </GridItem>
      <GridItem colSpan={1}>
        <SettingsTable
          tableName={"Stiffness Data"}
          columnPadding="0.75rem 1rem"
        />
      </GridItem>
      <GridItem colSpan={1}>
        <SettingsTable
          tableName={"Concrete Settings"}
          columnPadding="0.85rem 1rem"
        />
      </GridItem>
      <GridItem colSpan={1}>
        <SettingsTable
          tableName={"Cracked Section Modifier"}
          columnPadding="0.75rem 1rem"
        />
      </GridItem>
    </Grid>
  );
}

export default ConcreteDesign;
