import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import ColumnHome from "components/project/calculation types/column";
import { getCanvasDetails } from "store/selector";
import DefaultTable from "components/project/calculation types/DefaultTable";
import PunchingShear from "./calculation types/punchingShear";
import CrackWidth from "./calculation types/crackWidth";
import FlexureShear from "./calculation types/flexureShear";

const CalCTypes = {
    Column: ColumnHome,
    PunchingShear: PunchingShear,
    CrackWidth: CrackWidth,
    FlexureShearTorsion: FlexureShear,
    "Not Found": DefaultTable,
};

function ProjectTable(props) {
    const { selectedFile } = useSelector(getCanvasDetails);

    const key =
        (Object.keys(CalCTypes).includes(selectedFile?.calc_type) &&
            selectedFile?.calc_type) ||
        "Not Found";

    const TypeComponent = CalCTypes[key];
    return <TypeComponent {...props} />;
}

export default ProjectTable;
