import AGGridTable from "components/tests/DataTable";
import { AgGridReact } from "ag-grid-react";
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import "./section.module.css";
import { useState } from "react";
import { useEffect } from "react";
import { loadFileDetails, saveFileDetails } from "store/slices/entities/projectCanvas";
import { Box, Button, Grid, Text, Textarea } from "@chakra-ui/react";
import { useDispatch } from "react-redux";
import { apiCallBegin } from "store/api";
import store from "store/store";
import { useSelector } from "react-redux";
import { getEndCondition } from "store/selector";
import { loadEndCondition } from "store/slices/entities/endCondition";
import { getEndConditionValues } from "utils/formatter";
import { useCallback } from "react";
import { useRef } from "react";
import Table from "./Table";
import { BiRefresh, BiSave } from "react-icons/bi";

import { getCanvasDetails } from "store/selector";
import { updateFile } from "store/slices/entities/projectCanvas";

const SectionTest = ({ link }) => {

    const gridRef = useRef(null);

    const { updatedFileDetails } = useSelector(getCanvasDetails);
    const [remark, setRemark] = useState(link?.remarks);
    const onChange = (event) => {
        const latestUpdates = updatedFileDetails?.filter(
            (item) => item.url === link.url
        );
        const latest = latestUpdates.length > 0 ? { ...latestUpdates[0] } : { ...link }
        latest["remarks"] = event.target.value;
        updateFile(latest)
        setRemark(event.target.value);
    };


    useEffect(() => {
        loadEndCondition(link?.url);
    }, []);

    const handleSave = () => {
        saveFileDetails(link);
    };

    const buttons = [
        {
            text: "Calculate",
            leftIcon: <BiRefresh />,
            onClick: handleSave,
            // color: colors.gray,
        },
    ];


    return (
        <Box p={"1rem"}
            bgColor={"gray.100"}>
            <Grid
                gap={0}
                display={"flex"}
                justifyContent={{ sm: "flex-end" }}
                flexWrap={{ sm: "wrap", lg: "nowrap" }}
                alignItems={{
                    sm: "end",
                    lg: "end"
                }}
                css={{
                    "& button:last-of-type": {
                        borderTopRightRadius: "8px",
                        borderBottomRightRadius: "8px",
                    },
                    "& button:first-of-type": {
                        borderTopLeftRadius: "8px",
                        borderBottomLeftRadius: "8px",
                    },
                }}
            >
                <div>
                    {buttons?.map((btn, key) => (
                        <Button
                            {...btn}
                            key={key}
                            colorScheme='blue'
                            height="35px"
                        >
                            <Text>{btn.text}</Text>
                        </Button>
                    ))}
                </div>
            </Grid>
            <Box
                //p={"1rem"}
                // mt="6rem"
                bgColor={"gray.100"}
                overflow={"auto"}
                display={"flex"}
                justifyContent={"space-between"}
            >
                <Box display={"flex"} justifyContent={"space-between"} >
                    <Box width={"15rem"}>
                        <div style={{ padding: "4px" }}>
                            <span style={{ fontSize: "14px", fontWeight: "bold" }}>Section Details</span>
                        </div>
                        <div
                            className="ag-theme-quartz vertical-header-grid"
                            ref={gridRef}
                        >
                            <Table
                                headerless={true} tableName="information" data={link} font={"12px"} section="flexureShear"
                            />
                        </div>

                    </Box>

                </Box>

                <Box>
                    <div style={{ padding: "4px" }}>
                        <span style={{ fontSize: "14px", fontWeight: "bold" }}>Main Rebar</span>
                    </div>
                    <div
                        className="ag-theme-quartz vertical-header-grid"
                        ref={gridRef}
                    >
                        <Table
                            headerless={true} tableName="Main Rebar" data={link} font={"12px"} section="flexureShear"
                        />
                    </div>
                    <div style={{ padding: "4px" }}>
                        <span style={{ fontSize: "14px", fontWeight: "bold" }}>Transverse Rebar</span>
                    </div>

                    <Table
                        headerless={true} tableName="Transverse Rebar" data={link} font={"12px"} section="flexureShear"
                    />
                </Box>

                <Box>
                    <Box width={"15rem"} marginTop={"0rem"}>
                        <div style={{ padding: "4px" }}>
                            <span style={{ fontSize: "14px", fontWeight: "bold" }}>Material Properties</span>
                        </div>
                        <div
                            className="ag-theme-quartz vertical-header-grid"
                            ref={gridRef}
                        >
                            <Table
                                headerless={true} tableName="Material Properties" data={link} font={"12px"} section="flexureShear"
                            />
                        </div>
                    </Box>
                    <Box width={"15rem"}>
                        <div style={{ padding: "4px" }}>
                            <span style={{ fontSize: "14px", fontWeight: "bold" }}>Design Approval</span>
                        </div>
                        <div
                            className="ag-theme-quartz vertical-header-grid"
                            style={{ height: 150, }}
                            ref={gridRef}
                        >
                            <Table
                                headerless={true} tableName="Design Approval" data={link} font={"12px"} section="flexureShear"
                            />
                            <div style={{ padding: "4px" }}>
                                <span style={{ fontSize: "14px", fontWeight: "bold" }}>Remarks</span>
                            </div>
                            <Textarea height={10} name="remarks"
                                onChange={onChange}
                                value={remark}></Textarea>
                        </div>
                    </Box>
                </Box>

            </Box>
        </Box>
    )
}

export default SectionTest;