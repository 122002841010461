import { AgGridReact } from 'ag-grid-react';
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import "./style.css"
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getCanvasDetails, getLoadDesign } from "store/selector";
import {
    createRowLoadDesign,
    loadLoadDesign,
    removeRowLoadDesign,
    saveLoadDesign,
    saveBulkLoadDesign,
    updateLoadDesign,
} from "store/slices/entities/loadDesign";
import { loadFileDetails, saveFileDetails } from "store/slices/entities/projectCanvas";

import { loadDesign } from "utils/dataFormat";
import { useRef } from 'react';
import { AlertIcon, Box, Button, Grid, Input, Text } from '@chakra-ui/react';
import { colors } from "components/misc/colors";

import {
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverHeader,
    PopoverBody,
    PopoverFooter,
    PopoverArrow,
    PopoverCloseButton,
    PopoverAnchor,
} from '@chakra-ui/react'

import {
    BiExport,
    BiRefresh,
    BiReset,
    BiSearch,
    BiPlus,
    BiTrash,
    BiDownload,
    BiUpload,
    BiAlarmExclamation,
} from "react-icons/bi";
import { FiSend } from 'react-icons/fi';
import { useCallback } from 'react';
import { trueGray } from 'tailwindcss/colors';
import { loadEndCondition } from 'store/slices/entities/endCondition';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

import { FaExclamationTriangle } from 'react-icons/fa';

const AGGridTable = ({ link, headers, representation, rowDataOfSection }) => {

    const { info } = useSelector(getLoadDesign);
    const { selectedFile } = useSelector(getCanvasDetails)
    const gridRef = useRef(null);

    const [selectedRows, setSelectedRows] = useState([]);
    const [rowData, setRowData] = useState([]);

    const [fileUpload, setFileUpload] = useState(false);
    const [fileData, setFileData] = useState([]);

    const handleFileUpload = async (event) => {
        const file = event.target.files[0];
        if (file) {
            setFileUpload(true);
            const reader = new FileReader();
            reader.onload = async (e) => {
                const binaryStr = e.target.result;
                const workbook = XLSX.read(binaryStr, { type: 'binary' });

                const sheetName = workbook.SheetNames[0];
                const sheet = workbook.Sheets[sheetName];

                const jsonData = XLSX.utils.sheet_to_json(sheet);
                let transformedData;
                switch (representation) {
                    case "flexure-shear":
                        transformedData = jsonData.map(row => ({
                            no: row['No'],
                            load_no: row['Load No'],
                            mu: row['Mu(kN-m)'],
                            vu: row['Vu(kN)'],
                            tu: row['Tu(kN-m)']
                        }));
                        break;
                    case "column":
                        transformedData = jsonData.map(row => ({
                            No: row['No'],
                            load_no: row['Load No'],
                            n: row['N(kN)'],
                            t: row['T(kN-m)'],
                            vz: row['Vz(kN)'],
                            vy: row['Vy(kN)'],
                            my_top: row['MyTop(kN-m)'],
                            mz_top: row['MzTop(kN-m)'],
                            my_bottom: row['MyBtm(kN-m)'],
                            mz_bottom: row['MzBtm(kN-m)'],
                            bdns: row['(Bds)%'],
                            bds: row['(Bds)%']
                        }));
                        break;
                    case "punching-shear":
                        transformedData = jsonData.map(row => ({
                            No: row['No'],
                            load_no: row['Load No'],
                            vu: row['Vu(kN)'],
                            mx: row['Mx(kN-m)'],
                            my: row['My(kN-m)'],
                            vu0: row['Vu0(kN)'],
                        }));
                        break;
                    case "crackwidth":
                        transformedData = jsonData.map(row => ({
                            No: row['No'],
                            load_no: row['Load No'],
                            p: row['P(kN)'],
                            m_ser: row['Mservice(kN-m)'],
                        }));
                        break;
                    default:
                        break;
                }

                const url = rowData[0].row;
                const baseUrl = "https://tedaps.com/";
                const choppedUrl = url.replace(baseUrl, '');
                await setFileData(transformedData);
                setRowData(transformedData);
                await saveBulkLoadDesign(transformedData, choppedUrl);
                await setFileUpload(false);

            };
            reader.readAsBinaryString(file);
        }
    }

    const onGridReady = (params) => {
        gridRef.current = params.api;
    };

    const getChangedRows = (original, changed) => {
        const changedRows = [];

        const originalMap = new Map(original.map(item => [item.No, item]));

        for (const changedRow of changed) {
            const originalRow = originalMap.get(changedRow.No);

            if (!originalRow) continue;

            const isChanged = Object.keys(changedRow).some(key => originalRow[key] !== changedRow[key]);

            if (isChanged) {
                changedRows.push(changedRow);
            }
        }

        return changedRows;
    };

    const onChange = (params) => {
        if (params.api) {
            params.api.applyTransaction({ update: [params.data] });
        }
        setRowData(prevRowData =>
            prevRowData.map(row =>
                row === params.data ? { ...row, [params.colDef.field]: params.newValue } : row
            )
        );

    }

    const handleSubmit = async () => {

        if (gridRef.current && gridRef.current.api) {
            gridRef.current.api.setFocusedCell(0, 'columnKey');
            gridRef.current.api.stopEditing();
        }

        // const changedRows = getChangedRows(info?.load_rows, rowData);
        await saveLoadDesign(rowData, info?.url, info?.row);

        await loadEndCondition(link?.url)
        await loadFileDetails(selectedFile.link);

    };

    const onBtnExport = () => {
        if (!gridRef.current) {
            console.error('Grid API is not available');
            return;
        }
        const _rowData = [];
        gridRef.current.forEachNode(node => _rowData.push(node.data));
        const columnsToExclude = ['row', 'url'];

        const filteredData = rowData.map(row => {
            const filteredRow = { ...row };
            columnsToExclude.forEach(col => delete filteredRow[col]);
            return filteredRow;
        });

        const worksheet = XLSX.utils.json_to_sheet(filteredData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

        const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
        saveAs(blob, 'load-design.xlsx');
    }

    const onDelete = () => {
        const selectedNodes = gridRef.current.getSelectedNodes();
        const selectedData = selectedNodes.map(node => node.data);
        const remainingData = rowData.filter(row => !selectedData.includes(row));
        setRowData(remainingData);

        const deleteIds = selectedData.map((item) => {
            const urlSplit = item.url.split("/");
            return urlSplit[urlSplit.length - 2];
        });

        const url = selectedData.map((item) => {
            const row = item.row;
            return new URL(row).pathname.replace(/^\//, '');
        })

        switch (representation) {
            case "column":
                removeRowLoadDesign(deleteIds, url[0]);
                break;
            case "crackwidth":
                removeRowLoadDesign(deleteIds, url[0]);
                break;
            case "flexure-shear":
                removeRowLoadDesign(deleteIds, url[0]);
                break;
            case "punching-shear":
                removeRowLoadDesign(deleteIds, url[0]);
                break;
            default:
                break;
        }

        loadLoadDesign(link);
    };

    const [colDefs, setColDefs] = useState([]);

    const convertToRowData = (data, headers) => {

        const flattenHeaders = (headers) => {
            const fields = [];
            headers.forEach(header => {
                if (header.children) {
                    fields.push(...flattenHeaders(header.children));
                } else if (header.headerName != "") {
                    fields.push(header.field);
                }
            });
            return fields;
        };

        const flatHeaders = flattenHeaders(headers);

        return data.map(item => {
            const rowData = {};
            flatHeaders.forEach(field => {
                if (field && item.hasOwnProperty(field)) {
                    rowData[field] = item[field] === null ? null : String(item[field]);

                } else {
                    rowData[field] = item[field];
                }
            });
            return rowData;
        });
    };


    useEffect(() => {
        if (info?.load_rows) {
            const rows = convertToRowData(info?.load_rows, headers);
            setRowData(rows);
        }
        setColDefs(headers);
    }, [info]);

    useEffect(() => {
        loadLoadDesign(link);
    }, [link]);


    const buttons = [
        {
            text: "Add Row",
            leftIcon: <BiPlus />,
            onClick: () => {
                switch (representation) {
                    case "column":
                        createRowLoadDesign(info?.url, "api/column-load-row/");
                        break;
                    case "crackwidth":
                        createRowLoadDesign(info?.url, "api/crackwidth-load-row/");
                        break;
                    case "flexure-shear":
                        createRowLoadDesign(info?.url, "api/flexuresheartorsion-load-row/");
                        break;
                    case "punching-shear":
                        createRowLoadDesign(info?.url, "api/punchingshear-load-row/");
                        break;
                    default:
                        console.error("Unknown representation type");
                        break;
                }

            },
            // color: colors.add,
        },
        // {
        //     text: "Upload",
        //     leftIcon: <BiUpload />,
        //     onClick: () => {
        //         switch (representation) {
        //             case "flexure-shear":
        //                 break;
        //             default:
        //                 break;
        //         }
        //     }
        // },
        {
            text: "Refresh",
            leftIcon: <BiRefresh />,
            onClick: () => {
                loadLoadDesign(link);
            },
            // color: colors.refresh,
        },
        {
            text: "Calculate",
            leftIcon: <FiSend />,
            onClick: () => handleSubmit(),
            //color: colors.submit,
        },
        {
            text: "Delete",
            leftIcon: <BiTrash />,
            onClick: () => onDelete(),
            color: colors.delete,
        },
        {
            text: "Download",
            leftIcon: <BiDownload />,
            onClick: () => onBtnExport(),
            // color: colors.add,
        },
    ];

    const gridOptions = {
        animateRows: true,
        suppressRowHoverHighlight: false,
        headerHeight: 30,
        rowHeight: 30,
        suppressCellFocus: false,
        singleClickEdit: true,
        enableCellTextSelection: false,
        suppressRowClickSelection: true,
        suppressCellSelection: false,
        rowSelection: "multiple",
        enterNavigatesVerticallyAfterEdit: true,
        resizable: false,
        suppressMovable: false,
        stopEditingWhenCellsLoseFocus: true
    }

    const warnings = rowData && rowData.map((item) => {
        if (item?.warning != null && item.warning.length > 0) {
            return item.warning;
        } else {
            return null;
        }
    }).filter(warning => warning !== null);

    return (
        <div>
            <Grid
                gap={0}
                display={"flex"}
                justifyContent={{ sm: "space-between" }}
                flexWrap={{ sm: "wrap", lg: "nowrap" }}
                margin={{
                    sm: "1rem 0.2rem 1rem 0.2rem"
                }}
                alignItems={{
                    sm: "center",
                    lg: "center"
                }}
                css={{
                    "& button:last-of-type": {
                        borderTopRightRadius: "8px",
                        borderBottomRightRadius: "8px",
                    },
                    "& button:first-of-type": {
                        borderTopLeftRadius: "8px",
                        borderBottomLeftRadius: "8px",
                    },
                }}
            >
                <h3 style={{ fontSize: '16px', fontWeight: 'bold', color: '#010101' }}>
                    Forces of action and results Summary
                </h3>
                <div>
                    {warnings.length > 0 && (
                        <Popover>
                            <PopoverTrigger>
                                <Button outline={"1px solid #dcdddd"}
                                    color={"#f59e0b"}
                                    fontWeight={400}
                                    rounded={"0"}
                                    overflow={"hidden"}
                                    iconSpacing={0}
                                    size={"sm"}
                                    fontSize={{ sm: "14px", lg: "1rem" }}
                                    css={{
                                        width: "fit-content",
                                        "& p": {
                                            width: 0,
                                            opacity: 0,
                                            transition:
                                                "width 0.3s cubic-bezier(0.455, 0.030, 0.515, 0.955), opacity 0.3s cubic-bezier(0.455, 0.030, 0.515, 0.955)",
                                            // "transition-delay": "1s",
                                        },
                                        "&:hover": {
                                            "& p": {
                                                width: "70px",
                                                opacity: 1,
                                                // "transition-delay": "0s",
                                            },
                                        },
                                    }}><FaExclamationTriangle /> </Button>
                            </PopoverTrigger>
                            <PopoverContent>
                                <PopoverArrow />
                                <PopoverCloseButton />
                                <PopoverHeader>Warnings</PopoverHeader>
                                <PopoverBody maxH={250} overflowY={"scroll"}>
                                    {warnings.map((warning) => (
                                        <li>{warning}</li>
                                    ))}
                                </PopoverBody>
                            </PopoverContent>
                        </Popover>
                    )}
                    <Popover>
                        <PopoverTrigger>
                            <Button outline={"1px solid #dcdddd"}
                                fontWeight={400}
                                rounded={"0"}
                                overflow={"hidden"}
                                iconSpacing={0}
                                size={"sm"}
                                fontSize={{ sm: "14px", lg: "1rem" }}
                                css={{
                                    width: "fit-content",
                                    "& p": {
                                        width: 0,
                                        opacity: 0,
                                        transition:
                                            "width 0.3s cubic-bezier(0.455, 0.030, 0.515, 0.955), opacity 0.3s cubic-bezier(0.455, 0.030, 0.515, 0.955)",
                                        // "transition-delay": "1s",
                                    },
                                    "&:hover": {
                                        "& p": {
                                            width: "70px",
                                            opacity: 1,
                                            // "transition-delay": "0s",
                                        },
                                    },
                                }}><BiUpload /></Button>
                        </PopoverTrigger>
                        <PopoverContent>
                            <PopoverArrow />
                            <PopoverCloseButton />
                            <PopoverHeader>Upload Design</PopoverHeader>
                            <PopoverBody>
                                {!fileUpload && (
                                    <Input colorScheme='green' type="file" accept=".xlsx, .xls" onChange={handleFileUpload} placeholder='Upload File' />
                                )}
                                {fileUpload && (
                                    <>Uploading</>
                                )}
                                <Box fontSize='sm'> Supported file formats (.xlsx)</Box>
                            </PopoverBody>
                        </PopoverContent>
                    </Popover>
                    {buttons?.map((btn, key) => (
                        <Button
                            {...btn}
                            key={key}
                            outline={"1px solid #dcdddd"}
                            fontWeight={400}
                            rounded={"0"}
                            overflow={"hidden"}
                            iconSpacing={btn?.text === "" ? 0 : 0}
                            size={"sm"}
                            fontSize={{ sm: "14px", lg: "1rem" }}
                            css={{
                                width: "fit-content",
                                "& p": {
                                    width: 0,
                                    opacity: 0,
                                    transition:
                                        "width 0.3s cubic-bezier(0.455, 0.030, 0.515, 0.955), opacity 0.3s cubic-bezier(0.455, 0.030, 0.515, 0.955)",
                                    // "transition-delay": "1s",
                                },
                                "&:hover": {
                                    "& p": {
                                        width: "70px",
                                        opacity: 1,
                                        // "transition-delay": "0s",
                                    },
                                },
                            }}
                        >
                            <Text>{btn.text}</Text>
                        </Button>
                    ))}
                </div>
            </Grid>
            <div className="ag-theme-quartz" style={{ height: 300, width: '100%' }}>
                <AgGridReact
                    ref={gridRef}
                    gridOptions={gridOptions}
                    rowData={rowData}
                    columnDefs={colDefs}
                    onGridReady={onGridReady}
                    domLayout="normal"
                    onCellValueChanged={onChange}
                    onDelete={onDelete}
                    onSubmit={handleSubmit}
                    setSelectedRows={setSelectedRows}
                    rowStyle={{ cursor: "pointer" }}

                />
            </div>
        </div>
    )
}


export default AGGridTable;