import React, { useState } from "react";
import { DataTable as Table } from "primereact/datatable";
import { Column } from "primereact/column";

import ColumnBody from "./ColumnBody";
import { crackWidth, crackWidthSection } from "utils/dataFormat";
import { useEffect } from "react";

function DataTable({
    tableValue = [],
    columns,
    ExpandTemplate,
    onChange,
    style,
    columnGroup,
    selectedRows,
    setSelectedRows,
    formatObj = crackWidth,
    selection = false,
    expand = false,
    editable = true,
    rowReorder = false,
    columnFontSize = "16px",
    columnWidth = "60rem",
    columnPadding = "0.2rem 1rem",
    expandedRow,
    setExpandedRow,
    sidebarExpanded,
    ...otherProps
}) {
    const [editField, setEditField] = useState();

    const dynamicColumns = columns?.map((col, key) => (
        <Column
            key={key}
            field={col?.field || col}
            headerStyle={{ display: col?.header ? "" : "none", fontSize: otherProps?.font ? otherProps?.font : "16px" }}
            style={{
                textAlign: "left",
                fontSize: otherProps?.font ? otherProps?.font : columnFontSize,
                maxWidth: columnWidth,
                padding: columnPadding,
                wordWrap: "no-wrap"
            }}
            header={col?.header || ""}
            body={(obj) => (
                <ColumnBody
                    editable={editable}
                    rowData={obj}
                    col={col}
                    edit={editField}
                    setEdit={setEditField}
                    onChange={onChange}
                    formatObj={formatObj}
                />
            )}
        />
    ));

    return (
        <Table
            value={tableValue}
            selection={selectedRows}
            w={{ sm: "100%", lg: "0" }}
            style={{ ...style, textAlign: "center", marginBottom: "12px" }}
            onSelectionChange={(e) => setSelectedRows(e.value)}
            scrollDirection="horizontal"
            expandedRows={expandedRow}
            headerColumnGroup={columnGroup}
            rowExpansionTemplate={(e) => (
                <ExpandTemplate
                    rowData={e}
                    onExpandClose={setExpandedRow}
                    sidebarExpanded={sidebarExpanded}
                />
            )}
            onRowExpand={(e) => setExpandedRow([e.data])}
            onAuxClick={setEditField}
            {...otherProps}
        >
            {expand && (
                <Column expander style={{ width: "2px", paddingRight: "5px" }} />
            )}
            {selection && (
                <Column selectionMode="multiple" headerStyle={{ width: "0" }} />
            )}

            {rowReorder && (
                <Column rowReorder style={{ width: "3em" }} header="Order" />
            )}
            {dynamicColumns}
        </Table>
    );
}

export default DataTable;
