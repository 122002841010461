import { createSlice } from "@reduxjs/toolkit";
import routes from "api/routes";
import { apiCallBegin } from "store/api";

const slice = createSlice({
    name: "members",
    initialState: {
        list: [],
        loading: false,
    },
    reducers: {
        memberReceived: (members, action) => {
            members.list = action.payload;
        },
    },
});

const { memberReceived } = slice.actions;
export default slice.reducer;

export const loadMembers = () =>
    apiCallBegin({ url: routes.member.listOfMembers, method: "get", onSuccess: memberReceived.type });
