/* eslint-disable */
import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import { Icon, Link, Modal, useDisclosure } from "@chakra-ui/react";

import { Box, Flex, HStack, Text, useColorModeValue } from "@chakra-ui/react";
import { MdHome } from "react-icons/md";
import { AiFillContacts, AiOutlineFundProjectionScreen } from "react-icons/ai";
import { FaGraduationCap } from "react-icons/fa";
import { useSelector } from "react-redux";
// import {
//     changeProject
// } from "store/slices/entities/projectCanvas";
// import { resetCanvas } from "store/slices/entities/projectCanvas";

import { getCanvasDetails } from "store/selector"
export function SidebarLinks(props) {

    let location = useLocation();
    let activeColor = useColorModeValue("gray.700", "white");
    let inactiveColor = useColorModeValue(
        "secondaryGray.600",
        "secondaryGray.600"
    );
    let activeIcon = useColorModeValue("#217BF4", "white");
    let textColor = useColorModeValue("secondaryGray.500", "white");
    let brandColor = useColorModeValue("#217BF4", "brand.400");

    const { routes } = props;

    // verifies if routeName is the one active (in browser input)
    const activeRoute = (routeName) => {
        return location.pathname.includes(routeName);
    };

    const feedbackformModal = useDisclosure();

    const handleFeedback = () => {
        console.log("clicked to open feedback")
        return feedbackformModal.onOpen;
    }

    const renderUserLinks = (selectedProject) => (
        <Box>
            <HStack spacing="26px" py='5px' ps='10px'>
                <Flex w="100%" display="flex" flexDirection="column" alignItems='center' justifyContent='center'>
                    <NavLink to="/user/dashboard" onClick={() => { }}>
                        <Icon as={MdHome} width="25px" height="25px" color={(!selectedProject && activeRoute("/user/dashboard")) ? activeIcon : textColor} mb="26px" me='18px' />
                    </NavLink>
                    <NavLink to="/user/calculation">
                        <Icon as={AiOutlineFundProjectionScreen} width="25px" height="25px" color={activeRoute("/user/calculation") && selectedProject ? activeIcon : textColor} mb="26px" me='18px' />
                    </NavLink>
                    <NavLink to="/user/e-learning">
                        <Icon as={FaGraduationCap} width="25px" height="25px" color={activeRoute("/user/e-learning") ? activeIcon : textColor} mb="26px" me='18px' />
                    </NavLink>
                    <Flex onClick={handleFeedback}>
                        <Icon as={AiFillContacts} width="25px" height="25px" color={activeRoute("/user/contact") ? activeIcon : textColor} me='18px' />
                    </Flex>

                </Flex>
            </HStack>
        </Box>
    );

    const renderAdminLinks = (routes) => (
        routes.filter(route => route.layout === '/admin').map((route, index) => (
            <NavLink to={route.layout + route.path} key={index}>
                {route.icon ? (
                    <Box>
                        <HStack
                            spacing={activeRoute(route.path.toLowerCase()) ? "22px" : "26px"}
                            py='5px'
                            ps='10px'>
                            <Flex w='100%' alignItems='center' justifyContent='center'>
                                <Box
                                    color={activeRoute(route.path.toLowerCase()) ? activeIcon : textColor}
                                    me='18px'>
                                    {route.icon}
                                </Box>
                            </Flex>
                            <Box
                                h='36px'
                                w='4px'
                                bg={activeRoute(route.path.toLowerCase()) ? brandColor : "transparent"}
                                borderRadius='5px'
                            />
                        </HStack>
                    </Box>
                ) : (
                    <Box>
                        <HStack
                            spacing={
                                activeRoute(route.path.toLowerCase()) ? "22px" : "26px"
                            }
                            py='5px'
                            ps='10px'>
                            <Text
                                me='auto'
                                color={
                                    activeRoute(route.path.toLowerCase())
                                        ? activeColor
                                        : inactiveColor
                                }
                                fontWeight={
                                    activeRoute(route.path.toLowerCase()) ? "bold" : "normal"
                                }>
                            </Text>
                            <Box h='36px' w='4px' bg='brand.400' borderRadius='5px' />
                        </HStack>
                    </Box>
                )}
            </NavLink>
        ))
    );

    // this function creates the links from the secondary accordions (for example auth -> sign-in -> default)
    const createLinks = (routes) => {
        const { selectedProject } = useSelector(getCanvasDetails);

        return (
            <>
                {routes.some(route => route.layout === '/user') && renderUserLinks(selectedProject)}
                {renderAdminLinks(routes)}
                <Modal
                    modalHead="Add file"
                    isOpen={feedbackformModal.isOpen}
                    onClose={feedbackformModal.onClose}
                    confirmText={"Save"}
                />
            </>
        )


        // routes.map((route, index) => {
        //     if (route.layout == '/user') {
        //         return (
        //             (
        //                 <Box>
        //                     <HStack
        //                         spacing={"26px"}
        //                         py='5px'
        //                         ps='10px'>
        //                         <Flex w="100%" display={"flex"} flexDirection={"column"} alignItems={'center'} justifyContent={'center'} >
        //                             <NavLink to="/user/dashboard" onClick={() => { }}>
        //                                 <Icon as={MdHome} width="25px" height="25px" color={(!selectedProject && activeRoute("/user/dashboard")) ? activeIcon : textColor} mb="26px" me='18px' />
        //                             </NavLink>
        //                             <NavLink to="/user/calculation">
        //                                 <Icon as={AiOutlineFundProjectionScreen} width="25px" height="25px" color={activeRoute("/user/calculation") && selectedProject ? activeIcon : textColor} mb="26px" me='18px' />
        //                             </NavLink>
        //                             <NavLink to="/user/e-learning">
        //                                 <Icon as={FaGraduationCap} width="25px" height="25px" color={activeRoute("/user/e-learning") ? activeIcon : textColor} mb="26px" me='18px' />
        //                             </NavLink>
        //                             <NavLink to="/user/contact">
        //                                 <Icon as={AiFillContacts} width="25px" height="25px" color={activeRoute("/user/contact") ? activeIcon : textColor} me='18px' />
        //                             </NavLink>
        //                         </Flex>
        //                     </HStack>
        //                 </Box>
        //             )
        //         )
        //     } else if (route.layout == '/admin') {
        //         return (
        //             <NavLink to={route.layout + route.path} key={index}>
        //                 {route.icon ? (
        //                     <Box>
        //                         <HStack
        //                             spacing={
        //                                 activeRoute(route.path.toLowerCase()) ? "22px" : "26px"
        //                             }
        //                             py='5px'
        //                             ps='10px'>
        //                             <Flex w='100%' alignItems='center' justifyContent='center'>
        //                                 <Box
        //                                     color={
        //                                         activeRoute(route.path.toLowerCase())
        //                                             ? activeIcon
        //                                             : textColor
        //                                     }
        //                                     me='18px'>
        //                                     {route.icon}
        //                                 </Box>
        //                                 {/* <Text
        //                                                     me='auto'
        //                                                     fontSize={18}
        //                                                     color={
        //                                                         activeRoute(route.path.toLowerCase())
        //                                                         ? activeColor
        //                                                         : textColor
        //                                                     }
        //                                                     fontWeight={
        //                                                         activeRoute(route.path.toLowerCase())
        //                                                         ? "bold"
        //                                                         : "normal"
        //                                                     }>
        //                                                     {route.name}
        //                                                 </Text> */}
        //                             </Flex>
        //                             <Box
        //                                 h='36px'
        //                                 w='4px'
        //                                 bg={
        //                                     activeRoute(route.path.toLowerCase())
        //                                         ? brandColor
        //                                         : "transparent"
        //                                 }
        //                                 borderRadius='5px'
        //                             />
        //                         </HStack>
        //                     </Box>
        //                 ) : (
        //                     <Box>
        //                         <HStack
        //                             spacing={
        //                                 activeRoute(route.path.toLowerCase()) ? "22px" : "26px"
        //                             }
        //                             py='5px'
        //                             ps='10px'>
        //                             <Text
        //                                 me='auto'
        //                                 color={
        //                                     activeRoute(route.path.toLowerCase())
        //                                         ? activeColor
        //                                         : inactiveColor
        //                                 }
        //                                 fontWeight={
        //                                     activeRoute(route.path.toLowerCase()) ? "bold" : "normal"
        //                                 }>
        //                             </Text>
        //                             <Box h='36px' w='4px' bg='brand.400' borderRadius='5px' />
        //                         </HStack>
        //                     </Box>
        //                 )}
        //             </NavLink>
        //         )
        //     }
        // });

        // return routes.map((route, index) => {
        //     if (route.category) {
        //         return (
        //             <div key={index} >
        //                 <Text
        //                     fontSize={"md"}
        //                     color={activeColor}
        //                     fontWeight='bold'
        //                     mx='auto'
        //                     ps={{
        //                         sm: "10px",
        //                         xl: "16px",
        //                     }}
        //                     pt='18px'
        //                     pb='12px'
        //                 >
        //                     {route.name}
        //                 </Text>
        //                 {createLinks(route.items)}
        //             </div>
        //         );
        //     } else if (
        //         route.layout === "/user" || route.layout === '/admin'
        //     ) {
        //         return (
        //             <NavLink to={route.layout + route.path} key={index}>
        //                 {route.icon ? (
        //                     <Box>
        //                         <HStack
        //                             spacing={
        //                                 activeRoute(route.path.toLowerCase()) ? "22px" : "26px"
        //                             }
        //                             py='5px'
        //                             ps='10px'>
        //                             <Flex w='100%' alignItems='center' justifyContent='center'>
        //                                 <Box
        //                                     color={
        //                                         activeRoute(route.path.toLowerCase())
        //                                             ? activeIcon
        //                                             : textColor
        //                                     }
        //                                     me='18px'>
        //                                     {route.icon}
        //                                 </Box>
        //                                 {/* <Text
        //                                     me='auto'
        //                                     fontSize={18}
        //                                     color={
        //                                         activeRoute(route.path.toLowerCase())
        //                                         ? activeColor
        //                                         : textColor
        //                                     }
        //                                     fontWeight={
        //                                         activeRoute(route.path.toLowerCase())
        //                                         ? "bold"
        //                                         : "normal"
        //                                     }>
        //                                     {route.name}
        //                                 </Text> */}
        //                             </Flex>
        //                             <Box
        //                                 h='36px'
        //                                 w='4px'
        //                                 bg={
        //                                     activeRoute(route.path.toLowerCase())
        //                                         ? brandColor
        //                                         : "transparent"
        //                                 }
        //                                 borderRadius='5px'
        //                             />
        //                         </HStack>
        //                     </Box>
        //                 ) : (
        //                     <Box>
        //                         <HStack
        //                             spacing={
        //                                 activeRoute(route.path.toLowerCase()) ? "22px" : "26px"
        //                             }
        //                             py='5px'
        //                             ps='10px'>
        //                             <Text
        //                                 me='auto'
        //                                 color={
        //                                     activeRoute(route.path.toLowerCase())
        //                                         ? activeColor
        //                                         : inactiveColor
        //                                 }
        //                                 fontWeight={
        //                                     activeRoute(route.path.toLowerCase()) ? "bold" : "normal"
        //                                 }>
        //                             </Text>
        //                             <Box h='36px' w='4px' bg='brand.400' borderRadius='5px' />
        //                         </HStack>
        //                     </Box>
        //                 )}
        //             </NavLink>
        //         );
        //     }
        // });
    };
    //  BRAND
    return createLinks(routes);
}

export default SidebarLinks;
