import React, { useState, useRef } from "react";
import {
    Box,
    Grid,
    Flex,
    Accordion,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Text,
} from "@chakra-ui/react";
import NavMenu from "./NavMenu";
import { BiSave, BiRefresh, BiLinkExternal, BiDownload, BiPlus, BiX, BiChart } from "react-icons/bi";
import { Menubar } from "primereact/menubar";
import ExpanderTables from "./ExpanderTables";
import { Button } from "components/misc/Buttons";
import {
    loadFileDetails,
    saveFileDetails,
} from "store/slices/entities/projectCanvas";
import store from "store/store";
import RebarSettings from "components/project/calculation types/punchingShear/other/RebarSettings";
import LoadDesignSummary from "components/project/calculation types/punchingShear/other/LoadDesignSummary";
import EndCondition from "components/project/calculation types/punchingShear/other/EndCondition";
import ComponentModal from "components/common/ComponentModal";
import { colors } from "components/misc/colors";
import Load from "./other/Load";
import Rebar from "./other/Rebar";
import Graph from "./other/Graph";
import MainComponent from "./three/Main";
import { useEffect } from "react";
import End from "./other/End";
import AccordionComp from "./AccordionComp";

import { loadGeometry } from "store/slices/entities/loadGeometry";
import { useSelector } from "react-redux";
import { getLoadGeometry } from "store/selector";
import RebarTable from "components/tests/RebarTable";

function Expander({ rowData, onExpandClose, sidebarExpanded }) {
    const initial = { name: "", link: "" };
    const { data_points, loading } = useSelector(getLoadGeometry);

    const [show, setShow] = useState(false);

    const boxRef = useRef(null);
    const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

    const onShow = () => {
        setShow(!show);
    }

    useEffect(() => {
        // loadGeometry(
        //   "https://tedaps.com/api/columnrow/6e77ef3d-7680-44d0-a8f6-5c96c9693363/geometry/"
        // );

        loadGeometry(rowData?.geometry);
    }, [rowData]);

    useEffect(() => {
        if (boxRef.current) {
            const width = boxRef.current.clientWidth - 40;
            // width -= 20;
            const height = boxRef.current.clientHeight - 40;
            // height -= 20;
            setDimensions({ width, height });
            console.log(dimensions);
        }
    }, [sidebarExpanded]);

    const [modelObj, setModelObj] = useState(initial);

    const handleSave = () => {
        saveFileDetails(rowData);
    };

    const handleRefresh = () => {
        const { selectedFile } = store.getState().entities.projectCanvas;
        loadFileDetails(selectedFile?.link);
        onExpandClose();
    };

    const handleRebar = () => {
        setModelObj({ name: "Rebar Settings", link: rowData?.link_rebar });
    };

    const handleLoadDesign = () => {
        setModelObj({ name: "Load & Design Summary", link: "https://tedaps.com/api/punchingshear-load/6/" });
    };

    const handleEndCondition = () => {
        setModelObj({ name: "End Condition", link: rowData?.link_endcondition });
    };
    const handleGraph = () => {
        setModelObj({
            name: "Graph",
            // link: "https://tedaps.com/api/columnrow/6e77ef3d-7680-44d0-a8f6-5c96c9693363/graph_3d/",
            link: rowData?.graph_3d,
        });
    };

    const components = {
        "Rebar Settings": RebarSettings,
        "End Condition": EndCondition,
        "Load & Design Summary": LoadDesignSummary,
        Graph: Graph,
    };

    const buttons = [
        {
            text: "Submit",
            leftIcon: <BiSave />,
            onClick: handleSave,
            color: colors.gray,
        },
        // {
        //     text: "Download",
        //     icon: <BiDownload />,
        //     onClick: () => window.open(`${rowData?.url}download/`, "_blank"),
        //     color: colors.download,
        // },
        {
            text: "Refresh",
            leftIcon: <BiRefresh />,
            onClick: handleRefresh,
            color: colors.gray,
        },
        // {
        //     text: "Graph",
        //     leftIcon: <BiChart />,
        //     onClick: handleGraph,
        //     color: colors.gray,
        // },

        // {
        //   text: "Load & Design Summary",
        //   icon: <BiLinkExternal />,
        //   onClick: handleLoadDesign,
        //   color: colors.other,
        // },
    ];

    const ModalComponent = modelObj?.name && components[modelObj?.name];
    const itemRend = (item) => <>{item.component}</>;

    const component1 = <Load link={rowData?.link_load} />;
    const component2 = <RebarTable link={rowData?.link_rebar} />;
    const items = [
        {
            label: "button 1",
            items: [{ component: component1, template: itemRend }],
        },
        {
            label: "button 2",
            items: [{ component: component2, template: itemRend }],
        },
    ];

    return (
        <>
            <Box
                ref={boxRef}
                width={"100%"}
                minH="68vh"
                // p={"0.5rem"}
                overflow="hidden"
                borderRadius={15}
                position={"relative"}
            // border={"1px solid black"}
            >
                {show && (
                    <Box
                        zIndex={2}
                        position={"absolute"}
                        top={"2rem"}
                        left={"50%"}
                        transform={"translate(-50%)"}
                    >
                        <Grid
                            gap={0}
                            display={"flex"}
                            // justifyContent={{ sm: "center" }}
                            flexWrap={{ sm: "wrap", lg: "nowrap" }}
                            mt={{ sm: "1.5rem", xl: "0" }}
                            css={{
                                "& button:last-of-type": {
                                    borderTopRightRadius: "8px",
                                    borderBottomRightRadius: "8px",
                                },
                                "& button:first-of-type": {
                                    borderTopLeftRadius: "8px",
                                    borderBottomLeftRadius: "8px",
                                },
                            }}
                        >
                            {/* {buttons.map((btn, i) => (
                                <Button
                                    {...btn}
                                    key={i}
                                    outline={"1px solid #dcdddd"}
                                    fontWeight={400}
                                    rounded={"0"}
                                    overflow={"hidden"}
                                    bg={""}
                                    border={0}
                                    onClick={btn.onClick}
                                    iconSpacing={btn?.text === "" ? 0 : ""}
                                    size={"sm"}
                                    fontSize={{ sm: "14px", lg: "1rem" }}
                                    textColor="#495057"
                                    css={{
                                        width: "fit-content",
                                        "& p": {
                                            width: 0,
                                            opacity: 0,
                                            transition:
                                                "width 0.3s cubic-bezier(0.455, 0.030, 0.515, 0.955), opacity 0.3s cubic-bezier(0.455, 0.030, 0.515, 0.955)",
                                            // "transition-delay": "1s",
                                        },
                                        "&:hover": {
                                            "& p": {
                                                width: "70px",
                                                opacity: 1,
                                                // "transition-delay": "0s",
                                            },
                                        },
                                    }}
                                >
                                    <Text>{btn.text}</Text>
                                </Button>
                            ))} */}
                        </Grid>
                    </Box>
                )}

                <div
                    style={{ position: "absolute", zIndex: 1, left: "20px", top: "20px" }}
                >
                    {!loading ? (
                        <MainComponent
                            containerId={rowData.url}
                            wSize={{ width: dimensions.width, height: dimensions.height }}
                            mapData={data_points}
                        />
                    ) : (
                        <div
                            style={{
                                width: dimensions.width,
                                height: dimensions.height,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <h1>Loading Geometry...</h1>
                        </div>
                    )}
                </div>
                <Box
                    p={{ sm: "1rem", lg: "0" }}
                    mt={"2rem"}
                    ml={"1.5rem"}
                    h={dimensions.height}
                    w={dimensions.width} //1190 , 1325,1350
                >
                    {show && <ExpanderTables {...rowData} />}
                </Box>

                {/* <Box position={"absolute"} bottom={10} left={50} zIndex={99}>
                    <Button onClick={onShow}>
                        {show ? <BiX /> : <BiPlus />}
                    </Button>
                </Box> */}
                <Box position={"absolute"} bottom={10} right={360} zIndex={10}>
                    <Text fontSize={9} textColor={"#495057"}>Created by: {rowData?.created_by}</Text>
                    {rowData?.last_updated_by && <Text fontSize={9}>Modified: {rowData?.last_updated_by}</Text>}
                    <Text fontSize={9}>Revision: {rowData?.version}</Text>
                </Box>

                <Box
                    position={"absolute"}
                    bottom={"2rem"}
                    left={"50%"}
                    transform={"translateX(-50%)"}
                    zIndex={20}
                >
                    <NavMenu
                        showDetails={onShow}
                        linkRebar={rowData?.link_rebar}
                        linkLoad={rowData?.link_load}
                        endCondition={rowData}
                    />
                </Box>
            </Box>
            {modelObj?.name && (
                <ComponentModal modalHead={modelObj?.name} isOpen={modelObj?.name}>
                    <ModalComponent
                        link={modelObj?.link}
                        onClose={() => setModelObj(initial)}
                    />
                </ComponentModal>
            )}
        </>
    );
}

export default Expander;
